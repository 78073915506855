// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
// import { APIData, org } from '../authentication/APIData';
// import CustomSelect from 'assets/theme/components/Select/CustomSelect';
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftButton from "components/SoftButton";
// import SoftAvatar from "components/SoftAvatar";
// import Swal from "sweetalert2";
// import PropTypes from 'prop-types';

// const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));

// const TeacherInfo = ({ name }) => (
//     <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
//         <SoftBox mr={2}>
//             <SoftAvatar
//                 src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
//                 alt={name}
//                 size="sm"
//                 variant="rounded"
//             />
//         </SoftBox>
//         <SoftBox display="flex" flexDirection="column">
//             <SoftTypography variant="button" fontWeight="medium">
//                 {name}
//             </SoftTypography>
//         </SoftBox>
//     </SoftBox>
// );

// TeacherInfo.propTypes = {
//     name: PropTypes.string.isRequired,
// };

// const classLevelsEnum = [
//     "FIRST", "SECOND", "THIRD", "FOURTH", "FIFTH",
//     "SIXTH", "SEVENTH", "EIGHTH", "NINTH", "TENTH"
// ];

// const AssignTeacherDialog = ({ open, onClose, onSubmitSuccess }) => {
//     const [courses, setCourses] = useState([]);
//     const [employees, setEmployees] = useState([]);
//     const [assignments, setAssignments] = useState([]);
//     const [existingAssignments, setExistingAssignments] = useState([]);

//     const fetchCourses = async () => {
//         try {
//             const response = await axios.get(`${APIData.api}courses/?org=${org}`, {
//                 headers: APIData.headers,
//             });
//             setCourses(response.data.map(course => ({
//                 courseId: course.course_id,
//                 courseName: course.course_id,
//             })));
//         } catch (error) {
//             console.error("Error fetching courses:", error.response || error.message);
//         }
//     };

//     const fetchEmployees = async () => {
//         try {
//             const response = await axios.get(`${APIData.api}employee/details?org=${org}`, {
//                 headers: APIData.headers,
//             });
//             setEmployees(response.data.map(employee => ({
//                 teacherId: employee.employee_id,
//                 teacherName: employee.name,
//             })));
//         } catch (error) {
//             console.error("Error fetching employees:", error.response || error.message);
//         }
//     };

//     const fetchExistingAssignments = async () => {
//         try {
//             const response = await axios.get(`${APIData.api}courses/by-org?org=${org}`, {
//                 headers: APIData.headers,
//             });
//             const existing = response.data.map(assignment => ({
//                 ...assignment,
//                 id: assignment.id || 0,
//             }));
//             setExistingAssignments(existing);
//         } catch (error) {
//             console.error("Error fetching existing assignments:", error.response || error.message);
//         }
//     };

//     useEffect(() => {
//         if (open) {
//             Promise.all([fetchCourses(), fetchEmployees(), fetchExistingAssignments()]);
//         }
//     }, [open]);

//     useEffect(() => {
//         if (courses.length > 0 && existingAssignments.length > 0) {
//             const storedAssignments = JSON.parse(localStorage.getItem('submittedAssignments')) || [];

//             const unassignedCourses = courses.filter(course => {
//                 const isAssigned = existingAssignments.some(assignment =>
//                     assignment.courseId === course.courseId &&
//                     assignment.teacherId &&
//                     assignment.classLevels
//                 );
//                 return !isAssigned;
//             });

//             setAssignments(prevAssignments => {
//                 const newAssignments = unassignedCourses.map(course => {
//                     const storedAssignment = storedAssignments.find(a => a.courseId === course.courseId);

//                     return storedAssignment || {
//                         id: 0,
//                         courseId: course.courseId,
//                         courseName: course.courseId,
//                         teacherId: '',
//                         teacherName: '',
//                         classLevels: '',
//                         org: org,
//                         createDateTime: new Date().toISOString(),
//                         createdBy: sessiondetails.userName,
//                         updateDateTime: new Date().toISOString(),
//                         updatedBy: sessiondetails.userName,
//                     };
//                 });
//                 return newAssignments;
//             });
//         }
//     }, [courses, existingAssignments]);

//     const handleAssignmentChange = (courseId, field, selectedOption) => {
//         setAssignments(prevAssignments => {
//             return prevAssignments.map(assignment => {
//                 if (assignment.courseId === courseId) {
//                     const updatedAssignment = { ...assignment };

//                     if (field === 'teacher') {
//                         updatedAssignment.teacherId = selectedOption?.value || '';
//                         updatedAssignment.teacherName = selectedOption?.label || '';
//                     } else if (field === 'classLevel') {
//                         updatedAssignment.classLevels = selectedOption?.value || '';
//                     }

//                     return updatedAssignment;
//                 }
//                 return assignment;
//             });
//         });
//     };

//     const handleSubmit = async () => {
//         const validAssignments = assignments
//             .filter(assignment => assignment.teacherId && assignment.classLevels)
//             .map(assignment => ({
//                 ...assignment,
//                 createDateTime: new Date().toISOString(),
//                 updateDateTime: new Date().toISOString(),
//             }));

//         if (validAssignments.length === 0) {
//             Swal.fire({
//                 icon: "info",
//                 title: "Please select both teacher and class level for at least one course.",
//                 showConfirmButton: false,
//                 timer: 1500
//             });
//             return;
//         }

//         try {
//             await Promise.all(validAssignments.map(assignment =>
//                 axios.post(`${APIData.api}courses`, assignment, {
//                     headers: APIData.headers,
//                 })
//             ));
//             Swal.fire({
//                 icon: "success",
//                 title: "All assignments submitted successfully!",
//                 showConfirmButton: false,
//                 timer: 1500
//             });
//             localStorage.setItem('submittedAssignments', JSON.stringify(validAssignments));
//             await fetchExistingAssignments();
//             onSubmitSuccess && onSubmitSuccess(validAssignments);
//             onClose();
//         } catch (error) {
//             console.error("Error submitting assignments:", error);
//             Swal.fire({
//                 icon: "error",
//                 title: "Oops...",
//                 text: "Failed to submit assignments",
//                 footer: error.response?.data,
//             });
//         }
//     };

//     const unassignedCourses = assignments.filter(assignment => {
//         return !existingAssignments.some(existing =>
//             existing.courseId === assignment.courseId &&
//             existing.teacherId &&
//             existing.classLevels
//         );
//     });

//     return (
//         <Dialog
//             open={open}
//             onClose={onClose}
//             maxWidth="lg"
//             fullWidth
//             PaperProps={{
//                 style: {
//                     height: '70vh',
//                     maxHeight: '70vh',
//                 }
//             }}
//         >
//             <DialogTitle>
//                 <SoftTypography
//                     display="flex"
//                     justifyContent="center"
//                     alignItems="center"
//                     variant="body1"
//                 >
//                     Assign Teachers to Courses
//                 </SoftTypography>
//             </DialogTitle>
//             <DialogContent>
//                 {unassignedCourses.length === 0 ? (
//                     <SoftBox
//                         display="flex"
//                         justifyContent="center"
//                         alignItems="center"
//                         height="100%"
//                     >
//                         <SoftTypography variant="h5" color="secondary">
//                             There are no new courses available to assign teachers and grades.
//                         </SoftTypography>
//                     </SoftBox>
//                 ) : (
//                     <SoftBox sx={{ overflowY: 'auto', maxHeight: '50vh' }}>
//                         <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//                             <thead>
//                                 <tr>
//                                     <th style={{ textAlign: 'left', padding: '12px', borderBottom: '1px solid #ddd' }}>Course ID</th>
//                                     <th style={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd' }}>Teacher</th>
//                                     <th style={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd' }}>Class Level</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {unassignedCourses.map(course => (
//                                     <tr key={course.courseId}>
//                                         <td style={{ padding: '12px', borderBottom: '1px solid #ddd' }}>
//                                             <TeacherInfo name={course.courseId} />
//                                         </td>
//                                         <td style={{ padding: '12px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
//                                             <CustomSelect
//                                                 name={`teacher-${course.courseId}`}
//                                                 options={employees.map(teacher => ({
//                                                     value: teacher.teacherId,
//                                                     label: teacher.teacherName,
//                                                 }))}
//                                                 value={course.teacherId || null}
//                                                 onChange={(selectedOption) =>
//                                                     handleAssignmentChange(course.courseId, 'teacher', selectedOption)
//                                                 }
//                                                 placeholder="Select Teacher"
//                                             // isSearchable={true}
//                                             // menuPortalTarget={document.body}
//                                             // styles={{
//                                             //     menuPortal: (base) => ({ ...base, zIndex: 9999 }),
//                                             //     control: (base) => ({
//                                             //         ...base,
//                                             //         width: '200px',
//                                             //         minHeight: '40px',
//                                             //     }),
//                                             // }}
//                                             />
//                                         </td>
//                                         <td style={{ padding: '12px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
//                                             <CustomSelect
//                                                 name={`class-level-${course.courseId}`}
//                                                 options={classLevelsEnum.map(level => ({
//                                                     value: level,
//                                                     label: level
//                                                 }))}
//                                                 value={course.classLevels || null}
//                                                 onChange={(selectedOption) =>
//                                                     handleAssignmentChange(course.courseId, 'classLevel', selectedOption)
//                                                 }
//                                                 placeholder="Select Grade"
//                                             // isSearchable={true}
//                                             // menuPortalTarget={document.body}
//                                             // styles={{
//                                             //     menuPortal: (base) => ({ ...base, zIndex: 9999 }),
//                                             //     control: (base) => ({
//                                             //         ...base,
//                                             //         width: '180px',
//                                             //         minHeight: '40px',
//                                             //     }),
//                                             // }}
//                                             />
//                                         </td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </SoftBox>
//                 )}
//             </DialogContent>
//             <DialogActions>
//                 <SoftButton
//                     variant="text"
//                     color="secondary"
//                     onClick={onClose}
//                 >
//                     Cancel
//                 </SoftButton>
//                 <SoftButton
//                     variant="gradient"
//                     color="info"
//                     onClick={handleSubmit}
//                     disabled={unassignedCourses.length === 0}
//                 >
//                     Submit Assignments
//                 </SoftButton>
//             </DialogActions>
//         </Dialog>
//     );
// };

// AssignTeacherDialog.propTypes = {
//     open: PropTypes.bool.isRequired,
//     onClose: PropTypes.func.isRequired,
//     onSubmitSuccess: PropTypes.func,
// };

// export default AssignTeacherDialog;


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
// import { APIData, org } from '../authentication/APIData';
// import CustomSelect from 'assets/theme/components/Select/CustomSelect';
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftButton from "components/SoftButton";
// import SoftAvatar from "components/SoftAvatar";
// import Swal from "sweetalert2";
// import PropTypes from 'prop-types';

// const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));

// const TeacherInfo = ({ name }) => (
//     <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
//         <SoftBox mr={2}>
//             <SoftAvatar
//                 src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
//                 alt={name}
//                 size="sm"
//                 variant="rounded"
//             />
//         </SoftBox>
//         <SoftBox display="flex" flexDirection="column">
//             <SoftTypography variant="button" fontWeight="medium">
//                 {name}
//             </SoftTypography>
//         </SoftBox>
//     </SoftBox>
// );

// TeacherInfo.propTypes = {
//     name: PropTypes.string.isRequired,
// };

// const classLevelsEnum = [
//     "FIRST", "SECOND", "THIRD", "FOURTH", "FIFTH",
//     "SIXTH", "SEVENTH", "EIGHTH", "NINTH", "TENTH","PROFESSIONAL1","PROFESSIONAL2"
// ];

// const AssignTeacherDialog = ({ open, onClose, onSubmitSuccess }) => {
//     const [courses, setCourses] = useState([]);
//     const [employees, setEmployees] = useState([]);
//     const [assignments, setAssignments] = useState([]);
//     const [existingAssignments, setExistingAssignments] = useState([]);

//     const fetchCourses = async () => {
//         try {
//             const response = await axios.get(`${APIData.api}courses/?org=${org}`, {
//                 headers: APIData.headers,
//             });
            
//             // Process courses based on course_type
//             const processedCourses = response.data.flatMap(course => {
//                 if (course.course_type === "ACADEMICS") {
//                     // Create separate entries for each subject
//                     return course.gradeSubjects.map(subject => ({
//                         courseId: `${course.course_id}_${subject}`,
//                         courseName: `${subject}_${course.courseName}`,
//                         subject: subject,
//                         courseType: course.course_type
//                     }));
//                 } else {
//                     // Return single entry for professional courses
//                     return [{
//                         courseId: course.course_id,
//                         courseName: course.courseName,
//                         subject: course.courseName,
//                         courseType: course.course_type
//                     }];
//                 }
//             });
            
//             setCourses(processedCourses);
//         } catch (error) {
//             console.error("Error fetching courses:", error.response || error.message);
//         }
//     };

//     const fetchEmployees = async () => {
//         try {
//             const response = await axios.get(`${APIData.api}employee/empdetailsByDepartment?department=ACADEMY&org=${org} `, {
//                 headers: APIData.headers,
//             });
//             setEmployees(response.data.map(employee => ({
//                 teacherId: employee.employee_id,
//                 teacherName: employee.name,
//             })));
//         } catch (error) {
//             console.error("Error fetching employees:", error.response || error.message);
//         }
//     };

//     const fetchExistingAssignments = async () => {
//         try {
//             const response = await axios.get(`${APIData.api}courses/by-org?org=${org}`, {
//                 headers: APIData.headers,
//             });
//             const existing = response.data.map(assignment => ({
//                 ...assignment,
//                 id: assignment.id || 0,
//             }));
//             setExistingAssignments(existing);
//         } catch (error) {
//             console.error("Error fetching existing assignments:", error.response || error.message);
//         }
//     };

//     useEffect(() => {
//         if (open) {
//             Promise.all([fetchCourses(), fetchEmployees(), fetchExistingAssignments()]);
//         }
//     }, [open]);

//     useEffect(() => {
//         if (courses.length > 0 && existingAssignments.length > 0) {
//             const storedAssignments = JSON.parse(localStorage.getItem('submittedAssignments')) || [];

//             const unassignedCourses = courses.filter(course => {
//                 const isAssigned = existingAssignments.some(assignment =>
//                     assignment.courseId === course.courseId &&
//                     assignment.teacherId &&
//                     assignment.classLevels
//                 );
//                 return !isAssigned;
//             });

//             setAssignments(prevAssignments => {
//                 const newAssignments = unassignedCourses.map(course => ({
//                     id: 0,
//                     courseId: course.courseId,
//                     courseName: course.courseName,
//                     subject: course.subject,
//                     courseType: course.courseType,
//                     teacherId: '',
//                     teacherName: '',
//                     classLevels: '',
//                     org: org,
//                     createDateTime: new Date().toISOString(),
//                     createdBy: sessiondetails.userName,
//                     updateDateTime: new Date().toISOString(),
//                     updatedBy: sessiondetails.userName,
//                 }));
//                 return newAssignments;
//             });
//         }
//     }, [courses, existingAssignments]);

//     const handleAssignmentChange = (courseId, field, selectedOption) => {
//         setAssignments(prevAssignments => {
//             return prevAssignments.map(assignment => {
//                 if (assignment.courseId === courseId) {
//                     const updatedAssignment = { ...assignment };

//                     if (field === 'teacher') {
//                         updatedAssignment.teacherId = selectedOption?.value || '';
//                         updatedAssignment.teacherName = selectedOption?.label || '';
//                     } else if (field === 'classLevel') {
//                         updatedAssignment.classLevels = selectedOption?.value || '';
//                     }

//                     return updatedAssignment;
//                 }
//                 return assignment;
//             });
//         });
//     };

//     const handleSubmit = async () => {
//         const validAssignments = assignments
//             .filter(assignment => assignment.teacherId && assignment.classLevels)
//             .map(assignment => ({
//                 ...assignment,
//                 createDateTime: new Date().toISOString(),
//                 updateDateTime: new Date().toISOString(),
//             }));

//         if (validAssignments.length === 0) {
//             Swal.fire({
//                 icon: "info",
//                 title: "Please select both teacher and class level for at least one course.",
//                 showConfirmButton: false,
//                 timer: 1500
//             });
//             return;
//         }

//         try {
//             await Promise.all(validAssignments.map(assignment =>
//                 axios.post(`${APIData.api}courses`, assignment, {
//                     headers: APIData.headers,
//                 })
//             ));
//             Swal.fire({
//                 icon: "success",
//                 title: "All assignments submitted successfully!",
//                 showConfirmButton: false,
//                 timer: 1500
//             });
//             localStorage.setItem('submittedAssignments', JSON.stringify(validAssignments));
//             await fetchExistingAssignments();
//             onSubmitSuccess && onSubmitSuccess(validAssignments);
//             onClose();
//         } catch (error) {
//             console.error("Error submitting assignments:", error);
//             Swal.fire({
//                 icon: "error",
//                 title: "Oops...",
//                 text: "Failed to submit assignments",
//                 footer: error.response?.data,
//             });
//         }
//     };

//     const unassignedCourses = assignments.filter(assignment => {
//         return !existingAssignments.some(existing =>
//             existing.courseId === assignment.courseId &&
//             existing.teacherId &&
//             existing.classLevels
//         );
//     });

//     return (
//         <Dialog
//             open={open}
//             onClose={onClose}
//             maxWidth="lg"
//             fullWidth
//             PaperProps={{
//                 style: {
//                     height: '70vh',
//                     maxHeight: '70vh',
//                 }
//             }}
//         >
//             <DialogTitle>
//                 <SoftTypography
//                     display="flex"
//                     justifyContent="center"
//                     alignItems="center"
//                     variant="body1"
//                 >
//                     Assign Teachers to Courses
//                 </SoftTypography>
//             </DialogTitle>
//             <DialogContent>
//                 {unassignedCourses.length === 0 ? (
//                     <SoftBox
//                         display="flex"
//                         justifyContent="center"
//                         alignItems="center"
//                         height="100%"
//                     >
//                         <SoftTypography variant="h5" color="secondary">
//                             There are no new courses available to assign teachers and grades.
//                         </SoftTypography>
//                     </SoftBox>
//                 ) : (
//                     <SoftBox sx={{ overflowY: 'auto', maxHeight: '50vh' }}>
//                         <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//                             <thead>
//                                 <tr>
//                                     <th style={{ textAlign: 'left', padding: '12px', borderBottom: '1px solid #ddd' }}>Course Name</th>
//                                     <th style={{ textAlign: 'left', padding: '12px', borderBottom: '1px solid #ddd' }}>Subject</th>
//                                     <th style={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd' }}>Teacher</th>
//                                     <th style={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd' }}>Class Level</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {unassignedCourses.map(course => (
//                                     <tr key={course.courseId}>
//                                         <td style={{ padding: '12px', borderBottom: '1px solid #ddd' }}>
//                                             <TeacherInfo name={course.courseName} />
//                                         </td>
//                                         <td style={{ padding: '12px', borderBottom: '1px solid #ddd' }}>
//                                             {course.subject}
//                                         </td>
//                                         <td style={{ padding: '12px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
//                                             <CustomSelect
//                                                 name={`teacher-${course.courseId}`}
//                                                 options={employees.map(teacher => ({
//                                                     value: teacher.teacherId,
//                                                     label: teacher.teacherName,
//                                                 }))}
//                                                 value={course.teacherId || null}
//                                                 onChange={(selectedOption) =>
//                                                     handleAssignmentChange(course.courseId, 'teacher', selectedOption)
//                                                 }
//                                                 placeholder="Select Teacher"
//                                             />
//                                         </td>
//                                         <td style={{ padding: '12px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
//                                             <CustomSelect
//                                                 name={`class-level-${course.courseId}`}
//                                                 options={classLevelsEnum.map(level => ({
//                                                     value: level,
//                                                     label: level
//                                                 }))}
//                                                 value={course.classLevels || null}
//                                                 onChange={(selectedOption) =>
//                                                     handleAssignmentChange(course.courseId, 'classLevel', selectedOption)
//                                                 }
//                                                 placeholder="Select Grade"
//                                             />
//                                         </td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </SoftBox>
//                 )}
//             </DialogContent>
//             <DialogActions>
//                 <SoftButton
//                     variant="text"
//                     color="secondary"
//                     onClick={onClose}
//                 >
//                     Cancel
//                 </SoftButton>
//                 <SoftButton
//                     variant="gradient"
//                     color="info"
//                     onClick={handleSubmit}
//                     disabled={unassignedCourses.length === 0}
//                 >
//                     Submit Assignments
//                 </SoftButton>
//             </DialogActions>
//         </Dialog>
//     );
// };

// AssignTeacherDialog.propTypes = {
//     open: PropTypes.bool.isRequired,
//     onClose: PropTypes.func.isRequired,
//     onSubmitSuccess: PropTypes.func,
// };

// export default AssignTeacherDialog;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { APIData, org } from '../authentication/APIData';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftAvatar from "components/SoftAvatar";
import Swal from "sweetalert2";
import PropTypes from 'prop-types';

const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));

const TeacherInfo = ({ name }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                alt={name}
                size="sm"
                variant="rounded"
            />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
                {name}
            </SoftTypography>
        </SoftBox>
    </SoftBox>
);

TeacherInfo.propTypes = {
    name: PropTypes.string.isRequired,
};

const classLevelsEnum = [
    "FIRST", "SECOND", "THIRD", "FOURTH", "FIFTH",
    "SIXTH", "SEVENTH", "EIGHTH", "NINTH", "TENTH","PROFESSIONAL1","PROFESSIONAL2"
];

const AssignTeacherDialog = ({ open, onClose, onSubmitSuccess }) => {
    const [courses, setCourses] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [existingAssignments, setExistingAssignments] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchCourses = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${APIData.api}courses/?org=${org}`, {
                headers: APIData.headers,
            });
            console.log("Courses API response:", response.data);
            
            // Process courses based on course_type
            const processedCourses = response.data.flatMap(course => {
                if (course.course_type === "ACADEMICS") {
                    // Create separate entries for each subject
                    return course.gradeSubjects.map(subject => ({
                        courseId: `${course.course_id}_${subject}`,
                        courseName: `${subject}_${course.courseName}`,
                        subject: subject,
                        courseType: course.course_type
                    }));
                } else {
                    // Return single entry for professional courses
                    return [{
                        courseId: course.course_id,
                        courseName: course.courseName,
                        subject: course.courseName,
                        courseType: course.course_type
                    }];
                }
            });
            
            console.log("Processed courses:", processedCourses);
            setCourses(processedCourses);
        } catch (error) {
            console.error("Error fetching courses:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`${APIData.api}employee/empdetailsByDepartment?department=ACADEMY&org=${org} `, {
                headers: APIData.headers,
            });
            setEmployees(response.data.map(employee => ({
                teacherId: employee.employee_id,
                teacherName: employee.name,
            })));
        } catch (error) {
            console.error("Error fetching employees:", error);
        }
    };

    const fetchExistingAssignments = async () => {
        try {
            const response = await axios.get(`${APIData.api}courses/by-org?org=${org}`, {
                headers: APIData.headers,
            });
            console.log("Existing assignments API response:", response.data);
            const existing = response.data.map(assignment => ({
                ...assignment,
                id: assignment.id || 0,
            }));
            setExistingAssignments(existing);
        } catch (error) {
            console.error("Error fetching existing assignments:", error);
        }
    };

    useEffect(() => {
        if (open) {
            fetchCourses();
            fetchEmployees();
            fetchExistingAssignments();
        }
    }, [open]);

    useEffect(() => {
        if (courses.length > 0) {
            // Update assignments based on courses and existing assignments
            updateAssignments();
        }
    }, [courses, existingAssignments]);

    const updateAssignments = () => {
        if (courses.length === 0) return;

        console.log("Updating assignments with courses:", courses.length);
        console.log("Existing assignments:", existingAssignments.length);

        // Find unassigned courses by comparing with existing assignments
        const newAssignments = courses.filter(course => {
            // Check if this course is already assigned
            const isAlreadyAssigned = existingAssignments.some(existing => 
                existing.courseId === course.courseId && 
                existing.teacherId && 
                existing.classLevels
            );
            
            return !isAlreadyAssigned;
        }).map(course => ({
            id: 0,
            courseId: course.courseId,
            courseName: course.courseName,
            subject: course.subject,
            courseType: course.courseType,
            teacherId: '',
            teacherName: '',
            classLevels: '',
            org: org,
            createDateTime: new Date().toISOString(),
            createdBy: sessiondetails.userName,
            updateDateTime: new Date().toISOString(),
            updatedBy: sessiondetails.userName,
        }));

        console.log("New unassigned courses for assignments:", newAssignments.length);
        setAssignments(newAssignments);
    };

    const handleAssignmentChange = (courseId, field, selectedOption) => {
        setAssignments(prevAssignments => {
            return prevAssignments.map(assignment => {
                if (assignment.courseId === courseId) {
                    const updatedAssignment = { ...assignment };

                    if (field === 'teacher') {
                        updatedAssignment.teacherId = selectedOption?.value || '';
                        updatedAssignment.teacherName = selectedOption?.label || '';
                    } else if (field === 'classLevel') {
                        updatedAssignment.classLevels = selectedOption?.value || '';
                    }

                    return updatedAssignment;
                }
                return assignment;
            });
        });
    };

    const handleSubmit = async () => {
        const validAssignments = assignments
            .filter(assignment => assignment.teacherId && assignment.classLevels)
            .map(assignment => ({
                ...assignment,
                createDateTime: new Date().toISOString(),
                updateDateTime: new Date().toISOString(),
            }));

        if (validAssignments.length === 0) {
            Swal.fire({
                icon: "info",
                title: "Please select both teacher and class level for at least one course.",
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        try {
            await Promise.all(validAssignments.map(assignment =>
                axios.post(`${APIData.api}courses`, assignment, {
                    headers: APIData.headers,
                })
            ));
            Swal.fire({
                icon: "success",
                title: "All assignments submitted successfully!",
                showConfirmButton: false,
                timer: 1500
            });
            localStorage.setItem('submittedAssignments', JSON.stringify(validAssignments));
            await fetchExistingAssignments();
            onSubmitSuccess && onSubmitSuccess(validAssignments);
            onClose();
        } catch (error) {
            console.error("Error submitting assignments:", error);
            // Fix for the TypeError: Cannot read properties of undefined (reading 'data')
            const errorMessage = error.response?.data || "An unknown error occurred";
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to submit assignments",
                footer: errorMessage,
            });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                style: {
                    height: '70vh',
                    maxHeight: '70vh',
                }
            }}
        >
            <DialogTitle>
                <SoftTypography
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    variant="body1"
                >
                    Assign Teachers to Courses
                </SoftTypography>
            </DialogTitle>
            <DialogContent>
                {loading ? (
                    <SoftBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                    >
                        <SoftTypography variant="h5" color="info">
                            Loading courses...
                        </SoftTypography>
                    </SoftBox>
                ) : assignments.length === 0 ? (
                    <SoftBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                    >
                        <SoftTypography variant="h5" color="secondary">
                            There are no new courses available to assign teachers and grades.
                        </SoftTypography>
                    </SoftBox>
                ) : (
                    <SoftBox sx={{ overflowY: 'auto', maxHeight: '50vh' }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'left', padding: '12px', borderBottom: '1px solid #ddd' }}>Course Name</th>
                                    <th style={{ textAlign: 'left', padding: '12px', borderBottom: '1px solid #ddd' }}>Subject</th>
                                    <th style={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd' }}>Teacher</th>
                                    <th style={{ textAlign: 'center', padding: '12px', borderBottom: '1px solid #ddd' }}>Class Level</th>
                                </tr>
                            </thead>
                            <tbody>
                                {assignments.map(course => (
                                    <tr key={course.courseId}>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #ddd' }}>
                                            <TeacherInfo name={course.courseName} />
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #ddd' }}>
                                            {course.subject}
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                                            <CustomSelect
                                                name={`teacher-${course.courseId}`}
                                                options={employees.map(teacher => ({
                                                    value: teacher.teacherId,
                                                    label: teacher.teacherName,
                                                }))}
                                                value={course.teacherId || null}
                                                onChange={(selectedOption) =>
                                                    handleAssignmentChange(course.courseId, 'teacher', selectedOption)
                                                }
                                                placeholder="Select Teacher"
                                            />
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                                            <CustomSelect
                                                name={`class-level-${course.courseId}`}
                                                options={classLevelsEnum.map(level => ({
                                                    value: level,
                                                    label: level
                                                }))}
                                                value={course.classLevels || null}
                                                onChange={(selectedOption) =>
                                                    handleAssignmentChange(course.courseId, 'classLevel', selectedOption)
                                                }
                                                placeholder="Select Grade"
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SoftBox>
                )}
            </DialogContent>
            <DialogActions>
                <SoftButton
                    variant="text"
                    color="secondary"
                    onClick={onClose}
                >
                    Cancel
                </SoftButton>
                <SoftButton
                    variant="gradient"
                    color="info"
                    onClick={handleSubmit}
                    disabled={assignments.length === 0}
                >
                    Submit Assignments
                </SoftButton>
            </DialogActions>
        </Dialog>
    );
};

AssignTeacherDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmitSuccess: PropTypes.func,
};

export default AssignTeacherDialog;