import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './ZoomUtilitySelector.css';
import { 
  Container, 
  Typography, 
  TextField,
  Paper, 
  FormControl, 
  Link,
  Divider,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import HelpIcon from '@mui/icons-material/Help';
import SecurityIcon from '@mui/icons-material/Security';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { org, APIData } from 'authentication/APIData';
import axios from 'axios';
import Swal from 'sweetalert2';

// Custom Zoom icon component
const ZoomIcon = () => (
  <svg viewBox="0 0 24 24" width="60" height="60" fill="#2D8CFF">
    <path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm6.64,15.83a.81.81,0,0,1-.83.83H6.19a.81.81,0,0,1-.83-.83V9.33a.81.81,0,0,1,.83-.83h11.62a.81.81,0,0,1,.83.83ZM13.77,12a.79.79,0,0,1-.26.58L11,14.41a.83.83,0,0,1-.57.22.87.87,0,0,1-.31-.06A.79.79,0,0,1,9.6,13.9V10.09a.79.79,0,0,1,.52-.67.81.81,0,0,1,.88.16l2.51,1.83A.79.79,0,0,1,13.77,12Z"/>
  </svg>
);

const ZoomUtilitySelector = ({ onSubmit, submitRef, orgId = '' }) => {
  const [apiCredentials, setApiCredentials] = useState({
    accountId: '',
    accessToken: ''
  });
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  
  const apiFormRef = useRef(null);
  
  // Key change: Made the submitZoomCredentials function return a Promise
  const submitZoomCredentials = () => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      console.log("Submitting Zoom credentials...");
      
      const payload = {
        account: apiCredentials.accountId,
        org: org,
        ourZoom: true,
        token: apiCredentials.accessToken
      };
      
      axios.post(`${APIData.api}zoom/subscription/details`, payload, { headers: APIData.headers })
        .then(response => {
          console.log("API call successful:", response);
          Swal.fire({
            icon: "success",
            title: "Successfully Updated",
            showConfirmButton: false,
            timer: 1500
          });
          setLoading(false);
          resolve(apiCredentials);
        })
        .catch(error => {
          console.error("Error response:", error);
          Swal.fire({
            icon: "error",
            title: "Update Error",
            showConfirmButton: false,
            timer: 1500,
            text: error.response ? error.response.data : "Failed to submit Zoom credentials"
          });
          setLoading(false);
          reject(error);
        });
    });
  };
  
  useEffect(() => {
    if (submitRef) {
      submitRef.current = {
        click: async () => {
          console.log("Submit ref clicked");
          const isValid = apiCredentials.accountId && 
                          apiCredentials.accessToken;
          
          if (isValid) {
            try {
              console.log("Credentials valid, submitting...");
              const result = await submitZoomCredentials();
              console.log("Submission complete, notifying parent...");
              onSubmit(true, result);
            } catch (error) {
              console.error("Submission failed:", error);
              onSubmit(false, null);
            }
          } else {
            console.log("Invalid credentials");
            setNotification({
              open: true,
              message: 'Please fill in all required fields',
              severity: 'error'
            });
            onSubmit(false, null);
          }
        }
      };
    }
  }, [submitRef, apiCredentials, onSubmit]);

  const handleApiInputChange = (e) => {
    const { name, value } = e.target;
    setApiCredentials(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCloseNotification = () => {
    setNotification(prev => ({
      ...prev,
      open: false
    }));
  };

  useEffect(() => {
    // Add animation to API form when it appears
    setTimeout(() => {
      const form = document.querySelector('.api-form-container');
      if (form) form.classList.add('animate-in');
    }, 100);
  }, []);
  
  return (
    <div className="zoom-utility-selector">
      <Container maxWidth="lg" className="zoom-container">
        <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Paper elevation={3} className="api-form-container">
          <div className="api-form-header">
            <VpnKeyIcon className="api-header-icon" />
            <Typography variant="h4" gutterBottom align="center">
              Upload Your Zoom API Credentials
            </Typography>
            <Typography variant="body1" align="center" className="api-header-subtitle">
              Connect your existing Zoom account securely
            </Typography>
          </div>
          
          <div className="api-form-content">
            <div className="help-link-container">
              <Link 
                href="https://developers.zoom.us/docs/api/#getting-started" 
                target="_blank" 
                rel="noopener noreferrer"
                className="api-help-link"
              >
                <HelpIcon className="help-icon" />
                <Typography variant="body2">
                  How to get your Zoom API key and secret
                </Typography>
              </Link>
            </div>
            
            <div className="security-message">
              <SecurityIcon className="security-icon" />
              <Typography variant="body2">
                Your API credentials are encrypted with enterprise-grade security
              </Typography>
            </div>
            
            <Divider className="api-divider" />
            
            <FormControl fullWidth className="form-field">
              <Typography variant="subtitle1" gutterBottom>
                Zoom Account Id
              </Typography>
              <div className="input-container">
                <TextField
                  fullWidth
                  placeholder="Enter your Zoom Account ID"
                  variant="outlined"
                  type="email"
                  className="api-input"
                  name="accountId"
                  value={apiCredentials.accountId}
                  onChange={handleApiInputChange}
                  required
                  disabled={loading}
                />
              </div>
            </FormControl>
            
            <FormControl fullWidth className="form-field">
              <Typography variant="subtitle1" gutterBottom>
                Zoom Access Token
              </Typography>
              <div className="input-container">
                <TextField
                  fullWidth
                  placeholder="Enter your Zoom Access Token"
                  variant="outlined"
                  type="password"
                  className="api-input"
                  name="accessToken"
                  value={apiCredentials.accessToken}
                  onChange={handleApiInputChange}
                  required
                  disabled={loading}
                />
                <LockIcon className="input-icon" />
              </div>
            </FormControl>
          </div>
        </Paper>
      </Container>
      
      <Snackbar 
        open={notification.open} 
        autoHideDuration={6000} 
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          variant="filled"
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

ZoomUtilitySelector.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitRef: PropTypes.shape({
    current: PropTypes.any
  }).isRequired,
  orgId: PropTypes.string
};

export default ZoomUtilitySelector;