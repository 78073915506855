import React, { useState } from 'react';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import { Card, Grid, FormControl, Tooltip, FormHelperText } from "@mui/material";
import { APIData } from 'authentication/APIData';
import axios from 'axios';
import { toast } from "react-toastify";
import { CircularProgress, Backdrop } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import * as AiIcons from "react-icons/ai";
import Swal from 'sweetalert2';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { org } from 'authentication/APIData';
import CustomSelect from "assets/theme/components/Select/CustomSelect";

function ProductForm() {
    const [pname, setPname] = useState('');
    const [ptype, setPtype] = useState('');
    const [psubtype, setPsubtype] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState("");
    const [softwareUrl, setSoftwareUrl] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Product type options
    const productTypeOptions = [
        { value: 'HARDWARE', label: 'Hardware' },
        { value: 'SOFTWARE', label: 'Software' }
    ];

    const handleProductTypeChange = (selectedOption, actionMeta) => {
        if (selectedOption) {
            setPtype(selectedOption.value);
            // Reset software URL when changing product type
            if (selectedOption.value === 'HARDWARE') {
                setSoftwareUrl('');
            }
            // Clear any previous type-related errors
            setErrors(prev => ({ ...prev, ptype: '' }));
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 1000000) {
                Swal.fire({
                    icon: 'error',
                    title: 'Image Too Large',
                    text: 'Image should be less than 1MB.',
                });
                setImage(null);
                setImagePreview("");
                setErrors(prev => ({ ...prev, image: 'File too large. Max file size is 1MB.' }));
                return;
            }

            setImage(file);
            setImagePreview(URL.createObjectURL(file));
            setErrors(prev => ({ ...prev, image: '' }));
        }
    };

    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = error => reject(error);
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};

        // Validation
        const nameRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s]*$/;
        if (!pname) {
            newErrors.pname = "Product name is required.";
        } else if (!nameRegex.test(pname)) {
            newErrors.pname = "Product name cannot contain only numbers and must include at least one letter.";
        }

        if (!ptype) {
            newErrors.ptype = "Product type is required.";
        }

        if (description.length > 255) {
            newErrors.description = "Description cannot exceed 255 characters.";
        }

        if (ptype === 'SOFTWARE' && !softwareUrl) {
            newErrors.softwareUrl = "Software URL is required for software products.";
        }

        if (!image) {
            newErrors.image = "Product image is required.";
        }

        // Set errors if any
        setErrors(newErrors);

        // If there are errors, stop submission
        if (Object.keys(newErrors).length > 0) {
            return;
        }

        try {
            setLoading(true);

            // Convert image to base64
            const base64Image = await toBase64(image);

            const url = `${APIData.api}product-catalogue/`;
            const formData = {
                pid: "0",
                pname: pname,
                ptype: ptype,
                psubtype: psubtype,
                image: base64Image,
                softwareUrl: ptype === 'SOFTWARE' ? softwareUrl : null,
                description: description,
                org: org
            };
            
            await axios.post(url, formData, { headers: APIData.headers });

            Swal.fire({
                icon: "success",
                title: "Product Added Successfully",
                showConfirmButton: false,
                timer: 1500
            });
            setTimeout(() => {
                navigate("/product-list");
            }, 1500);

        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: "error",
                title: "Failed to Add Product",
                text: error.response?.data?.message || "An error occurred while adding the product.",
                confirmButtonText: "OK",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4" mt={3}>
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Tooltip title="Close" arrow>
                                <Link to="/product-list">
                                    <AiIcons.AiOutlineCloseCircle />
                                </Link>
                            </Tooltip>
                            <SoftTypography mb={2} variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Add New Product
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox component="form" role="form" onSubmit={handleSubmit} px={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Product Name *</SoftTypography>
                                        <FormControl fullWidth error={!!errors.pname}>
                                            <SoftInput
                                                id="pname"
                                                name="pname"
                                                value={pname}
                                                onChange={(e) => {
                                                    setPname(e.target.value);
                                                    setErrors(prev => ({ ...prev, pname: '' }));
                                                }}
                                            />
                                            {errors.pname && (
                                                <FormHelperText>{errors.pname}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Product Type *</SoftTypography>
                                        <FormControl fullWidth error={!!errors.ptype}>
                                            <CustomSelect
                                                options={productTypeOptions}
                                                placeholder="Select Product Type"
                                                name="ptype"
                                                onChange={handleProductTypeChange}
                                                value={ptype}
                                            />
                                            {errors.ptype && (
                                                <FormHelperText>{errors.ptype}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Product Subtype</SoftTypography>
                                        <FormControl fullWidth>
                                            <SoftInput
                                                id="psubtype"
                                                name="psubtype"
                                                value={psubtype}
                                                onChange={(e) => setPsubtype(e.target.value)}
                                            />
                                        </FormControl>
                                    </SoftBox>
                                </Grid>

                                {ptype === 'SOFTWARE' && (
                                    <Grid item xs={12}>
                                        <SoftBox>
                                            <SoftTypography variant="h6">Software URL *</SoftTypography>
                                            <FormControl fullWidth error={!!errors.softwareUrl}>
                                                <SoftInput
                                                    id="softwareUrl"
                                                    name="softwareUrl"
                                                    value={softwareUrl}
                                                    onChange={(e) => {
                                                        setSoftwareUrl(e.target.value);
                                                        setErrors(prev => ({ ...prev, softwareUrl: '' }));
                                                    }}
                                                />
                                                {errors.softwareUrl && (
                                                    <FormHelperText>{errors.softwareUrl}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </SoftBox>
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Description *</SoftTypography>
                                        <FormControl fullWidth error={!!errors.description}>
                                            <SoftInput
                                                id="description"
                                                name="description"
                                                value={description}
                                                onChange={(e) => {
                                                    setDescription(e.target.value);
                                                    setErrors(prev => ({ ...prev, description: '' }));
                                                }}
                                                multiline
                                                rows={4}
                                            />
                                            {errors.description && (
                                                <FormHelperText>{errors.description}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox display="flex" flexDirection="column" alignItems="start">
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="upload-image"
                                            type="file"
                                            onChange={handleImageChange}
                                        />
                                        <label htmlFor="upload-image">
                                            <SoftButton variant="contained" component="span">
                                                Upload Product Image
                                            </SoftButton>
                                        </label>
                                        {imagePreview && (
                                            <SoftBox mt={2} display="flex" justifyContent="center" alignItems="center">
                                                <img 
                                                    src={imagePreview} 
                                                    alt="Product Preview" 
                                                    style={{ width: '200px', height: 'auto', objectFit: 'contain' }} 
                                                />
                                            </SoftBox>
                                        )}
                                        {errors.image && (
                                            <FormHelperText error>{errors.image}</FormHelperText>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox display="flex" justifyContent="center" mt={4} mb={2}>
                                        <SoftButton 
                                            type="submit" 
                                            variant="gradient" 
                                            color="info" 
                                            fullWidth 
                                            disabled={loading}
                                        >
                                            {loading ? "Submitting..." : "Add Product"}
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default ProductForm;