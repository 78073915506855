/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// // @mui material components
// import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// // Soft UI Dashboard React components
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";

// function MiniStatisticsCard({ bgColor, title, count, percentage, icon, direction }) {
//   return (
//     <Card>
//       <SoftBox bgColor={bgColor} variant="gradient">
//         <SoftBox p={2}>
//           <Grid container alignItems="center">
//             {direction === "left" ? (
//               <Grid item>
//                 <SoftBox
//                   variant="gradient"
//                   bgColor={bgColor === "white" ? icon.color : "white"}
//                   color={bgColor === "white" ? "white" : "dark"}
//                   width="3rem"
//                   height="3rem"
//                   borderRadius="md"
//                   display="flex"
//                   justifyContent="center"
//                   alignItems="center"
//                   shadow="md"
//                 >
//                   <Icon fontSize="small" color="inherit">
//                     {icon.component}
//                   </Icon>
//                 </SoftBox>
//               </Grid>
//             ) : null}
//             <Grid item xs={8}>
//               <SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
//                 <SoftTypography
//                   variant="button"
//                   color={bgColor === "white" ? "text" : "white"}
//                   opacity={bgColor === "white" ? 1 : 0.7}
//                   textTransform="capitalize"
//                   fontWeight={title.fontWeight}
//                 >
//                   {title.text}
//                 </SoftTypography>
//                 <SoftTypography
//                   variant="h5"
//                   fontWeight="bold"
//                   color={bgColor === "white" ? "dark" : "white"}
//                 >
//                   {count}{" "}
//                   <SoftTypography variant="button" color={percentage.color} fontWeight="bold">
//                     {percentage.text}
//                   </SoftTypography>
//                 </SoftTypography>
//               </SoftBox>
//             </Grid>
//             {direction === "right" ? (
//               <Grid item xs={4}>
//                 <SoftBox
//                   variant="gradient"
//                   bgColor={bgColor === "white" ? icon.color : "white"}
//                   color={bgColor === "white" ? "white" : "dark"}
//                   width="3rem"
//                   height="3rem"
//                   marginLeft="auto"
//                   borderRadius="md"
//                   display="flex"
//                   justifyContent="center"
//                   alignItems="center"
//                   shadow="md"
//                 >
//                   <Icon fontSize="small" color="inherit">
//                     {icon.component}
//                   </Icon>
//                 </SoftBox>
//               </Grid>
//             ) : null}
//           </Grid>
//         </SoftBox>
//       </SoftBox>
//     </Card>
//   );
// }

// // Setting default values for the props of MiniStatisticsCard
// MiniStatisticsCard.defaultProps = {
//   bgColor: "white",
//   title: {
//     fontWeight: "medium",
//     text: "",
//   },
//   percentage: {
//     color: "success",
//     text: "",
//   },
//   direction: "right",
// };

// // Typechecking props for the MiniStatisticsCard
// MiniStatisticsCard.propTypes = {
//   bgColor: PropTypes.oneOf([
//     "white",
//     "primary",
//     "secondary",
//     "info",
//     "success",
//     "warning",
//     "error",
//     "dark",
//   ]),
//   title: PropTypes.PropTypes.shape({
//     fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
//     text: PropTypes.string,
//   }),
//   count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//   percentage: PropTypes.shape({
//     color: PropTypes.oneOf([
//       "primary",
//       "secondary",
//       "info",
//       "success",
//       "warning",
//       "error",
//       "dark",
//       "white",
//     ]),
//     text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   }),
//   icon: PropTypes.shape({
//     color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
//     component: PropTypes.node.isRequired,
//   }).isRequired,
//   direction: PropTypes.oneOf(["right", "left"]),
// };

// export default MiniStatisticsCard;

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function MiniStatisticsCard({ 
  bgColor, 
  title, 
  count, 
  percentage, 
  icon, 
  direction,
  // New navigation props
  showNavigation,
  onPrevClick,
  onNextClick 
}) {
  const CardContent = () => (
    <Grid container alignItems="center">
      {direction === "left" ? (
        <Grid item>
          <SoftBox
            variant="gradient"
            bgColor={bgColor === "white" ? icon.color : "white"}
            color={bgColor === "white" ? "white" : "dark"}
            width="2rem"
            height="2rem"
            borderRadius="md"
            display="flex"
            justifyContent="center"
            alignItems="center"
            shadow="md"
          >
            <Icon fontSize="small" color="inherit">
              {icon.component}
            </Icon>
          </SoftBox>
        </Grid>
      ) : null}
      <Grid item xs={8}>
        <SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
          <SoftTypography
            variant="button"
            color={bgColor === "white" ? "text" : "white"}
            opacity={bgColor === "white" ? 1 : 0.7}
            textTransform="capitalize"
            fontWeight={title.fontWeight}
          >
            {title.text}
          </SoftTypography>
          <SoftTypography
            variant="h5"
            fontWeight="bold"
            color={bgColor === "white" ? "dark" : "white"}
          >
            {count}{" "}
            
          </SoftTypography>
          <SoftTypography variant="button" color={percentage.color} fontWeight="bold">
              {percentage.text}
            </SoftTypography>
        </SoftBox>
      </Grid>
      {direction === "right" ? (
        <Grid item xs={4}>
          <SoftBox
            variant="gradient"
            bgColor={bgColor === "white" ? icon.color : "white"}
            color={bgColor === "white" ? "white" : "dark"}
            width="2rem"
            height="2rem"
            marginLeft="auto"
            borderRadius="md"
            display="flex"
            justifyContent="center"
            alignItems="center"
            shadow="md"
          >
            <Icon fontSize="small" color="inherit">
              {icon.component}
            </Icon>
          </SoftBox>
        </Grid>
      ) : null}
    </Grid>
  );

  return (
    <Card>
      <SoftBox bgColor={bgColor} variant="gradient">
        {showNavigation ? (
          <SoftBox p={2}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <IconButton onClick={onPrevClick} size="small">
                  <Icon sx={{ fontSize: 20 }}>chevron_left</Icon>
                </IconButton>
              </Grid>
              <Grid item xs>
                <CardContent />
              </Grid>
              <Grid item>
                <IconButton onClick={onNextClick} size="small">
                  <Icon sx={{ fontSize: 20 }}>chevron_right</Icon>
                </IconButton>
              </Grid>
            </Grid>
          </SoftBox>
        ) : (
          <SoftBox p={2}>
            <CardContent />
          </SoftBox>
        )}
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
MiniStatisticsCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "medium",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
  direction: "right",
  showNavigation: false,
  onPrevClick: () => {},
  onNextClick: () => {},
};

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),
  showNavigation: PropTypes.bool,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
};

export default MiniStatisticsCard;