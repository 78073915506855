// import React from 'react';
// import {
//     Box,
//     Container,
//     Typography,
//     Button,
//     CssBaseline,
//     Grid,
//     Card
// } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import SoftBox from 'components/SoftBox';
// import SoftTypography from 'components/SoftTypography';
// import SocialSidebar from './SocialSidebar';

// // Import images
// import asat from 'assets/images/asat.png';
// import shikshak from 'assets/images/shikashak.jpg';
// import bnb from 'assets/images/bnb.png';
// import smarterportal from 'assets/images/smarterportal.webp';
// import Navbarrr from './Navbarrr';
// import Footers from './Footers';
// import PageLayout from 'examples/LayoutContainers/PageLayout';
// import SoftButton from 'components/SoftButton';

// // Product data with navigation paths and buttons
// const products = [
//     {
//         id: 1,
//         title: 'SkillShare Pro',
//         description: 'SkillShare Pro connects learners with expert tutors for online, offline, and home learning services. Access a vast network of educators across academic subjects, professional courses, hobbies, and more. Learn at your own pace with personalized guidance from qualified instructors who are passionate about helping you achieve your goals.',
//         imageSrc: shikshak,
//         buttonText: 'Explore Platform',
//         path: '/skillshare-pro',
//         externalLink: 'https://play.google.com/store/apps/details?id=com.shikshapro.care4edu.release&pcampaignid=web_share' // Optional external link
//     },
//     {
//         id: 2,
//         title: 'BNB Square Mobile App',
//         description: 'No more endless searching! Find boutiques, tailors, bridal dressmakers, and makeup artists near you. We\'re your trusted guide to local fashion and beauty, connecting you directly with talented professionals ready to bring your vision to life. Our curated network ensures quality service while our intuitive interface makes booking appointments effortless.',
//         imageSrc: bnb,
//         buttonText: 'Download App',
//         path: '/bnb-square',
//         externalLink: 'https://play.google.com/store/apps/details?id=com.bnbsquare.care4edu.release&pcampaignid=web_share'
//     },
//     {
//         id: 3,
//         title: 'ASAT - Online Assessment Portal',
//         description: 'The ASAT scholarship test is available to 9th and 11th grade students, featuring 30 questions to be completed within a 30-minute timeframe. For 9th-grade participants, questions cover Mathematics, Science, and Social Science, while 11th-grade students focus on Physics, Chemistry, and Mathematics for 1st PUC. Our secure platform ensures fair assessment with immediate results and detailed performance analytics.',
//         imageSrc: asat,
//         buttonText: 'Take Assessment',
//         path: '/asat-portal',
//         externalLink: 'https://portal.care4edu.com/#/asat'
//     },
//     {
//         id: 4,
//         title: 'Smarter Portal',
//         description: 'Our comprehensive application provides a centralized platform for students and faculty alike, offering a range of features. From detailed timetables ensuring efficient scheduling to faculty profiles providing insight into instructors expertise, we have it covered. Users can access holiday lists to plan ahead, explore available courses, and delve into additional resources seamlessly. Experience education management reimagined.',
//         imageSrc: smarterportal,
//         buttonText: 'Access Portal',
//         path: '/smarter-portal',
//         externalLink: 'https://portal.care4edu.com'
//     },
// ];

// // ProductsDisplay component with inline styling instead of ThemeProvider
// const ProductsDisplay = () => {
//     const navigate = useNavigate();

//     // Function to handle button clicks
//     const handleProductClick = (product) => {
//         // Check if it's an external link or internal route
//         if (product.externalLink) {
//             // Open external link in a new tab
//             window.open(product.externalLink, '_blank');
//         } else {
//             // Navigate to internal route
//             navigate(product.path);
//         }
//     };

//     // Define colors that were previously in the theme
//     const colors = {
//         primary: {
//             main: '#1e90ff',
//             light: '#4da6ff',
//             dark: '#0066cc',
//         },
//         secondary: {
//             main: '#6a0dad',
//             light: '#8c2be0',
//             dark: '#4b0082',
//         },
//         background: {
//             default: '#f8f9fa',
//             paper: '#ffffff',
//         },
//         text: {
//             primary: '#333333',
//             secondary: '#555555',
//         },
//     };

//     return (
//         <PageLayout>
//             <CssBaseline />
//             <Navbarrr/>
//             <SocialSidebar />
//             <Box sx={{ 
//                 flexGrow: 1, 
//                 backgroundColor: colors.background.default,
//                 minHeight: '100vh' 
//             }}>
                
//                 <Container maxWidth="lg" sx={{ py: 8 }}>
//                     {products.map((product, index) => (
//                         <Card 
//                             key={product.id} 
//                             elevation={0} 
//                             sx={{ 
//                                 mb: 8,
//                                 backgroundColor: index % 2 === 0 ? 'rgba(30,144,255,0.03)' : 'rgba(106,13,173,0.03)',
//                                 overflow: 'visible',
//                                 borderRadius: 4,
//                                 boxShadow: '0 8px 24px rgba(0,0,0,0.08)',
//                                 transition: 'transform 0.3s ease, box-shadow 0.3s ease',
//                                 '&:hover': {
//                                     transform: 'translateY(-4px)',
//                                     boxShadow: '0 12px 30px rgba(0,0,0,0.12)',
//                                 },
//                             }}
//                         >
//                             <Grid 
//                                 container 
//                                 spacing={6} 
//                                 direction={index % 2 === 0 ? 'row' : 'row-reverse'}
//                                 sx={{ p: 3 }}
//                             >
//                                 {/* Enhanced Image Section */}
//                                 <Grid item xs={12} md={5} sx={{ 
//                                     display: 'flex', 
//                                     alignItems: 'center', 
//                                     justifyContent: 'center',
//                                     position: 'relative'
//                                 }}>
//                                     <Box
//                                         component="img"
//                                         src={product.imageSrc}
//                                         alt={product.title}
//                                         sx={{
//                                             width: '100%',
//                                             maxWidth: '340px',
//                                             height: 'auto',
//                                             aspectRatio: '4/3',
//                                             objectFit: 'cover',
//                                             borderRadius: 4,
//                                             boxShadow: '0 20px 40px rgba(0,0,0,0.15)',
//                                             transform: index % 2 === 0 ? 'rotate(-2deg)' : 'rotate(2deg)',
//                                             border: '8px solid white',
//                                             zIndex: 2,
//                                             cursor: 'pointer', // Make image clickable
//                                         }}
//                                         onClick={() => handleProductClick(product)} // Make image clickable
//                                     />
//                                     {/* Decorative background element */}
//                                     <Box sx={{
//                                         position: 'absolute',
//                                         width: '70%',
//                                         height: '70%',
//                                         borderRadius: '50%',
//                                         background: index % 2 === 0 
//                                             ? 'radial-gradient(circle, rgba(30,144,255,0.2) 0%, rgba(30,144,255,0) 70%)'
//                                             : 'radial-gradient(circle, rgba(106,13,173,0.2) 0%, rgba(106,13,173,0) 70%)',
//                                         zIndex: 1,
//                                     }} />
//                                 </Grid>

//                                 {/* Enhanced Content Section */}
//                                 <Grid item xs={12} md={7} sx={{ 
//                                     display: 'flex', 
//                                     flexDirection: 'column', 
//                                     justifyContent: 'center',
//                                     py: 4
//                                 }}>
//                                     <Typography 
//                                         variant="h2" 
//                                         component="h2" 
//                                         sx={{ 
//                                             mb: 3, 
//                                             fontWeight: 'bold',
//                                             fontSize: {
//                                                 xs: '1.8rem',
//                                                 md: '2.2rem'
//                                             },
//                                             letterSpacing: '-0.01em',
//                                             fontFamily: "'Segoe UI', 'Roboto', 'Arial', sans-serif",
//                                             color: index % 2 === 0 ? colors.primary.dark : colors.secondary.dark,
//                                             position: 'relative',
//                                             cursor: 'pointer', // Make title clickable
//                                             '&:after': {
//                                                 content: '""',
//                                                 position: 'absolute',
//                                                 bottom: '-12px',
//                                                 left: 0,
//                                                 width: '60px',
//                                                 height: '4px',
//                                                 backgroundColor: index % 2 === 0 ? colors.primary.main : colors.secondary.main,
//                                                 borderRadius: '2px',
//                                             }
//                                         }}
//                                         onClick={() => handleProductClick(product)} // Make title clickable
//                                     >
//                                         {product.title}
//                                     </Typography>
//                                     <Typography 
//                                         variant="body1" 
//                                         sx={{ 
//                                             mb: 4, 
//                                             lineHeight: 1.7,
//                                             textAlign: 'justify',
//                                             color: colors.text.secondary,
//                                             opacity: 0.9,
//                                             fontSize: '1.2rem',
//                                             fontFamily: "'Segoe UI', 'Roboto', 'Arial', sans-serif",
//                                         }}
//                                     >
//                                         {product.description}
//                                     </Typography>
//                                     <Box>
//                                         <SoftButton 
//                                             variant="gradient" 
//                                             color="info"
//                                             onClick={() => handleProductClick(product)}
//                                         >
//                                             {product.buttonText}
//                                         </SoftButton>
//                                     </Box>
//                                 </Grid>
//                             </Grid>
//                         </Card>
//                     ))}
//                 </Container>

//                 {/* Footer Section */}
//                 <Box 
//                     sx={{ 
//                         bgcolor: colors.background.paper,
//                         py: 4,
//                         borderTop: '1px solid rgba(0,0,0,0.06)',
//                         textAlign: 'center'
//                     }}
//                 >
//                 </Box>
//             </Box>
//             <Footers/>
//         </PageLayout>
//     );
// };

// export default ProductsDisplay;


// import React, { useState, useEffect } from 'react';
// import {
//     Box,
//     Container,
//     Typography,
//     CssBaseline,
//     Grid,
//     Card,
//     CircularProgress
// } from '@mui/material';
// import { Link, useNavigate } from 'react-router-dom';
// import SoftBox from 'components/SoftBox';
// import SoftTypography from 'components/SoftTypography';
// import SoftButton from 'components/SoftButton';
// import SocialSidebar from './SocialSidebar';
// import { APIData } from 'authentication/APIData';
// import { org } from 'authentication/APIData';
// import Navbarrr from './Navbarrr';
// import Footers from './Footers';
// import PageLayout from 'examples/LayoutContainers/PageLayout';

// const ProductsDisplay = () => {
//     const navigate = useNavigate();
//     const [products, setProducts] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     // Define colors that were previously in the theme
//     const colors = {
//         primary: {
//             main: '#1e90ff',
//             light: '#4da6ff',
//             dark: '#0066cc',
//         },
//         secondary: {
//             main: '#6a0dad',
//             light: '#8c2be0',
//             dark: '#4b0082',
//         },
//         background: {
//             default: '#f8f9fa',
//             paper: '#ffffff',
//         },
//         text: {
//             primary: '#333333',
//             secondary: '#555555',
//         },
//     };

//     useEffect(() => {
//         const fetchProducts = async () => {
//             setLoading(true);
//             try {
//                 const response = await fetch(`${APIData.api}product-catalogue/?org=${org}`, {
//                     method: 'GET',
//                     headers: APIData.headers
//                 });
//                 const data = await response.json();
//                 setProducts(data);
//                 setError(null);
//             } catch (error) {
//                 console.error('Error fetching products:', error);
//                 setError(error.message);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchProducts();
//     }, []);

//     // Function to handle button clicks
//     const handleProductClick = (product) => {
//         // Check if it's a software product with a URL
//         if (product.ptype === 'SOFTWARE' && product.softwareUrl) {
//             window.open(product.softwareUrl, '_blank');
//         }
//     };

//     if (loading) {
//         return (
//             <PageLayout>
//                 <Navbarrr/>
//                 <SoftBox
//                     display="flex"
//                     justifyContent="center"
//                     alignItems="center"
//                     minHeight="calc(100vh - 100px)"
//                     className="overflow-hidden"
//                 >
//                     <CircularProgress />
//                     <SoftTypography variant="h6" ml={2}>
//                         Loading...
//                     </SoftTypography>
//                 </SoftBox>
//                 <Footers />
//             </PageLayout>
//         );
//     }

//     return (
//         <PageLayout>
//             <Navbarrr/>
//             <SocialSidebar />
//             <SoftBox
//                 display="flex"
//                 flexDirection="column"
//                 minHeight="calc(100vh - 100px)"
//                 className="overflow-y-auto"
//                 sx={{ 
//                     flexGrow: 1, 
//                     backgroundColor: colors.background.default,
//                 }}
//             >
//                 {error ? (
//                     <SoftBox display="flex" flexDirection="column" alignItems="center" p={3}>
//                         <SoftTypography
//                             variant="h4"
//                             color="error"
//                             style={{
//                                 fontFamily: "Georgia, serif",
//                                 fontWeight: "bold",
//                                 fontSize: "50px"
//                             }}
//                         >
//                             Error Loading Products
//                         </SoftTypography>
//                         <SoftTypography 
//                             variant="body1" 
//                             color="error" 
//                             align="center" 
//                             mt={2} 
//                             mb={3}
//                         >
//                             {error}
//                         </SoftTypography>
//                         <SoftButton 
//                             variant="contained" 
//                             color="info" 
//                             onClick={() => window.location.reload()}
//                         >
//                             Retry
//                         </SoftButton>
//                     </SoftBox>
//                 ) : products.length === 0 ? (
//                     <SoftBox 
//                         display="flex" 
//                         flexDirection="column" 
//                         alignItems="center" 
//                         justifyContent="center"
//                         flex={1} 
//                         sx={{
//                             flexGrow: 1,
//                             minHeight: 'calc(100vh - 200px)', // Adjust this value to create more vertical space
//                             display: 'flex',
//                             flexDirection: 'column',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             textAlign: 'center',
//                             padding: '0 20px'
//                         }}
//                     >
//                         <SoftTypography
//                             variant="h4"
//                             color="info"
//                             style={{
//                                 fontFamily: "Georgia, serif",
//                                 fontWeight: "bold",
//                                 fontSize: "25px",
//                                 color: '#00bfff' // Matching the image's text color
//                             }}
//                         >
//                             Currently, we are not having any products. 
//                         </SoftTypography>
//                         <Link to='/' style={{ textDecoration: 'none', marginTop: '20px' }}>
//                             <SoftButton 
//                                 variant="contained" 
//                                 color="info"
//                                 sx={{
//                                     backgroundColor: '#00bfff', // Matching the image's button color
//                                     '&:hover': {
//                                         backgroundColor: '#1e90ff'
//                                     }
//                                 }}
//                             >
//                                 Go Back
//                             </SoftButton>
//                         </Link>
//                     </SoftBox>
//                 ) : (
//                     <Container maxWidth="lg" sx={{ py: 8 }}>
//                         {products.map((product, index) => (
//                             <Card 
//                                 key={product.pid} 
//                                 elevation={0} 
//                                 sx={{ 
//                                     mb: 8,
//                                     backgroundColor: index % 2 === 0 ? 'rgba(30,144,255,0.03)' : 'rgba(106,13,173,0.03)',
//                                     overflow: 'visible',
//                                     borderRadius: 4,
//                                     boxShadow: '0 8px 24px rgba(0,0,0,0.08)',
//                                     transition: 'transform 0.3s ease, box-shadow 0.3s ease',
//                                     '&:hover': {
//                                         transform: 'translateY(-4px)',
//                                         boxShadow: '0 12px 30px rgba(0,0,0,0.12)',
//                                     },
//                                 }}
//                             >
//                                 <Grid 
//                                     container 
//                                     spacing={6} 
//                                     direction={index % 2 === 0 ? 'row' : 'row-reverse'}
//                                     sx={{ p: 3 }}
//                                 >
//                                     {/* Image Section */}
//                                     <Grid item xs={12} md={5} sx={{ 
//                                         display: 'flex', 
//                                         alignItems: 'center', 
//                                         justifyContent: 'center',
//                                         position: 'relative'
//                                     }}>
//                                         <Box
//                                             component="img"
//                                             src={product.image}
//                                             alt={product.pname}
//                                             sx={{
//                                                 width: '100%',
//                                                 maxWidth: '340px',
//                                                 height: 'auto',
//                                                 aspectRatio: '4/3',
//                                                 objectFit: 'cover',
//                                                 borderRadius: 4,
//                                                 boxShadow: '0 20px 40px rgba(0,0,0,0.15)',
//                                                 transform: index % 2 === 0 ? 'rotate(-2deg)' : 'rotate(2deg)',
//                                                 border: '8px solid white',
//                                                 zIndex: 2,
//                                                 cursor: 'pointer', 
//                                             }}
//                                         />
//                                     </Grid>

//                                     {/* Content Section */}
//                                     <Grid item xs={12} md={7} sx={{ 
//                                         display: 'flex', 
//                                         flexDirection: 'column', 
//                                         justifyContent: 'center',
//                                         py: 4
//                                     }}>
//                                         <Typography 
//                                             variant="h2" 
//                                             component="h2" 
//                                             sx={{ 
//                                                 mb: 3, 
//                                                 fontWeight: 'bold',
//                                                 fontSize: {
//                                                     xs: '1.8rem',
//                                                     md: '2.2rem'
//                                                 },
//                                                 letterSpacing: '-0.01em',
//                                                 fontFamily: "'Segoe UI', 'Roboto', 'Arial', sans-serif",
//                                                 color: index % 2 === 0 ? colors.primary.dark : colors.secondary.dark,
//                                                 position: 'relative',
//                                                 '&:after': {
//                                                     content: '""',
//                                                     position: 'absolute',
//                                                     bottom: '-12px',
//                                                     left: 0,
//                                                     width: '60px',
//                                                     height: '4px',
//                                                     backgroundColor: index % 2 === 0 ? colors.primary.main : colors.secondary.main,
//                                                     borderRadius: '2px',
//                                                 }
//                                             }}
//                                         >
//                                             {product.pname}
//                                         </Typography>
//                                         <Typography 
//                                             variant="body1" 
//                                             sx={{ 
//                                                 mb: 4, 
//                                                 lineHeight: 1.7,
//                                                 textAlign: 'justify',
//                                                 color: colors.text.secondary,
//                                                 opacity: 0.9,
//                                                 fontSize: '1.2rem',
//                                                 fontFamily: "'Segoe UI', 'Roboto', 'Arial', sans-serif",
//                                             }}
//                                         >
//                                             {product.description}
//                                         </Typography>
//                                         {product.ptype === 'SOFTWARE' && (
//                                             <Box>
//                                                 <SoftButton 
//                                                     variant="gradient" 
//                                                     color="info"
//                                                     onClick={() => handleProductClick(product)}
//                                                     disabled={!product.softwareUrl}
//                                                 >
//                                                     Explore Platform
//                                                 </SoftButton>
//                                             </Box>
//                                         )}
//                                     </Grid>
//                                 </Grid>
//                             </Card>
//                         ))}
//                     </Container>
//                 )}
//                 <Footers/>
//             </SoftBox>
//         </PageLayout>
//     );
// };

// export default ProductsDisplay;


import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Typography,
    CssBaseline,
    Grid,
    Card,
    CircularProgress,
    Skeleton
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import SocialSidebar from './SocialSidebar';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import Navbarrr from './Navbarrr';
import Footers from './Footers';
import PageLayout from 'examples/LayoutContainers/PageLayout';

const ProductsDisplay = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Define colors that were previously in the theme
    const colors = {
        primary: {
            main: '#1e90ff',
            light: '#4da6ff',
            dark: '#0066cc',
        },
        secondary: {
            main: '#6a0dad',
            light: '#8c2be0',
            dark: '#4b0082',
        },
        background: {
            default: '#f8f9fa',
            paper: '#ffffff',
        },
        text: {
            primary: '#333333',
            secondary: '#555555',
        },
    };

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${APIData.api}product-catalogue/?org=${org}`, {
                    method: 'GET',
                    headers: APIData.headers
                });
                const data = await response.json();
                setProducts(data);
                setError(null);
            } catch (error) {
                console.error('Error fetching products:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    // Function to handle button clicks
    const handleProductClick = (product) => {
        // Check if it's a software product with a URL
        if (product.ptype === 'SOFTWARE' && product.softwareUrl) {
            window.open(product.softwareUrl, '_blank');
        }
    };

    // Skeleton loading UI that matches the final product display
    const renderSkeletonProducts = () => {
        return Array.from(new Array(3)).map((_, index) => (
            <Card 
                key={`skeleton-${index}`} 
                elevation={0} 
                sx={{ 
                    mb: 8,
                    backgroundColor: index % 2 === 0 ? 'rgba(30,144,255,0.03)' : 'rgba(106,13,173,0.03)',
                    overflow: 'visible',
                    borderRadius: 4,
                    boxShadow: '0 8px 24px rgba(0,0,0,0.08)',
                }}
            >
                <Grid 
                    container 
                    spacing={6} 
                    direction={index % 2 === 0 ? 'row' : 'row-reverse'}
                    sx={{ p: 3 }}
                >
                    {/* Image Skeleton */}
                    <Grid item xs={12} md={5} sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        position: 'relative'
                    }}>
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{
                                width: '100%',
                                maxWidth: '340px',
                                height: '255px', // Approximate aspect ratio of 4/3
                                aspectRatio: '4/3',
                                borderRadius: 4,
                                transform: index % 2 === 0 ? 'rotate(-2deg)' : 'rotate(2deg)',
                                zIndex: 2,
                            }}
                        />
                    </Grid>

                    {/* Content Skeleton */}
                    <Grid item xs={12} md={7} sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'center',
                        py: 4
                    }}>
                        <Skeleton 
                            variant="text" 
                            animation="wave"
                            width="70%" 
                            height={60} 
                            sx={{ mb: 3 }} 
                        />
                        <Skeleton 
                            variant="text" 
                            animation="wave"
                            width="100%" 
                            height={25} 
                            sx={{ mb: 1 }} 
                        />
                        <Skeleton 
                            variant="text" 
                            animation="wave"
                            width="100%" 
                            height={25} 
                            sx={{ mb: 1 }} 
                        />
                        <Skeleton 
                            variant="text" 
                            animation="wave"
                            width="95%" 
                            height={25} 
                            sx={{ mb: 1 }} 
                        />
                        <Skeleton 
                            variant="text" 
                            animation="wave"
                            width="90%" 
                            height={25} 
                            sx={{ mb: 4 }} 
                        />
                        <Skeleton 
                            variant="rectangular" 
                            animation="wave"
                            width={140} 
                            height={40} 
                            sx={{ borderRadius: 1 }} 
                        />
                    </Grid>
                </Grid>
            </Card>
        ));
    };

    return (
        <PageLayout>
            <Navbarrr/>
            <SocialSidebar />
            <SoftBox
                display="flex"
                flexDirection="column"
                minHeight="calc(100vh - 100px)"
                className="overflow-y-auto"
                sx={{ 
                    flexGrow: 1, 
                    backgroundColor: colors.background.default,
                }}
            >
                {loading ? (
                    <Container maxWidth="lg" sx={{ py: 8 }}>
                        {renderSkeletonProducts()}
                    </Container>
                ) : error ? (
                    <SoftBox display="flex" flexDirection="column" alignItems="center" p={3}>
                        <SoftTypography
                            variant="h4"
                            color="error"
                            style={{
                                fontFamily: "Georgia, serif",
                                fontWeight: "bold",
                                fontSize: "50px"
                            }}
                        >
                            Error Loading Products
                        </SoftTypography>
                        <SoftTypography 
                            variant="body1" 
                            color="error" 
                            align="center" 
                            mt={2} 
                            mb={3}
                        >
                            {error}
                        </SoftTypography>
                        <SoftButton 
                            variant="contained" 
                            color="info" 
                            onClick={() => window.location.reload()}
                        >
                            Retry
                        </SoftButton>
                    </SoftBox>
                ) : products.length === 0 ? (
                    <SoftBox 
                        display="flex" 
                        flexDirection="column" 
                        alignItems="center" 
                        justifyContent="center"
                        flex={1} 
                        sx={{
                            flexGrow: 1,
                            minHeight: 'calc(100vh - 200px)', // Adjust this value to create more vertical space
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            padding: '0 20px'
                        }}
                    >
                        <SoftTypography
                            variant="h4"
                            color="info"
                            style={{
                                fontFamily: "Georgia, serif",
                                fontWeight: "bold",
                                fontSize: "25px",
                                color: '#00bfff' // Matching the image's text color
                            }}
                        >
                            Currently, we are not having any products. 
                        </SoftTypography>
                        <Link to='/' style={{ textDecoration: 'none', marginTop: '20px' }}>
                            <SoftButton 
                                variant="contained" 
                                color="info"
                                sx={{
                                    backgroundColor: '#00bfff', // Matching the image's button color
                                    '&:hover': {
                                        backgroundColor: '#1e90ff'
                                    }
                                }}
                            >
                                Go Back
                            </SoftButton>
                        </Link>
                    </SoftBox>
                ) : (
                    <Container maxWidth="lg" sx={{ py: 8 }}>
                        {products.map((product, index) => (
                            <Card 
                                key={product.pid} 
                                elevation={0} 
                                sx={{ 
                                    mb: 8,
                                    backgroundColor: index % 2 === 0 ? 'rgba(30,144,255,0.03)' : 'rgba(106,13,173,0.03)',
                                    overflow: 'visible',
                                    borderRadius: 4,
                                    boxShadow: '0 8px 24px rgba(0,0,0,0.08)',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-4px)',
                                        boxShadow: '0 12px 30px rgba(0,0,0,0.12)',
                                    },
                                }}
                            >
                                <Grid 
                                    container 
                                    spacing={6} 
                                    direction={index % 2 === 0 ? 'row' : 'row-reverse'}
                                    sx={{ p: 3 }}
                                >
                                    {/* Image Section */}
                                    <Grid item xs={12} md={5} sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        position: 'relative'
                                    }}>
                                        <Box
                                            component="img"
                                            src={product.image}
                                            alt={product.pname}
                                            sx={{
                                                width: '100%',
                                                maxWidth: '340px',
                                                height: 'auto',
                                                aspectRatio: '4/3',
                                                objectFit: 'cover',
                                                borderRadius: 4,
                                                boxShadow: '0 20px 40px rgba(0,0,0,0.15)',
                                                transform: index % 2 === 0 ? 'rotate(-2deg)' : 'rotate(2deg)',
                                                border: '8px solid white',
                                                zIndex: 2,
                                                cursor: 'pointer', 
                                            }}
                                        />
                                    </Grid>

                                    {/* Content Section */}
                                    <Grid item xs={12} md={7} sx={{ 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        justifyContent: 'center',
                                        py: 4
                                    }}>
                                        <Typography 
                                            variant="h2" 
                                            component="h2" 
                                            sx={{ 
                                                mb: 3, 
                                                fontWeight: 'bold',
                                                fontSize: {
                                                    xs: '1.8rem',
                                                    md: '2.2rem'
                                                },
                                                letterSpacing: '-0.01em',
                                                fontFamily: "'Segoe UI', 'Roboto', 'Arial', sans-serif",
                                                color: index % 2 === 0 ? colors.primary.dark : colors.secondary.dark,
                                                position: 'relative',
                                                '&:after': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    bottom: '-12px',
                                                    left: 0,
                                                    width: '60px',
                                                    height: '4px',
                                                    backgroundColor: index % 2 === 0 ? colors.primary.main : colors.secondary.main,
                                                    borderRadius: '2px',
                                                }
                                            }}
                                        >
                                            {product.pname}
                                        </Typography>
                                        <Typography 
                                            variant="body1" 
                                            sx={{ 
                                                mb: 4, 
                                                lineHeight: 1.7,
                                                textAlign: 'justify',
                                                color: colors.text.secondary,
                                                opacity: 0.9,
                                                fontSize: '1.2rem',
                                                fontFamily: "'Segoe UI', 'Roboto', 'Arial', sans-serif",
                                            }}
                                        >
                                            {product.description}
                                        </Typography>
                                        {product.ptype === 'SOFTWARE' && (
                                            <Box>
                                                <SoftButton 
                                                    variant="gradient" 
                                                    color="info"
                                                    onClick={() => handleProductClick(product)}
                                                    disabled={!product.softwareUrl}
                                                >
                                                    Explore Platform
                                                </SoftButton>
                                            </Box>
                                        )}
                                    </Grid>
                                </Grid>
                            </Card>
                        ))}
                    </Container>
                )}
                <Footers/>
            </SoftBox>
        </PageLayout>
    );
};

export default ProductsDisplay;