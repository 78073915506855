
// import React from "react";
// import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded'; // Importing the icon

// const cardStyle = {
//   backgroundColor: "#fff",
//   padding: "32px",
//   borderRadius: "8px",
//   boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
//   transition: "transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease",
//   marginBottom: "16px",
//   cursor: "pointer",
//   width: "350px",
//   height: "250px",
// };

// const cardHeaderStyle = {
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   borderBottom: "1px solid #ddd",
//   paddingBottom: "8px",
//   marginBottom: "8px",
// };

// const cardTitleStyle = {
//   fontSize: "20px",
//   fontWeight: "bold",
//   display: "flex", // Aligns the icon and the title in one line
//   alignItems: "center", // Vertically aligns the icon with the text
// };

// const dateStyle = {
//   fontSize: "14px",
//   color: "#666",
// };

// const descriptionStyle = {
//   fontSize: "14px",
//   color: "#444",
//   marginTop: "12px",
// };

// const containerStyle = {
//   maxWidth: "1200px",
//   margin: "0 auto",
//   padding: "40px 20px",
// };

// const headingStyle = {
//   textAlign: "center",
//   fontSize: "32px",
//   fontWeight: "bold",
//   marginBottom: "24px",
//   color: "#333",
// };

// const updates = [
//   {
//     version: "v2.1",
//     date: "June 15, 2023",
//     description: "Enhanced collaboration tools and new integrations with popular CRMs.",
//   },
//   {
//     version: "v2.0",
//     date: "May 1, 2023",
//     description: "Improved UI, predictive analytics, and multi-currency support added.",
//   },
//   {
//     version: "v1.9",
//     date: "March 10, 2023",
//     description: "Advanced inventory management and two-factor authentication enabled.",
//   },
// ];

// export default function Updates() {
//   return (
//     <section style={containerStyle} id="updates">
//       <h2 style={headingStyle}>Latest Updates
//       <span style={{
//                   content: "''",
//                   display: "block",
//                   height: "4px",
//                   background: "linear-gradient(to right, #2563eb,rgb(255, 255, 255))", // Gradient line
//                   marginTop: "8px",
//                   width: "30%", // Adjust width of the line
//                   margin: "8px auto 0",
//                 }}></span>
//       </h2>
//       <div
//         style={{
//           display: "flex",
//           flexWrap: "wrap", // Allow cards to wrap onto the next line
//           justifyContent: "space-between",
//           gap: "15px",
//         }}
//       >
//         {updates.map((update, index) => (
//           <div
//             key={index}
//             style={cardStyle}
//             onMouseEnter={(e) => {
//               e.currentTarget.style.transform = "scale(1.05)";
//               e.currentTarget.style.color = "black";
//             }}
//             onMouseLeave={(e) => {
//               e.currentTarget.style.transform = "scale(1)";
//               e.currentTarget.style.color = "black";
//             }}
//           >
//             <div style={cardHeaderStyle}>
//               <div style={cardTitleStyle}>
//                 <TipsAndUpdatesRoundedIcon style={{ marginRight: "8px", fontSize: "29px" }} />
//                 {update.version}
//               </div>
//               <span style={dateStyle}>{update.date}</span>
//             </div>
//             <p style={descriptionStyle}>{update.description}</p>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// }

// import React, { useEffect, useState } from "react";
// import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded'; // Importing the icon
// import { org,APIData } from "authentication/APIData"; // Import the apidata file (assuming it's exported from a file)

// const cardStyle = {
//   backgroundColor: "#fff",
//   padding: "32px",
//   borderRadius: "8px",
//   boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
//   transition: "transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease",
//   marginBottom: "16px",
//   cursor: "pointer",
//   width: "350px",
//   height: "250px",
// };

// const cardHeaderStyle = {
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   borderBottom: "1px solid #ddd",
//   paddingBottom: "8px",
//   marginBottom: "8px",
// };

// const cardTitleStyle = {
//   fontSize: "20px",
//   fontWeight: "bold",
//   display: "flex", // Aligns the icon and the title in one line
//   alignItems: "center", // Vertically aligns the icon with the text
// };

// const dateStyle = {
//   fontSize: "14px",
//   color: "#666",
// };

// const descriptionStyle = {
//   fontSize: "14px",
//   color: "#444",
//   marginTop: "12px",
// };

// const containerStyle = {
//   maxWidth: "1200px",
//   margin: "0 auto",
//   padding: "40px 20px",
// };

// const headingStyle = {
//   textAlign: "center",
//   fontSize: "32px",
//   fontWeight: "bold",
//   marginBottom: "24px",
//   color: "#333",
// };

// export default function Updates() {
//   const [updates, setUpdates] = useState([]);
//   const [loading, setLoading] = useState(false); // To show loading state
//   const [error, setError] = useState(null); // To handle errors
//   const orgId = org; // Extract org id from the imported apidata file

//   useEffect(() => {
//     // Fetch updates data from the API using the orgId
//     const fetchUpdates = async () => {
//       setLoading(true); // Set loading state to true
//       setError(null); 
//       try {
//         const response = await fetch(`${APIData.api}landing-page/org/${orgId}`, {
//           headers: APIData.headers,
//         });

//         // Check if the response is not OK (e.g., status code 401, 500, etc.)
//         if (!response.ok) {
//           const errorDetails = await response.text(); // Get error details
//           throw new Error(`Failed to fetch: ${errorDetails}`);
//         }

//         const data = await response.json(); // Parse the response body
//         console.log("API Data:", data); // Log the response to verify the structure

//         // Update the way you access latestUpdates based on the new response structure
//         setUpdates(data.latestUpdates || []); // Set the updates
//       } catch (error) {
//         setError("Error fetching updates: " + error.message); // Handle error
//         console.error("Error fetching updates:", error);
//       } finally {
//         setLoading(false); // Set loading to false after fetching
//       }
//     };

//     fetchUpdates();
//   }, [orgId]);

//   // Conditional rendering based on loading and error states
//   if (loading) {
//     return <div>Loading...</div>; // Show loading message while fetching
//   }

//   if (error) {
//     return <div>{error}</div>; // Show error message if an error occurred
//   }

//   // Render updates
//   return (
//     <section style={containerStyle} id="updates">
//       <h2 style={headingStyle}>Latest Updates</h2>
//       <div
//         style={{
//           display: "flex",
//           flexWrap: "wrap", // Allow cards to wrap onto the next line
//           justifyContent: "space-between",
//           gap: "15px",
//         }}
//       >
//         {updates.length > 0 ? (
//           updates.map((update, index) => (
//             <div
//               key={index}
//               style={cardStyle}
//               onMouseEnter={(e) => {
//                 e.currentTarget.style.transform = "scale(1.05)";
//                 e.currentTarget.style.color = "black";
//               }}
//               onMouseLeave={(e) => {
//                 e.currentTarget.style.transform = "scale(1)";
//                 e.currentTarget.style.color = "black";
//               }}
//             >
//               <div style={cardHeaderStyle}>
//                 <div style={cardTitleStyle}>
//                   <TipsAndUpdatesRoundedIcon style={{ marginRight: "8px", fontSize: "29px" }} />
//                   {update.title}
//                 </div>
//                 <span style={dateStyle}>{new Date().toLocaleDateString()}</span>
//               </div>
//               <p style={descriptionStyle}>{update.description}</p>
//             </div>
//           ))
//         ) : (
//           <p>No updates available</p>
//         )}
//       </div>
//     </section>
//   );
// }

// import React, { useEffect, useState } from "react";
// import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';
// import { org, APIData } from "authentication/APIData";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   IconButton,
//   Tooltip,
//   Backdrop,
//   CircularProgress
// } from "@mui/material";
// import { FaEdit, FaSave, FaTimes } from "react-icons/fa";
// import AddIcon from "@mui/icons-material/Add";
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import CloseIcon from "@mui/icons-material/Close";
// import { CloudUploadIcon } from "lucide-react";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftInput from "components/SoftInput";
// import SoftButton from "components/SoftButton";
// import { FormControl, FormHelperText } from "@mui/material";
// import Swal from "sweetalert2";

// const cardStyle = {
//   backgroundColor: "#fff",
//   padding: "32px",
//   borderRadius: "8px",
//   boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
//   transition: "transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease",
//   marginBottom: "16px",
//   cursor: "pointer",
//   width: "350px",
//   height: "auto",
//   minHeight: "300px",
//   position: "relative",
//   display: "flex",
//   flexDirection: "column",
// };

// const cardHeaderStyle = {
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   borderBottom: "1px solid #ddd",
//   paddingBottom: "8px",
//   marginBottom: "8px",
// };

// const cardTitleStyle = {
//   fontSize: "20px",
//   fontWeight: "bold",
//   display: "flex",
//   alignItems: "center",
// };

// const dateStyle = {
//   fontSize: "14px",
//   color: "#666",
// };

// const descriptionStyle = {
//   fontSize: "14px",
//   color: "#444",
//   marginTop: "12px",
// };

// const containerStyle = {
//   maxWidth: "1200px",
//   margin: "0 auto",
//   padding: "40px 20px",
// };

// const headingStyle = {
//   textAlign: "center",
//   fontSize: "32px",
//   fontWeight: "bold",
//   marginBottom: "24px",
//   color: "#333",
// };

// const imageStyle = {
//   width: "100%",
//   height: "200px",
//   objectFit: "cover",
//   borderRadius: "8px",
//   marginTop: "12px",
// };

// export default function Updates() {
//   const [updates, setUpdates] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [showAddDialog, setShowAddDialog] = useState(false);
//   const [editingUpdate, setEditingUpdate] = useState(null);
//   const [currentUser, setCurrentUser] = useState(null);
//   const [permission, setPermission] = useState(false);
//   const [isSuperAdmin, setIsSuperAdmin] = useState(false);
//   const [landingData, setLandingData] = useState({});
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const [newUpdate, setNewUpdate] = useState({
//     title: "",
//     description: "",
//     image: null,
//     imagePreview: null
//   });

//   const [errors, setErrors] = useState({
//     title: "",
//     description: "",
//     image: ""
//   });

//   useEffect(() => {
//     const loadUserPermissions = () => {
//       const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
//       const accessLevel = localStorage.getItem('Access Level');
      
//       if (sessionDetails && sessionDetails.user) {
//         setCurrentUser(sessionDetails.user);
//         // Store userType in state to ensure it persists
//         const userType = sessionDetails.userType;
//         setIsSuperAdmin(userType === "SUPERADMIN");
//       }
      
//       if (accessLevel) {
//         setPermission(parseInt(accessLevel));
//       }
//     };

//     loadUserPermissions();
//     window.addEventListener('storage', loadUserPermissions);
    
//     return () => {
//       window.removeEventListener('storage', loadUserPermissions);
//     };
//   }, []);

//   const shouldShowEditButton = () => {
//     if (permission === null) return false;
    
//     if (permission === 1100 || permission === 1000) {
//       return false;
//     }
//     return isSuperAdmin || permission === 1111;
//   };

//   useEffect(() => {
//     shouldShowEditButton();
//   }, []);

//   const fetchUpdates = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${APIData.api}landing-page/org/${org}`, {
//         headers: APIData.headers,
//       });

//       if (!response.ok) {
//         throw new Error("Failed to fetch updates");
//       }

//       const data = await response.json();
//       setLandingData(data);
//       setUpdates(data.latestUpdates || []);
//     } catch (error) {
//       setError("Error fetching updates: " + error.message);
//       console.error("Error fetching updates:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchUpdates();
//   }, []);

//   const handleDeleteUpdate = async (index) => {
//     try {
//       const result = await Swal.fire({
//         title: 'Are you sure?',
//         text: "You won't be able to revert this!",
//         icon: 'warning',
//         showCancelButton: true,
//         confirmButtonColor: '#6b46c1',
//         cancelButtonColor: '#d33',
//         confirmButtonText: 'Yes, delete it!'
//       });

//       if (result.isConfirmed) {
//         setLoading(true);
//         const updatedUpdates = updates.filter((_, i) => i !== index);
//         const payload = {
//           ...landingData,
//           latestUpdates: updatedUpdates,
//           org: org,
//           updatedBy: currentUser || "SYSTEM",
//           updatedDateTime: new Date().toISOString()
//         };

//         const response = await fetch(`${APIData.api}landing-page/Add`, {
//           method: "POST",
//           headers: {
//             ...APIData.headers,
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(payload),
//         });

//         if (!response.ok) {
//           throw new Error("Failed to delete update");
//         }

//         await fetchUpdates();

//         await Swal.fire({
//           icon: 'success',
//           title: 'Deleted!',
//           text: 'Update has been deleted.',
//           timer: 1500,
//           showConfirmButton: false
//         });
//       }
//     } catch (error) {
//       console.error("Error deleting update:", error);
//       await Swal.fire({
//         icon: 'error',
//         title: 'Error',
//         text: 'Failed to delete update: ' + error.message,
//         confirmButtonColor: '#6b46c1'
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       if (file.type.startsWith('image/')) {
//         setNewUpdate(prev => ({
//           ...prev,
//           image: file,
//           imagePreview: URL.createObjectURL(file)
//         }));
//         setErrors(prev => ({ ...prev, image: "" }));
//       } else {
//         setErrors(prev => ({ ...prev, image: "Please select an image file" }));
//       }
//     }
//   };

//   const handleEditFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       if (file.type.startsWith('image/')) {
//         setEditingUpdate(prev => ({
//           ...prev,
//           newImage: file,
//           imagePreview: URL.createObjectURL(file)
//         }));
//         setErrors(prev => ({ ...prev, image: "" }));
//       } else {
//         setErrors(prev => ({ ...prev, image: "Please select an image file" }));
//       }
//     }
//   };

//   const validateUpdate = (update) => {
//     const newErrors = {};

//     if (!update.title) {
//       newErrors.title = "Title is required.";
//     } else if (update.title.length > 30) {
//       newErrors.title = "Title must be less than 30 characters.";
//     }

//     if (!update.description) {
//       newErrors.description = "Description is required.";
//     } else if (update.description.length > 200) {
//       newErrors.description = "Description must be less than 200 characters.";
//     }

//     return newErrors;
//   };

//   const handleAddUpdate = async () => {
//     const newErrors = validateUpdate(newUpdate);
//     setErrors(newErrors);

//     if (Object.keys(newErrors).length === 0) {
//       setIsSubmitting(true);
//       try {
//         const currentDateTime = new Date().toISOString();
//         const newUpdateData = {
//           title: newUpdate.title,
//           description: newUpdate.description,
//           image: "" // Initially empty, will be updated after upload
//         };

//         const updatedData = {
//           ...landingData,
//           latestUpdates: [...updates, newUpdateData],
//           org: org,
//           updatedBy: currentUser || "SYSTEM",
//           updatedDateTime: currentDateTime
//         };

//         const response = await fetch(`${APIData.api}landing-page/Add`, {
//           method: "POST",
//           headers: {
//             ...APIData.headers,
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(updatedData),
//         });

//         if (!response.ok) {
//           throw new Error("Failed to add update");
//         }

//         // Upload image if exists
//         if (newUpdate.image) {
//           const updateIndex = updates.length;
//           const formData = new FormData();
//           formData.append("id", org);
//           formData.append("latestUpdatesImage", newUpdate.image);
//           formData.append("latestUpdateIndex", updateIndex);

//           const imageResponse = await fetch(
//             `${APIData.api}landing-page/${org}/upload-images?latestUpdateIndex=${updateIndex}`,
//             {
//               method: "POST",
//               headers: {
//                 ...APIData.headers,
//               },
//               body: formData,
//             }
//           );

//           if (!imageResponse.ok) {
//             throw new Error("Failed to upload image");
//           }
//         }

//         await fetchUpdates();
//         setShowAddDialog(false);
//         setNewUpdate({ title: "", description: "", image: null, imagePreview: null });

//         await Swal.fire({
//           icon: "success",
//           title: "Update Added Successfully",
//           timer: 1500,
//           showConfirmButton: false,
//         });
//       } catch (error) {
//         console.error("Error adding update:", error);
//         await Swal.fire({
//           icon: 'error',
//           title: 'Error',
//           text: 'Failed to add update: ' + error.message,
//           confirmButtonColor: '#6b46c1'
//         });
//       } finally {
//         setIsSubmitting(false);
//       }
//     }
//   };

//   const handleEditUpdate = async () => {
//     const newErrors = validateUpdate(editingUpdate);
//     setErrors(newErrors);

//     if (Object.keys(newErrors).length === 0) {
//       setIsSubmitting(true);
//     try {
//       const updatedUpdates = updates.map((update, index) =>
//         index === editingUpdate.index ? {
//           title: editingUpdate.title,
//           description: editingUpdate.description,
//           image: editingUpdate.image
//         } : update
//       );

//       const payload = {
//         ...landingData,
//         latestUpdates: updatedUpdates,
//         org: org,
//         updatedBy: currentUser || "SYSTEM",
//         updatedDateTime: new Date().toISOString()
//       };

//       const response = await fetch(`${APIData.api}landing-page/Add`, {
//         method: "POST",
//         headers: {
//           ...APIData.headers,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(payload),
//       });

//       if (!response.ok) {
//         throw new Error("Failed to update");
//       }

//       // Upload new image if exists
//       if (editingUpdate.newImage) {
//         const formData = new FormData();
//         formData.append("id", org);
//         formData.append("latestUpdatesImage", editingUpdate.newImage);
//         formData.append("latestUpdateIndex", editingUpdate.index);

//         console.log(editingUpdate.index);


//         const imageResponse = await fetch(
//           `${APIData.api}landing-page/${org}/upload-images?latestUpdateIndex=${editingUpdate.index}`,
//           {
//             method: "POST",
//             headers: {
//               ...APIData.headers,
//             },
//             body: formData,
//           }
//         );

//         if (!imageResponse.ok) {
//           throw new Error("Failed to upload image");
//         }
//       }

//       await fetchUpdates();
//       setEditingUpdate(null);

//       await Swal.fire({
//         icon: "success",
//         title: "Update Modified Successfully",
//         timer: 1500,
//         showConfirmButton: false,
//       });
//     } catch (error) {
//       console.error("Error updating:", error);
//       await Swal.fire({
//         icon: 'error',
//         title: 'Error',
//         text: 'Failed to update: ' + error.message,
//         confirmButtonColor: '#6b46c1'
//       });
//     } finally {
//       setIsSubmitting(false);
//     }
//   }
// };

//   return (
//     <section style={containerStyle} id="updates">
//       <h2 style={headingStyle}>Latest Updates</h2>
//       {updates.length > 0 ? (
//         <div
//           style={{
//             display: "flex",
//             flexWrap: "wrap",
//             justifyContent: "flex-start",
//             gap: "24px",
//             padding: "0 16px",
//           }}
//         >
//           {updates.map((update, index) => (
//             <div
//               key={index}
//               style={cardStyle}
//               onMouseEnter={(e) => {
//                 e.currentTarget.style.transform = "scale(1.05)";
//               }}
//               onMouseLeave={(e) => {
//                 e.currentTarget.style.transform = "scale(1)";
//               }}
//             >
//               {shouldShowEditButton() && (
//                 <div style={{
//                   position: "absolute",
//                   top: "10px",
//                   right: "10px",
//                   zIndex: 1,
//                   display: "flex",
//                   gap: "5px"
//                 }}>
//                   <Tooltip title="Edit Update">
//                     <IconButton
//                       size="small"
//                       onClick={() => {
//                         fetchUpdates();
//                         setEditingUpdate({ ...update, index });
//                       }}
//                     >
//                       <FaEdit />
//                     </IconButton>
//                   </Tooltip>
//                   <Tooltip title="Delete Update">
//                     <IconButton
//                       size="small"
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleDeleteUpdate(index);
//                       }}
//                       sx={{ color: 'error.main' }}
//                     >
//                       <DeleteForeverIcon />
//                     </IconButton>
//                   </Tooltip>
//                 </div>
//               )}
//               <div style={cardHeaderStyle}>
//                 <div style={cardTitleStyle}>
//                   <TipsAndUpdatesRoundedIcon style={{ marginRight: "8px", fontSize: "29px" }} />
//                   {update.title}
//                 </div>
//                 <span style={dateStyle}>{new Date().toLocaleDateString()}</span>
//               </div>
//               {update.image && (
//                 <img src={update.image} alt={update.title} style={imageStyle} />
//               )}
//               <p style={descriptionStyle}>{update.description}</p>
//             </div>
//           ))}

//           {shouldShowEditButton() && (
//             <div
//               style={{
//                 ...cardStyle,
//                 border: "2px dashed #6b46c1",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 background: "transparent",
//                 minHeight: "300px"
//               }}
//               onClick={() => {
//                 fetchUpdates();
//                 setShowAddDialog(true);
//               }}
//               onMouseEnter={(e) => {
//                 e.currentTarget.style.background = "rgba(107, 70, 193, 0.1)";
//               }}
//               onMouseLeave={(e) => {
//                 e.currentTarget.style.background = "transparent";
//               }}
//             >
//               <div style={{ textAlign: "center" }}>
//                 <AddIcon sx={{ fontSize: 40, color: "#6b46c1", marginBottom: 1 }} />
//                 <p style={{ color: "#6b46c1" }}>Add New Update</p>
//               </div>
//             </div>
//           )}
//         </div>
//       ) : (
//         <div style={{ textAlign: "center", padding: "20px" }}>
//           <p style={{ fontSize: "1.2rem", color: "#555" }}>
//             Stay tuned! We are working hard to bring you the latest updates. Check back soon for exciting news and announcements.
//           </p>
//         </div>
//       )}

//       {/* Add Update Dialog */}
//       <Dialog
//         open={showAddDialog}
//         onClose={() => !isSubmitting && setShowAddDialog(false)}
//         maxWidth="sm"
//         fullWidth
//       >
//         <SoftBox p={3} mb={1} display="flex" alignItems="center">
//           <Tooltip title="Close" arrow>
//             <IconButton
//               onClick={() => !isSubmitting && setShowAddDialog(false)}
//               disabled={isSubmitting}
//             >
//               <CloseIcon />
//             </IconButton>
//           </Tooltip>
//           <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
//             Add New Update
//           </SoftTypography>
//         </SoftBox>

//         <SoftBox component="form" role="form" px={3}>
//         <SoftBox mb={2}>
//           <SoftTypography variant="h6">Update Title *</SoftTypography>
//           <FormControl fullWidth error={!!errors.title}>
//             <SoftInput
//               placeholder="Update Title"
//               value={newUpdate.title}
//               onChange={(e) => setNewUpdate(prev => ({ ...prev, title: e.target.value }))}
//               size="small"
//             />
//             <FormHelperText>
//               {errors.title || `${newUpdate.title.length}/30 characters`}
//             </FormHelperText>
//           </FormControl>
//         </SoftBox>

//         <SoftBox mb={2}>
//           <SoftTypography variant="h6">Description *</SoftTypography>
//           <FormControl fullWidth error={!!errors.description}>
//             <SoftInput
//               placeholder="Update Description"
//               value={newUpdate.description}
//               onChange={(e) => setNewUpdate(prev => ({ ...prev, description: e.target.value }))}
//               multiline
//               rows={4}
//               size="small"
//             />
//             <FormHelperText>
//               {errors.description || `${newUpdate.description.length}/200 characters`}
//             </FormHelperText>
//           </FormControl>
//         </SoftBox>

//           <SoftBox mb={2}>
//             <SoftTypography variant="h6">Image</SoftTypography>
//             <FormControl fullWidth error={!!errors.image}>
//               <input
//                 accept="image/*"
//                 style={{ display: 'none' }}
//                 id="update-image-upload"
//                 type="file"
//                 onChange={handleFileChange}
//               />
//               <label htmlFor="update-image-upload">
//                 <SoftButton
//                   component="span"
//                   variant="outlined"
//                   color="info"
//                   fullWidth
//                   startIcon={<CloudUploadIcon />}
//                   disabled={isSubmitting}
//                 >
//                   Upload Image
//                 </SoftButton>
//               </label>
//               {errors.image && (
//                 <FormHelperText>{errors.image}</FormHelperText>
//               )}
//             </FormControl>
//             {newUpdate.imagePreview && (
//               <img
//                 src={newUpdate.imagePreview}
//                 alt="Preview"
//                 style={{ width: '100%', maxHeight: '200px', objectFit: 'cover', borderRadius: '8px', marginTop: '8px' }}
//               />
//             )}
//           </SoftBox>
//         </SoftBox>

//         <DialogActions sx={{ px: 3, pb: 3 }}>
//           <SoftButton
//             variant="outlined"
//             color="secondary"
//             onClick={() => !isSubmitting && setShowAddDialog(false)}
//             disabled={isSubmitting}
//           >
//             Cancel
//           </SoftButton>
//           <SoftButton
//             variant="gradient"
//             color="info"
//             onClick={handleAddUpdate}
//             disabled={isSubmitting}
//             startIcon={isSubmitting ? <CircularProgress size={20} /> : <FaSave />}
//           >
//             {isSubmitting ? 'Saving...' : 'Save'}
//           </SoftButton>
//         </DialogActions>
//       </Dialog>

//       {/* Edit Update Dialog */}
//       <Dialog
//         open={!!editingUpdate}
//         onClose={() => !isSubmitting && setEditingUpdate(null)}
//         maxWidth="sm"
//         fullWidth
//       >
//         <DialogTitle>
//           <SoftBox display="flex" alignItems="center">
//             <Tooltip title="Close" arrow>
//               <IconButton
//                 onClick={() => !isSubmitting && setEditingUpdate(null)}
//                 disabled={isSubmitting}
//               >
//                 <FaTimes />
//               </IconButton>
//             </Tooltip>
//             <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
//               Edit Update
//             </SoftTypography>
//           </SoftBox>
//         </DialogTitle>

//         <DialogContent>
//         <SoftBox component="form" role="form">
//           <SoftBox mb={2}>
//             <SoftTypography variant="h6">Update Title *</SoftTypography>
//             <FormControl fullWidth error={!!errors.title}>
//               <SoftInput
//                 placeholder="Update Title"
//                 value={editingUpdate?.title || ''}
//                 onChange={(e) => setEditingUpdate(prev => ({ ...prev, title: e.target.value }))}
//                 size="small"
//               />
//               <FormHelperText>
//                 {errors.title || `${editingUpdate?.title?.length || 0}/30 characters`}
//               </FormHelperText>
//             </FormControl>
//           </SoftBox>

//           <SoftBox mb={2}>
//             <SoftTypography variant="h6">Description *</SoftTypography>
//             <FormControl fullWidth error={!!errors.description}>
//               <SoftInput
//                 placeholder="Update Description"
//                 value={editingUpdate?.description || ''}
//                 onChange={(e) => setEditingUpdate(prev => ({ ...prev, description: e.target.value }))}
//                 multiline
//                 rows={4}
//                 size="small"
//               />
//               <FormHelperText>
//                 {errors.description || `${editingUpdate?.description?.length || 0}/200 characters`}
//               </FormHelperText>
//             </FormControl>
//             </SoftBox>

//             <SoftBox mb={2}>
//               <SoftTypography variant="h6">Image</SoftTypography>
//               <FormControl fullWidth error={!!errors.image}>
//                 <input
//                   accept="image/*"
//                   style={{ display: 'none' }}
//                   id="edit-update-image-upload"
//                   type="file"
//                   onChange={handleEditFileChange}
//                 />
//                 <label htmlFor="edit-update-image-upload">
//                   <SoftButton
//                     component="span"
//                     variant="outlined"
//                     color="info"
//                     fullWidth
//                     startIcon={<CloudUploadIcon />}
//                     disabled={isSubmitting}
//                   >
//                     Change Image
//                   </SoftButton>
//                 </label>
//                 {errors.image && (
//                   <FormHelperText>{errors.image}</FormHelperText>
//                 )}
//               </FormControl>
//               {(editingUpdate?.imagePreview || editingUpdate?.image) && (
//                 <img
//                   src={editingUpdate.imagePreview || editingUpdate.image}
//                   alt="Preview"
//                   style={{ width: '100%', maxHeight: '200px', objectFit: 'cover', borderRadius: '8px', marginTop: '8px' }}
//                 />
//               )}
//             </SoftBox>
//           </SoftBox>
//         </DialogContent>

//         <DialogActions sx={{ px: 3, pb: 3 }}>
//           <SoftButton
//             variant="outlined"
//             color="secondary"
//             onClick={() => !isSubmitting && setEditingUpdate(null)}
//             disabled={isSubmitting}
//           >
//             Cancel
//           </SoftButton>
//           <SoftButton
//             variant="gradient"
//             color="info"
//             onClick={handleEditUpdate}
//             disabled={isSubmitting}
//             startIcon={isSubmitting ? <CircularProgress size={20} /> : <FaSave />}
//           >
//             {isSubmitting ? 'Saving...' : 'Save Changes'}
//           </SoftButton>
//         </DialogActions>
//       </Dialog>

//       <Backdrop
//         sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//         open={loading}
//       >
//         <CircularProgress color="inherit" />
//       </Backdrop>
//     </section>
//   );
// }
import React, { useEffect, useState } from "react";
import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';
import { org, APIData } from "authentication/APIData";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  Backdrop,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Skeleton
} from "@mui/material";
import { FaEdit, FaSave, FaTimes } from "react-icons/fa";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from "@mui/icons-material/Close";
import { CloudUploadIcon } from "lucide-react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { FormControl, FormHelperText } from "@mui/material";
import Swal from "sweetalert2";
import update from "assets/images/uodate.jpeg"

// Responsive card styles with media queries
const getCardStyle = (isMobile, isTablet) => ({
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease",
  marginBottom: "16px",
  cursor: "pointer",
  width: isMobile ? "100%" : isTablet ? "calc(50% - 12px)" : "calc(25% - 18px)",
  height: "auto",
  minHeight: isMobile ? "220px" : "280px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
});

const cardHeaderStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #ddd",
  paddingBottom: "8px",
  marginBottom: "8px",
  flexWrap: "wrap", // Allow wrapping for smaller screens
};

const cardTitleStyle = {
  fontSize: "18px",
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "100%",
};

const dateStyle = {
  fontSize: "12px",
  color: "#666",
  marginTop: "4px", // Add spacing when it wraps
};

const descriptionStyle = {
  fontSize: "14px",
  color: "#444",
  marginTop: "12px",
  display: "-webkit-box",
  WebkitLineClamp: "4",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const getContainerStyle = (isMobile) => ({
  width: "100%",
  maxWidth: "1400px",
  margin: "0 auto",
  padding: isMobile ? "20px 10px" : "40px 20px",
});

const headingStyle = {
  textAlign: "center",
  fontSize: "32px",
  fontWeight: "bold",
  marginBottom: "24px",
  color: "#333",
};

const getImageStyle = (isMobile) => ({
  width: "100%",
  height: isMobile ? "120px" : "150px",
  objectFit: "cover",
  borderRadius: "8px",
  marginTop: "12px",
});

export default function Updates() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

  const [updates, setUpdates] = useState([
    // Dummy data when no updates are fetched
    {
      title: "New Feature Launch",
      description: "We've introduced an exciting new feature that will streamline your workflow and enhance productivity.",
      image: update
    },
    {
      title: "Upcoming Webinar",
      description: "Join our upcoming webinar to learn about the latest trends and innovations in our industry. Don't miss this opportunity!",
      image: update
    },
    {
      title: "Performance Improvements",
      description: "Our latest update includes significant performance optimizations and bug fixes to provide you with a smoother experience.",
      image: update
    }
  ]);
  const [loading, setLoading] = useState(true); // Start with loading true
  const [error, setError] = useState(null);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [editingUpdate, setEditingUpdate] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [landingData, setLandingData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fullScreenDialog, setFullScreenDialog] = useState(false);

  const [newUpdate, setNewUpdate] = useState({
    title: "",
    description: "",
    image: null,
    imagePreview: null
  });

  const [errors, setErrors] = useState({
    title: "",
    description: "",
    image: ""
  });

  useEffect(() => {
    setFullScreenDialog(isMobile);
  }, [isMobile]);

  useEffect(() => {
    const loadUserPermissions = () => {
      const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
      const accessLevel = localStorage.getItem('Access Level');
      
      if (sessionDetails && sessionDetails.user) {
        setCurrentUser(sessionDetails.user);
        // Store userType in state to ensure it persists
        const userType = sessionDetails.userType;
        setIsSuperAdmin(userType === "SUPERADMIN");
      }
      
      if (accessLevel) {
        setPermission(parseInt(accessLevel));
      }
    };

    loadUserPermissions();
    window.addEventListener('storage', loadUserPermissions);
    
    return () => {
      window.removeEventListener('storage', loadUserPermissions);
    };
  }, []);

  const shouldShowEditButton = () => {
    if (permission === null) return false;
    
    if (permission === 1100 || permission === 1000) {
      return false;
    }
    return isSuperAdmin || permission === 1111;
  };

  useEffect(() => {
    shouldShowEditButton();
  }, []);

  const fetchUpdates = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${APIData.api}landing-page/org/${org}`, {
        headers: APIData.headers,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch updates");
      }

      const data = await response.json();
      setLandingData(data);
      setUpdates(data.latestUpdates || []);
    } catch (error) {
      setError("Error fetching updates: " + error.message);
      console.error("Error fetching updates:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 800); // Give a slight delay to show skeleton
    }
  };

  useEffect(() => {
    fetchUpdates();
  }, []);

  const handleDeleteUpdate = async (index) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6b46c1',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        setLoading(true);
        const updatedUpdates = updates.filter((_, i) => i !== index);
        const payload = {
          ...landingData,
          latestUpdates: updatedUpdates,
          org: org,
          updatedBy: currentUser || "SYSTEM",
          updatedDateTime: new Date().toISOString()
        };

        const response = await fetch(`${APIData.api}landing-page/Add`, {
          method: "POST",
          headers: {
            ...APIData.headers,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error("Failed to delete update");
        }

        await fetchUpdates();

        await Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: 'Update has been deleted.',
          timer: 1500,
          showConfirmButton: false
        });
      }
    } catch (error) {
      console.error("Error deleting update:", error);
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete update: ' + error.message,
        confirmButtonColor: '#6b46c1'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setNewUpdate(prev => ({
          ...prev,
          image: file,
          imagePreview: URL.createObjectURL(file)
        }));
        setErrors(prev => ({ ...prev, image: "" }));
      } else {
        setErrors(prev => ({ ...prev, image: "Please select an image file" }));
      }
    }
  };

  const handleEditFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setEditingUpdate(prev => ({
          ...prev,
          newImage: file,
          imagePreview: URL.createObjectURL(file)
        }));
        setErrors(prev => ({ ...prev, image: "" }));
      } else {
        setErrors(prev => ({ ...prev, image: "Please select an image file" }));
      }
    }
  };

  const validateUpdate = (update) => {
    const newErrors = {};

    if (!update.title) {
      newErrors.title = "Title is required.";
    } else if (update.title.length > 30) {
      newErrors.title = "Title must be less than 30 characters.";
    }

    if (!update.description) {
      newErrors.description = "Description is required.";
    } else if (update.description.length > 200) {
      newErrors.description = "Description must be less than 200 characters.";
    }

    return newErrors;
  };

  const handleAddUpdate = async () => {
    const newErrors = validateUpdate(newUpdate);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsSubmitting(true);
      try {
        const currentDateTime = new Date().toISOString();
        const newUpdateData = {
          title: newUpdate.title,
          description: newUpdate.description,
          image: "" // Initially empty, will be updated after upload
        };

        const updatedData = {
          ...landingData,
          latestUpdates: [...updates, newUpdateData],
          org: org,
          updatedBy: currentUser || "SYSTEM",
          updatedDateTime: currentDateTime
        };

        const response = await fetch(`${APIData.api}landing-page/Add`, {
          method: "POST",
          headers: {
            ...APIData.headers,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
          throw new Error("Failed to add update");
        }

        // Upload image if exists
        if (newUpdate.image) {
          const updateIndex = updates.length;
          const formData = new FormData();
          formData.append("id", org);
          formData.append("latestUpdatesImage", newUpdate.image);
          formData.append("latestUpdateIndex", updateIndex);

          const imageResponse = await fetch(
            `${APIData.api}landing-page/${org}/upload-images?latestUpdateIndex=${updateIndex}`,
            {
              method: "POST",
              headers: {
                ...APIData.headers,
              },
              body: formData,
            }
          );

          if (!imageResponse.ok) {
            throw new Error("Failed to upload image");
          }
        }

        await fetchUpdates();
        setShowAddDialog(false);
        setNewUpdate({ title: "", description: "", image: null, imagePreview: null });

        await Swal.fire({
          icon: "success",
          title: "Update Added Successfully",
          timer: 1500,
          showConfirmButton: false,
        });
      } catch (error) {
        console.error("Error adding update:", error);
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to add update: ' + error.message,
          confirmButtonColor: '#6b46c1'
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleEditUpdate = async () => {
    const newErrors = validateUpdate(editingUpdate);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsSubmitting(true);
    try {
      const updatedUpdates = updates.map((update, index) =>
        index === editingUpdate.index ? {
          title: editingUpdate.title,
          description: editingUpdate.description,
          image: editingUpdate.image
        } : update
      );

      const payload = {
        ...landingData,
        latestUpdates: updatedUpdates,
        org: org,
        updatedBy: currentUser || "SYSTEM",
        updatedDateTime: new Date().toISOString()
      };

      const response = await fetch(`${APIData.api}landing-page/Add`, {
        method: "POST",
        headers: {
          ...APIData.headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to update");
      }

      // Upload new image if exists
      if (editingUpdate.newImage) {
        const formData = new FormData();
        formData.append("id", org);
        formData.append("latestUpdatesImage", editingUpdate.newImage);
        formData.append("latestUpdateIndex", editingUpdate.index);

        const imageResponse = await fetch(
          `${APIData.api}landing-page/${org}/upload-images?latestUpdateIndex=${editingUpdate.index}`,
          {
            method: "POST",
            headers: {
              ...APIData.headers,
            },
            body: formData,
          }
        );

        if (!imageResponse.ok) {
          throw new Error("Failed to upload image");
        }
      }

      await fetchUpdates();
      setEditingUpdate(null);

      await Swal.fire({
        icon: "success",
        title: "Update Modified Successfully",
        timer: 1500,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error("Error updating:", error);
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to update: ' + error.message,
        confirmButtonColor: '#6b46c1'
      });
    } finally {
      setIsSubmitting(false);
    }
  }
};

  // Responsive heading style based on screen size
  const responsiveHeadingStyle = {
    ...headingStyle,
    fontSize: isMobile ? "24px" : isTablet ? "28px" : "32px",
    marginBottom: isMobile ? "16px" : "24px",
  };

  // Skeleton card component
  const SkeletonCard = () => {
    return (
      <div style={getCardStyle(isMobile, isTablet)}>
        <div style={{
          ...cardHeaderStyle,
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "flex-start" : "center",
        }}>
          <div style={{
            ...cardTitleStyle,
            fontSize: isMobile ? "16px" : "18px",
            marginBottom: isMobile ? "4px" : "0",
          }}>
            <Skeleton variant="circular" width={isMobile ? 18 : 20} height={isMobile ? 18 : 20} style={{ marginRight: "6px" }} />
            <Skeleton variant="text" width={isMobile ? 120 : 150} height={isMobile ? 24 : 28} />
          </div>
          <Skeleton variant="text" width={80} height={16} style={{ marginLeft: isMobile ? 0 : 'auto' }} />
        </div>
        <Skeleton variant="rectangular" width="100%" height={isMobile ? 120 : 150} style={{ 
          borderRadius: "8px", 
          marginTop: "12px" 
        }} />
        <Skeleton variant="text" width="100%" height={16} style={{ marginTop: "16px" }} />
        <Skeleton variant="text" width="100%" height={16} style={{ marginTop: "8px" }} />
        <Skeleton variant="text" width="90%" height={16} style={{ marginTop: "8px" }} />
        <Skeleton variant="text" width="80%" height={16} style={{ marginTop: "8px" }} />
      </div>
    );
  };

  // Add skeleton card component
  const SkeletonAddCard = () => {
    return (
      <div style={{
        ...getCardStyle(isMobile, isTablet),
        border: "2px dashed #E0E0E0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(0, 0, 0, 0.04)",
        minHeight: isMobile ? "220px" : "280px"
      }}>
        <div style={{ textAlign: "center" }}>
          <Skeleton variant="circular" width={isMobile ? 40 : 60} height={isMobile ? 40 : 60} style={{ margin: '0 auto' }} />
          <Skeleton variant="text" width={100} height={24} style={{ margin: '8px auto' }} />
        </div>
      </div>
    );
  };

  return (
    <section style={getContainerStyle(isMobile)} id="updates">
      {loading ? (
        <Skeleton variant="text" width={isMobile ? 200 : 300} height={isMobile ? 38 : 48} style={{ margin: '0 auto', marginBottom: isMobile ? "16px" : "24px", borderRadius: "4px" }} />
      ) : (
        <h2 style={responsiveHeadingStyle}>Latest Updates</h2>
      )}
      
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: isMobile ? "center" : "flex-start",
          gap: isMobile ? "16px" : isTablet ? "20px" : "24px",
          padding: isMobile ? "0 8px" : "0 16px",
        }}
      >
        {loading ? (
          // Render skeleton cards when loading
          <>
            {[...Array(3)].map((_, index) => (
              <SkeletonCard key={index} />
            ))}
            {shouldShowEditButton() && <SkeletonAddCard />}
          </>
        ) : (
          // Render actual content when loaded
          <>
            {updates.map((update, index) => (
              <div
                key={index}
                style={getCardStyle(isMobile, isTablet)}
                onMouseEnter={(e) => {
                  if (!isMobile) {
                    e.currentTarget.style.transform = "scale(1.03)";
                    e.currentTarget.style.boxShadow = "0 6px 14px rgba(0, 0, 0, 0.15)";
                  }
                }}
                onMouseLeave={(e) => {
                  if (!isMobile) {
                    e.currentTarget.style.transform = "scale(1)";
                    e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                  }
                }}
              >
                {shouldShowEditButton() && (
                  <div style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    zIndex: 1,
                    display: "flex",
                    gap: "4px"
                  }}>
                    <Tooltip title="Edit Update">
                      <IconButton
                        size={isMobile ? "small" : "medium"}
                        onClick={() => {
                          fetchUpdates();
                          setEditingUpdate({ ...update, index });
                        }}
                      >
                        <FaEdit style={{ fontSize: isMobile ? "12px" : "14px" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Update">
                      <IconButton
                        size={isMobile ? "small" : "medium"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteUpdate(index);
                        }}
                        sx={{ color: 'error.main' }}
                      >
                        <DeleteForeverIcon style={{ fontSize: isMobile ? "14px" : "16px" }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                <div style={{
                  ...cardHeaderStyle,
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                }}>
                  <div style={{
                    ...cardTitleStyle,
                    fontSize: isMobile ? "16px" : "18px",
                    marginBottom: isMobile ? "4px" : "0",
                  }}>
                    <TipsAndUpdatesRoundedIcon style={{ 
                      marginRight: "6px", 
                      fontSize: isMobile ? "18px" : "20px" 
                    }} />
                    {update.title}
                  </div>
                  <span style={dateStyle}>{new Date().toLocaleDateString()}</span>
                </div>
                {update.image && (
                  <img src={update.image} alt={update.title} style={getImageStyle(isMobile)} />
                )}
                <p style={{
                  ...descriptionStyle,
                  WebkitLineClamp: isMobile ? "3" : "4",
                }}>{update.description}</p>
              </div>
            ))}

            {shouldShowEditButton() && (
              <div
                style={{
                  ...getCardStyle(isMobile, isTablet),
                  border: "2px dashed #6b46c1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "transparent",
                  minHeight: isMobile ? "220px" : "280px"
                }}
                onClick={() => {
                  fetchUpdates();
                  setShowAddDialog(true);
                }}
                onMouseEnter={(e) => {
                  if (!isMobile) {
                    e.currentTarget.style.background = "rgba(107, 70, 193, 0.1)";
                    e.currentTarget.style.transform = "scale(1.03)";
                  }
                }}
                onMouseLeave={(e) => {
                  if (!isMobile) {
                    e.currentTarget.style.background = "transparent";
                    e.currentTarget.style.transform = "scale(1)";
                  }
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <AddIcon sx={{ 
                    fontSize: isMobile ? 24 : 32, 
                    color: "#6b46c1", 
                    marginBottom: 1 
                  }} />
                  <p style={{ color: "#6b46c1" }}>Add New Update</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {/* Add Update Dialog */}
      <Dialog
        open={showAddDialog}
        onClose={() => !isSubmitting && setShowAddDialog(false)}
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreenDialog}
      >
        <SoftBox p={isMobile ? 2 : 3} mb={1} display="flex" alignItems="center">
          <Tooltip title="Close" arrow>
            <IconButton
              onClick={() => !isSubmitting && setShowAddDialog(false)}
              disabled={isSubmitting}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <SoftTypography variant={isMobile ? "h6" : "h5"} fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
            Add New Update
          </SoftTypography>
        </SoftBox>

        <SoftBox component="form" role="form" px={isMobile ? 2 : 3}>
        <SoftBox mb={2}>
          <SoftTypography variant={isMobile ? "subtitle2" : "h6"}>Update Title *</SoftTypography>
          <FormControl fullWidth error={!!errors.title}>
            <SoftInput
              placeholder="Update Title"
              value={newUpdate.title}
              onChange={(e) => setNewUpdate(prev => ({ ...prev, title: e.target.value }))}
              size="small"
            />
            <FormHelperText>
              {errors.title || `${newUpdate.title.length}/30 characters`}
            </FormHelperText>
          </FormControl>
        </SoftBox>

        <SoftBox mb={2}>
          <SoftTypography variant={isMobile ? "subtitle2" : "h6"}>Description *</SoftTypography>
          <FormControl fullWidth error={!!errors.description}>
            <SoftInput
              placeholder="Update Description"
              value={newUpdate.description}
              onChange={(e) => setNewUpdate(prev => ({ ...prev, description: e.target.value }))}
              multiline
              rows={isMobile ? 3 : 4}
              size="small"
            />
            <FormHelperText>
              {errors.description || `${newUpdate.description.length}/200 characters`}
            </FormHelperText>
          </FormControl>
        </SoftBox>

          <SoftBox mb={2}>
            <SoftTypography variant={isMobile ? "subtitle2" : "h6"}>Image</SoftTypography>
            <FormControl fullWidth error={!!errors.image}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="update-image-upload"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="update-image-upload">
                <SoftButton
                  component="span"
                  variant="outlined"
                  color="info"
                  fullWidth
                  startIcon={<CloudUploadIcon />}
                  disabled={isSubmitting}
                >
                  Upload Image
                </SoftButton>
              </label>
              {errors.image && (
                <FormHelperText>{errors.image}</FormHelperText>
              )}
            </FormControl>
            {newUpdate.imagePreview && (
              <div style={{ 
                maxHeight: isMobile ? '150px' : '200px',
                overflow: 'hidden',
                marginTop: '8px',
                borderRadius: '8px'
              }}>
                <img
                  src={newUpdate.imagePreview}
                  alt="Preview"
                  style={{ 
                    width: '100%', 
                    objectFit: 'cover', 
                    borderRadius: '8px' 
                  }}
                />
              </div>
            )}
          </SoftBox>
        </SoftBox>

        <DialogActions sx={{ px: isMobile ? 2 : 3, pb: isMobile ? 2 : 3 }}>
          <SoftButton
            variant="outlined"
            color="secondary"
            onClick={() => !isSubmitting && setShowAddDialog(false)}
            disabled={isSubmitting}
            size={isMobile ? "small" : "medium"}
          >
            Cancel
          </SoftButton>
          <SoftButton
            variant="gradient"
            color="info"
            onClick={handleAddUpdate}
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={isMobile ? 16 : 20} /> : <FaSave />}
            size={isMobile ? "small" : "medium"}
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </SoftButton>
        </DialogActions>
      </Dialog>

      {/* Edit Update Dialog */}
      <Dialog
        open={!!editingUpdate}
        onClose={() => !isSubmitting && setEditingUpdate(null)}
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreenDialog}
      >
        <DialogTitle>
          <SoftBox display="flex" alignItems="center">
            <Tooltip title="Close" arrow>
              <IconButton
                onClick={() => !isSubmitting && setEditingUpdate(null)}
                disabled={isSubmitting}
              >
                <FaTimes />
              </IconButton>
            </Tooltip>
            <SoftTypography variant={isMobile ? "h6" : "h5"} fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
              Edit Update
            </SoftTypography>
          </SoftBox>
        </DialogTitle>

        <DialogContent>
        <SoftBox component="form" role="form">
          <SoftBox mb={2}>
            <SoftTypography variant={isMobile ? "subtitle2" : "h6"}>Update Title *</SoftTypography>
            <FormControl fullWidth error={!!errors.title}>
              <SoftInput
                placeholder="Update Title"
                value={editingUpdate?.title || ''}
                onChange={(e) => setEditingUpdate(prev => ({ ...prev, title: e.target.value }))}
                size="small"
              />
              <FormHelperText>
                {errors.title || `${editingUpdate?.title?.length || 0}/30 characters`}
              </FormHelperText>
            </FormControl>
          </SoftBox>

          <SoftBox mb={2}>
            <SoftTypography variant={isMobile ? "subtitle2" : "h6"}>Description *</SoftTypography>
            <FormControl fullWidth error={!!errors.description}>
               <SoftInput
                placeholder="Update Description"
                value={editingUpdate?.description || ''}
                onChange={(e) => setEditingUpdate(prev => ({ ...prev, description: e.target.value }))}
                multiline
                rows={isMobile ? 3 : 4}
                size="small"
              />
              <FormHelperText>
                {errors.description || `${editingUpdate?.description?.length || 0}/200 characters`}
              </FormHelperText>
            </FormControl>
            </SoftBox>

            <SoftBox mb={2}>
              <SoftTypography variant={isMobile ? "subtitle2" : "h6"}>Image</SoftTypography>
              <FormControl fullWidth error={!!errors.image}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="edit-update-image-upload"
                  type="file"
                  onChange={handleEditFileChange}
                />
                <label htmlFor="edit-update-image-upload">
                  <SoftButton
                    component="span"
                    variant="outlined"
                    color="info"
                    fullWidth
                    startIcon={<CloudUploadIcon />}
                    disabled={isSubmitting}
                    size={isMobile ? "small" : "medium"}
                  >
                    Change Image
                  </SoftButton>
                </label>
                {errors.image && (
                  <FormHelperText>{errors.image}</FormHelperText>
                )}
              </FormControl>
              {(editingUpdate?.imagePreview || editingUpdate?.image) && (
                <div style={{ 
                  maxHeight: isMobile ? '150px' : '200px',
                  overflow: 'hidden',
                  marginTop: '8px',
                  borderRadius: '8px'
                }}>
                  <img
                    src={editingUpdate.imagePreview || editingUpdate.image}
                    alt="Preview"
                    style={{ 
                      width: '100%', 
                      objectFit: 'cover', 
                      borderRadius: '8px' 
                    }}
                  />
                </div>
              )}
            </SoftBox>
          </SoftBox>
        </DialogContent>

        <DialogActions sx={{ px: isMobile ? 2 : 3, pb: isMobile ? 2 : 3 }}>
          <SoftButton
            variant="outlined"
            color="secondary"
            onClick={() => !isSubmitting && setEditingUpdate(null)}
            disabled={isSubmitting}
            size={isMobile ? "small" : "medium"}
          >
            Cancel
          </SoftButton>
          <SoftButton
            variant="gradient"
            color="info"
            onClick={handleEditUpdate}
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={isMobile ? 16 : 20} /> : <FaSave />}
            size={isMobile ? "small" : "medium"}
          >
            {isSubmitting ? 'Saving...' : 'Save Changes'}
          </SoftButton>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </section>
  );
}