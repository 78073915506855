import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Facebook, Twitter, Linkedin, Instagram, Youtube } from "lucide-react";
import { org, APIData } from "authentication/APIData";
import axios from "axios";
 
export default function Footers() {
  const [socialLinks, setSocialLinks] = useState({});
  const [orgName, setOrgName] = useState("");
  const [orgShortName, setorgShortName] = useState("");
  const [orgEmail, setOrgEmail] = useState("info@care4edu.com");
  const [orgPhone, setOrgPhone] = useState("+91 9535352376");
  const [orgAddress, setOrgAddress] = useState("Doddabommasandra,Vidyaranyapura, Near Basva Temple, Bengaluru");
 
  useEffect(() => {
    // Fetch social media links
    axios
      .get(`${APIData.api}social-media/org/${org}`, {
        headers: APIData.headers,
      })
      .then((response) => {
        console.log("Social Media API Response:", response.data);
        setSocialLinks(response.data);
      })
      .catch((error) => console.error("Error fetching social media links:", error));
 
    // Fetch organization details
    axios
      .get(`https://qa-api.care4edu.com/c4e/org-mdm/org-id?orgId=${org}`)
      .then((response) => {
        console.log("Organization API Response:", response.data);
        setOrgName(response.data.orgName);
        setorgShortName(response.data.orgShortName);
        setOrgEmail(response.data.orgEmailId || "info@care4edu.com");
        setOrgPhone(response.data.orgMob || "+91 9535352376");
        setOrgAddress(response.data.orgAddress || "Doddabommasandra,Vidyaranyapura, Near Basva Temple, Bengaluru");
      })
      .catch((error) => console.error("Error fetching organization details:", error));
  }, []);
 
  return (
    <footer
      style={{
        backgroundColor: "#2d2d2d",
        color: "#ffffff",
        padding: "16px 0",
        width: "100%"
      }}
    >
      <div
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "0 16px",
          display: "flex",
          flexDirection: "column",
          gap: "24px"
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "40px",
            width: "100%"
          }}
        >
          <div style={{ minWidth: "120px", flex: "1" }}>
            <h3 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "12px" }}>Pages</h3>
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              <li style={{ marginBottom: "6px" }}>
                <Link to="/blog" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }}>
                  Blog
                </Link>
              </li>
              {/* <li style={{ marginBottom: "6px" }}>
                <Link to="/products" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }}>
                  Products
                </Link>
              </li> */}
              <li style={{ marginBottom: "6px" }}>
                <Link to="/courses" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }}>
                  Courses
                </Link>
              </li>
            </ul>
          </div>
 
          <div style={{ minWidth: "120px", flex: "1" }}>
            <h3 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "12px" }}>Company</h3>
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              <li style={{ marginBottom: "6px" }}>
                <Link to="/about-us" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }}>
                  About Us
                </Link>
              </li>
              <li style={{ marginBottom: "6px" }}>
                <Link to="/contact-us" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
 
          <div style={{ minWidth: "120px", flex: "1" }}>
            <h3 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "12px" }}>Connect</h3>
            <div style={{ display: "flex", gap: "12px", flexWrap: "wrap", marginBottom: "12px" }}>
              {socialLinks.facebook && (
                <Link to={socialLinks.facebook} target="_blank" rel="noopener noreferrer">
                  <Facebook style={{ width: "24px", height: "24px", color: "#9ca3af" }} />
                </Link>
              )}
              {socialLinks.twitter && (
                <Link to={socialLinks.twitter} target="_blank" rel="noopener noreferrer">
                  <Twitter style={{ width: "24px", height: "24px", color: "#9ca3af" }} />
                </Link>
              )}
              {socialLinks.linkedin && (
                <Link to={socialLinks.linkedin} target="_blank" rel="noopener noreferrer">
                  <Linkedin style={{ width: "24px", height: "24px", color: "#9ca3af" }} />
                </Link>
              )}
              {socialLinks.instagram && (
                <Link to={socialLinks.instagram} target="_blank" rel="noopener noreferrer">
                  <Instagram style={{ width: "24px", height: "24px", color: "#9ca3af" }} />
                </Link>
              )}
              {socialLinks.youtube && (
                <Link to={socialLinks.youtube} target="_blank" rel="noopener noreferrer">
                  <Youtube style={{ width: "24px", height: "24px", color: "#9ca3af" }} />
                </Link>
              )}
            </div>
          </div>
         
          <div style={{ minWidth: "120px", flex: "1" }}>
            <h3 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "12px" }}>
              {orgName || "Care 4 Edu Solutions Pvt Ltd"}
            </h3>
            <p style={{ fontSize: "14px", color: "#9ca3af", marginBottom: "12px" }}>
              {orgAddress}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}