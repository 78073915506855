import React, { useEffect, useState } from 'react';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import { Card, Grid, FormControl, Tooltip } from "@mui/material";
import { APIData } from 'authentication/APIData';
import axios from 'axios';
import { toast } from "react-toastify";
import { CircularProgress, Backdrop } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import * as AiIcons from "react-icons/ai";
import Swal from 'sweetalert2';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { org } from 'authentication/APIData';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import {
    MenuItem,
    TextField,
    Stack,
    Typography
} from '@mui/material';
import { AccessTime } from '@mui/icons-material';

function EventForm() {
    const [eventName, setEventName] = useState('');
    const [description, setDescription] = useState('');
    const [duration, setDuration] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [location, setLocation] = useState('');
    const [organizer, setOrganizer] = useState('');
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState("");
    const [mobileImage, setMobileImage] = useState(null);
    const [mobileImagePreview, setMobileImagePreview] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [usernames, setUsernames] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [startTime, setStartTime] = useState('10:00 AM');
    const [endTime, setEndTime] = useState('12:00 PM');
    const [fetchingDescription, setFetchingDescription] = useState(false);

    const fetchDescription = async (eventTitle) => {
        if (!eventTitle?.trim() || eventTitle.length < 3) {
            return;
        }

        setFetchingDescription(true);

        try {
            const prompt = `give only one description about ${eventTitle} event in three or four lines`;
            const encodedQuestion = encodeURIComponent(prompt);

            const response = await axios({
                method: 'POST',
                url: `${APIData.tpapi}openai/content?question=${encodedQuestion}`,
                headers: APIData.tpheaders
            });

            const aiDescription = response.data;
            setDescription(aiDescription.trim());

        } catch (error) {
            console.error("Error fetching AI description:", error);
            await Swal.fire({
                icon: "error",
                title: "Failed to Fetch Description",
                text: error.response?.data?.message || "An error occurred while generating the description. You can enter it manually.",
                confirmButtonText: "OK",
            });
        } finally {
            setFetchingDescription(false);
        }
    };

    const handleEventNameChange = (e) => {
        const newEventName = e.target.value;
        setEventName(newEventName);

        // Trigger description fetch when event name is at least 3 characters
        if (newEventName.length >= 3) {
            fetchDescription(newEventName);
        }
    };

    // Generate time options in 30-minute intervals
    const convertTimeToMinutes = (timeStr) => {
        const [time, period] = timeStr.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (period === 'PM' && hours !== 12) hours += 12;
        if (period === 'AM' && hours === 12) hours = 0;

        return hours * 60 + minutes;
    };

    // Check time difference
    const validateTimeDifference = (start, end) => {
        if (!start || !end) return true; // Skip validation if either time is not selected

        const startMinutes = convertTimeToMinutes(start);
        const endMinutes = convertTimeToMinutes(end);
        const difference = endMinutes - startMinutes;

        if (difference <= 0) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Duration',
                text: 'End time must be after start time.',
            });
            return false;
        }

        if (difference < 30) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Duration',
                text: 'Minimum duration must be 30 minutes.',
            });
            return false;
        }

        return true;
    };

    // Generate time options in 30-minute intervals
    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const h = hour % 12 || 12;
                const m = minute.toString().padStart(2, '0');
                const ampm = hour < 12 ? 'AM' : 'PM';
                options.push(`${h}:${m} ${ampm}`);
            }
        }
        return options;
    };

    const timeOptions = generateTimeOptions();

    const handleStartTimeChange = (event) => {
        const newTime = event.target.value;
        setStartTime(newTime);
        if (validateTimeDifference(newTime, endTime)) {
            setDuration(`${newTime} to ${endTime}`);
        }
    };

    const handleEndTimeChange = (event) => {
        const newTime = event.target.value;
        setEndTime(newTime);
        if (validateTimeDifference(startTime, newTime)) {
            setDuration(`${startTime} to ${newTime}`);
        }
    };

    // Check if duration is empty for required validation
    useEffect(() => {
        if (!startTime || !endTime) {
            setDuration(''); // Clear duration if either time is not selected
        }
    }, [startTime, endTime]);


    useEffect(() => {
        const fetchUsernames = async () => {
            try {
                const response = await axios.get(`${APIData.api}employee/username?org=c4e`, {
                    headers: APIData.headers
                });

                const options = response.data.map(username => ({
                    value: username,
                    label: username
                }));

                setUsernames(options);
            } catch (error) {
                toast.error('Error fetching usernames');
                console.error('Error:', error);
            }
        };

        fetchUsernames();
    }, []);

    const handleSelectChange = (selectedOption) => {
        setSelectedUser(selectedOption);
    };

    const handleImageChange = (event, type) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 1000000) {
                setError("File too large. Max file size is 1MB.");
                if (type === 'desktop') {
                    setImage(null);
                    setImagePreview("");
                } else {
                    setMobileImage(null);
                    setMobileImagePreview("");
                }

                Swal.fire({
                    icon: 'error',
                    title: 'Image Too Large',
                    text: 'Image should be less than 1MB.',
                });

                return;
            }

            if (type === 'desktop') {
                setImage(file);
                setImagePreview(URL.createObjectURL(file));
            } else {
                setMobileImage(file);
                setMobileImagePreview(URL.createObjectURL(file));
            }

            setError("");
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const eventNameRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s]*$/;
        if (!eventNameRegex.test(eventName)) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Event Name',
                text: 'Event name cannot contain only numbers and must include at least one letter.',
            });
            return;
        }

        // const descriptionRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s]*$/;
        // if (!descriptionRegex.test(description)) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Invalid Description',
        //         text: 'Description cannot contain only numbers or special characters. It must include some letter.',
        //     });
        //     return;
        // }

        if (description.length > 255) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Description',
                text: 'Description cannot exceed 255 characters.',
            });
            return;
        }

        // const durationRegex = /^(0[1-9]|1[0-2]):[0-5][0-9](am|pm) to (0[1-9]|1[0-2]):[0-5][0-9](am|pm)$/i;
        const durationRegex = /^([0-9]|0[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM|am|pm)\s*to\s*([0-9]|0[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM|am|pm)$/i;

        
        if (!durationRegex.test(duration)) {
            
            Swal.fire({
                icon: 'error',
                title: 'Invalid Duration',
                text: 'Duration must be in the format "01:00pm to 04:00pm".',
            });
            return;
        }

        const today = new Date().toISOString().split('T')[0];
        if (eventDate < today) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Date',
                text: 'Past date is not allowed.',
            });
            return;
        }

        const locationRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s]*$/;
        if (!locationRegex.test(location)) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Description',
                text: 'Location cannot contain only numbers or special characters. It must include some letter.',
            });
            return;
        }

        if (location.length > 255) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Location',
                text: 'Location cannot exceed 255 characters.',
            });
            return;
        }

        const organizerRegex = /\d|[!@#$%^&*(),.?":{}|<>]/;
        if (organizerRegex.test(organizer)) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Organizer',
                text: 'Organizer cannot contain numbers or special characters.',
            });
            return;
        }

        if (!image || !mobileImage) {
            Swal.fire({
                icon: 'error',
                title: 'Images Required',
                text: 'Both desktop and mobile images are required.',
            });
            return;
        }

        try {
            setLoading(true);

            const url = `${APIData.api}events/`;
            const formData = {
                description,
                duration,
                event_date: eventDate,
                event_name: eventName,
                location,
                org: org,
                organizer: selectedUser?.value,
                image_url: "",
                mobile_image: ""
            };

            const eventResponse = await axios.post(url, formData, { headers: APIData.headers });
            const eventid = eventResponse.data.id;

            const imageFormData = new FormData();
            imageFormData.append('id', eventid);
            imageFormData.append('image', image);
            imageFormData.append('mobile_image', mobileImage);

            const putUrl = `${APIData.api}events/?id=${eventid}&org=${org}`;
            await axios.put(putUrl, imageFormData, { headers: { ...APIData.headers, 'Content-Type': 'multipart/form-data' } });

            Swal.fire({
                icon: "success",
                title: "Event Created Successfully",
                showConfirmButton: false,
                timer: 1500
            });
            setTimeout(() => {
                navigate("/event-list");
            }, 1500);

        } catch (error) {
            console.error(error);
            toast.error('Error submitting form');
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4" mt={3}>
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Tooltip title="Close" arrow>
                                <Link to="/event-list">
                                    <AiIcons.AiOutlineCloseCircle />
                                </Link>
                            </Tooltip>
                            <SoftTypography mb={2} variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Create New Event
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox component="form" role="form" onSubmit={handleSubmit} px={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Event Name</SoftTypography>
                                        <FormControl fullWidth>
                                            <SoftInput
                                                id="eventName"
                                                name="eventName"
                                                value={eventName}
                                                onChange={handleEventNameChange}
                                                required
                                            />
                                        </FormControl>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Description</SoftTypography>
                                        <FormControl fullWidth>
                                            <SoftInput
                                                id="description"
                                                name="description"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                multiline
                                                rows={4}
                                                required
                                                disabled={fetchingDescription}
                                            />
                                        </FormControl>
                                        {fetchingDescription && (
                                            <SoftTypography variant="caption" color="text">
                                                Generating AI description based on the event name...
                                            </SoftTypography>
                                        )}
                                        <SoftTypography variant="caption" color="text">
                                            You can edit this description if needed
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Duration*</SoftTypography>
                                        <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 1 }}>
                                            <TextField
                                                select
                                                required
                                                value={startTime}
                                                onChange={handleStartTimeChange}
                                                variant="outlined"
                                                sx={{ width: 200 }}
                                                InputProps={{
                                                    startAdornment: <AccessTime sx={{ mr: 1, color: 'text.secondary' }} />,
                                                }}
                                                placeholder="Select start time"
                                            >
                                                {timeOptions.map((time) => (
                                                    <MenuItem key={time} value={time}>
                                                        {time}
                                                    </MenuItem>
                                                ))}
                                            </TextField>

                                            <Typography variant="body1">to</Typography>

                                            <TextField
                                                select
                                                required
                                                value={endTime}
                                                onChange={handleEndTimeChange}
                                                variant="outlined"
                                                sx={{ width: 200 }}
                                                InputProps={{
                                                    startAdornment: <AccessTime sx={{ mr: 1, color: 'text.secondary' }} />,
                                                }}
                                                placeholder="Select end time"
                                            >
                                                {timeOptions.map((time) => (
                                                    <MenuItem key={time} value={time}>
                                                        {time}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Stack>
                                        <SoftTypography variant="caption" sx={{ mt: 1, display: 'block', color: 'text.secondary' }}>
                                            *Required. Minimum duration: 30 minutes
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Event Date</SoftTypography>
                                        <FormControl fullWidth>
                                            <SoftInput
                                                id="eventDate"
                                                name="eventDate"
                                                type="date"
                                                value={eventDate}
                                                onChange={(e) => setEventDate(e.target.value)}
                                                required
                                            />
                                        </FormControl>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Location</SoftTypography>
                                        <FormControl fullWidth>
                                            <SoftInput
                                                id="location"
                                                name="location"
                                                value={location}
                                                onChange={(e) => setLocation(e.target.value)}
                                                required
                                            />
                                        </FormControl>
                                    </SoftBox>
                                </Grid>

                                {/* <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Organizer</SoftTypography>
                                        <FormControl fullWidth>
                                            <SoftInput
                                                id="organizer"
                                                name="organizer"
                                                value={organizer}
                                                onChange={(e) => setOrganizer(e.target.value)}
                                                required
                                            />
                                        </FormControl>
                                    </SoftBox>
                                </Grid> */}

                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Organizer</SoftTypography>
                                        <CustomSelect
                                            options={usernames}
                                            placeholder="Select Organizer"
                                            name="organizer"
                                            value={selectedUser}
                                            onChange={handleSelectChange}
                                        />
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" gap="3">
                                        <label htmlFor="upload-image">
                                            <SoftButton variant="contained" component="span">
                                                Upload Image
                                            </SoftButton>
                                            <input
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                id="upload-image"
                                                type="file"
                                                onChange={(e) => handleImageChange(e, 'desktop')}
                                            />
                                        </label>
                                        <label htmlFor="upload-mobile-image">
                                            <SoftButton variant="contained" component="span">
                                                Upload Mobile Image
                                            </SoftButton>
                                            <input
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                id="upload-mobile-image"
                                                type="file"
                                                onChange={(e) => handleImageChange(e, 'mobile')}
                                            />
                                        </label>
                                    </SoftBox>
                                    <SoftBox mt={2} display="flex" justifyContent="center" alignItems="center">
                                        {imagePreview && (
                                            <img src={imagePreview} alt="Desktop Preview" style={{ width: '200px', marginRight: '10px' }} />
                                        )}
                                        {mobileImagePreview && (
                                            <img src={mobileImagePreview} alt="Mobile Preview" style={{ width: '200px' }} />
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox display="flex" justifyContent="center" mt={4} mb={2}>
                                        <SoftButton type="submit" variant="gradient" color="info" fullWidth disabled={loading}>
                                            {loading ? "Submitting..." : "Submit"}
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default EventForm;