import React, { useState, useEffect } from "react";
import { org, APIData } from "authentication/APIData";
import axios from "axios";
import "./Appbar.css"
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";

const SocialSidebar = () => {
  const [socialLinks, setSocialLinks] = useState({});

  useEffect(() => {
    axios
      .get(`${APIData.api}social-media/org/${org}`, {
        headers: APIData.headers,
      })
      .then((response) => {
        console.log("API Response:", response.data);
        setSocialLinks(response.data);
      })
      .catch((error) => console.error("Error fetching social media links:", error));
  }, []);

  return (
    <div style={styles.sidebar}>
      {socialLinks.facebook && (
        <a 
          href={socialLinks.facebook} 
          target="_blank" 
          rel="noopener noreferrer" 
          style={{...styles.icon, backgroundColor: "#1877F2", color: "#FFFFFF"}}
          className="social-icon"
        >
          <FaFacebook size={30} />
        </a>
      )}
      {socialLinks.instagram && (
        <a 
          href={socialLinks.instagram} 
          target="_blank" 
          rel="noopener noreferrer" 
          style={{...styles.icon, backgroundColor: "#E4405F", color: "#FFFFFF"}}
          className="social-icon"
        >
          <FaInstagram size={30} />
        </a>
      )}
      {socialLinks.linkedin && (
        <a 
          href={socialLinks.linkedin} 
          target="_blank" 
          rel="noopener noreferrer" 
          style={{...styles.icon, backgroundColor: "#0A66C2", color: "#FFFFFF"}}
          className="social-icon"
        >
          <FaLinkedin size={30} />
        </a>
      )}
      {socialLinks.twitter && (
        <a 
          href={socialLinks.twitter} 
          target="_blank" 
          rel="noopener noreferrer" 
          style={{...styles.icon, backgroundColor: "#1DA1F2", color: "#FFFFFF"}}
          className="social-icon"
        >
          <FaTwitter size={30} />
        </a>
      )}
      {socialLinks.youtube && (
        <a 
          href={socialLinks.youtube} 
          target="_blank" 
          rel="noopener noreferrer" 
          style={{...styles.icon, backgroundColor: "#FF0000", color: "#FFFFFF"}}
          className="social-icon"
        >
          <FaYoutube size={30} />
        </a>
      )}
    </div>
  );
};

// Inline styles
const styles = {
  sidebar: {
    position: "fixed",
    right: "7px",
    top: "50%",
    transform: "translateY(-50%)",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    zIndex: 1000,
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "45px",
    height: "45px",
    borderRadius: "50%",
    textDecoration: "none",
    transition: "all 0.2s ease-in-out",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  },
};

export default SocialSidebar;