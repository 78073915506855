import React, { useState, useEffect } from "react";
import { APIData, org } from '../authentication/APIData';
import { 
  CircularProgress, 
  Grid, 
  Box, 
  Chip, 
  Typography, 
  IconButton, 
  Tooltip,
  Skeleton
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SoftButton from "components/SoftButton";
import PageLayout from "examples/LayoutContainers/PageLayout";
import axios from "axios";
import { CurrencyRupee } from "@mui/icons-material";

const LandCourses = () => {
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeFilter, setActiveFilter] = useState("All");
    const [courseTypes, setCourseTypes] = useState([]);
    const [hoveredCourse, setHoveredCourse] = useState(null);

    useEffect(() => {
        const fetchCourses = async () => {
            setLoading(true);
            try {
                const url = `${APIData.api}courses/?org=${org}`;
                const response = await axios.get(url, { headers: APIData.headers });
                setCourses(response.data);
                const types = [...new Set(response.data.map(course => course.course_type))];
                setCourseTypes(['All', ...types]);
            } catch (err) {
                setError(err.message);
                console.error("Error fetching courses", err);
                setCourses([]);
            } finally {
                setLoading(false);
            }
        };
        fetchCourses();
    }, []);

    const handleCourseClick = () => {
        navigate('/authentication/sign-in/');
    };

    const handleFilterClick = (type) => {
        setActiveFilter(type);
    };

    const getFilteredCourses = () => {
        if (activeFilter === "All") return courses;
        return courses.filter(course => course.course_type === activeFilter);
    };

    const getRandomRotation = () => {
        return Math.floor(Math.random() * 3) - 1; // -1, 0, or 1 degrees
    };

    // Skeleton component for course cards
    const CourseCardSkeleton = () => (
        <Box
            sx={{
                borderRadius: '12px',
                overflow: 'hidden',
                height: '100%',
                position: 'relative',
                background: '#fff',
                boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '280px',
                margin: '0 auto',
                transform: `rotate(${getRandomRotation()}deg)`,
            }}
        >
            <Skeleton variant="rectangular" height={140} width="100%" animation="wave" />
            <Box sx={{ p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Skeleton variant="text" height={32} width="90%" animation="wave" sx={{ mb: 1 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, mt: 'auto' }}>
                    <Skeleton variant="text" height={24} width="40%" animation="wave" />
                    <Skeleton variant="text" height={24} width="30%" animation="wave" />
                </Box>
                <Skeleton 
                    variant="rectangular" 
                    height={36} 
                    width="100%" 
                    animation="wave" 
                    sx={{ borderRadius: '20px' }} 
                />
            </Box>
        </Box>
    );

    // Skeleton for filter chips
    const FilterChipsSkeleton = () => (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3, flexWrap: 'wrap', gap: 1 }}>
            {[1, 2, 3, 4].map((item) => (
                <Skeleton 
                    key={item} 
                    variant="rectangular" 
                    width={80} 
                    height={32} 
                    animation="wave"
                    sx={{ borderRadius: '16px' }}
                />
            ))}
        </Box>
    );

    return (
        <PageLayout>
            {/* <Navbarrr /> */}
            <Box sx={{
                background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
                borderRadius: '20px',
                padding: '30px 20px',
                margin: '20px'
            }}>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    {loading ? (
                        <Skeleton 
                            variant="text" 
                            height={60} 
                            width="60%" 
                            animation="wave" 
                            sx={{ mx: 'auto' }} 
                        />
                    ) : (
                        <Typography variant="h3" fontWeight="bold" sx={{
                            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            mb: 1
                        }}>
                            Explore Our Programs
                        </Typography>
                    )}
                </Box>

                {error && (
                    <Typography color="error" align="center" sx={{ mb: 3 }}>{error}</Typography>
                )}

                {loading ? (
                    <FilterChipsSkeleton />
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3, flexWrap: 'wrap', gap: 1 }}>
                        {courseTypes.map((type, index) => (
                            <Chip
                                key={index}
                                label={type}
                                clickable
                                onClick={() => handleFilterClick(type)}
                                color={activeFilter === type ? "primary" : "default"}
                                sx={{
                                    fontSize: '0.9rem',
                                    py: 2,
                                    px: 1,
                                    fontWeight: activeFilter === type ? 'bold' : 'normal',
                                    // transform: activeFilter === type ? 'scale(1.05)' : 'scale(1)',
                                    // transition: 'all 0.3s ease'
                                }}
                            />
                        ))}
                    </Box>
                )}

                <Grid container spacing={3} sx={{ mt: 1 }}>
                    {loading ? (
                        // Display skeleton cards when loading
                        Array.from(new Array(8)).map((_, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <CourseCardSkeleton />
                            </Grid>
                        ))
                    ) : (
                        // Display actual course cards when loaded
                        getFilteredCourses().map((course, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={course.id || index}>
                                <Box
                                    sx={{
                                        borderRadius: '12px',
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                        // transition: 'all 0.4s ease',
                                        // transform: hoveredCourse === index ? 'translateY(-5px)' : `rotate(${getRandomRotation()}deg)`,
                                        height: '100%',
                                        position: 'relative',
                                        background: '#fff',
                                        boxShadow: hoveredCourse === index
                                            ? '0 12px 20px rgba(0,0,0,0.15)'
                                            : '0 4px 10px rgba(0,0,0,0.1)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        maxWidth: '280px',
                                        margin: '0 auto'
                                    }}
                                    onClick={handleCourseClick}
                                    onMouseEnter={() => setHoveredCourse(index)}
                                    onMouseLeave={() => setHoveredCourse(null)}
                                >
                                    <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                                        <Box
                                            sx={{
                                                backgroundImage: `url(${course.image_url})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                height: '140px',
                                                // transition: 'transform 0.6s ease',
                                                // transform: hoveredCourse === index ? 'scale(1.1)' : 'scale(1)',
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                background: 'rgba(0,0,0,0.3)',
                                                opacity: hoveredCourse === index ? 0 : 1,
                                                // transition: 'opacity 0.4s ease'
                                            }}
                                        />
                                        <Chip
                                            label={course.course_type}
                                            size="small"
                                            sx={{
                                                position: 'absolute',
                                                top: '8px',
                                                right: '8px',
                                                fontWeight: 'bold',
                                                background: 'linear-gradient(45deg, #FF9800 30%, #FFC107 90%)',
                                                color: 'white'
                                            }}
                                        />
                                    </Box>

                                    <Box sx={{ p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
                                            {course.courseName}
                                        </Typography>

                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, mt: 'auto' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <AccessTimeIcon sx={{ color: '#1976d2', mr: 0.5, fontSize: '1rem' }} />
                                                <Typography variant="body2" fontWeight="medium">
                                                    {course.course_duration}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <CurrencyRupee sx={{ color: '#1976d2', mr: 0.5, fontSize: '1rem' }} />
                                                <Typography variant="body2" fontWeight="bold">
                                                    {course.course_fees}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <SoftButton
                                            variant="contained"
                                            color="info"
                                            fullWidth
                                            size="small"
                                            sx={{
                                                borderRadius: '20px',
                                                py: 0.75,
                                                background: hoveredCourse === index
                                                    ? 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
                                                    : 'linear-gradient(45deg, #2196F3 30%, #21CBF3 60%)',
                                                // transition: 'all 0.3s ease',
                                                // transform: hoveredCourse === index ? 'scale(1.05)' : 'scale(1)',
                                                boxShadow: hoveredCourse === index
                                                    ? '0 4px 10px rgba(33,150,243,0.3)'
                                                    : '0 2px 5px rgba(33,150,243,0.2)'
                                            }}
                                        >
                                            Enroll Now
                                        </SoftButton>
                                    </Box>
                                </Box>
                            </Grid>
                        ))
                    )}
                </Grid>
            </Box>
        </PageLayout>
    );
};

export default LandCourses;