import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Checkbox, FormControlLabel, Dialog, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SoftButton from 'components/SoftButton';
import axios from 'axios';
import { org } from 'authentication/APIData';
import { APIData } from 'authentication/APIData';

const ConsentForm = () => {
  const navigate = useNavigate();
  const [agreed, setAgreed] = useState(false);
  const [showConsent, setShowConsent] = useState(true);
  const [hasConsented, setHasConsented] = useState(false);

  useEffect(() => {
    // Check if user has already consented
    const checkConsentStatus = async () => {
      try {
        const response = await axios.get(`${APIData.api}consent/${org}`, { 
          headers: APIData.headers 
        });
        if (response.data && response.data.consentForm === "YES") {
          setHasConsented(true);
          setShowConsent(false);
          navigate('#step=0');  // Navigate to the stepper form if already consented
        }
      } catch (error) {
        console.error('Error checking consent status:', error);
      }
    };

    checkConsentStatus();
  }, [navigate]);

  const handleAgree = async () => {
    if (!agreed) {
      return;
    }

    try {
      // Send consent to backend with the correct JSON structure
      await axios.post(`${APIData.api}consent`, {
        org: org,
        consentForm: "YES",
        consentDate: new Date().toISOString(),
        createdBy: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).username : '',
        createdDateTime: new Date().toISOString(),
        updatedBy: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).username : '',
        updatedDateTime: new Date().toISOString()
      }, { 
        headers: APIData.headers 
      });
      
      setShowConsent(false);
      navigate('#step=0');  // Navigate to the stepper form
    } catch (error) {
      console.error('Error submitting consent:', error);
    }
  };

  const handleCancel = async () => {
    try {
      // Log the rejection with the correct JSON structure
      await axios.post(`${APIData.api}consent`, {
        org: org,
        consentForm: "NO",
        consentDate: new Date().toISOString(),
        createdBy: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).username : '',
        createdDateTime: new Date().toISOString(),
        updatedBy: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).username : '',
        updatedDateTime: new Date().toISOString()
      }, { 
        headers: APIData.headers 
      });
      
      // Clear any local storage
      localStorage.removeItem('stepperActiveStep');
      localStorage.removeItem('stepValidation');
      localStorage.removeItem('stepperFormData');
      
      // Clear token or other auth data
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      
      // Redirect to login page
      window.location.href = '/authentication/sign-in';
    } catch (error) {
      console.error('Error logging consent rejection:', error);
      // Redirect anyway in case of error
      window.location.href = '/authentication/sign-in';
    }
  };

  if (!showConsent && hasConsented) {
    return null; // Don't render anything if already consented
  }

  return (
    <Dialog 
      open={showConsent} 
      fullWidth 
      maxWidth="md"
      disableEscapeKeyDown
    >
      <Paper elevation={3} sx={{ p: { xs: 2, sm: 3, md: 4 }, m: { xs: 1, sm: 2 } }}>
        <Typography variant="h4" component="h1" align="center" gutterBottom fontWeight="bold" sx={{ color: '#1a73e8' }}>
          Consent Agreement
        </Typography>
        
        {/* Note section */}
        <Paper 
          elevation={1} 
          sx={{ 
            p: 2, 
            my: 2, 
            bgcolor: '#f8f9fa', 
            border: '1px solid #e0e0e0',
            borderRadius: 2,
            borderLeft: '4px solid #1a73e8'
          }}
        >
          <Typography variant="subtitle1" fontWeight="medium" sx={{ mb: 1 }}>
            Note:
          </Typography>
          <Typography variant="body2" paragraph>
            All images and content displayed are default selections based on industry selected. You can customize your view by exploring the portal. The platform is designed for easy self-configuration, allowing you to modify or replace images and content as per your preferences. Default images are randomly assigned and can be edited anytime to match your specific needs.
          </Typography>
        </Paper>

        <Box sx={{ my: 3, height: { xs: '300px', sm: '350px' }, overflowY: 'auto', p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
          <Typography variant="h5" gutterBottom align="center" fontWeight="bold" sx={{ color: '#1a73e8', mb: 2 }}>
            Consent for Data Storage and Security
          </Typography>
          
          <Typography variant="body1" paragraph>
            By reading this form, you acknowledge and consent to the storage of your personal data in our secure database. We prioritize your privacy and implement industry-standard security measures to protect your information.
          </Typography>

          <Box component="ul" sx={{ pl: 4 }}>
            <Box component="li" sx={{ mb: 1 }}>
              <Typography variant="body1">
                Your data is securely stored in our database.
              </Typography>
            </Box>
            
            <Box component="li" sx={{ mb: 1 }}>
              <Typography variant="body1">
                All sensitive information is encrypted using the AES (Advanced Encryption Standard) algorithm to ensure confidentiality and security.
              </Typography>
            </Box>
            
            <Box component="li" sx={{ mb: 1 }}>
              <Typography variant="body1">
                We do not share, sell, or disclose your data to unauthorized third parties.
              </Typography>
            </Box>
            
            <Box component="li" sx={{ mb: 1 }}>
              <Typography variant="body1">
                Our systems comply with applicable data protection laws and regulations to maintain the highest security standards.
              </Typography>
            </Box>
          </Box>
          
          <Typography variant="body1" paragraph>
            If you have any concerns or wish to withdraw your consent, you may contact us at [your contact information].
          </Typography>
        </Box>
        
        <Divider sx={{ my: 2 }} />
        
        <FormControlLabel
          control={
            <Checkbox 
              checked={agreed} 
              onChange={(e) => setAgreed(e.target.checked)} 
              color="primary"
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            />
          }
          label={
            <Typography variant="body1" fontWeight="medium">
              I have read and agree to the terms and conditions
            </Typography>
          }
          sx={{ mb: 2 }}
        />
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, flexWrap: { xs: 'wrap', sm: 'nowrap' }, gap: 2 }}>
          <SoftButton
            variant="gradient"
            color="error"
            onClick={handleCancel}
            fullWidth={window.innerWidth < 600}
            sx={{ mb: { xs: 1, sm: 0 }, minWidth: { sm: '120px' } }}
          >
            Cancel
          </SoftButton>
          
          <SoftButton
            variant="gradient"
            color="success"
            onClick={handleAgree}
            disabled={!agreed}
            fullWidth={window.innerWidth < 600}
            sx={{ minWidth: { sm: '180px' } }}
          >
            Accept & Continue
          </SoftButton>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default ConsentForm;