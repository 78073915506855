import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Backdrop, Box, IconButton } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { Card } from "@mui/material";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import axios from 'axios';
import * as AiIcons from "react-icons/ai";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import { APIData } from 'authentication/APIData';

const Editmdm = () => {
    const { id } = useParams();
    // console.log(id);

    const [usersData, setUserData] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [orgImagePreview, setOrgImagePreview] = useState(null);
    const [selectedOrgImage, setSelectedOrgImage] = useState(null);
    const [formData, setFormData] = useState({
        org_id: '',
        org_name: '',
        org_short_name: '',
        org_emp_code: '',
        org_email_id: '',
        org_mob: '',
        org_spoc_name: '',
        org_spoc_email_id: '',
        org_spoc_mob: '',
        org_status: 'ACTIVE',
        org_address: '',
        orgurl: '',
        org_account_number: '',
        orggst: '',
        orgShortAddress: '',
        orgSpocPosition: '',
        ifsc: '',
        type: '',
        subtype: '',
        ourzoom: '',
        about: [''],
    });

    useEffect(() => {
        const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
        setUserData(userData_Local);
        fetchOrgData();
    }, [id]);

    const fetchOrgData = async () => {
        if (!id) {
            window.location.href = '/orgmdmdata';
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get(`${APIData.api}org-mdm/org-id?orgId=${id}`, {
                headers: APIData.headers
            });

            if (response.data) {

                const mappedData = {
                    org_id: response.data.orgId,
                    org_name: response.data.orgName,
                    org_short_name: response.data.orgShortName,
                    org_email_id: response.data.orgEmailId,
                    org_mob: response.data.orgMob,
                    org_spoc_name: response.data.orgSpocName,
                    org_spoc_email_id: response.data.orgSpocEmailId,
                    org_spoc_mob: response.data.orgSpocMob,
                    org_status: response.data.orgStatus,
                    org_address: response.data.orgAddress,
                    orgurl: response.data.orgURL,
                    org_account_number: response.data.orgAccountNuber,
                    orggst: response.data.orgGST,
                    orgShortAddress: response.data.orgShortAddress,
                    orgSpocPosition: response.data.orgSpocPosition,
                    ifsc: response.data.ifsc,
                    type: response.data.type || '',
                    subtype: response.data.subType || '',
                    ourzoom: response.data.ourZoom || 'NO',
                    about: response.data.about ?
                        (Array.isArray(response.data.about) ? response.data.about : [response.data.about])
                        : [''],
                };
                setFormData(mappedData);
                if (response.data.orgURL) {
                    setImagePreview(response.data.orgURL);
                }
                if (response.data.orgImage) {
                    setOrgImagePreview(response.data.orgImage);
                }
            }
        } catch (error) {
            console.error('Error fetching organization data:', error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to fetch organization data"
            }).then(() => {
                window.location.href = '/orgmdmdata';
            });
        } finally {
            setLoading(false);
        }
    };

    const statusOptions = [
        { value: 'ACTIVE', label: 'ACTIVE' },
        { value: 'INACTIVE', label: 'INACTIVE' },
        { value: 'REVOKE', label: 'REVOKED' }
    ];

    const positionOptions = [
        { value: 'CEO', label: 'CEO' },
        { value: 'CFO', label: 'CFO' },
        { value: 'Manager', label: 'Manager' },
        { value: 'Senior Manager', label: 'Senior Manager' },
        { value: 'Admin', label: 'Admin' }
    ];

    const industryTypeOptions = [
        { value: 'IT', label: 'Information Technology' },
        { value: 'Finance', label: 'Finance & Banking' },
        { value: 'Healthcare', label: 'Healthcare' },
        { value: 'Education', label: 'Education' },
        { value: 'Manufacturing', label: 'Manufacturing' },
        { value: 'Retail', label: 'Retail' },
        { value: 'Telecom', label: 'Telecommunications' },
        { value: 'Energy', label: 'Energy & Utilities' },
        { value: 'Media', label: 'Media & Entertainment' },
        { value: 'Other', label: 'Other' }
    ];

    const subtypeOptions = {
        'IT': [
            { value: 'SaaS', label: 'Software as a Service' },
            { value: 'Consulting', label: 'IT Consulting' },
            { value: 'DevOps', label: 'DevOps' },
            { value: 'CloudServices', label: 'Cloud Services' }
        ],
        'Finance': [
            { value: 'Banking', label: 'Banking' },
            { value: 'Insurance', label: 'Insurance' },
            { value: 'Investment', label: 'Investment' },
            { value: 'FinTech', label: 'Financial Technology' }
        ],
        'Healthcare': [
            { value: 'Hospital', label: 'Hospital' },
            { value: 'Pharma', label: 'Pharmaceutical' },
            { value: 'MedDevice', label: 'Medical Devices' },
            { value: 'Telemedicine', label: 'Telemedicine' }
        ],
        'Education': [
            { value: 'Training', label: 'Corporate Training' },
            { value: 'Academy', label: 'Academy' },
            { value: 'School', label: 'School' }
        ],
        'Manufacturing': [
            { value: 'Automotive', label: 'Automotive' },
            { value: 'Electronics', label: 'Electronics' },
            { value: 'FMCG', label: 'Fast-Moving Consumer Goods' },
            { value: 'Industrial', label: 'Industrial Equipment' }
        ],
        'Retail': [
            { value: 'Ecommerce', label: 'E-commerce' },
            { value: 'Grocery', label: 'Grocery' },
            { value: 'Fashion', label: 'Fashion & Apparel' },
            { value: 'Electronics', label: 'Electronics Retail' }
        ],
        'Other': [
            { value: 'Consulting', label: 'Consulting' },
            { value: 'NonProfit', label: 'Non-Profit' },
            { value: 'Government', label: 'Government' }
        ]
    };

    // Add after handleFileChange function
    const handleOrgImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedOrgImage(file);
        setOrgImagePreview(URL.createObjectURL(file));
    };

    const handleAboutChange = (index, value) => {
        const updatedAbout = [...formData.about];
        updatedAbout[index] = value;
        setFormData(prev => ({
            ...prev,
            about: updatedAbout
        }));

        if (!value.trim()) {
            setErrors(prev => ({
                ...prev,
                about: "About text is required"
            }));
        } else {
            setErrors(prev => ({
                ...prev,
                about: ""
            }));
        }
    };

    const addAboutField = () => {
        setFormData(prev => ({
            ...prev,
            about: [...prev.about, '']
        }));
    };

    const removeAboutField = (index) => {
        if (formData.about.length === 1) return;

        const updatedAbout = [...formData.about];
        updatedAbout.splice(index, 1);

        setFormData(prev => ({
            ...prev,
            about: updatedAbout
        }));
    };

    const handleToggleChange = () => {
        setFormData(prev => ({
            ...prev,
            ourzoom: prev.ourzoom === "YES" ? "NO" : "YES"
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if ((name === 'org_mob' || name === 'org_spoc_mob') && !/^\d*$/.test(value)) {
            return;
        }

        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        const error = validateField(name, value);
        setErrors(prev => ({
            ...prev,
            [name]: error
        }));
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const name = actionMeta.name;
        const value = selectedOption ? selectedOption.value : '';

        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));

        const error = validateField(name, value);
        setErrors(prev => ({
            ...prev,
            [name]: error
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setImagePreview(URL.createObjectURL(file));
    };

    const validateField = (name, value) => {
        let error = '';

        const validations = {
            org_id: () => !value.trim() && "Organization ID is required",
            org_name: () => !value.trim() && "Organization Name is required",
            org_short_name: () => !value.trim() && "Organization Short Name is required",
            org_email_id: () => {
                if (!value.trim()) return "Organization Email is required";
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) return "Invalid email format";
            },
            org_mob: () => {
                if (!value.trim()) return "Organization Mobile is required";
                if (!/^\d{10}$/.test(value)) return "Mobile number must be 10 digits";
                if (parseInt(value[0]) < 6) return "Mobile number must start with 6 or greater";
            },
            org_spoc_name: () => !value.trim() && "SPOC Name is required",
            org_spoc_email_id: () => {
                if (!value.trim()) return "SPOC Email is required";
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) return "Invalid email format";
            },
            org_spoc_mob: () => {
                if (!value.trim()) return "SPOC Mobile is required";
                if (!/^\d{10}$/.test(value)) return "Mobile number must be 10 digits";
                if (parseInt(value[0]) < 6) return "Mobile number must start with 6 or greater";
            },
            orgSpocPosition: () => !value && "SPOC Position is required",
            org_status: () => !value && "Organization Status is required",
            org_address: () => !value.trim() && "Organization Address is required",
            orgShortAddress: () => !value.trim() && "Short Address is required",
            orgurl: () => {
                if (!value.trim()) return "Organization URL is required";
                if (!/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(value)) return "Invalid URL format";
            },
            org_account_number: () => {
                if (!/^\d{9,18}$/.test(value)) return "Invalid account number format";
            },
            type: () => !value && "Industry Type is required",
            subtype: () => !value && "Industry Subtype is required",
        };

        if (validations[name]) {
            error = validations[name]();
        }

        return error;
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(formData).forEach(field => {
            const error = validateField(field, formData[field]);
            if (error) {
                newErrors[field] = error;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formIsValid = validateForm();
        if (!formIsValid) {
            Swal.fire({
                icon: "error",
                title: "Validation Error",
                text: "Please fill all required fields correctly",
                showConfirmButton: true
            });
            return;
        }
        if (formData.about.length === 0 || !formData.about[0].trim()) {
            setErrors(prev => ({
                ...prev,
                about: "At least one About entry is required"
            }));
            Swal.fire({
                icon: "error",
                title: "Validation Error",
                text: "Please add at least one About entry",
                showConfirmButton: true
            });
            return;
        }

        setIsSubmitting(true);
        setLoading(true);

        try {
            const submitFormData = new FormData();
            Object.keys(formData).forEach(key => {
                if (key === 'about') {
                    // Handle about array
                    formData.about.forEach((item, index) => {
                        submitFormData.append(`about[${index}]`, item);
                    });
                } else {
                    submitFormData.append(key, formData[key]);
                }
            });

            if (selectedFile) {
                submitFormData.append('logo', selectedFile);
            }

            if (selectedOrgImage) {
                submitFormData.append('orgImage', selectedOrgImage);
            }

            submitFormData.append('updated_by', usersData?.userName || '');

            const response = await axios.put(
                `${APIData.api}org-mdm/`,
                submitFormData,
                {
                    headers: { ...APIData.headers, 'Content-Type': 'multipart/form-data' }
                }
            );

            Swal.fire({
                icon: "success",
                title: "Successfully Updated",
                showConfirmButton: false,
                timer: 1500
            });

            setTimeout(() => {
                window.location.hash = '#/orgmdmdata';
            }, 1500);

        } catch (error) {
            console.error('API Error:', error);
            Swal.fire({
                icon: "error",
                title: "Update Error",
                text: error.response?.data?.message ||
                    error.response?.data?.error ||
                    "Error updating organization data"
            });
        } finally {
            setLoading(false);
            setIsSubmitting(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
                <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item xs={12} sm={10}>
                    <Card sx={{ height: 'auto' }}>
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/orgmdmdata">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Update Organization Details
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox pt={1} pb={3} px={3}>
                            <SoftBox component="form" onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Organization Id *</SoftTypography>
                                            <SoftInput
                                                placeholder="Organization Id"
                                                name="org_id"
                                                size="small"
                                                value={formData.org_id}
                                                onChange={handleChange}
                                                error={!!errors.org_id}
                                                required
                                                disabled
                                            />
                                            {errors.org_id && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.org_id}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Organization Name *</SoftTypography>
                                            <SoftInput
                                                placeholder="Organization Name"
                                                name="org_name"
                                                size="small"
                                                value={formData.org_name}
                                                onChange={handleChange}
                                                error={!!errors.org_name}
                                                required
                                            />
                                            {errors.org_name && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.org_name}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Organization Short Name *</SoftTypography>
                                            <SoftInput
                                                placeholder="Organization Short Name"
                                                name="org_short_name"
                                                size="small"
                                                value={formData.org_short_name}
                                                onChange={handleChange}
                                                error={!!errors.org_short_name}
                                                required
                                            />
                                            {errors.org_short_name && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.org_short_name}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Organization Email *</SoftTypography>
                                            <SoftInput
                                                type="email"
                                                size="small"
                                                placeholder="Organization Email"
                                                name="org_email_id"
                                                value={formData.org_email_id}
                                                onChange={handleChange}
                                                error={!!errors.org_email_id}
                                                required
                                                disabled
                                            />
                                            {errors.org_email_id && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.org_email_id}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Organization Mobile *</SoftTypography>
                                            <SoftInput
                                                placeholder="Organization Mobile"
                                                name="org_mob"
                                                size="small"
                                                value={formData.org_mob}
                                                onChange={handleChange}
                                                error={!!errors.org_mob}
                                                required
                                                inputProps={{
                                                    maxLength: 10,
                                                    pattern: "[0-9]*",
                                                    inputMode: "numeric"
                                                }}
                                            />
                                            {errors.org_mob && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.org_mob}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">SPOC Name *</SoftTypography>
                                            <SoftInput
                                                placeholder="SPOC Name"
                                                name="org_spoc_name"
                                                size="small"
                                                value={formData.org_spoc_name}
                                                onChange={handleChange}
                                                error={!!errors.org_spoc_name}
                                                required

                                            />
                                            {errors.org_spoc_name && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.org_spoc_name}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">SPOC Position *</SoftTypography>
                                            <CustomSelect
                                                options={positionOptions}
                                                placeholder="SPOC Position"
                                                name="orgSpocPosition"
                                                onChange={handleSelectChange}
                                                value={formData.orgSpocPosition}
                                                error={!!errors.orgSpocPosition}
                                                required

                                            />
                                            {errors.orgSpocPosition && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.orgSpocPosition}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">SPOC Email *</SoftTypography>
                                            <SoftInput
                                                type="email"
                                                placeholder="SPOC Email"
                                                size="small"
                                                name="org_spoc_email_id"
                                                value={formData.org_spoc_email_id}
                                                onChange={handleChange}
                                                error={!!errors.org_spoc_email_id}
                                                required

                                            />
                                            {errors.org_spoc_email_id && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.org_spoc_email_id}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">SPOC Mobile *</SoftTypography>
                                            <SoftInput
                                                placeholder="SPOC Mobile"
                                                name="org_spoc_mob"
                                                size="small"
                                                value={formData.org_spoc_mob}
                                                onChange={handleChange}
                                                error={!!errors.org_spoc_mob}
                                                inputProps={{
                                                    maxLength: 10,
                                                    pattern: "[0-9]*",
                                                    inputMode: "numeric"
                                                }}
                                                required
                                            />
                                            {errors.org_spoc_mob && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.org_spoc_mob}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Organization Short Address *</SoftTypography>
                                            <SoftInput
                                                placeholder="Organization Short Address"
                                                name="orgShortAddress"
                                                size="small"
                                                value={formData.orgShortAddress}
                                                onChange={handleChange}
                                                error={!!errors.orgShortAddress}
                                                required
                                            />
                                            {errors.orgShortAddress && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.orgShortAddress}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Organization Status *</SoftTypography>
                                            <CustomSelect
                                                options={statusOptions}
                                                placeholder="Organization Status"
                                                name="org_status"
                                                size="small"
                                                onChange={handleSelectChange}
                                                value={formData.org_status}
                                                error={!!errors.org_status}
                                                required
                                            />
                                            {errors.org_status && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.org_status}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Organization Address *</SoftTypography>
                                            <SoftInput
                                                placeholder="Organization Address"
                                                name="org_address"
                                                size="small"
                                                value={formData.org_address}
                                                onChange={handleChange}
                                                error={!!errors.org_address}
                                                required
                                            />
                                            {errors.org_address && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.org_address}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Organization URL *</SoftTypography>
                                            <SoftInput
                                                placeholder="Organization URL"
                                                name="orgurl"
                                                size="small"
                                                value={formData.orgurl}
                                                onChange={handleChange}
                                                error={!!errors.orgurl}
                                                required
                                            />
                                            {errors.orgurl && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.orgurl}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Organization Bank Account Number</SoftTypography>
                                            <SoftInput
                                                placeholder="Organization Bank Account Number"
                                                name="org_account_number"
                                                size="small"
                                                value={formData.org_account_number}
                                                onChange={handleChange}
                                                error={!!errors.org_account_number}
                                            />
                                            {errors.org_account_number && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.org_account_number}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Organization GST</SoftTypography>
                                            <SoftInput
                                                placeholder="Organization GST"
                                                name="orggst"
                                                size="small"
                                                value={formData.orggst}
                                                onChange={handleChange}
                                                error={!!errors.orggst}
                                            />
                                            {errors.orggst && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.orggst}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Organization IFSC Code</SoftTypography>
                                            <SoftInput
                                                placeholder="Organization IFSC Code"
                                                name="ifsc"
                                                size="small"
                                                value={formData.ifsc}
                                                onChange={handleChange}
                                                error={!!errors.ifsc}
                                            />
                                            {errors.ifsc && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.ifsc}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Upload Image</SoftTypography>
                                            <input
                                                type="file"
                                                onChange={handleFileChange}
                                                style={{
                                                    width: '100%',
                                                    padding: '8px',
                                                    border: '1px solid #d2d6da',
                                                    borderRadius: '8px'
                                                }}
                                            />
                                            {imagePreview && (
                                                <img
                                                    src={imagePreview}
                                                    alt="Preview"
                                                    style={{
                                                        width: '20%',
                                                        marginTop: '10px'
                                                    }}
                                                />
                                            )}
                                        </SoftBox>
                                    </Grid> */}
                                    {/* Add these components before the <SoftBox mt={4} mb={1}> containing the submit button */}

                                    {/* Industry Type */}
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Industry Type *</SoftTypography>
                                            <CustomSelect
                                                options={industryTypeOptions}
                                                placeholder="Select Industry Type"
                                                name="type"
                                                onChange={handleSelectChange}
                                                value={formData.type}
                                                error={!!errors.type}
                                                required
                                            />
                                            {errors.type && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.type}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    {/* Industry Subtype */}
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Industry Subtype *</SoftTypography>
                                            <CustomSelect
                                                options={formData.type ? subtypeOptions[formData.type] || [] : []}
                                                placeholder="Select Industry Subtype"
                                                name="subtype"
                                                onChange={handleSelectChange}
                                                value={formData.subtype}
                                                error={!!errors.subtype}
                                                isDisabled={!formData.type}
                                                required
                                            />
                                            {errors.subtype && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.subtype}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    {/* Zoom Toggle */}
                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">Zoom Utility</SoftTypography>
                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                                <div style={{
                                                    width: '50px',
                                                    height: '24px',
                                                    backgroundColor: formData.ourzoom === "YES" ? '#90caf9' : '#f5f5f5',
                                                    borderRadius: '12px',
                                                    position: 'relative',
                                                    cursor: 'pointer',
                                                    transition: 'background-color 0.3s'
                                                }} onClick={handleToggleChange}>
                                                    <div style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundColor: 'white',
                                                        borderRadius: '50%',
                                                        position: 'absolute',
                                                        top: '2px',
                                                        left: formData.ourzoom === "YES" ? '28px' : '2px',
                                                        transition: 'left 0.3s'
                                                    }}></div>
                                                </div>
                                                <SoftTypography variant="body2" style={{ marginLeft: '10px' }}>
                                                    {formData.ourzoom === "YES" ? "Selected Own Zoom Utility" : "Selected C4E Zoom Utility"}
                                                </SoftTypography>
                                            </div>
                                        </SoftBox>
                                    </Grid>

                                    {/* About Organization */}
                                    <Grid item xs={12}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">About Organization *</SoftTypography>
                                            {formData.about.map((item, index) => (
                                                <div key={index} className="flex items-center gap-2 mb-2">
                                                    <SoftInput
                                                        placeholder={`About Entry ${index + 1}`}
                                                        value={item}
                                                        onChange={(e) => handleAboutChange(index, e.target.value)}
                                                        error={!!errors.about}
                                                        multiline
                                                        rows={2}
                                                        fullWidth
                                                    />
                                                    <IconButton
                                                        onClick={() => removeAboutField(index)}
                                                        disabled={formData.about.length === 1}
                                                    >
                                                        <AiIcons.AiOutlineClose />
                                                    </IconButton>
                                                </div>
                                            ))}
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={addAboutField}
                                                startIcon={<AiIcons.AiOutlinePlus />}
                                            >
                                                Add More
                                            </SoftButton>
                                            {errors.about && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.about}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    {/* Organization Images */}
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <SoftBox mb={2} sx={{ px: 3 }}>
                                                <SoftTypography variant="h6" mb={1}>Organization Logo</SoftTypography>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                                    {imagePreview ? (
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                position: 'relative',
                                                                border: '1px solid #eee',
                                                                borderRadius: '8px',
                                                                p: 3,
                                                                mb: 2
                                                            }}
                                                        >
                                                            <img
                                                                src={imagePreview}
                                                                alt="Logo Preview"
                                                                style={{
                                                                    width: '100%',
                                                                    maxHeight: '150px',
                                                                    objectFit: 'contain'
                                                                }}
                                                            />
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                                                <SoftButton
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setImagePreview(null);
                                                                        document.getElementById('logo-upload').value = '';
                                                                    }}
                                                                >
                                                                    Change Image
                                                                </SoftButton>
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        <Box sx={{ width: '100%' }}>
                                                            <label htmlFor="logo-upload">
                                                                <SoftButton
                                                                    component="span"
                                                                    variant="gradient"
                                                                    color="info"
                                                                    fullWidth
                                                                    size="small"
                                                                >
                                                                    Upload Image
                                                                </SoftButton>
                                                            </label>
                                                            <input
                                                                id="logo-upload"
                                                                type="file"
                                                                onChange={handleFileChange}
                                                                style={{ display: 'none' }}
                                                                accept="image/*"
                                                            />
                                                        </Box>
                                                    )}
                                                </Box>
                                            </SoftBox>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <SoftBox mb={2} sx={{ px: 3 }}>
                                                <SoftTypography variant="h6" mb={1}>Organization Image</SoftTypography>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                                    {orgImagePreview ? (
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                position: 'relative',
                                                                border: '1px solid #eee',
                                                                borderRadius: '8px',
                                                                p: 3,
                                                                mb: 2
                                                            }}
                                                        >
                                                            <img
                                                                src={orgImagePreview}
                                                                alt="Organization Image"
                                                                style={{
                                                                    width: '100%',
                                                                    maxHeight: '150px',
                                                                    objectFit: 'contain'
                                                                }}
                                                            />
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                                                <SoftButton
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setOrgImagePreview(null);
                                                                        document.getElementById('org-image-upload').value = '';
                                                                    }}
                                                                >
                                                                    Change Image
                                                                </SoftButton>
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        <Box sx={{ width: '100%' }}>
                                                            <label htmlFor="org-image-upload">
                                                                <SoftButton
                                                                    component="span"
                                                                    variant="gradient"
                                                                    color="info"
                                                                    fullWidth
                                                                    size="small"
                                                                >
                                                                    Upload Image
                                                                </SoftButton>
                                                            </label>
                                                            <input
                                                                id="org-image-upload"
                                                                type="file"
                                                                onChange={handleOrgImageChange}
                                                                style={{ display: 'none' }}
                                                                accept="image/*"
                                                            />
                                                        </Box>
                                                    )}
                                                </Box>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>                                </Grid>
                                <SoftBox mt={4} mb={1}>
                                    <SoftButton
                                        type="submit"
                                        variant="gradient"
                                        color="info"
                                        fullWidth
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Updating...' : 'Update'}
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default Editmdm;