import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Skeleton from "@mui/material/Skeleton";

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const [loading, setLoading] = useState(true);

  // const testimonials = [
  //     {
  //       "quote": "The teaching methods at Centre of Maths Education are outstanding! Concepts are explained in such a clear and simple way.",
  //       "author": "Rahul Sharma",
  //       "company": "Student, Class 10"
  //     },
  //     {
  //       "quote": "Thanks to this academy, my problem-solving skills have improved significantly. The teachers are very supportive and knowledgeable.",
  //       "author": "Ananya Verma",
  //       "company": "Student, Class 12"
  //     },
  //     {
  //       "quote": "Centre of Maths Education has helped my child develop a strong foundation in mathematics. Highly recommended for students who want to excel!",
  //       "author": "Sunil Mehta",
  //       "company": "Parent"
  //     }
  // ];

  // const testimonials = [
  //   {
  //     "quote": "AADHEESH ACADEMY has transformed the way I understand Maths and Science. The teachers explain concepts in a simple and engaging way!",
  //     "author": "Kavya R.",
  //     "company": "Student, Class 8"
  //   },
  //   {
  //     "quote": "The faculty here is excellent! My child's confidence in English and Science has improved tremendously. Highly recommended for all students.",
  //     "author": "Vikas Sharma",
  //     "company": "Parent"
  //   },
  //   {
  //     "quote": "Studying Computer Science at AADHEESH ACADEMY has been a great experience. The practical approach to learning has helped me excel in my BCA subjects.",
  //     "author": "Arjun M.",
  //     "company": "BCA Student"
  //   }
  // ];
  
//   const testimonials = [
//     {
//       "quote": "Have visited the centre today,the staff are extremely polite and helpful.Came to know about different therapies conducted here. They are doing a wonderful Job especially for ASD,ADHD and LD.Very impressive as this is also doctor centric centre Coming from Australia,I am happy to note that these kind of facilities are available here.Kudos to the management and staff.",
//       "author": "Prathima Seetharam",
//       "company": "Visiter"
//     },
//     {
//       "quote": "I did an internship here and the staff was wonderful and very welcoming. The staff showed deep interest in the children's and made sure each child was accomodated for what they should be with special care. It was also my first time seeing a center where the parents were allowed to see how the process works and be shadow therapists. Wonderful experience and would love to work here again.",
//       "author": "Krisha Vora",
//       "company": "Intern"
//     },
//     {
//       "quote": "It's a really nice environment for children with all facilities and staff catering to their needs. They also take a parent based approach which is very useful in the development of the child. They focus on holistic development and equip the kids to attend school. The staff are very warm, welcoming and helpful. They try to keep good rapport with the parents and stay in the loop on the child's health and progress. I had a very good experience and would definitely recommend it.",
//       "author": "Netra Raghunandhan",
//       "company": "Parent"
//     }
// ];
const testimonials = [
  {
    "quote": "Care4Edu Solutions has been a reliable partner in providing exceptional educational products and services. Their commitment to quality and customer satisfaction is commendable. Highly recommended for educational institutions looking for innovative solutions.",
    "author": "Cadvin Technologies",
    "company": "Customer"
  },
  {
    "quote": "We have had a wonderful experience collaborating with Care4Edu Solutions. Their products are well-designed and cater to diverse educational needs. The team is highly professional and always ready to assist.",
    "author": "Sangamitra",
    "company": "Customer"
  },
  {
    "quote": "Care4Edu Solutions has significantly contributed to enhancing our educational practices. Their innovative approach and dedicated support have been invaluable. It’s a pleasure working with them.",
    "author": "Centre of Math Education",
    "company": "Customer"
  },
  {
    "quote": "Excellent services and exceptional products! Care4Edu Solutions has truly transformed our educational approach. Their solutions are practical, effective, and user-friendly. Highly satisfied with their service.",
    "author": "Adheesh Academy",
    "company": "Customer"
  }
];

  // Simulate loading state for demonstration
  useEffect(() => {
    // Simulate data fetching delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    
    return () => clearTimeout(timer);
  }, []);

  // Auto-slide functionality
  useEffect(() => {
    if (loading || !isAutoPlaying) return;
    
    const interval = setInterval(() => {
      handleNext();
    }, 5000);
    
    return () => clearInterval(interval);
  }, [currentIndex, isAutoPlaying, loading]);
  
  // Pause auto-play when user interacts with carousel
  const pauseAutoPlay = () => {
    setIsAutoPlaying(false);
    // Resume after 10 seconds of inactivity
    setTimeout(() => setIsAutoPlaying(true), 10000);
  };

  const handlePrev = () => {
    pauseAutoPlay();
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    pauseAutoPlay();
    setCurrentIndex((prevIndex) => 
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const getCardPosition = (index) => {
    const position = index - currentIndex;
    
    // Handle wrap-around for circular carousel
    if (position < -1) return position + testimonials.length;
    if (position > 1) return position - testimonials.length;
    return position;
  };

  // Handle keyboard navigation
  useEffect(() => {
    if (loading) return;
    
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") handlePrev();
      if (e.key === "ArrowRight") handleNext();
    };
    
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [currentIndex, loading]);

  // Testimonial card skeleton component
  const TestimonialCardSkeleton = () => (
    <div className="testimonial-card" style={{ opacity: 1, transform: "translateX(0%) scale(1)", zIndex: 10 }}>
      <div className="testimonial-content">
        <div className="quote-icon">
          <Skeleton variant="circular" width={32} height={32} />
        </div>
        <Skeleton variant="text" height={20} style={{ marginBottom: "8px" }} />
        <Skeleton variant="text" height={20} style={{ marginBottom: "8px" }} />
        <Skeleton variant="text" height={20} style={{ marginBottom: "16px" }} />
        <div className="author-info">
          <Skeleton variant="text" width="40%" height={20} style={{ marginLeft: "auto" }} />
          <Skeleton variant="text" width="30%" height={16} style={{ marginLeft: "auto" }} />
        </div>
      </div>
    </div>
  );

  return (
    <section className="testimonials-section">
      <div className="testimonials-container">
        <div className="testimonials-header">
          <h2 className="testimonials-title">
            Clients <span className="testimonials-title-accent">Feedback</span>
          </h2>
        </div>
        
        {/* Testimonial Carousel */}
        <div className="carousel-container">
          {/* Carousel track */}
          <div className="carousel-track">
            {loading ? (
              <TestimonialCardSkeleton />
            ) : (
              testimonials.map((testimonial, index) => {
                const position = getCardPosition(index);
                return (
                  <div
                    key={index}
                    className="testimonial-card"
                    style={{
                      opacity: Math.abs(position) > 1 ? 0 : 1 - Math.abs(position) * 0.4,
                      transform: `
                        translateX(${position * 100}%) 
                        scale(${1 - Math.abs(position) * 0.15})
                        translateZ(${position === 0 ? '0' : '-100px'})
                      `,
                      zIndex: 10 - Math.abs(position),
                      filter: `blur(${Math.abs(position) * 2}px)`,
                    }}
                    aria-hidden={position !== 0}
                  >
                    <div className="testimonial-content">
                      {/* Quote icon */}
                      <div className="quote-icon">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor">
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                      </div>
                      
                      {/* Testimonial quote */}
                      <p className="testimonial-text">&ldquo;{testimonial.quote}&rdquo;</p>
                      
                      {/* Author info - without avatar */}
                      <div className="author-info">
                        <p className="author-name">{testimonial.author}</p>
                        <p className="author-company">{testimonial.company}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          
          {/* Carousel controls - disabled during loading */}
          <button 
            onClick={handlePrev}
            className={`carousel-button prev-button ${loading ? 'disabled-button' : ''}`}
            aria-label="Previous testimonial"
            disabled={loading}
          >
            <ChevronLeft className="arrow-icon" />
          </button>
          
          <button 
            onClick={handleNext}
            className={`carousel-button next-button ${loading ? 'disabled-button' : ''}`}
            aria-label="Next testimonial"
            disabled={loading}
          >
            <ChevronRight className="arrow-icon" />
          </button>
          
          {/* Indicator dots */}
          <div className="carousel-indicators">
            {loading ? (
              // Skeleton indicators
              Array(3).fill(0).map((_, index) => (
                <Skeleton 
                  key={index}
                  variant="rounded" 
                  width={index === 0 ? 24 : 8} 
                  height={8} 
                  style={{ borderRadius: index === 0 ? '4px' : '50%' }}
                />
              ))
            ) : (
              testimonials.map((_, index) => (
                <button
                  key={index}
                  onClick={() => {
                    pauseAutoPlay();
                    setCurrentIndex(index);
                  }}
                  className={`indicator-dot ${index === currentIndex ? "active" : ""}`}
                  aria-label={`Go to testimonial ${index + 1}`}
                  aria-current={index === currentIndex ? "true" : "false"}
                />
              ))
            )}
          </div>
        </div>
      </div>

      <style>{`
        .testimonials-section {
          width: 100%;
          padding: 64px 0;
          background: linear-gradient(to bottom, #ffffff, #f7f7f7);
        }

        .testimonials-container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 16px;
        }

        .testimonials-header {
          text-align: center;
          margin-bottom: 48px;
        }

        .testimonials-title {
          font-size: 32px;
          font-weight: bold;
          color: #333333;
          margin-bottom: 8px;
        }

        .testimonials-title-accent {
          color: #3b82f6;
          font-style: italic;
        }

        .testimonials-subtitle {
          color: #666666;
          max-width: 600px;
          margin: 0 auto;
          font-size: 16px;
        }

        .carousel-container {
          position: relative;
          height: 320px;  /* Reduced height from 400px */
          width: 100%;
        }

        .carousel-track {
          position: absolute;
          inset: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .testimonial-card {
          position: absolute;
          width: 100%;
          max-width: 420px;  /* Reduced max-width from 500px */
          border-radius: 10px;  /* Slightly reduced border radius */
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
          background-color: white;
          transition: all 0.5s ease-in-out;
        }

        .testimonial-content {
          padding: 24px;  /* Reduced padding from 32px */
        }

        .quote-icon {
          color: #3b82f6;
          margin-bottom: 12px;  /* Reduced margin */
          opacity: 0.25;
        }

        .testimonial-text {
          color: #4b5563;
          font-style: italic;
          font-size: 16px;  /* Reduced from 18px */
          line-height: 1.5;
          margin-bottom: 16px;  /* Reduced margin */
        }

        .author-info {
          text-align: right;  /* Changed from left to right without avatar */
        }

        .author-name {
          font-weight: bold;
          color: #111827;
        }

        .author-company {
          color: #6b7280;
          font-size: 14px;
        }

        .carousel-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: rgba(255, 255, 255, 0.9);
          color: #1f2937;
          padding: 10px;  /* Smaller buttons */
          border-radius: 50%;
          border: none;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          z-index: 20;
          transition: all 0.2s ease;
        }

        .carousel-button:hover:not(.disabled-button) {
          background-color: #ffffff;
          transform: translateY(-50%) scale(1.1);
        }

        .carousel-button:focus:not(.disabled-button) {
          outline: none;
          box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
        }

        .disabled-button {
          opacity: 0.5;
          cursor: default;
          pointer-events: none;
        }

        .prev-button {
          left: 8px;
        }

        .next-button {
          right: 8px;
        }

        .arrow-icon {
          height: 20px;  /* Reduced from 24px */
          width: 20px;
        }

        .carousel-indicators {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          gap: 8px;
          margin-bottom: 12px;  /* Reduced from 16px */
        }

        .indicator-dot {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          background-color: #d1d5db;
          border: none;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .indicator-dot.active {
          background-color: #3b82f6;
          width: 24px;
          border-radius: 4px;
        }

        .indicator-dot:hover:not(.active) {
          background-color: #9ca3af;
        }

        /* Media Queries for Responsiveness */
        @media (min-width: 640px) {
          .testimonials-container {
            padding: 0 24px;
          }
          
          .prev-button {
            left: 32px;
          }
          
          .next-button {
            right: 32px;
          }
        }

        @media (max-width: 640px) {
          .testimonial-card {
            max-width: 85%;  /* Slightly reduced from 90% */
          }
          
          .testimonial-content {
            padding: 20px;  /* Reduced from 24px */
          }
          
          .testimonial-text {
            font-size: 15px;  /* Reduced from 16px */
          }
        }
      `}</style>
    </section>
  );
};

export default Testimonials;