import axios from "axios";
var basicAuth = "Basic " + btoa("c4esathyaraj_raj".concat(":", "Welcome"));
var thirdpartyAuth = "Basic " + btoa("admin".concat(":", "5A4U8S3Y9I0E1W2R6T4O9P8G2F4D3"));
var today = new Date();

export const APIData = {
  api: "https://api.care4edu.com/c4e/",
  tpapi: "https://tp.care4edu.com/c4e/",
  headers: { Authorization: basicAuth },
  tpheaders: { Authorization: thirdpartyAuth },
  url: "https://portal.care4edu.com/",
  admin: "c4e_superadmin",
  website: "https://portal.care4edu.com/",
  date: today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate(),
  orgName: "Care4Edu",
  sessiondetails: JSON.parse(localStorage.getItem("sessiondetails")),
};

export const AuthorizationRoles = ["SUPERADMIN", "ADMIN"];
export const empRole = "employee";
export const exitKeyWord = "exit";
export const org = "c4e";
// export const org = process.env.REACT_APP_ORG ;

const apiUrl = "https://api.care4edu.com/c4e/asat";
const username = "c4esathyaraj_raj";
const password = "Welcome";

const McqBaseUrl = "https://api.care4edu.com/c4e/";
const McqAdmin = "c4esathyaraj_raj";
const McqPassword = "Welcome";

export const c4eApi = axios.create({
  baseURL: apiUrl,
  auth: {
    username: username,
    password: password,
  },
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiData = {
  apiUrl,
  username,
  password,
};

export const Mcq = axios.create({
  baseURL: McqBaseUrl,
  auth: {
    username: McqAdmin,
    password: McqPassword,
  },
  headers: {
    "Content-Type": "application/json",
  },
});

export const Signin = axios.create({
  baseURL: McqBaseUrl,
  auth: {
    username: McqAdmin,
    password: McqPassword,
  },
  headers: {
    "Content-Type": "application/json",
  },
});
