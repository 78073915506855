import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Appbar.css";

function NavbarSecondary() {
  return (
    <div>

      <Navbar expand="lg" style={{ height: "fit-content", margin: "0", padding: "0" ,border:"1px solid grey"}}>
        <Container fluid className="nav-container" style={{ backgroundColor: "",display: "flex",
            alignItems: "center",
            justifyContent: "center", }} >
          <Navbar.Toggle aria-controls="navbarScroll" style={{ height: "40px", width: "100%" }} />
          <Navbar.Collapse id="navbarScroll">
            <Nav style={{ margin: "0", padding: "0" }}>
              <Link to="/" className="navitem" onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
  onMouseLeave={(e) => (e.target.style.textDecoration = "none")}>
                <Nav.Item style={{ height: "100%", fontFamily: "Roboto Slab" }}>Home</Nav.Item>
              </Link>
              <Link to="/about" className="navitem" onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
  onMouseLeave={(e) => (e.target.style.textDecoration = "none")}>
                <Nav.Item style={{ height: "100%", fontFamily: "Roboto Slab", }}>About us</Nav.Item>
              </Link>
              <Link to="/training" className="navitem" onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
  onMouseLeave={(e) => (e.target.style.textDecoration = "none")}>
                <Nav.Item style={{ height: "100%", fontFamily: "Roboto Slab" }}>Training</Nav.Item>
              </Link>
              <Link to="/careers" className="navitem" onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
  onMouseLeave={(e) => (e.target.style.textDecoration = "none")}>
                <Nav.Item style={{ height: "100%", fontFamily: "Roboto Slab" }}>Careers</Nav.Item>
              </Link>
              <Link to="/contact" className="navitem" onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
  onMouseLeave={(e) => (e.target.style.textDecoration = "none")}>
                <Nav.Item style={{ height: "100%", fontFamily: "Roboto Slab" }}>Contact</Nav.Item>
              </Link>
              <Link to="/Techcenter" className="navitem" onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
  onMouseLeave={(e) => (e.target.style.textDecoration = "none")}>
                <Nav.Item style={{ height: "100%", fontFamily: "Roboto Slab" }}>Technology Center</Nav.Item>
              </Link>
              <Link to="/videos" className="navitem" onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
  onMouseLeave={(e) => (e.target.style.textDecoration = "none")}>
                <Nav.Item style={{ height: "100%", fontFamily: "Roboto Slab" }}>Videos</Nav.Item>
              </Link>


            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavbarSecondary;

{/* <Link to="/products" className="navitem">
              <Nav.Item style={{height:"100%",fontFamily:"Roboto Slab"}}>Products</Nav.Item>
            </Link> */}
{/* <div className="navitem" >
                <NavDropdown title="Products" className="custom-dropdown" style={{ height: "100%", fontFamily: "Roboto Slab", fontSize: "1rem", }}>
                  <Link to="/solidcam" className="dropdown-item ">
                    Solidcam
                  </Link>
                  <Link to="/solidwork" className="dropdown-item " >
                    Solidworks
                  </Link>
                  <Link to="/cnc" className="dropdown-item ">
                    CNC Machines
                  </Link>
                  <Link to='/grabert' className="dropdown-item">
                    Ares Graebert
                  </Link>
                </NavDropdown>
              </div> */}


// <Dropdown
//   title='Products'
//   //  className="custom-dropdown"
//   className="navitem"
//   position="right"
//   buttonVariant="text"
//   style={{ fontFamily: "Roboto Slab",  backgroundColor:"transparent" , margin:"1px", }}
// >

//   <Link to="/solidcam" >
//     <Dropdown.Item>
//       Solidcam
//     </Dropdown.Item></Link>
//   <Link to="/solidwork"  >
//     <Dropdown.Item >
//       Solidcam
//     </Dropdown.Item></Link>
//   <Link to="/cnc" >
//     <Dropdown.Item >
//       CNC Machines
//     </Dropdown.Item></Link>
//   <Dropdown.Item>
//     Ares Graebert
//     <Dropdown.Submenu position="right">
//       <Link to='/Arescommender'>
//         <Dropdown.Item>
//           Ares Commander
//         </Dropdown.Item></Link>
//       <Dropdown.Item>
//         Ares Kudo
//       </Dropdown.Item>
//       <Dropdown.Item>
//         Ares Touch
//       </Dropdown.Item>
//     </Dropdown.Submenu>
//   </Dropdown.Item>
// </Dropdown>