// import React, { useState, useEffect } from "react";
// import { APIData, org } from '../authentication/APIData';
// import axios from "axios";
// import { CircularProgress, Grid } from "@mui/material";
// import { Link, useNavigate } from 'react-router-dom';
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftButton from "components/SoftButton";
// import Footer from "examples/Footer";
// import Card from "@mui/material/Card";
// import DefaultProjectCard1 from "examples/Cards/ProjectCards/DefaultProjectCard/index1";
// import PageLayout from "examples/LayoutContainers/PageLayout";
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import Header from "Home/Mheader";
// import Footers from "Home/Footers";
// import Navbarrr from "Home/Navbarrr";
// import SocialSidebar from "Home/SocialSidebar";

// const sessiondetails = localStorage.getItem("sessiondetails");

// export const UserJobs = () => {
//     const navigate = useNavigate();
//     const [jobs, setJobs] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [selectedJobType, setSelectedJobType] = useState("EXTERNAL");

//     const fetchJobs = async (jobType) => {
//         setLoading(true);
//         try {
//             const url = APIData.api + `jobs/valid-and-opening-type?valid=YES&openingType=${jobType}&org=${org}`;
//             const response = await axios.get(url, { headers: APIData.headers });
//             setJobs(response.data);
//         } catch (err) {
//             setError(err.message);
//             console.error(`Error fetching ${jobType} jobs`, err);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchJobs(selectedJobType);
//     }, [selectedJobType]);

//     const handleJobClick = (job) => {
//         localStorage.setItem('selectedItem', JSON.stringify(job));
//         navigate(`/jobView/${job.job_id}`, { state: { job } });
//     };

//     if (loading) {
//         return (
//             <PageLayout>
//                 {/* <DefaultNavbar /> */}
//                 <Navbarrr/>
//                 <SoftBox display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
//                     <CircularProgress />
//                     <SoftTypography variant="h6" ml={2}>
//                         Loading...
//                     </SoftTypography>
//                 </SoftBox>
//                 <Footer />
//             </PageLayout>
//         );
//     }

//     return (
//         <PageLayout>
//             {!sessiondetails && <Navbarrr />}
//             <SoftBox mb={3} mt={12}>
//                 <Card>
//                     <SoftBox pt={2} px={2}>
//                         <SoftBox mb={1}>
//                             <SoftTypography
//                                 variant="h4"
//                                 fontWeight="regular"
//                                 color="info"
//                                 textAlign="center"
//                                 style={{ fontFamily: "Georgia, serif" }}
//                             >
//                                 Career Opportunities
//                             </SoftTypography>
//                         </SoftBox>
//                     </SoftBox>

//                     <SoftBox p={2}>
//                         {error ? (
//                             <SoftTypography color="error" align="center">
//                                 {error}
//                             </SoftTypography>
//                         ) : jobs && jobs.length > 0 ? (
//                             <Grid container spacing={3}>
//                                 {jobs.map((job, index) => (
//                                     <Grid item xs={12} md={6} xl={3} key={job.id || index}>
//                                         <div onClick={() => handleJobClick(job)} style={{ cursor: 'pointer' }}>
//                                             <DefaultProjectCard1
//                                                 image={job.image_url}
//                                                 title={job.job_designation}
//                                                 label={`Department: ${job.job_dept}`}
//                                                 description={
//                                                     <>
//                                                         <SoftTypography variant="body2" color="text">
//                                                             Location: {job.location}
//                                                         </SoftTypography>
//                                                         <SoftTypography variant="body2" color="text">
//                                                             Job Tenure: {job.job_tenure}
//                                                         </SoftTypography>
//                                                         <SoftTypography variant="body2" color="text">
//                                                             Salary: {job.salary}
//                                                         </SoftTypography>
//                                                     </>
//                                                 }
//                                                 action={{
//                                                     type: "internal",
//                                                     route: "javascript:void(0)",
//                                                     color: "info",
//                                                     label: "Know More"
//                                                 }}
//                                                 sx={{
//                                                     boxShadow: 3,
//                                                     '&:hover': {
//                                                         transform: 'scale(1.05)',
//                                                         transition: 'transform 0.3s ease-in-out'
//                                                     },
//                                                 }}
//                                             />
//                                         </div>
//                                     </Grid>
//                                 ))}
//                             </Grid>
//                         ) : (
//                             <SoftBox display="flex" flexDirection="column" alignItems="center" p={3}>
//                                 <SoftTypography
//                                     variant="h4"
//                                     color="info"
//                                     style={{
//                                         fontFamily: "Georgia, serif",
//                                         fontWeight: "bold",
//                                         fontSize: "25px"
//                                     }}
//                                 >
//                                     We Are not Hiring
//                                 </SoftTypography>
//                                 <Link to='/' style={{ textDecoration: 'none', marginTop: '20px' }}>
//                                     <SoftButton variant="contained" color="info">
//                                         Go Back
//                                     </SoftButton>
//                                 </Link>
//                             </SoftBox>
//                         )}
//                     </SoftBox>
//                 </Card>
//             </SoftBox>
//             <SocialSidebar />
//             <Footers />
//         </PageLayout>
//     );
// };

// export default UserJobs;


import React, { useState, useEffect } from "react";
import { APIData, org } from '../authentication/APIData';
import axios from "axios";
import { CircularProgress, Grid, Skeleton } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import DefaultProjectCard1 from "examples/Cards/ProjectCards/DefaultProjectCard/index1";
import PageLayout from "examples/LayoutContainers/PageLayout";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Header from "Home/Mheader";
import Footers from "Home/Footers";
import Navbarrr from "Home/Navbarrr";
import SocialSidebar from "Home/SocialSidebar";

const sessiondetails = localStorage.getItem("sessiondetails");

export const UserJobs = () => {
    const navigate = useNavigate();
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedJobType, setSelectedJobType] = useState("EXTERNAL");

    const fetchJobs = async (jobType) => {
        setLoading(true);
        try {
            const url = APIData.api + `jobs/valid-and-opening-type?valid=YES&openingType=${jobType}&org=${org}`;
            const response = await axios.get(url, { headers: APIData.headers });
            setJobs(response.data);
        } catch (err) {
            setError(err.message);
            console.error(`Error fetching ${jobType} jobs`, err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJobs(selectedJobType);
    }, [selectedJobType]);

    const handleJobClick = (job) => {
        localStorage.setItem('selectedItem', JSON.stringify(job));
        navigate(`/jobView/${job.job_id}`, { state: { job } });
    };

    // Skeleton card component that mimics the appearance of DefaultProjectCard1 for jobs
    const SkeletonCard = () => (
        <Card sx={{
            boxShadow: 3,
            '&:hover': {
                transform: 'scale(1.05)',
                transition: 'transform 0.3s ease-in-out'
            },
        }}>
            <SoftBox p={0}>
                {/* Skeleton for the image */}
                <Skeleton 
                    variant="rectangular" 
                    width="100%" 
                    height={200} 
                    animation="wave"
                    sx={{ borderRadius: '0.5rem 0.5rem 0 0' }}
                />
                
                <SoftBox p={3}>
                    {/* Skeleton for job designation */}
                    <Skeleton variant="text" width="80%" height={30} animation="wave" sx={{ mb: 1 }} />
                    
                    {/* Skeleton for department */}
                    <Skeleton variant="text" width="60%" height={20} animation="wave" sx={{ mb: 1 }} />
                    
                    {/* Skeleton for job details (Location, Tenure, Salary) */}
                    <Skeleton variant="text" width="70%" height={20} animation="wave" sx={{ mb: 0.5 }} />
                    <Skeleton variant="text" width="50%" height={20} animation="wave" sx={{ mb: 0.5 }} />
                    <Skeleton variant="text" width="40%" height={20} animation="wave" sx={{ mb: 2 }} />
                    
                    {/* Skeleton for "Know More" button */}
                    <Skeleton 
                        variant="rectangular" 
                        width="40%" 
                        height={35} 
                        animation="wave" 
                        sx={{ borderRadius: '0.5rem' }}
                    />
                </SoftBox>
            </SoftBox>
        </Card>
    );
    
    const renderSkeletonGrid = () => (
        <Grid container spacing={3}>
            {[...Array(8)].map((_, index) => (
                <Grid item xs={12} md={6} xl={3} key={`skeleton-${index}`}>
                    <SkeletonCard />
                </Grid>
            ))}
        </Grid>
    );

    return (
        <PageLayout>
            {!sessiondetails && <Navbarrr />}
            <SoftBox mb={3} mt={12}>
                <Card>
                    <SoftBox pt={2} px={2}>
                        <SoftBox mb={1}>
                            <SoftTypography
                                variant="h4"
                                fontWeight="regular"
                                color="info"
                                textAlign="center"
                                style={{ fontFamily: "Georgia, serif" }}
                            >
                                Career Opportunities
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>

                    <SoftBox p={2}>
                        {error ? (
                            <SoftTypography color="error" align="center">
                                {error}
                            </SoftTypography>
                        ) : loading ? (
                            renderSkeletonGrid()
                        ) : jobs && jobs.length > 0 ? (
                            <Grid container spacing={3}>
                                {jobs.map((job, index) => (
                                    <Grid item xs={12} md={6} xl={3} key={job.id || index}>
                                        <div onClick={() => handleJobClick(job)} style={{ cursor: 'pointer' }}>
                                            <DefaultProjectCard1
                                                image={job.image_url}
                                                title={job.job_designation}
                                                label={`Department: ${job.job_dept}`}
                                                description={
                                                    <>
                                                        <SoftTypography variant="body2" color="text">
                                                            Location: {job.location}
                                                        </SoftTypography>
                                                        <SoftTypography variant="body2" color="text">
                                                            Job Tenure: {job.job_tenure}
                                                        </SoftTypography>
                                                        <SoftTypography variant="body2" color="text">
                                                            Salary: {job.salary}
                                                        </SoftTypography>
                                                    </>
                                                }
                                                action={{
                                                    type: "internal",
                                                    route: "javascript:void(0)",
                                                    color: "info",
                                                    label: "Know More"
                                                }}
                                                sx={{
                                                    boxShadow: 3,
                                                    '&:hover': {
                                                        transform: 'scale(1.05)',
                                                        transition: 'transform 0.3s ease-in-out'
                                                    },
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <SoftBox display="flex" flexDirection="column" alignItems="center" p={3}>
                                <SoftTypography
                                    variant="h4"
                                    color="info"
                                    style={{
                                        fontFamily: "Georgia, serif",
                                        fontWeight: "bold",
                                        fontSize: "25px"
                                    }}
                                >
                                    We Are not Hiring
                                </SoftTypography>
                                <Link to='/' style={{ textDecoration: 'none', marginTop: '20px' }}>
                                    <SoftButton variant="contained" color="info">
                                        Go Back
                                    </SoftButton>
                                </Link>
                            </SoftBox>
                        )}
                    </SoftBox>
                </Card>
            </SoftBox>
            <SocialSidebar />
            <Footers />
        </PageLayout>
    );
};

export default UserJobs;