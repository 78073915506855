import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import breakpoints from "assets/theme/base/breakpoints";
import { Link } from 'react-router-dom';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import Swal from 'sweetalert2';
import { Card } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import CircularProgress from "@mui/material/CircularProgress";

const Name = ({ name, email }) => {
    const [showFullName, setShowFullName] = useState(false);
    const nameWords = name.split(" ");

    const handleToggleFullName = () => {
        setShowFullName(!showFullName);
    };

    const displayedName = showFullName || nameWords.length <= 3
        ? name
        : `${nameWords.slice(0, 3).join(" ")}...`;

    return (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
            <SoftBox mr={2}>
                <SoftAvatar
                    src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                    alt={name}
                    size="sm"
                    variant="rounded"
                />
            </SoftBox>
            <SoftBox display="flex" flexDirection="column">
                <SoftTypography variant="button" fontWeight="medium">
                    {displayedName}
                </SoftTypography>
                {nameWords.length > 3 && (
                    <SoftTypography
                        variant="caption"
                        color="info"
                        onClick={handleToggleFullName}
                        style={{ cursor: "pointer" }}
                    >
                        {showFullName ? "Show Less" : "Read More"}
                    </SoftTypography>
                )}
                <SoftTypography variant="caption" color="secondary">
                    {email}
                </SoftTypography>
            </SoftBox>
        </SoftBox>
    );
};

Name.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
};

const Organization = ({ salesType, orgName }) => (
    <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="caption" fontWeight="medium" color="text">
            {salesType}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
            {orgName}
        </SoftTypography>
    </SoftBox>
);

Organization.propTypes = {
    salesType: PropTypes.string.isRequired,
    orgName: PropTypes.string.isRequired,
};

const CoursesEnrolled = () => {
    const [courseData, setCourseData] = useState([]);
    const [status, setStatus] = useState("All");
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [error, setError] = useState(null);

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(accessLevel);
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);

    const handleDelete = async (studentId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This action will permanently delete the student's course enrollment!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const deleteUrl = `${APIData.api}courses-enrolled/${studentId}`;
                    const response = await fetch(deleteUrl, {
                        method: 'DELETE',
                        headers: APIData.headers
                    });

                    if (response.ok) {
                        Swal.fire({
                            icon: "success",
                            title: "Deleted successfully!",
                            showConfirmButton: false,
                            timer: 1500
                        });
                        setCourseData(prevData => prevData.filter(course => course.student_id !== studentId));
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Failed to Delete Course',
                            text: 'Please try again later.',
                            confirmButtonText: 'OK'
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Submission Error",
                        text: error.response?.data?.message || 'Error deleting course. Please try again later.',
                        confirmButtonText: 'OK'
                    });
                }
            }
        });
    };

    useEffect(() => {
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }
        window.addEventListener("resize", handleTabsOrientation);

        handleTabsOrientation();

        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const handleSetTabValue = (event, newValue) => {
        return setTabValue(newValue);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const baseUrl = `${APIData.api}courses-enrolled?org=${org}`;

            try {
                const response = await fetch(baseUrl, { headers: APIData.headers });
                if (!response.ok) throw new Error("Failed to fetch course data");
                const data = await response.json();
                
                 const updatedData = data.map(course => ({
                    ...course,
                    courseName: course.course_id || 'N/A',
                    studentName: course.studentName || 'N/A'
                }));
                
                setCourseData(updatedData);
                setError(null);
            } catch (error) {
                setError('Failed to fetch course data');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to fetch course data.',
                });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [tabValue]);

    const filteredCourses = courseData.filter(course =>
        (course.courseName || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (course.studentName || '').toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedCourses = filteredCourses.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const columns = [
        { name: "Course", align: "left" },
        { name: "Student", align: "left" },
        ...(permission === "1111" ? [{ name: "Action", align: "left" }] : [])
    ];

    const rows = paginatedCourses.map((course) => ({
        Course: <Name name={course.courseName || 'N/A'} />,
        Student: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {course.studentName || 'N/A'}
            </SoftTypography>
        ),
        Action: (
            <>
                {(permission === "1111" || isSuperAdmin) && (
                    <Link>
                        <SoftTypography
                            component="a"
                            variant="button"
                            color="error"
                            fontWeight="medium"
                            sx={{ marginLeft: "10px" }}
                            onClick={() => handleDelete(course.student_id)}
                        >
                            Delete
                        </SoftTypography>
                    </Link>
                )}
            </>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card sx={{ maxWidth: '1000px', margin: '0 auto' }}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Courses Enrolled</SoftTypography>
                            <SoftBox>
                                <SoftInput
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    size="small"
                                />
                            </SoftBox>
                        </SoftBox>
                        <SoftBox sx={{ overflow: 'auto' }}>
                            {loading ? (
                                <SoftBox display="flex" justifyContent="center" p={3}>
                                    <CircularProgress />
                                </SoftBox>
                            ) : error ? (
                                <SoftBox display="flex" justifyContent="center" p={3}>
                                    <SoftTypography color="error">{error}</SoftTypography>
                                </SoftBox>
                            ) : (
                                <>
                                    <SoftBox
                                        sx={{
                                            "& .MuiTableRow-root:not(:last-child)": {
                                                "& td": {
                                                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                        `${borderWidth[1]} solid ${borderColor}`,
                                                },
                                            },
                                        }}
                                    >
                                        <Table columns={columns} rows={rows} />
                                    </SoftBox>
                                    <SoftBox
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        p={3}
                                    >
                                        <SoftBox display="flex" gap={1} alignItems="center">
                                            <SoftTypography variant="caption" color="secondary">
                                                {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredCourses.length)} of {filteredCourses.length}
                                            </SoftTypography>
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                                disabled={page === 0}
                                                iconOnly
                                            >
                                                <KeyboardArrowLeft />
                                            </SoftButton>
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={() => setPage(prev => Math.min(Math.ceil(filteredCourses.length / itemsPerPage) - 1, prev + 1))}
                                                disabled={page >= Math.ceil(filteredCourses.length / itemsPerPage) - 1}
                                                iconOnly
                                            >
                                                <KeyboardArrowRight />
                                            </SoftButton>
                                        </SoftBox>
                                    </SoftBox>
                                </>
                            )}
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default CoursesEnrolled;