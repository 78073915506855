import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { APIData } from 'authentication/APIData';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from 'components/SoftButton';
import SoftInput from "components/SoftInput";
import { Card, Grid, Tooltip, FormControl, FormHelperText } from "@mui/material";
import * as AiIcons from "react-icons/ai";
import Swal from 'sweetalert2';
import axios from 'axios';
import { org } from 'authentication/APIData';

const EditService = () => {
    const { id } = useParams();
    const [serviceDetails, setServiceDetails] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchServiceDetails = async () => {
            const url = `${APIData.api}services/${id}`;
            try {
                const response = await fetch(url, { headers: APIData.headers });
                if (response.ok) {
                    const data = await response.json();
                    setServiceDetails(data);
                    if (data.image) setImagePreview(data.image);
                } else {
                    Swal.fire('Error', 'Failed to fetch service details', 'error');
                }
            } catch (error) {
                console.error('Error fetching service details:', error);
                Swal.fire('Error', 'Failed to fetch service details', 'error');
            }
        };

        fetchServiceDetails();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setServiceDetails(prevDetails => ({ ...prevDetails, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: '' }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 1000000) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "File too large. Max file size is 1MB.",
                });
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
                setServiceDetails(prev => ({ ...prev, image: reader.result }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        const newErrors = {};
        
        // Validation
        if (!serviceDetails.name || /^\d+$/.test(serviceDetails.name)) {
            newErrors.name = "Service name is required and must not be only numbers.";
        }
        if (serviceDetails.description && (/^\d+$/.test(serviceDetails.description) || serviceDetails.description.length > 500)) {
            newErrors.description = "Description must not be only numbers and must not exceed 500 characters.";
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please fix the following errors and try again:',
                footer: Object.values(newErrors).join('<br>')
            });
            return;
        }

        setLoading(true);

        const payload = {
            id: id,
            name: serviceDetails.name,
            org: org
        };

        // Conditionally add optional fields
        if (serviceDetails.description) {
            payload.description = serviceDetails.description;
        }

        // If image is present, add to payload
        if (imagePreview) {
            payload.image = imagePreview;
        }

        try {
            const response = await axios.put(`${APIData.api}services/`, payload, {
                headers: { 
                    ...APIData.headers
                },
                timeout: 10000 // 10 second timeout
            });

            if (response.status === 200 || response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Service details updated successfully',
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(() => {
                    navigate('/services');
                }, 1500);
            } else {
                Swal.fire('Error', 'Failed to update service details', 'error');
            }
        } catch (error) {
            console.error('Full error object:', error);
    
            // Comprehensive error handling
            if (error.code === 'ECONNABORTED') {
                Swal.fire('Timeout', 'The request took too long. Please check your network connection.', 'error');
            } else if (!navigator.onLine) {
                Swal.fire('Network Error', 'You are offline. Please check your internet connection.', 'error');
            } else if (error.message === 'Network Error') {
                Swal.fire('Network Error', 'Unable to connect to the server. Please check your network settings.', 'error');
            } else {
                Swal.fire('Error', 'Failed to update service: ' + error.message, 'error');
            }
            
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4" mt={3}>
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Tooltip title="Close" arrow>
                                <Link to="/services">
                                    <AiIcons.AiOutlineCloseCircle />
                                </Link>
                            </Tooltip>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Edit Service
                            </SoftTypography>
                        </SoftBox>
                        {serviceDetails ? (
                            <SoftBox component="form" role="form" onSubmit={handleSaveChanges} px={3}>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Service Name *</SoftTypography>
                                    <FormControl fullWidth error={!!errors.name}>
                                        <SoftInput
                                            placeholder="Service Name"
                                            name="name"
                                            value={serviceDetails.name || ''}
                                            onChange={handleInputChange}
                                        />
                                        {errors.name && (
                                            <FormHelperText>{errors.name}</FormHelperText>
                                        )}
                                    </FormControl>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Description</SoftTypography>
                                    <FormControl fullWidth error={!!errors.description}>
                                        <SoftInput
                                            multiline
                                            rows={4}
                                            placeholder="Enter service description..."
                                            name="description"
                                            value={serviceDetails.description || ''}
                                            onChange={handleInputChange}
                                        />
                                        {errors.description && (
                                            <FormHelperText>{errors.description}</FormHelperText>
                                        )}
                                    </FormControl>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="service-image"
                                            type="file"
                                            onChange={handleImageChange}
                                        />
                                        <label htmlFor="service-image">
                                            <SoftButton variant="contained" component="span">
                                                Upload Service Image
                                            </SoftButton>
                                        </label>
                                        {imagePreview && (
                                            <SoftBox mt={2}>
                                                <img
                                                    src={imagePreview}
                                                    alt="Service Preview"
                                                    style={{ width: '300px', height: 'auto', objectFit: 'contain' }}
                                                />
                                            </SoftBox>
                                        )}
                                    </SoftBox>
                                </SoftBox>

                                <SoftBox mt={4} mb={1}>
                                    <SoftButton
                                        type="submit"
                                        variant="gradient"
                                        color="info"
                                        fullWidth
                                        disabled={loading}
                                    >
                                        {loading ? "Saving Changes..." : "Save Changes"}
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        ) : (
                            <SoftBox p={3} className="text-center">
                                <SoftTypography variant="h6" color="text">
                                    Loading service details...
                                </SoftTypography>
                            </SoftBox>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default EditService;