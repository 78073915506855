// import React, { useState, useEffect } from 'react';
// import { Box, Typography, Grid, CircularProgress, IconButton, Tooltip, Avatar } from '@mui/material';
// import { motion, AnimatePresence } from 'framer-motion';
// import { CalendarToday, LocationOn, AccessTime, ArrowForward, ArrowBack } from '@mui/icons-material';
// import { APIData, org } from 'authentication/APIData';
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import PageLayout from 'examples/LayoutContainers/PageLayout';

// const UpcomingEvents = () => {
//   const [events, setEvents] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [currentEventIndex, setCurrentEventIndex] = useState(0);
//   const [isDetailsOpen, setIsDetailsOpen] = useState(false);

//   useEffect(() => {
//     const fetchUpcomingEvents = async () => {
//       setLoading(true);
//       try {
//         const url = `${APIData.api}events/?org=${org}`;
//         const response = await fetch(url, { headers: APIData.headers });
//         const allEvents = await response.json();
        
//         // Filter for today and future events
//         const currentDate = new Date();
//         currentDate.setHours(0, 0, 0, 0); // Set to beginning of today
        
//         const upcomingEvents = allEvents.filter(event => {
//           const eventDate = new Date(event.event_date);
//           eventDate.setHours(0, 0, 0, 0);
//           return eventDate >= currentDate;
//         });
        
//         // Sort by date (closest first)
//         upcomingEvents.sort((a, b) => new Date(a.event_date) - new Date(b.event_date));
        
//         // Take first 6 events
//         setEvents(upcomingEvents.slice(0, 6));
//       } catch (error) {
//         console.error('Error fetching upcoming events:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUpcomingEvents();
//   }, []);

//   const formatDate = (dateString) => {
//     const options = { weekday: 'long', month: 'long', day: 'numeric' };
//     return new Date(dateString).toLocaleDateString('en-US', options);
//   };

//   // Check if event is today
//   const isToday = (dateString) => {
//     const eventDate = new Date(dateString);
//     const today = new Date();
//     return eventDate.getDate() === today.getDate() &&
//            eventDate.getMonth() === today.getMonth() &&
//            eventDate.getFullYear() === today.getFullYear();
//   };

//   const nextEvent = () => {
//     setIsDetailsOpen(false);
//     setTimeout(() => {
//       setCurrentEventIndex((prev) => (prev + 1) % events.length);
//     }, 300);
//   };

//   const prevEvent = () => {
//     setIsDetailsOpen(false);
//     setTimeout(() => {
//       setCurrentEventIndex((prev) => (prev - 1 + events.length) % events.length);
//     }, 300);
//   };

//   const toggleDetails = () => {
//     setIsDetailsOpen(!isDetailsOpen);
//   };

//   // Calculate days remaining
//   const getDaysRemaining = (dateString) => {
//     const eventDate = new Date(dateString);
//     eventDate.setHours(0, 0, 0, 0);
//     const today = new Date();
//     today.setHours(0, 0, 0, 0);
    
//     const diffTime = eventDate - today;
//     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
//     if (diffDays === 0) return "Today";
//     if (diffDays === 1) return "Tomorrow";
//     return `${diffDays} days`;
//   };

//   return (
//     <PageLayout>
//       <SoftBox py={4} px={2}>
//         <motion.div
//           initial={{ opacity: 0, y: -20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.8 }}
//         >
//           <SoftBox 
//             display="flex" 
//             flexDirection="column" 
//             alignItems="center" 
//             textAlign="center" 
//             mb={4}
//           >
//             <SoftTypography 
//               variant="h3" 
//               fontWeight="bold" 
//               color="dark" 
//               mb={1}
//               sx={{ 
//                 position: 'relative',
//                 display: 'inline-block',
//                 '&::after': {
//                   content: '""',
//                   position: 'absolute',
//                   width: '60px',
//                   height: '4px',
//                   bottom: '-10px',
//                   left: 'calc(50% - 30px)',
//                   borderRadius: '2px'
//                 }
//               }}
//             >
//               Upcoming Events
//             </SoftTypography>
//           </SoftBox>
//         </motion.div>

//         {loading ? (
//           <Box display="flex" justifyContent="center" alignItems="center" minHeight="250px">
//             <CircularProgress color="info" />
//           </Box>
//         ) : events.length === 0 ? (
//           <Box 
//             display="flex" 
//             justifyContent="center" 
//             alignItems="center" 
//             minHeight="200px"
//             borderRadius="10px"
//             bgcolor="white"
//             boxShadow="0 2px 12px 0 rgba(0,0,0,0.05)"
//           >
//             <SoftTypography variant="h6" color="text">
//               No upcoming events scheduled
//             </SoftTypography>
//           </Box>
//         ) : (
//           <Box sx={{ position: 'relative', overflow: 'hidden' }}>
//             {/* Timeline Indicator */}
//             <Box sx={{ 
//               display: 'flex', 
//               justifyContent: 'center', 
//               mb: 3, 
//               position: 'relative'
//             }}>
//               <Box sx={{ 
//                 width: '80%', 
//                 height: '3px', 
//                 bgcolor: 'rgba(0,0,0,0.1)', 
//                 borderRadius: '4px',
//                 position: 'absolute',
//                 top: '50%',
//                 transform: 'translateY(-50%)',
//                 zIndex: 0
//               }} />
              
//               <Grid container justifyContent="space-between" sx={{ width: '80%', position: 'relative', zIndex: 1 }}>
//                 {events.map((event, index) => (
//                   <Grid item key={event.id}>
//                     <Tooltip title={new Date(event.event_date).toLocaleDateString()}>
//                       <Box 
//                         onClick={() => {
//                           setIsDetailsOpen(false);
//                           setTimeout(() => setCurrentEventIndex(index), 300);
//                         }}
//                         sx={{ 
//                           cursor: 'pointer',
//                           display: 'flex',
//                           flexDirection: 'column',
//                           alignItems: 'center'
//                         }}
//                       >
//                         <Avatar 
//                           sx={{ 
//                             width: 36, 
//                             height: 36, 
//                             bgcolor: index === currentEventIndex ? '#3f51b5' : 'white',
//                             color: index === currentEventIndex ? 'white' : '#3f51b5',
//                             border: '3px solid #3f51b5',
//                             transition: 'all 0.3s ease',
//                             transform: index === currentEventIndex ? 'scale(1.2)' : 'scale(1)',
//                             boxShadow: index === currentEventIndex ? '0 4px 12px rgba(63, 81, 181, 0.3)' : 'none'
//                           }}
//                         >
//                           {new Date(event.event_date).getDate()}
//                         </Avatar>
//                         {index === currentEventIndex && (
//                           <Box 
//                             sx={{ 
//                               width: '100%', 
//                               textAlign: 'center', 
//                               mt: 1, 
//                               position: 'absolute',
//                               top: '100%',
//                               whiteSpace: 'nowrap',
//                               fontWeight: 'bold',
//                               color: '#3f51b5',
//                               fontSize: '11px'
//                             }}
//                           >
//                             {new Date(event.event_date).toLocaleDateString('en-US', { month: 'short' })}
//                           </Box>
//                         )}
//                       </Box>
//                     </Tooltip>
//                   </Grid>
//                 ))}
//               </Grid>
//             </Box>

//             {/* Navigation Controls */}
//             <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
//               <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
//                 <IconButton 
//                   onClick={prevEvent} 
//                   sx={{ 
//                     bgcolor: 'rgba(63, 81, 181, 0.1)', 
//                     '&:hover': { bgcolor: 'rgba(63, 81, 181, 0.2)' } 
//                   }}
//                 >
//                   <ArrowBack />
//                 </IconButton>
                
//                 <SoftTypography variant="body2" fontWeight="bold" color="text">
//                   {currentEventIndex + 1} of {events.length}
//                 </SoftTypography>
                
//                 <IconButton 
//                   onClick={nextEvent} 
//                   sx={{ 
//                     bgcolor: 'rgba(63, 81, 181, 0.1)', 
//                     '&:hover': { bgcolor: 'rgba(63, 81, 181, 0.2)' } 
//                   }}
//                 >
//                   <ArrowForward />
//                 </IconButton>
//               </Box>
//             </Box>

//             {/* Main Event Display */}
//             <AnimatePresence mode="wait">
//               {events.length > 0 && (
//                 <motion.div
//                   key={currentEventIndex}
//                   initial={{ opacity: 0, x: 100 }}
//                   animate={{ opacity: 1, x: 0 }}
//                   exit={{ opacity: 0, x: -100 }}
//                   transition={{ duration: 0.4 }}
//                 >
//                   <Box
//                     sx={{
//                       display: 'flex',
//                       flexDirection: { xs: 'column', md: 'row' },
//                       borderRadius: '16px',
//                       overflow: 'hidden',
//                       boxShadow: '0 10px 40px rgba(0,0,0,0.1)',
//                       height: { md: isDetailsOpen ? '500px' : '320px' }, // Reduced height
//                       transition: 'height 0.4s ease',
//                       bgcolor: 'white',
//                       mx: { xs: 2, md: 4 }
//                     }}
//                   >
//                     {/* Event Image */}
//                     <Box
//                       sx={{
//                         position: 'relative',
//                         width: { xs: '100%', md: '50%' },
//                         height: { xs: '180px', md: '100%' } // Reduced height for mobile
//                       }}
//                     >
//                       <Box
//                         component="img"
//                         src={events[currentEventIndex].image_url}
//                         alt={events[currentEventIndex].event_name}
//                         sx={{
//                           width: '100%',
//                           height: '100%',
//                           objectFit: 'cover'
//                         }}
//                       />
                      
//                       {/* Countdown Overlay with improved contrast */}
//                       <Box
//                         sx={{
//                           position: 'absolute',
//                           bottom: 0,
//                           left: 0,
//                           width: '100%',
//                           background: 'linear-gradient(to top, rgba(0,0,0,0.8), transparent)',
//                           padding: '20px',
//                           display: 'flex',
//                           justifyContent: 'space-between',
//                           alignItems: 'center'
//                         }}
//                       >
//                         <Box>
//                           <Typography variant="h6" color="white" fontWeight="bold">
//                             {isToday(events[currentEventIndex].event_date) ? "TODAY!" : "Coming Soon"}
//                           </Typography>
//                         </Box>
                        
//                         <Box
//                           sx={{
//                             bgcolor: 'rgba(255, 255, 255, 0.9)', // More opaque background
//                             borderRadius: '30px',
//                             padding: '8px 16px',
//                             display: 'flex',
//                             alignItems: 'center',
//                             boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
//                           }}
//                         >
//                           <CalendarToday fontSize="small" sx={{ mr: 1, color: '#3f51b5' }} />
//                           <Typography variant="body2" fontWeight="bold" color="#3f51b5">
//                             {getDaysRemaining(events[currentEventIndex].event_date)}
//                           </Typography>
//                         </Box>
//                       </Box>
//                     </Box>

//                     {/* Event Details */}
//                     <Box
//                       sx={{
//                         width: { xs: '100%', md: '50%' },
//                         padding: { xs: '20px', md: '25px' }, // Reduced padding
//                         position: 'relative',
//                         display: 'flex',
//                         flexDirection: 'column'
//                       }}
//                     >
//                       <Typography
//                         variant="h5" // Reduced from h4 to h5
//                         fontWeight="bold"
//                         sx={{
//                           color: '#3f51b5',
//                           mb: 1.5, // Reduced margin
//                           position: 'relative',
//                           '&::after': {
//                             content: '""',
//                             position: 'absolute',
//                             width: '40px',
//                             height: '3px',
//                             background: '#3f51b5',
//                             bottom: '-6px',
//                             left: '0',
//                             borderRadius: '2px'
//                           }
//                         }}
//                       >
//                         {events[currentEventIndex].event_name}
//                       </Typography>

//                       <Box sx={{ mt: 2 }}> {/* Reduced margin */}
//                         <Grid container spacing={2}> {/* Reduced spacing */}
//                           <Grid item xs={12}>
//                             <Box display="flex" alignItems="center">
//                               <CalendarToday fontSize="small" sx={{ mr: 1.5, color: '#3f51b5' }} />
//                               <Typography variant="body2" color="text.secondary"> {/* Reduced from body1 to body2 */}
//                                 {formatDate(events[currentEventIndex].event_date)}
//                               </Typography>
//                             </Box>
//                           </Grid>
                          
//                           <Grid item xs={12}>
//                             <Box display="flex" alignItems="center">
//                               <AccessTime fontSize="small" sx={{ mr: 1.5, color: '#3f51b5' }} />
//                               <Typography variant="body2" color="text.secondary">
//                                 {events[currentEventIndex].duration}
//                               </Typography>
//                             </Box>
//                           </Grid>
                          
//                           <Grid item xs={12}>
//                             <Box display="flex" alignItems="flex-start">
//                               <LocationOn fontSize="small" sx={{ mr: 1.5, mt: 0.3, color: '#3f51b5' }} />
//                               <Typography variant="body2" color="text.secondary">
//                                 {events[currentEventIndex].location}
//                               </Typography>
//                             </Box>
//                           </Grid>
//                         </Grid>
//                       </Box>

//                       <AnimatePresence>
//                         {isDetailsOpen && (
//                           <motion.div
//                             initial={{ opacity: 0, height: 0 }}
//                             animate={{ opacity: 1, height: 'auto' }}
//                             exit={{ opacity: 0, height: 0 }}
//                             transition={{ duration: 0.3 }}
//                           >
//                             <Box sx={{ mt: 3 }}> {/* Reduced margin */}
//                               <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
//                                 {events[currentEventIndex].description}
//                               </Typography>
                              
//                               <Box
//                                 sx={{
//                                   mt: 1.5,
//                                   p: 1.5,
//                                   borderLeft: '3px solid #3f51b5',
//                                   bgcolor: 'rgba(63, 81, 181, 0.05)',
//                                   borderRadius: '0 8px 8px 0'
//                                 }}
//                               >
//                                 <Typography variant="body2" color="text.secondary">
//                                   <span style={{ fontWeight: 'bold' }}>Organizer:</span> {events[currentEventIndex].organizer}
//                                 </Typography>
//                               </Box>
//                             </Box>
//                           </motion.div>
//                         )}
//                       </AnimatePresence>

//                       <Box
//                         sx={{
//                           mt: 'auto',
//                           pt: 2, // Reduced padding
//                           display: 'flex',
//                           justifyContent: 'space-between',
//                           alignItems: 'center'
//                         }}
//                       >
//                         <motion.button
//                           whileHover={{ scale: 1.05 }}
//                           whileTap={{ scale: 0.95 }}
//                           onClick={toggleDetails}
//                           style={{
//                             backgroundColor: '#3f51b5',
//                             color: 'white',
//                             border: 'none',
//                             padding: '8px 16px', // Reduced padding
//                             borderRadius: '30px',
//                             fontWeight: 'bold',
//                             cursor: 'pointer',
//                             boxShadow: '0 4px 12px rgba(63, 81, 181, 0.3)',
//                             display: 'flex',
//                             alignItems: 'center',
//                             gap: '6px',
//                             fontSize: '14px' // Smaller font size
//                           }}
//                         >
//                           {isDetailsOpen ? 'Show Less' : 'View Details'}
//                           <motion.div
//                             animate={{ rotate: isDetailsOpen ? 180 : 0 }}
//                             transition={{ duration: 0.3 }}
//                           >
//                             <ArrowForward fontSize="small" />
//                           </motion.div>
//                         </motion.button>
//                       </Box>
//                     </Box>
//                   </Box>
//                 </motion.div>
//               )}
//             </AnimatePresence>

//             {/* Event Thumbnails */}
//             <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
//               <Grid container spacing={1.5} sx={{ maxWidth: '90%' }}> {/* Reduced spacing */}
//                 {events.map((event, index) => (
//                   <Grid item xs={4} sm={2} key={event.id}>
//                     <motion.div 
//                       whileHover={{ scale: 1.05 }}
//                       whileTap={{ scale: 0.95 }}
//                     >
//                       <Box
//                         onClick={() => {
//                           setIsDetailsOpen(false);
//                           setTimeout(() => setCurrentEventIndex(index), 300);
//                         }}
//                         sx={{
//                           width: '100%',
//                           height: '50px', // Reduced height
//                           borderRadius: '8px',
//                           overflow: 'hidden',
//                           position: 'relative',
//                           cursor: 'pointer',
//                           border: index === currentEventIndex ? '3px solid #3f51b5' : '3px solid transparent',
//                           boxShadow: index === currentEventIndex ? '0 4px 12px rgba(63, 81, 181, 0.3)' : '0 2px 8px rgba(0,0,0,0.1)',
//                           transition: 'all 0.3s ease'
//                         }}
//                       >
//                         <Box
//                           component="img"
//                           src={event.image_url}
//                           alt={event.event_name}
//                           sx={{
//                             width: '100%',
//                             height: '100%',
//                             objectFit: 'cover'
//                           }}
//                         />
//                         <Box
//                           sx={{
//                             position: 'absolute',
//                             top: 0,
//                             left: 0,
//                             width: '100%',
//                             height: '100%',
//                             bgcolor: index === currentEventIndex ? 'rgba(63, 81, 181, 0.3)' : 'rgba(0, 0, 0, 0.4)',
//                             transition: 'all 0.3s ease'
//                           }}
//                         />
//                       </Box>
//                     </motion.div>
//                   </Grid>
//                 ))}
//               </Grid>
//             </Box>
//           </Box>
//         )}
//       </SoftBox>
//     </PageLayout>
//   );
// };

// export default UpcomingEvents;


import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, CircularProgress, IconButton, Tooltip, Avatar, Skeleton } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { CalendarToday, LocationOn, AccessTime, ArrowForward, ArrowBack } from '@mui/icons-material';
import { APIData, org } from 'authentication/APIData';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PageLayout from 'examples/LayoutContainers/PageLayout';

const UpcomingEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  useEffect(() => {
    const fetchUpcomingEvents = async () => {
      setLoading(true);
      try {
        const url = `${APIData.api}events/?org=${org}`;
        const response = await fetch(url, { headers: APIData.headers });
        const allEvents = await response.json();
        
        // Filter for today and future events
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set to beginning of today
        
        const upcomingEvents = allEvents.filter(event => {
          const eventDate = new Date(event.event_date);
          eventDate.setHours(0, 0, 0, 0);
          return eventDate >= currentDate;
        });
        
        // Sort by date (closest first)
        upcomingEvents.sort((a, b) => new Date(a.event_date) - new Date(b.event_date));
        
        // Take first 6 events
        setEvents(upcomingEvents.slice(0, 6));
      } catch (error) {
        console.error('Error fetching upcoming events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUpcomingEvents();
  }, []);

  const formatDate = (dateString) => {
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  // Check if event is today
  const isToday = (dateString) => {
    const eventDate = new Date(dateString);
    const today = new Date();
    return eventDate.getDate() === today.getDate() &&
           eventDate.getMonth() === today.getMonth() &&
           eventDate.getFullYear() === today.getFullYear();
  };

  const nextEvent = () => {
    setIsDetailsOpen(false);
    setTimeout(() => {
      setCurrentEventIndex((prev) => (prev + 1) % events.length);
    }, 300);
  };

  const prevEvent = () => {
    setIsDetailsOpen(false);
    setTimeout(() => {
      setCurrentEventIndex((prev) => (prev - 1 + events.length) % events.length);
    }, 300);
  };

  const toggleDetails = () => {
    setIsDetailsOpen(!isDetailsOpen);
  };

  // Calculate days remaining
  const getDaysRemaining = (dateString) => {
    const eventDate = new Date(dateString);
    eventDate.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const diffTime = eventDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays === 0) return "Today";
    if (diffDays === 1) return "Tomorrow";
    return `${diffDays} days`;
  };

  // Skeleton loading UI components
  const SkeletonTimelineIndicator = () => (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      mb: 3, 
      position: 'relative'
    }}>
      <Box sx={{ 
        width: '80%', 
        height: '3px', 
        bgcolor: 'rgba(0,0,0,0.1)', 
        borderRadius: '4px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 0
      }} />
      
      <Grid container justifyContent="space-between" sx={{ width: '80%', position: 'relative', zIndex: 1 }}>
        {[...Array(6)].map((_, index) => (
          <Grid item key={index}>
            <Box 
              sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Skeleton 
                variant="circular" 
                width={36} 
                height={36} 
                sx={{ bgcolor: 'rgba(63, 81, 181, 0.1)' }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const SkeletonNavigation = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Skeleton 
          variant="circular" 
          width={40} 
          height={40} 
          sx={{ bgcolor: 'rgba(63, 81, 181, 0.1)' }}
        />
        
        <Skeleton 
          variant="text" 
          width={40} 
          sx={{ bgcolor: 'rgba(63, 81, 181, 0.1)' }}
        />
        
        <Skeleton 
          variant="circular" 
          width={40} 
          height={40} 
          sx={{ bgcolor: 'rgba(63, 81, 181, 0.1)' }}
        />
      </Box>
    </Box>
  );

  const SkeletonMainEvent = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        borderRadius: '16px',
        overflow: 'hidden',
        boxShadow: '0 10px 40px rgba(0,0,0,0.1)',
        height: { md: '320px' },
        bgcolor: 'white',
        mx: { xs: 2, md: 4 }
      }}
    >
      {/* Event Image Skeleton */}
      <Box
        sx={{
          position: 'relative',
          width: { xs: '100%', md: '50%' },
          height: { xs: '180px', md: '100%' }
        }}
      >
        <Skeleton 
          variant="rectangular" 
          width="100%" 
          height="100%" 
          sx={{ bgcolor: 'rgba(63, 81, 181, 0.1)' }} 
        />
        
        {/* Countdown Overlay Skeleton */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            padding: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Skeleton 
            variant="text" 
            width={100} 
            height={30} 
            sx={{ bgcolor: 'rgba(255, 255, 255, 0.3)' }} 
          />
          
          <Skeleton 
            variant="rectangular" 
            width={100} 
            height={36} 
            sx={{ 
              bgcolor: 'rgba(255, 255, 255, 0.3)',
              borderRadius: '30px'
            }} 
          />
        </Box>
      </Box>

      {/* Event Details Skeleton */}
      <Box
        sx={{
          width: { xs: '100%', md: '50%' },
          padding: { xs: '20px', md: '25px' },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Skeleton 
          variant="text" 
          width="70%" 
          height={40} 
          sx={{ bgcolor: 'rgba(63, 81, 181, 0.1)' }} 
        />

        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {[...Array(3)].map((_, index) => (
              <Grid item xs={12} key={index}>
                <Box display="flex" alignItems="center">
                  <Skeleton 
                    variant="circular" 
                    width={24} 
                    height={24} 
                    sx={{ mr: 1.5, bgcolor: 'rgba(63, 81, 181, 0.1)' }} 
                  />
                  <Skeleton 
                    variant="text" 
                    width="60%" 
                    sx={{ bgcolor: 'rgba(63, 81, 181, 0.1)' }} 
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          sx={{
            mt: 'auto',
            pt: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Skeleton 
            variant="rectangular" 
            width={120} 
            height={36} 
            sx={{ 
              bgcolor: 'rgba(63, 81, 181, 0.1)',
              borderRadius: '30px'
            }} 
          />
        </Box>
      </Box>
    </Box>
  );

  const SkeletonThumbnails = () => (
    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
      <Grid container spacing={1.5} sx={{ maxWidth: '90%' }}>
        {[...Array(6)].map((_, index) => (
          <Grid item xs={4} sm={2} key={index}>
            <Skeleton 
              variant="rectangular" 
              width="100%" 
              height={50} 
              sx={{ 
                bgcolor: 'rgba(63, 81, 181, 0.1)',
                borderRadius: '8px'
              }} 
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const SkeletonUI = () => (
    <>
      <SkeletonTimelineIndicator />
      <SkeletonNavigation />
      <SkeletonMainEvent />
      <SkeletonThumbnails />
    </>
  );

  return (
    <PageLayout>
      <SoftBox py={4} px={2}>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <SoftBox 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            textAlign="center" 
            mb={4}
          >
            <SoftTypography 
              variant="h3" 
              fontWeight="bold" 
              color="dark" 
              mb={1}
              sx={{ 
                position: 'relative',
                display: 'inline-block',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  width: '60px',
                  height: '4px',
                  bottom: '-10px',
                  left: 'calc(50% - 30px)',
                  borderRadius: '2px'
                }
              }}
            >
              Upcoming Events
            </SoftTypography>
          </SoftBox>
        </motion.div>

        {loading ? (
          <Box display="flex" flexDirection="column" minHeight="250px">
            <SkeletonUI />
          </Box>
        ) : events.length === 0 ? (
          <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            minHeight="200px"
            borderRadius="10px"
            bgcolor="white"
            boxShadow="0 2px 12px 0 rgba(0,0,0,0.05)"
          >
            <SoftTypography variant="h6" color="text">
              No upcoming events scheduled
            </SoftTypography>
          </Box>
        ) : (
          <Box sx={{ position: 'relative', overflow: 'hidden' }}>
            {/* Timeline Indicator */}
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              mb: 3, 
              position: 'relative'
            }}>
              <Box sx={{ 
                width: '80%', 
                height: '3px', 
                bgcolor: 'rgba(0,0,0,0.1)', 
                borderRadius: '4px',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 0
              }} />
              
              <Grid container justifyContent="space-between" sx={{ width: '80%', position: 'relative', zIndex: 1 }}>
                {events.map((event, index) => (
                  <Grid item key={event.id}>
                    <Tooltip title={new Date(event.event_date).toLocaleDateString()}>
                      <Box 
                        onClick={() => {
                          setIsDetailsOpen(false);
                          setTimeout(() => setCurrentEventIndex(index), 300);
                        }}
                        sx={{ 
                          cursor: 'pointer',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}
                      >
                        <Avatar 
                          sx={{ 
                            width: 36, 
                            height: 36, 
                            bgcolor: index === currentEventIndex ? '#3f51b5' : 'white',
                            color: index === currentEventIndex ? 'white' : '#3f51b5',
                            border: '3px solid #3f51b5',
                            transition: 'all 0.3s ease',
                            transform: index === currentEventIndex ? 'scale(1.2)' : 'scale(1)',
                            boxShadow: index === currentEventIndex ? '0 4px 12px rgba(63, 81, 181, 0.3)' : 'none'
                          }}
                        >
                          {new Date(event.event_date).getDate()}
                        </Avatar>
                        {index === currentEventIndex && (
                          <Box 
                            sx={{ 
                              width: '100%', 
                              textAlign: 'center', 
                              mt: 1, 
                              position: 'absolute',
                              top: '100%',
                              whiteSpace: 'nowrap',
                              fontWeight: 'bold',
                              color: '#3f51b5',
                              fontSize: '11px'
                            }}
                          >
                            {new Date(event.event_date).toLocaleDateString('en-US', { month: 'short' })}
                          </Box>
                        )}
                      </Box>
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Navigation Controls */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton 
                  onClick={prevEvent} 
                  sx={{ 
                    bgcolor: 'rgba(63, 81, 181, 0.1)', 
                    '&:hover': { bgcolor: 'rgba(63, 81, 181, 0.2)' } 
                  }}
                >
                  <ArrowBack />
                </IconButton>
                
                <SoftTypography variant="body2" fontWeight="bold" color="text">
                  {currentEventIndex + 1} of {events.length}
                </SoftTypography>
                
                <IconButton 
                  onClick={nextEvent} 
                  sx={{ 
                    bgcolor: 'rgba(63, 81, 181, 0.1)', 
                    '&:hover': { bgcolor: 'rgba(63, 81, 181, 0.2)' } 
                  }}
                >
                  <ArrowForward />
                </IconButton>
              </Box>
            </Box>

            {/* Main Event Display */}
            <AnimatePresence mode="wait">
              {events.length > 0 && (
                <motion.div
                  key={currentEventIndex}
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.4 }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      borderRadius: '16px',
                      overflow: 'hidden',
                      boxShadow: '0 10px 40px rgba(0,0,0,0.1)',
                      height: { md: isDetailsOpen ? '500px' : '320px' }, // Reduced height
                      transition: 'height 0.4s ease',
                      bgcolor: 'white',
                      mx: { xs: 2, md: 4 }
                    }}
                  >
                    {/* Event Image */}
                    <Box
                      sx={{
                        position: 'relative',
                        width: { xs: '100%', md: '50%' },
                        height: { xs: '180px', md: '100%' } // Reduced height for mobile
                      }}
                    >
                      <Box
                        component="img"
                        src={events[currentEventIndex].image_url}
                        alt={events[currentEventIndex].event_name}
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover'
                        }}
                      />
                      
                      {/* Countdown Overlay with improved contrast */}
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          width: '100%',
                          background: 'linear-gradient(to top, rgba(0,0,0,0.8), transparent)',
                          padding: '20px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Box>
                          <Typography variant="h6" color="white" fontWeight="bold">
                            {isToday(events[currentEventIndex].event_date) ? "TODAY!" : "Coming Soon"}
                          </Typography>
                        </Box>
                        
                        <Box
                          sx={{
                            bgcolor: 'rgba(255, 255, 255, 0.9)', // More opaque background
                            borderRadius: '30px',
                            padding: '8px 16px',
                            display: 'flex',
                            alignItems: 'center',
                            boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
                          }}
                        >
                          <CalendarToday fontSize="small" sx={{ mr: 1, color: '#3f51b5' }} />
                          <Typography variant="body2" fontWeight="bold" color="#3f51b5">
                            {getDaysRemaining(events[currentEventIndex].event_date)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {/* Event Details */}
                    <Box
                      sx={{
                        width: { xs: '100%', md: '50%' },
                        padding: { xs: '20px', md: '25px' }, // Reduced padding
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Typography
                        variant="h5" // Reduced from h4 to h5
                        fontWeight="bold"
                        sx={{
                          color: '#3f51b5',
                          mb: 1.5, // Reduced margin
                          position: 'relative',
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            width: '40px',
                            height: '3px',
                            background: '#3f51b5',
                            bottom: '-6px',
                            left: '0',
                            borderRadius: '2px'
                          }
                        }}
                      >
                        {events[currentEventIndex].event_name}
                      </Typography>

                      <Box sx={{ mt: 2 }}> {/* Reduced margin */}
                        <Grid container spacing={2}> {/* Reduced spacing */}
                          <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                              <CalendarToday fontSize="small" sx={{ mr: 1.5, color: '#3f51b5' }} />
                              <Typography variant="body2" color="text.secondary"> {/* Reduced from body1 to body2 */}
                                {formatDate(events[currentEventIndex].event_date)}
                              </Typography>
                            </Box>
                          </Grid>
                          
                          <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                              <AccessTime fontSize="small" sx={{ mr: 1.5, color: '#3f51b5' }} />
                              <Typography variant="body2" color="text.secondary">
                                {events[currentEventIndex].duration}
                              </Typography>
                            </Box>
                          </Grid>
                          
                          <Grid item xs={12}>
                            <Box display="flex" alignItems="flex-start">
                              <LocationOn fontSize="small" sx={{ mr: 1.5, mt: 0.3, color: '#3f51b5' }} />
                              <Typography variant="body2" color="text.secondary">
                                {events[currentEventIndex].location}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <AnimatePresence>
                        {isDetailsOpen && (
                          <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                          >
                            <Box sx={{ mt: 3 }}> {/* Reduced margin */}
                              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                {events[currentEventIndex].description}
                              </Typography>
                              
                              <Box
                                sx={{
                                  mt: 1.5,
                                  p: 1.5,
                                  borderLeft: '3px solid #3f51b5',
                                  bgcolor: 'rgba(63, 81, 181, 0.05)',
                                  borderRadius: '0 8px 8px 0'
                                }}
                              >
                                <Typography variant="body2" color="text.secondary">
                                  <span style={{ fontWeight: 'bold' }}>Organizer:</span> {events[currentEventIndex].organizer}
                                </Typography>
                              </Box>
                            </Box>
                          </motion.div>
                        )}
                      </AnimatePresence>

                      <Box
                        sx={{
                          mt: 'auto',
                          pt: 2, // Reduced padding
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={toggleDetails}
                          style={{
                            backgroundColor: '#3f51b5',
                            color: 'white',
                            border: 'none',
                            padding: '8px 16px', // Reduced padding
                            borderRadius: '30px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            boxShadow: '0 4px 12px rgba(63, 81, 181, 0.3)',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            fontSize: '14px' // Smaller font size
                          }}
                        >
                          {isDetailsOpen ? 'Show Less' : 'View Details'}
                          <motion.div
                            animate={{ rotate: isDetailsOpen ? 180 : 0 }}
                            transition={{ duration: 0.3 }}
                          >
                            <ArrowForward fontSize="small" />
                          </motion.div>
                        </motion.button>
                      </Box>
                    </Box>
                  </Box>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Event Thumbnails */}
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
              <Grid container spacing={1.5} sx={{ maxWidth: '90%' }}> {/* Reduced spacing */}
                {events.map((event, index) => (
                  <Grid item xs={4} sm={2} key={event.id}>
                    <motion.div 
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Box
                        onClick={() => {
                          setIsDetailsOpen(false);
                          setTimeout(() => setCurrentEventIndex(index), 300);
                        }}
                        sx={{
                          width: '100%',
                          height: '50px', // Reduced height
                          borderRadius: '8px',
                          overflow: 'hidden',
                          position: 'relative',
                          cursor: 'pointer',
                          border: index === currentEventIndex ? '3px solid #3f51b5' : '3px solid transparent',
                          boxShadow: index === currentEventIndex ? '0 4px 12px rgba(63, 81, 181, 0.3)' : '0 2px 8px rgba(0,0,0,0.1)',
                          transition: 'all 0.3s ease'
                        }}
                      >
                        <Box
                          component="img"
                          src={event.image_url}
                          alt={event.event_name}
                          sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                        />
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            bgcolor: index === currentEventIndex ? 'rgba(63, 81, 181, 0.3)' : 'rgba(0, 0, 0, 0.4)',
                            transition: 'all 0.3s ease'
                          }}
                        />
                      </Box>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        )}
      </SoftBox>
    </PageLayout>
  );
};

export default UpcomingEvents;