import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftInput from "components/SoftInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { APIData,org } from 'authentication/APIData';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlinePlusCircle } from "react-icons/ai";
import {
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Divider
} from '@mui/material';
import SoftButton from 'components/SoftButton';
import Swal from 'sweetalert2';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const ServiceImage = ({ image, serviceName, onClick }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={image || '/img/service-placeholder.jpg'}
                alt={serviceName}
                size="sm"
                variant="rounded"
                style={{ cursor: 'pointer' }}
                onClick={onClick}
            />
        </SoftBox>
        <SoftTypography variant="button" fontWeight="medium">
            {serviceName}
        </SoftTypography>
    </SoftBox>
);

ServiceImage.propTypes = {
    image: PropTypes.string,
    serviceName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const ServicesList = () => {
    const [serviceData, setServiceData] = useState([]);
    const [openImagePreview, setOpenImagePreview] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(accessLevel);
    }, []);

    useEffect(() => {
        const fetchServiceData = async () => {
            const url = `${APIData.api}services/by-org?org=${org}`;
            try {
                const response = await fetch(url, { headers: APIData.headers });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                console.log('Fetched service data:', data);

                // Process data to match the expected format
                const processedData = data.map(service => ({
                    id: service.id || 0,
                    name: service.name || '',
                    description: service.description || '',
                    image: service.image || null,
                    createdBy: service.createdBy || '',
                    updatedBy: service.updatedBy,
                    createdDateTime: service.createdDateTime,
                    updatedDateTime: service.updatedDateTime,
                    org: service.org || ''
                }));

                setServiceData(processedData);
            } catch (error) {
                console.error('Error fetching service data:', error);
                setServiceData([]);
                Swal.fire('Error', 'Failed to fetch service data. Please try again later.', 'error');
            }
        };

        fetchServiceData();
    }, []);

    const handleViewDetails = async (serviceId) => {
        try {
            const service = serviceData.find(s => s.id === serviceId);
            if (service) {
                setSelectedService(service);
                setOpenDetailsDialog(true);
            } else {
                Swal.fire('Error', 'Service not found', 'error');
            }
        } catch (error) {
            console.error('Error fetching service details:', error);
            Swal.fire('Error', 'Failed to fetch service details', 'error');
        }
    };

    const ServiceDetailsDialog = () => (
        <Dialog
            open={openDetailsDialog}
            onClose={() => setOpenDetailsDialog(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <SoftTypography variant="h6">Service Details</SoftTypography>
            </DialogTitle>
            <DialogContent>
                {selectedService && (
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} mb={2}>
                            <SoftBox display="flex" justifyContent="center">
                                <img
                                    src={selectedService.image || '/img/service-placeholder.jpg'}
                                    alt={selectedService.name}
                                    style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }}
                                />
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <SoftTypography variant="subtitle2" color="text">Service Name</SoftTypography>
                            <SoftTypography variant="body2">{selectedService.name}</SoftTypography>
                        </Grid>
                        <Grid item xs={12}>
                            <SoftTypography variant="subtitle2" color="text">Description</SoftTypography>
                            <SoftTypography variant="body2">{selectedService.description || '-'}</SoftTypography>
                        </Grid>
                        <Grid item xs={6}>
                            <SoftTypography variant="subtitle2" color="text">Created By</SoftTypography>
                            <SoftTypography variant="body2">{selectedService.createdBy || '-'}</SoftTypography>
                        </Grid>
                        <Grid item xs={6}>
                            <SoftTypography variant="subtitle2" color="text">Created Date</SoftTypography>
                            <SoftTypography variant="body2">
                                {selectedService.createdDateTime ? 
                                    formatDateTime(selectedService.createdDateTime) : '-'}
                            </SoftTypography>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <SoftButton onClick={() => setOpenDetailsDialog(false)} color="secondary">
                    Close
                </SoftButton>
            </DialogActions>
        </Dialog>
    );

    // Helper function to format date time array
    const formatDateTime = (dateTimeArray) => {
        if (!dateTimeArray || dateTimeArray.length < 6) return '-';
        
        const [year, month, day, hour, minute] = dateTimeArray;
        return `${day}/${month}/${year} ${hour}:${minute < 10 ? '0' + minute : minute}`;
    };

    const canEditDelete = (service) => {
        return true;
    };

    const handleDelete = (serviceId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete this service?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                const deleteUrl = `${APIData.api}services/${serviceId}`;
                fetch(deleteUrl, { method: 'DELETE', headers: APIData.headers })
                    .then(response => {
                        if (response.ok) {
                            Swal.fire('Deleted!', 'Your service has been deleted.', 'success');
                            setServiceData(serviceData.filter(service => service.id !== serviceId));
                        } else {
                            Swal.fire('Failed!', 'There was an error deleting the service.', 'error');
                        }
                    })
                    .catch((error) => {
                        console.error('Error deleting service:', error);
                        Swal.fire('Error!', 'There was an error deleting the service.', 'error');
                    });
            }
        });
    };

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl || '/img/service-placeholder.jpg');
        setOpenImagePreview(true);
    };

    const handleNewServiceClick = () => {
        navigate("/service-form");
    };

    const columns = [
        { name: "Service Name", align: "left" },
        { name: "Description", align: "left" },
        { name: "Action", align: "center" },
    ];

    // Safe search function to handle potentially undefined values
    const safeSearch = (value) => {
        if (value === null || value === undefined) return '';
        return String(value).toLowerCase();
    };

    // Filtering logic to safely handle potentially undefined values
    const filteredServices = serviceData.filter(service => {
        if (!searchTerm) return true;

        const searchLower = searchTerm.toLowerCase();
        return (
            safeSearch(service.name).includes(searchLower) ||
            safeSearch(service.description).includes(searchLower)
        );
    });

    const paginatedServices = filteredServices.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const rows = paginatedServices.map((service) => ({
        "Service Name": <ServiceImage image={service.image} serviceName={service.name || 'Unnamed Service'} onClick={() => handleImageClick(service.image)} />,
        "Description": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {service.description 
                    ? (service.description.length > 30 
                        ? `${service.description.substring(0, 30)}...` 
                        : service.description)
                    : '-'}
            </SoftTypography>
        ),
        Action: (
            <SoftBox display="flex" justifyContent="center">
                <SoftTypography
                    component="span"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    mr={2}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleViewDetails(service.id)}
                >
                    View
                </SoftTypography>
                {canEditDelete(service) && (
                    <>
                        <Link to={`/edit-service/${service.id}`}>
                            {(permission === "1110" || permission === "1111" || isSuperAdmin) ? (
                                <SoftTypography
                                    component="span"
                                    variant="button"
                                    color="warning"
                                    fontWeight="medium"
                                    mr={2}
                                >
                                    Edit
                                </SoftTypography>
                            ) : null}
                        </Link>
                        {(permission === "1111" || isSuperAdmin) ? <SoftTypography
                            component="span"
                            variant="button"
                            color="error"
                            fontWeight="medium"
                            onClick={() => handleDelete(service.id)}
                            style={{ cursor: 'pointer' }}
                        >
                            Delete
                        </SoftTypography> : ""}
                    </>
                )}
            </SoftBox>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Service List</SoftTypography>
                            <SoftBox display="flex" alignItems="center" gap={2}>
                                <SoftBox>
                                    <SoftInput
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        size="small"
                                    />
                                </SoftBox>
                                {(permission === "1100" || permission === "1110" || permission === "1111" || isSuperAdmin) ?
                                    <Tooltip title="Add New Service" arrow>
                                        <SoftButton variant="gradient" color="info" size="small" onClick={handleNewServiceClick} startIcon={<AiOutlinePlusCircle />}>
                                            Add
                                        </SoftButton>
                                    </Tooltip> : ""}
                            </SoftBox>
                        </SoftBox>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={columns} rows={rows} />
                            <SoftBox
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                                p={3}
                            >
                                <SoftBox display="flex" gap={1} alignItems="center">
                                    <SoftTypography variant="caption" color="secondary">
                                        {filteredServices.length > 0 ?
                                            `${page * itemsPerPage + 1}-${Math.min((page + 1) * itemsPerPage, filteredServices.length)} of ${filteredServices.length}` :
                                            'No services found'}
                                    </SoftTypography>
                                    <SoftButton
                                        variant="text"
                                        color="info"
                                        onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                        disabled={page === 0 || filteredServices.length === 0}
                                        iconOnly
                                    >
                                        <KeyboardArrowLeft />
                                    </SoftButton>
                                    <SoftButton
                                        variant="text"
                                        color="info"
                                        onClick={() => setPage(prev => Math.min(Math.ceil(filteredServices.length / itemsPerPage) - 1, prev + 1))}
                                        disabled={page >= Math.ceil(filteredServices.length / itemsPerPage) - 1 || filteredServices.length === 0}
                                        iconOnly
                                    >
                                        <KeyboardArrowRight />
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />

            {/* Image Preview Dialog */}
            <Dialog
                open={openImagePreview}
                onClose={() => setOpenImagePreview(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    <img
                        src={selectedImage}
                        alt="Preview"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </DialogContent>
            </Dialog>

            <ServiceDetailsDialog />
        </DashboardLayout>
    );
};

export default ServicesList;