import React, { useEffect } from 'react';
import { AppBar, Box, Container, Grid, Link, Stack, useTheme, useMediaQuery } from '@mui/material';
import { Menu as MenuIcon, ChevronRight, Star, FlashOn, Security, Message } from '@mui/icons-material';
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import SoftTypography from 'components/SoftTypography';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import SoftButton from 'components/SoftButton';
import Events from './Events';
import Footer from 'layouts/authentication/components/Footer';
import Carousel from './Carousel';
import { useNavigate } from 'react-router-dom';
import Hero from './Hero';
import Features from './Features';
import Mfeatures from './Mfeatures';
import Stats from './Stats';
import Updates from './Updates';
import Pricing from './Pricing';
import Testimonials from './Testimonials';
import CTA from './CTA';
import Header from './Mheader';
import Footers from './Footers';
import ProductPage from './ProductPage';
import Navbarrr from './Navbarrr';
import SocialSidebar from './SocialSidebar';
import { org } from 'authentication/APIData';
import ServicesDisplay from './ServicesDisplay';
import UpcomingEvents from './UpcomingEvents';
import UserCourses from 'Courses/UserCourses';
import LandCourses from './LandCourses';
import LandingPageBlog from './LandingPageBlog';

const Landing2 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  useEffect(() => {

    const handleSignOut = () => {
      localStorage.clear();
      navigate("/");
    };
    handleSignOut();
  }, []);

  const footerLinks = {
    Product: ['Features', 'Pricing', 'Documentation'],
    Company: ['About', 'Careers', 'Blog'],
    Resources: ['Community', 'Contact', 'Support'],
    Legal: ['Privacy', 'Terms', 'Security']
  };

  return (
    <PageLayout>
      <Box>
        <AppBar position="fixed" color="inherit" elevation={1}>
          {/* <DefaultNavbar /> */}
          {/* <Header/> */}
          <Navbarrr />
        </AppBar>
        <SocialSidebar />


        {/* Hero Section */}
        {/* <Box
          sx={{
            pt: 15,
            pb: 8,
            background: 'linear-gradient(180deg, #f3e8ff 0%, #ffffff 100%)'
          }}
        >
          <Container maxWidth="lg">
            <Box textAlign="center" maxWidth="800px" mx="auto">
              <SoftTypography variant="h2" component="h1" gutterBottom fontWeight="bold">
                Make Your Vision a Reality

              </SoftTypography>
              <SoftTypography variant="h5" color="text.secondary" paragraph>
                Transform your Organization into stunning digital experiences with our powerful platform.
              </SoftTypography>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                justifyContent="center"
                mt={4}
              >
                <SoftButton
                  variant="contained"
                  size="large"
                  endIcon={<ChevronRight />}
                >
                  Get Started
                </SoftButton>
              </Stack>
            </Box>
          </Container>
        </Box> */}

        {/* Carousel Section */}
        <Box
          sx={{
            py: 10,
            backgroundColor: '#f8fafc',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          {/* <Container maxWidth="xl">
            <Box 
              sx={{ 
                height: '600px',
                position: 'relative'
              }}
            >
              <Carousel />
            </Box>
          </Container> */}
          <Hero />
          <Features />
          {/* <Mfeatures/> */}
          {/* <Stats/> */}
          <ServicesDisplay/>
          <Updates />
          <UpcomingEvents/>
          <LandCourses/>
          <LandingPageBlog/>
          {/* {(org === "c4e" || org === "avinya") && (
            <div>
              <ProductPage />
              <Pricing />
            </div>

          )} */}
          <Testimonials />

          {/* <CTA/> */}
        </Box>

        {/* CTA Section */}
        {/* <Box sx={{ py: 8, px: 7, backgroundColor: 'white', color: 'black' }}>
          <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <SoftTypography variant="h3" component="h2" gutterBottom fontWeight="bold">
                  Ready to Get Started?
                </SoftTypography>
                <SoftTypography variant="h6" sx={{ opacity: 0.9 }}>
                  Join thousands of satisfied customers who have transformed their business with us.
                </SoftTypography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={2}
                  justifyContent={{ xs: 'center', md: 'flex-end' }}
                >
                  <SoftButton
                    variant="contained"
                    size="large"
                    sx={{ color: 'black', borderColor: 'white' }}
                    startIcon={<Message />}
                  >
                    Contact Sales
                  </SoftButton>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box> */}

        {/* <Footer /> */}
        <Footers />
      </Box>
    </PageLayout>
  );
};

export default Landing2;
// import React, { useEffect } from 'react';
// import {
//   AppBar,
//   Box,
//   Container,
//   Grid,
//   Stack,
//   useTheme,
//   useMediaQuery,
// } from '@mui/material';
// import { ChevronRight, Message } from '@mui/icons-material';
// import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
// import SoftTypography from 'components/SoftTypography';
// import PageLayout from 'examples/LayoutContainers/PageLayout';
// import SoftButton from 'components/SoftButton';
// import Footer from 'layouts/authentication/components/Footer';
// import Carousel from './Carousel';
// import { useNavigate } from 'react-router-dom';

// const Landing2 = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('md'));
//   const navigate = useNavigate();

//   useEffect(() => {
//     const handleSignOut = () => {
//       localStorage.clear();
//       navigate('/');
//     };
//     handleSignOut();
//   }, []);

//   return (
//     <PageLayout>
//       <Box>
//         <AppBar position="fixed" color="inherit" elevation={1}>
//           <DefaultNavbar />
//         </AppBar>

//         {/* Hero Section and CTA Section Combined with Same Background */}
//         <Box
//           sx={{
//             pt: 15,
//             pb: 8,
//             background: 'linear-gradient(180deg,rgb(107, 182, 247) 0%, #ffffff 100%)', // Light blue to white gradient
//           }}
//         >
//           <Container maxWidth="xl">
//             <Grid container alignItems="center">
//               <Grid
//                 item
//                 xs={12}
//                 md={6}
//                 display="flex"
//                 justifyContent="center"
//                 alignItems="center"
//               >
//                 <Box sx={{ marginLeft: '40px' }}>
//                   <SoftTypography
//                     variant="h2"
//                     component="h1"
//                     gutterBottom
//                     fontWeight="bold"
//                     sx={{ fontSize: '3.5rem' }}
//                   >
//                     Make Your Vision a Reality
//                   </SoftTypography>
//                   <SoftTypography
//                     variant="h5"
//                     color="text.secondary"
//                     paragraph
//                     sx={{ fontSize: '1.8rem' }}
//                   >
//                     Transform your Organization into stunning digital experiences with our powerful platform.
//                   </SoftTypography>
//                   <Stack
//                     direction={{ xs: 'column', sm: 'row' }}
//                     spacing={2}
//                     justifyContent="center"
//                     mt={4}
//                   >
//                     <SoftButton
//                       variant="contained"
//                       size="large"
//                       endIcon={<ChevronRight />}
//                       sx={{
//                         backgroundColor: 'rgb(19, 140, 245)', // Dark blue for the button
//                         color: 'white',
//                         '&:hover': {
//                           backgroundColor: 'rgb(11, 111, 199)', // Darker shade on hover
//                         },
//                       }}
//                     >
//                       Get Started
//                     </SoftButton>
//                   </Stack>
//                 </Box>
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 md={6}
//                 display="flex"
//                 justifyContent="center"
//                 alignItems="center"
//               >
//                 <Box sx={{ height: '650px', width: '100%' }}>
//                   <Carousel />
//                 </Box>
//               </Grid>
//             </Grid>

//             {/* CTA Section */}
//             <Grid container spacing={4} alignItems="center" mt={8}>
//               <Grid
//                 item
//                 xs={12}
//                 md={6}
//                 display="flex"
//                 justifyContent="center"
//                 alignItems="center"
//               >
//                 <Box>
//                   <SoftTypography
//                     variant="h3"
//                     component="h2"
//                     gutterBottom
//                     fontWeight="bold"
//                   >
//                     Ready to Get Started?
//                   </SoftTypography>
//                   <SoftTypography variant="h6" sx={{ opacity: 0.9 }}>
//                     Join thousands of satisfied customers who have transformed their business with us.
//                   </SoftTypography>
//                 </Box>
//               </Grid>

//               {/* Button aligned to the right of the text */}
//               <Grid
//                 item
//                 xs={12}
//                 md={6}
//                 display="flex"
//                 justifyContent="flex-end" // Align button to the right
//                 alignItems="center"
//               >
//                 <SoftButton
//                   variant="contained"
//                   size="large"
//                   sx={{
//                     color: 'white',
//                     backgroundColor: 'rgb(19, 140, 245)', // Dark blue for the button
//                     '&:hover': {
//                       backgroundColor: 'rgb(11, 111, 199)', // Darker shade on hover
//                     },
//                   }}
//                   startIcon={<Message />}
//                 >
//                   Contact Sales
//                 </SoftButton>
//               </Grid>
//             </Grid>
//           </Container>
//         </Box>

//         <Footer />
//       </Box>
//     </PageLayout>
//   );
// };

// export default Landing2;
