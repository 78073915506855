
import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";
import Shop from "examples/Icons/Shop";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import CustomerSalesOnboard from "sales/CustomerSalesOnboard";
import AssetList from "layouts/stores/assets-list";
import AddJob from "Super_Admin/Addjob";
import Add_designation from "Super_Admin/Add_designation";
import JobEditButtons from "sales/JobEditButtons";
import EmailServices from "layouts/emailServices";
import OrgMdmdata from "sales/OrgMdmdata";
import OrgHierarchy from "layouts/orgHierarchy/org-hierarchy";
import StationaryItems from "layouts/stationary/stationaryItems";
import CandidateData from "candidate/CandidateData";
import ProcurementList from "layouts/procurement/procurement-list";
import EmployeeIdCard from "layouts/idCards/employeeIdCard";
import LetterHead from "LetterHeadTemp/LetterHead";
import EnrollmentsData from "SqlTables/EnrollmentsData";
import CoursesTable from "SqlTables/CoursesTable";
import ClassroomTable from "SqlTables/ClassroomTable";
import TimetableComponent from "SqlTables/TimetableComponent";
import OrgPolicies from "layouts/org-policies/orgpolicies";
import { CreateSuperadmin } from "sales/CraeteSuperadmin";
import CoursesDetails from "Courses/CoursesDetails";
import Courses from "Courses/Courses";
import CoursesEnrolled from "Courses/CoursesEnrolled";
import FestivalList from "layouts/festival/festival-list";
import MarksData from "SqlTables/MarksData";
import ExpensesDetails from "Expenses/ExpensesDeatils";
import TicketTable from "layouts/ticket/ticket-list";
import AddQuestions from "question_paper/Addquestions";
import ViewQuestions from "question_paper/ViewQuestions";
import QuestionPaperByAll from "question_paper/QuestionPaperByAll";
import Schedule from "ScheduleMeeting";
import OrgPassword from "Password/OrgPassword";
import PasswordReq from "Password/PasswordReq";
import AdminDashboard from "ASAT/Components/Admin/AdminDashboard";
import CustSuportData from "sales/CustSuportData";
import SpocData from "layouts/DepartmentSpoc/SpocData";
import { InternalJob } from "Internal Job/InternalJob";
import Event from "layouts/event/eventList";
import HolidayList from "layouts/holidays/holidayList";
import RegisterationDetails from "SqlTables/RegisterationDetails";
import AdminConsoleForExit from "EmployeeExit/AdminConsoleForExit/AdminConsoleForExit";
import ResignationForm from "EmployeeExit/ResignationForm";
import DetailsForExit from "EmployeeExit/AdminConsoleForExit/DetailsForExit";
import EmployeeExit from "EmployeeExit/EmployeeExit";
import OfficeDocumentsData from "document/officeDocument";
import DocumentsData from "document/document";
import InvoiceNew from "Expenses/Invoice";
import StatusPage from "SqlTables/statusPage";
import UserPage from "SqlTables/userPage";
import Members from "layouts/members/members";
import MessageBroadCast from "layouts/messageBroadCast/messageBroadCast";
import TimestepperPage from "SqlTables/Timestepper";
import ViewTimetable from "SqlTables/ViewTimetable";
import Invoice from "Expenses/Invoice/Invoice";
import StudentIdCard from "layouts/idCards/studentIdCard";

import EmployeeAttendance from "SqlTables/EmployeeAttendance";
import StudentEnrollmentsData from "SqlTables/tudentEnrollmentsData";
import { 
    User, Banknote, FileText, Users, PlusCircle, TagIcon, 
    Briefcase, UserPlus, Network, GraduationCap, CheckCircle, UserSquare, 
    UserCheck, GitBranch, IdCard, BookOpen, Calendar, CalendarCheck, CircleAlert,
    LogOut, Clock, BarChart, UserCog, UserShield, MessageCircle, ListChecks, 
    PieChart, Eye, Book, BookMarked, SendHorizontal, Box, Clipboard, Warehouse, 
    ShoppingCart, Settings, Mail, Folder, HelpCircle, MessageSquare, FileUser,
    VideoIcon, Lock, Key, KeyRound,CircleHelp,CircleFadingArrowUp,
    Sparkles,CircleUser,SquareUserRound,UserRoundPen,
    ShieldPlus,Table,LayoutDashboard,
    Globe
  } from 'lucide-react';
import Infra from "SqlTables/Infra";
import PayslipPage from "SqlTables/payslip";
import MentorChat from "SqlTables/MentorChat";
import JobStepperPage from "Super_Admin/JobStepperPage";
import MentorMonitor from "SqlTables/MonitorMentor";
import StudentUpdate from "SqlTables/studentChat";
import StudentProfile from "layouts/profile/StudentProfile";
import Landing2 from "Home/Landing2";
import Hero from "Home/Hero";
import SetUplandingPage from "Home/SetUpLandingPage";
import CreateBlog from "Home/CreateBlog";
import BlogList from "Home/BlogList";
import ZoomUtilitySelector from "stepperform/ZoomUtilitySelector";
import ProductList from "layouts/dynamicProduct/productList";
import ServicesList from "layouts/dynamicProduct/ServicesList";


const getUserRole = () => {
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const type = sessionDetails?.userType;
    const dept = localStorage.getItem("Depart Details");
    return { type, dept };
};

const userRole = getUserRole();

const routes = [
    {
        type: "collapse",
        name: "Profile",
        key: "profile",
        route: "/profile",
        icon: <UserRoundPen size="16px" />,
        component: <Profile />,
        noCollapse: true,
        departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
        userTypes: ["EMPLOYEE"],
    },
    {
        type: "collapse",
        name: "Profile",
        key: "studentprofile",
        route: "/studentprofile",
        icon: <CustomerSupport size="16px" />,
        component: <StudentProfile />,
        noCollapse: true,
        hideForSuperadmin: true,
        userTypes: ["STUDENT"],
    },
    {
        type: "collapse",
        name: "Finance",
        key: "Finance",
        icon: <Banknote size="16px" />,
        noCollapse: false,
        departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
        userTypes: ["EMPLOYEE"],
        children: [
            {
                type: "collapse",
                name: "Invoice",
                key: "new-invoice",
                route: "/new-invoice",
                icon: <FileText size="16px" />,
                component: <Invoice />,
                noCollapse: true,
                departments: ["FINANCE"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Expenses",
                key: "expenses",
                route: "/expenses",
                icon: <CreditCard size="16px" />,
                component: <ExpensesDetails />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },

        ]
    },
    {
        type: "collapse",
        name: "HR",
        key: "HR",
        icon: <Users size="16px" />,
        noCollapse: false,
        departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
        userTypes: ["EMPLOYEE"],
        children: [
            {
                type: "collapse",
                name: "Job",
                key: "Job",
                icon: <PlusCircle size="16px" />,
                noCollapse: false,
                departments: ["HR"],
                userTypes: ["EMPLOYEE"],
                children: [
                    {
                        type: "collapse",
                        name: "Job Creation",
                        key: "jobStepperPage",
                        route: "/jobStepperPage",
                        icon: <TagIcon  size="16px" />,
                        component: <JobStepperPage />,
                        noCollapse: true,
                        departments: ["HR"],
                        userTypes: ["EMPLOYEE"],
                    },

                    {
                        type: "collapse",
                        name: "Job Posting",
                        key: "jobEditButtons",
                        route: "/jobEditButtons",
                        icon: <Briefcase  size="16px" />,
                        component: <JobEditButtons />,
                        noCollapse: true,
                        departments: ["HR"],
                        userTypes: ["EMPLOYEE"],
                    },
                ]
            },
            {
                type: "collapse",
                name: "Candidate Onboarding",
                key: "candidateData",
                route: "/candidateData",
                icon: <UserPlus  size="16px" />,
                component: <CandidateData />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "PaySlips",
                key: "payslip",
                route: "/payslip",
                icon: <UserPlus  size="16px" />,
                component: <PayslipPage />,
                noCollapse: true,
                departments: ["HR"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Infrastructure",
                key: "infra",
                route: "/infra",
                icon: <UserPlus  size="16px" />,
                component: <Infra />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Internal Job",
                key: "internal-job",
                route: "/internal-job",
                icon: <Network size="16px" />,
                component: <InternalJob />,
                noCollapse: true,
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Enrollments",
                key: "enrollmentsData",
                route: "/enrollmentsData",
                icon: <GraduationCap  size="16px" />,
                component: <EnrollmentsData />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Member Status",
                key: "member-Status",
                route: "/member-Status",
                icon: <CheckCircle  size="16px" />,
                component: <StatusPage />,
                noCollapse: true,
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Users",
                key: "user-Status",
                route: "/user-Status",
                icon: <UserSquare  size="16px" />,
                component: <UserPage />,
                noCollapse: true,
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Employees",
                key: "members",
                route: "/members",
                icon: <UserCheck  size="16px" />,
                component: <Members />,
                noCollapse: true,
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "OrgHierarchy",
                key: "orghierarchy",
                route: "/orghierarchy",
                icon: <GitBranch  size="16px" />,
                component: <OrgHierarchy />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },


            {
                type: "collapse",
                name: "LetterHeads",
                key: "letter-heads",
                route: "/letter-heads",
                icon: <FileText  size="16px" />,
                component: <LetterHead />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Employee IdCard",
                key: "employeeIdCard",
                route: "/employeeIdCard",
                icon: <IdCard  size="16px" />,
                component: <EmployeeIdCard />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Policy",
                key: "orgPolicies",
                route: "/orgPolicies",
                icon: <BookOpen  size="16px" />,
                component: <OrgPolicies />,
                noCollapse: true,
                userTypes: ["EMPLOYEE", "USER"],
            },
            {
                type: "collapse",
                name: "Festival",
                key: "festival-list",
                route: "/festival-list",
                icon: <Sparkles  size="16px" />,
                component: <FestivalList />,
                noCollapse: true,
                userTypes: ["EMPLOYEE", "USER"],
            },
            {
                type: "collapse",
                name: "HolidaysList",
                key: "holidaysList",
                route: "/holidaysList",
                icon: <Calendar  size="16px" />,
                component: <HolidayList />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Events",
                key: "events",
                route: "/events",
                icon: <CalendarCheck  size="16px" />,
                component: <Event />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Employee Exit",
                key: "Employee Exit",
                icon: <LogOut  size="16px" />,
                noCollapse: false,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
                children: [
                    {
                        type: "collapse",
                        name: "Dashboard",
                        key: "adminConsoleForExit",
                        route: "/adminConsoleForExit",
                        icon: <Shop size="16px" />,
                        component: <AdminConsoleForExit />,
                        noCollapse: true,
                        departments: ["HR"],
                        userTypes: ["EMPLOYEE"],
                    },
                    {
                        type: "collapse",
                        name: "Overview",
                        key: "detailsForExit",
                        route: "/detailsForExit",
                        icon: <Shop size="16px" />,
                        component: <DetailsForExit />,
                        noCollapse: true,
                        departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                        userTypes: ["EMPLOYEE"],
                    },
                    {
                        type: "collapse",
                        name: "Apply Form",
                        key: "employeeExit",
                        route: "/employeeExit",
                        icon: <Shop size="16px" />,
                        component: <EmployeeExit />,
                        noCollapse: true,
                        departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                        userTypes: ["EMPLOYEE"],
                    },
                ]
            },
            {
                type: "collapse",
                name: "Attedence",
                key: "employee_Attendance",
                route: "/employee_Attendance",
                icon: <Clock  size="16px" />,
                component: <EmployeeAttendance/>,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },

        ]
    },
    {
        type: "collapse",
        name: "Sales",
        key: "Sales",
        icon: <BarChart  size="16px" />,
        noCollapse: false,
        departments: ["SALES"],
        userTypes: ["EMPLOYEE"],
        children: [
            {
                type: "collapse",
                name: "Customer Sales Onboarding",
                key: "customerSalesOnboarding",
                route: "/customerSalesOnboard",
                icon: <UserPlus  size="16px" />,
                component: <CustomerSalesOnboard />,
                noCollapse: true,
                departments: ["SALES",],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Customer Mdm Onboarding",
                key: "orgmdmdata",
                route: "/orgmdmdata",
                icon: <UserCog  size="16px" />,
                component: <OrgMdmdata />,
                noCollapse: true,
                departments: ["SALES"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Create-superadmin",
                key: "create-superadmin",
                route: "/create-superadmin",
                icon: <ShieldPlus  size="16px" />,
                component: <CreateSuperadmin />,
                noCollapse: true,
                departments: ["SALES"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Customer Onboard",
                key: "customer-support",
                route: "/customer-support",
                icon: <SquareUserRound  size="16px" />,
                component: <CustSuportData />,
                noCollapse: true,
                departments: ["SALES"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Products",
                key: "products",
                route: "/products",
                icon: <ShoppingCart  size="16px" />,
                component: <ProductList />,
                noCollapse: true,
                departments: ["SALES"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Services",
                key: "services",
                route: "/services",
                icon: <UserCog  size="16px" />,
                component: <ServicesList />,
                noCollapse: true,
                departments: ["SALES"],
                userTypes: ["EMPLOYEE"],
            },
        ]
    },
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        route: "/dashboard",
        icon: <LayoutDashboard size="16px" />,
        component: <Dashboard />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "Academy",
        key: "Academy",
        icon: <BookOpen  size="16px" />,
        noCollapse: false,
        departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
        userTypes: ["EMPLOYEE"],
        children: [
            {
                type: "collapse",
                name: "MarksData",
                key: "marksData",
                route: "/marksData",
                icon: <ListChecks  size="16px" />,
                component: <MarksData />,
                noCollapse: true,
                departments: ["ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "MarksData",
                key: "marksData",
                route: "/marksData",
                icon: <ListChecks  size="16px" />,
                component: <MarksData />,
                noCollapse: true,
                hideForSuperadmin: true,
                userTypes: ["STUDENT"],
            },
            {
                type: "collapse",
                name: "Student Enrollments",
                key: "studentEnrollment",
                route: "/studentEnrollment",
                icon: <UserCheck  size="16px" />,
                component: <StudentEnrollmentsData/>,
                noCollapse: true,
                departments: ["ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "ASAT Dashboard",
                key: "adminDashboard",
                route: "/adminDashboard",
                icon: <PieChart  size="16px" />,
                component: <AdminDashboard />,
                noCollapse: true,
                departments: ["ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },

            {
                type: "collapse",
                name: "Mentor",
                key: "Mentor",
                icon: <CircleUser size="16px" />,
                noCollapse: false,
                departments: ["ACADEMY"],
                userTypes: ["EMPLOYEE"],
                children: [
                    {
                        type: "collapse",
                        name: "MentorChat",
                        key: "mentorChat",
                        route: "/mentorChat",
                        icon: <MessageSquare  size="16px" />,
                        component: <MentorChat />,
                        noCollapse: true,
                        departments: ["ACADEMY"],
                        userTypes: ["EMPLOYEE"],
                    },
                    {
                        type: "collapse",
                        name: "Monitor Mentor",
                        key: "mentor-monitor",
                        route: "/mentor-monitor",
                        icon: <FileUser  size="16px" />,
                        component: <MentorMonitor />,
                        noCollapse: true,
                        departments: ["ACADEMY"],
                        userTypes: ["EMPLOYEE"],
                    },
                ]
            },
            {
                type: "collapse",
                name: "Daily Update",
                key: "daily-update",
                route: "/daily-update",
                icon: <CircleFadingArrowUp  size="16px" />,
                component: <StudentUpdate />,
                noCollapse: true,
                departments: ["ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Daily Update",
                key: "daily-update",
                route: "/apply-courses",
                icon: <UserPlus  size="16px" />,
                component: <StudentUpdate />,
                noCollapse: true,
                userTypes: ["STUDENT"],
                hideForSuperadmin: true,
            },
            {
                type: "collapse",
                name: "Student Details",
                key: "registerationDetails",
                route: "/registerationDetails",
                icon: <Users  size="16px" />,
                component: <RegisterationDetails />,
                noCollapse: true,
                departments: ["ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Student IdCard",
                key: "studentIdCard",
                route: "/studentIdCard",
                icon: <IdCard  size="16px" />,
                component: <StudentIdCard />,
                noCollapse: true,
                departments: ["ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "View Timetable",
                key: "viewTimetable",
                route: "/viewTimetable",
                icon: <Clock  size="16px" />,
                component: <ViewTimetable />,
                noCollapse: true,
                userTypes: ["STUDENT"],
            },
            {
                type: "collapse",
                name: "TimeTable",
                key: "TimeTable",
                icon: <Table size="16px" />,
                noCollapse: false,
                departments: ["ACADEMY"],
                userTypes: ["EMPLOYEE"],
                children: [
                    {
                        type: "collapse",
                        name: "View Timetable",
                        key: "viewTimetable",
                        route: "/viewTimetable",
                        icon: <Clock  size="16px" />,
                        component: <ViewTimetable />,
                        noCollapse: true,
                        departments: ["ACADEMY"],
                        userTypes: ["EMPLOYEE"],
                    },
                    {
                        type: "collapse",
                        name: "Generate TimeTable",
                        key: "timestepper",
                        route: "/timestepper",
                        icon: <Calendar  size="16px" />,
                        component: <TimestepperPage />,
                        noCollapse: true,
                        departments: ["ACADEMY"],
                        userTypes: ["EMPLOYEE"],
                    },
                ]
            },
            {
                type: "collapse",
                name: "Question",
                key: "Question",
                icon: <CircleHelp size="16px" />,
                noCollapse: false,
                departments: ["ACADEMY"],
                userTypes: ["EMPLOYEE"],
                children: [
                    {
                        type: "collapse",
                        name: "Add Questions",
                        key: "addquestions",
                        route: "/addquestions",
                        icon: <PlusCircle  size="16px" />,
                        component: <AddQuestions />,
                        noCollapse: true,
                        departments: ["ACADEMY"],
                        userTypes: ["EMPLOYEE"],
                    },
                    {
                        type: "collapse",
                        name: "View Questions",
                        key: "viewQuestions",
                        route: "/viewQuestions",
                        icon: <Eye  size="16px" />,
                        component: <ViewQuestions />,
                        noCollapse: true,
                        departments: ["ACADEMY"],
                        userTypes: ["EMPLOYEE"],
                    },
                    {
                        type: "collapse",
                        name: "Generate Question Paper",
                        key: "all-questions",
                        route: "/all-questions",
                        icon: <FileText  size="16px" />,
                        component: <QuestionPaperByAll />,
                        noCollapse: true,
                        departments: ["ACADEMY"],
                        userTypes: ["EMPLOYEE"],
                    },
                ]
            },
            {
                type: "collapse",
                name: "View Questions",
                key: "viewQuestions",
                route: "/viewQuestions",
                icon: <Eye  size="16px" />,
                component: <ViewQuestions />,
                noCollapse: true,
                userTypes: ["STUDENT"],
            },
            {
                type: "collapse",
                name: "Courses",
                key: "Courses",
                icon: <Shop size="16px" />,
                noCollapse: false,
                departments: ["ACADEMY"],
                userTypes: ["EMPLOYEE","USER"],
                children: [
                    {
                        type: "collapse",
                        name: "Courses",
                        key: "courses-details",
                        route: "/courses-details",
                        icon: <Book  size="16px" />,
                        component: <CoursesDetails />,
                        noCollapse: true,
                        userTypes: ["EMPLOYEE","USER"],
                    },
                    {
                        type: "collapse",
                        name: "Enrolled Courses",
                        key: "enrolled-Courses",
                        route: "/enrolled-Courses",
                        icon: <BookMarked  size="16px" />,
                        component: <CoursesEnrolled />,
                        departments: ["ACADEMY"],
                        userTypes: ["EMPLOYEE"],
                        noCollapse: true,
                    },
                ]
            },
            {
                type: "collapse",
                name: "Apply for Courses",
                key: "apply-courses",
                route: "/apply-courses",
                icon: <UserPlus  size="16px" />,
                component: <Courses />,
                noCollapse: true,
                userTypes: ["USER"],
                hideForSuperadmin: true,
            },
            // {
            //     type: "collapse",
            //     name: "Message Broadcast",
            //     key: "messageBroadCast",
            //     route: "/messageBroadCast",
            //     icon: <SendHorizontal  size="16px" />,
            //     component: <MessageBroadCast />,
            //     noCollapse: true,
            //     departments: ["ACADEMY"],
            //     userTypes: ["EMPLOYEE"],
            // },
        ]
    },
    {
        type: "collapse",
        name: "Assets",
        key: "Assets",
        icon: <Box  size="16px" />,
        noCollapse: false,
        departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
        userTypes: ["EMPLOYEE"],
        children: [
            {
                type: "collapse",
                name: "Stationary",
                key: "stationaryItems",
                route: "/stationaryItems",
                icon: <Clipboard  size="16px" />,
                component: <StationaryItems />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Stores",
                key: "assets-list",
                route: "/assets-list",
                icon: <Warehouse  size="16px" />,
                component: <AssetList />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Procurement",
                key: "procurement-list",
                route: "/procurement-list",
                icon: <ShoppingCart  size="16px" />,
                component: <ProcurementList />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
        ]
    },
    {
        type: "collapse",
        name: "Setup",
        key: "Setup",
        icon: <Settings  size="16px" />,
        noCollapse: false,
        departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
        userTypes: ["EMPLOYEE"],
        children: [
            {
                type: "collapse",
                name: "Email Service",
                key: "emailServices",
                route: "/emailServices",
                icon: <Mail  size="16px" />,
                component: <EmailServices />,
                noCollapse: true,
                userTypes: ["EMPLOYEE",],
            },
            {
                type: "collapse",
                name: "Blog",
                key: "bloglist",
                route: "/bloglist",
                icon: <Mail  size="16px" />,
                component: <BlogList />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE",],
            },
            {
                type: "collapse",
                name: "Department Spoc",
                key: "depart-spoc",
                route: "/depart-spoc",
                icon: <UserCog  size="16px" />,
                component: <SpocData />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Landing Page",
                key: "landing-page",
                route: "/landing-page",
                icon: <Globe size="16px" />,
                component: <SetUplandingPage />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
        ]
    },
    {
        type: "collapse",
        name: "DMS",
        key: "DMS",
        icon: <Folder  size="16px" />,
        noCollapse: false,
        departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
        userTypes: ["EMPLOYEE"],
        children: [
            {
                type: "collapse",
                name: "Course Document",
                key: "document",
                route: "/document",
                icon: <BookOpen  size="16px" />,
                component: <DocumentsData />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Office Document",
                key: "office-document",
                route: "/office-document",
                icon: <FileText  size="16px" />,
                component: <OfficeDocumentsData />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
        ]
    },
    {
        type: "collapse",
        name: "Support",
        key: "Support",
        icon: <CircleAlert  size="16px" />,
        noCollapse: false,
        departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
        userTypes: ["EMPLOYEE"],
        children: [
           
            {
                type: "collapse",
                name: "Query",
                key: "ticket-list",
                route: "/ticket-list",
                icon: <MessageSquare  size="16px" />,
                component: <TicketTable />,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Meeting schedule",
                key: "schedule-meeting",
                route: "/schedule-meeting",
                icon: <VideoIcon  size="16px" />,
                component: <Schedule  />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            
        ]
    },
    {
        type: "collapse",
        name: "Vault",
        key: "Vault",
        icon: <Lock  size="16px" />,
        noCollapse: false,
        departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
        userTypes: ["EMPLOYEE"],
        children: [
            {
                type: "collapse",
                name: "Passwords",
                key: "password-managing",
                route: "/password-managing",
                icon: <Key  size="16px" />,
                component: <OrgPassword />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
            {
                type: "collapse",
                name: "Requested Passwords",
                key: "requested-password",
                route: "/requested-password",
                icon: <KeyRound  size="16px" />,
                component: <PasswordReq />,
                noCollapse: true,
                departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
                userTypes: ["EMPLOYEE"],
            },
        ]
    },

];
const getDisplayName = (route) => {     
    if (route.type === "title") {         
        return ""; 
    }     
    return route.name ? route.name.toLowerCase() : ""; 
};  

// Modify the sorting logic
const sortedRoutes = [...routes].sort((a, b) => {     
    const nameA = getDisplayName(a);     
    const nameB = getDisplayName(b);      

    // Keep titles in their original position     
    if (a.type === "title") return -1;     
    if (b.type === "title") return 1;      

    // Separate profile route to be placed last     
    if (nameA === 'profile') return 1;     
    if (nameB === 'profile') return -1;     

    // Sort other items alphabetically     
    return nameA.localeCompare(nameB); 
});  

// Modify the filterChildren function to sort children
const filterChildren = (children) => {     
    if (!children) return null;      

    const filteredChildren = children.filter(route => {         
        if (route.hideForSuperadmin && userRole.type === "SUPERADMIN") {             
            return false;         
        }         
        if (userRole.type === "SUPERADMIN") {             
            return true;         
        }         
        if (route.userTypes) {             
            if (route.userTypes.includes(userRole.type)) {                 
                if (route.departments && route.departments.includes(userRole.dept)) {                     
                    return true;                 }                 
                if (!route.departments) {                     
                    return true;                 }             
            }         
        }         
        return false;     
    });      

    // Sort filtered children alphabetically
    const sortedChildren = filteredChildren.sort((a, b) => 
        getDisplayName(a).localeCompare(getDisplayName(b))
    );

    return sortedChildren.length > 0 ? sortedChildren : null; 
};  

// Filter the routes array including children
const filteredRoutes = sortedRoutes.filter(route => {     
    // If the route is explicitly hidden, exclude it     
    if (route.visible === false) {         
        return false;     
    }      

    if (route.hideForSuperadmin && userRole.type === "SUPERADMIN") {         
        return false;     
    }      

    // Handle children routes     
    if (route.children) {         
        const filteredChildren = filterChildren(route.children);         
        if (filteredChildren) {             
            route.children = filteredChildren;             
            return true;         
        }         
        return false;     
    }      

    // If userType is superadmin, show all routes     
    if (userRole.type === "SUPERADMIN") {         
        return true;     
    }      

    // If userType is listed for this route, include this route     
    if (route.userTypes) {         
        // Check if the current userType is allowed for this route         
        if (route.userTypes.includes(userRole.type)) {             
            // Additionally, check if the department is allowed (if specified)             
            if (route.departments && route.departments.includes(userRole.dept)) {                 
                return true;             
            }             
            // Show route if no department restrictions or user is in the allowed department             
            if (!route.departments) {                 
                return true;             }         
        }     
    }     
    return false; 
});
export default filteredRoutes;