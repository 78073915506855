import { useState, useEffect } from "react";
import {
  Card, CardContent, Dialog, DialogTitle, DialogContent,
  DialogActions, IconButton, Tooltip,
  TextField,
  Backdrop,
  CircularProgress
} from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { APIData, org } from "authentication/APIData";
import Swal from "sweetalert2";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { FormControl, FormHelperText } from "@mui/material";
import { CloudUploadIcon, DeleteIcon } from "lucide-react";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { FaEdit, FaSave, FaTimes } from "react-icons/fa";
import Skeleton from "@mui/material/Skeleton";
import { Collections } from "@mui/icons-material"; // Add this import
import ImageLibrarySelector from "Super_Admin/ImageIconLibrary";

export default function Features() {
  const [features, setFeatures] = useState([]);
  const [featureHeader, setFeatureHeader] = useState("Powerful Features for Your Business");
  const [featureDescription, setFeatureDescription] = useState("Everything you need to manage and grow your business efficiently");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingFeature, setEditingFeature] = useState(null);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [landingData, setLandingData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showImageLibrary, setShowImageLibrary] = useState(false);

  const [editedData, setEditedData] = useState({
    featureHeader: "",
    featureHeaderDesc: "",
  });

  const [newFeature, setNewFeature] = useState({
    title: "",
    description: "",
    logo: null,
    logoPreview: null
  });

  const [errors, setErrors] = useState({
    title: "",
    description: "",
    logo: ""
  });

  useEffect(() => {
    const loadUserPermissions = () => {
      const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
      const accessLevel = localStorage.getItem('Access Level');

      if (sessionDetails && sessionDetails.user) {
        setCurrentUser(sessionDetails.user);
        // Store userType in state to ensure it persists
        const userType = sessionDetails.userType;
        setIsSuperAdmin(userType === "SUPERADMIN");
      }

      if (accessLevel) {
        setPermission(parseInt(accessLevel));
      }
    };

    loadUserPermissions();
    window.addEventListener('storage', loadUserPermissions);

    return () => {
      window.removeEventListener('storage', loadUserPermissions);
    };
  }, []);

  const shouldShowEditButton = () => {
    if (permission === null) return false;

    if (permission === 1100 || permission === 1000) {
      return false;
    }
    return isSuperAdmin || permission === 1111;
  };

  useEffect(() => {
    shouldShowEditButton();
  }, []);

  useEffect(() => {
    fetchFeatures();
  }, [org]);

  const fetchFeatures = async () => {
    try {
      const response = await fetch(`${APIData.api}landing-page/org/${org}`, {
        headers: APIData.headers,
      });
      const data = await response.json();

      setLandingData(data);
      setFeatures(data.feature || []);
      setFeatureHeader(data.featureHeader || featureHeader);
      setFeatureDescription(data.featureHeaderDesc || featureDescription);
      setEditedData({
        featureHeader: data.featureHeader || featureHeader,
        featureHeaderDesc: data.featureHeaderDesc || featureDescription,
      });
    } catch (err) {
      console.error("Error fetching organization data:", err);
      setError("Failed to load features");
    } finally {
      setLoading(false);
    }
  };

  const validateContent = () => {
    const errors = [];

    if (editedData.featureHeader.length > 75) {
      errors.push('Header must be less than 75 characters');
    }

    if (editedData.featureHeaderDesc.length > 400) {
      errors.push('Description must be less than 400 characters');
    }

    return errors;
  };

  const handleSave = async () => {
    const errors = validateContent();

    if (errors.length > 0) {
      await Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        html: errors.join('<br>'),
        confirmButtonColor: '#6b46c1'
      });
      return;
    }

    const currentDateTime = new Date().toISOString();
    const payload = {
      ...landingData,
      featureHeader: editedData.featureHeader,
      featureHeaderDesc: editedData.featureHeaderDesc,
      feature: features,
      org: org,
      updatedBy: currentUser || "SYSTEM",
      updatedDateTime: currentDateTime
    };

    try {
      const response = await fetch(`${APIData.api}landing-page/Add`, {
        method: "POST",
        headers: {
          ...APIData.headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setFeatureHeader(editedData.featureHeader);
        setFeatureDescription(editedData.featureHeaderDesc);
        setIsEditing(false);

        await Swal.fire({
          icon: 'success',
          title: 'Saved Successfully',
          timer: 1500,
          showConfirmButton: false
        });
      } else {
        throw new Error('Failed to save');
      }
    } catch (error) {
      console.error("Error saving:", error);
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to save changes',
        confirmButtonColor: '#6b46c1'
      });
    }
  };

  const handleImageSelect = (libraryImage) => {
    // Convert the image URL to base64 if needed
    fetchImageAsBase64(libraryImage.imageUrl)
      .then(base64Data => {
        setNewFeature(prev => ({
          ...prev,
          logo: null,
          logoPreview: libraryImage.imageUrl,
          logoBase64: base64Data
        }));
        setShowImageLibrary(false);
      })
      .catch(error => {
        console.error("Error converting image to base64:", error);
        Swal.fire({
          icon: 'error',
          title: 'Image Processing Error',
          text: 'Failed to process the selected image',
        });
      });
  };

  // Function to handle image selection while editing
  const handleEditImageSelect = (libraryImage) => {
    fetchImageAsBase64(libraryImage.imageUrl)
      .then(base64Data => {
        setEditingFeature(prev => ({
          ...prev,
          newLogo: null,
          logoPreview: libraryImage.imageUrl,
          logoBase64: base64Data
        }));
        setShowImageLibrary(false);
      })
      .catch(error => {
        console.error("Error converting image to base64:", error);
        Swal.fire({
          icon: 'error',
          title: 'Image Processing Error',
          text: 'Failed to process the selected image',
        });
      });
  };

  // Function to convert image URL to base64
  const fetchImageAsBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64string = reader.result.split(",")[1];
          resolve(base64string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setNewFeature(prev => ({
          ...prev,
          logo: file,
          logoPreview: URL.createObjectURL(file)
        }));
        setErrors(prev => ({ ...prev, logo: "" }));
      } else {
        setErrors(prev => ({ ...prev, logo: "Please select an image file" }));
      }
    }
  };

  const validateFeature = (feature) => {
    const newErrors = {};

    if (!feature.title) {
      newErrors.title = "Title is required.";
    } else if (feature.title.length > 40) {
      newErrors.title = "Title must be less than 40 characters.";
    }

    if (!feature.description) {
      newErrors.description = "Description is required.";
    } else if (feature.description.length > 200) {
      newErrors.description = "Description must be less than 200 characters.";
    }

    if (!feature.logo && !feature.logoPreview) {
      newErrors.logo = "Logo is required.";
    }

    return newErrors;
  };

  const handleAddFeature = async () => {
    const newErrors = validateFeature(newFeature);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsSubmitting(true); // Start loading
      try {
        // Step 1: Add feature with title and description first (empty logo)
        const currentDateTime = new Date().toISOString();
        const newFeatureData = {
          title: newFeature.title,
          description: newFeature.description,
          logo: "" // Empty logo initially
        };

        const initialPayload = {
          ...landingData,
          feature: [...features, newFeatureData],
          org: org,
          updatedBy: currentUser || "SYSTEM",
          updatedDateTime: currentDateTime
        };

        const addResponse = await fetch(`${APIData.api}landing-page/Add`, {
          method: "POST",
          headers: {
            ...APIData.headers,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(initialPayload),
        });

        if (!addResponse.ok) {
          throw new Error("Failed to create feature");
        }

        const featureIndex = features.length;
        console.log("index number :", featureIndex);

        const formData = new FormData();
        formData.append("id", org);
        formData.append("featureLogoImage", newFeature.logo);
        formData.append("featureIndex", featureIndex);

        const logoResponse = await fetch(`${APIData.api}landing-page/${org}/upload-images?featureIndex=${featureIndex}`, {
          method: "POST",
          headers: {
            ...APIData.headers,
          },
          body: formData,
        });

        if (!logoResponse.ok) {
          throw new Error("Failed to upload logo");
        }

        await fetchFeatures();
        setShowAddDialog(false);
        setNewFeature({ title: "", description: "", logo: null, logoPreview: null });

        await Swal.fire({
          icon: "success",
          title: "Feature Added Successfully",
          timer: 1500,
          showConfirmButton: false,
        });

      } catch (error) {
        console.error("Error adding feature:", error);
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to add feature: ' + error.message,
          confirmButtonColor: '#6b46c1'
        });
      } finally {
        setIsSubmitting(false); // Stop loading regardless of outcome
      }
    }
  };

  const handleDeleteFeature = async (index) => {
    try {
      // Show confirmation dialog
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6b46c1',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        setLoading(true);

        // Delete the feature images first
        const deleteResponse = await fetch(
          `${APIData.api}landing-page/${org}/delete-images?featureIndex=${index}`,
          {
            method: "DELETE",
            headers: APIData.headers,
          }
        );

        if (!deleteResponse.ok) {
          throw new Error("Failed to delete feature");
        }

        await fetchFeatures();

        await Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: 'Feature has been deleted.',
          timer: 1500,
          showConfirmButton: false
        });
      }
    } catch (error) {
      console.error("Error deleting feature:", error);
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete feature: ' + error.message,
        confirmButtonColor: '#6b46c1'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleStartEdit = (feature) => {
    setEditingFeature({
      ...feature,
      title: feature.title,
      description: feature.description,
      logo: feature.logo,
      originalIndex: features.findIndex(f => f.title === feature.title)
    });
  };

  const handleEditFeature = async (editedFeature, index) => {
    const newErrors = validateFeature(editedFeature);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsSubmitting(true);
      try {
        console.log("Editing feature:", editedFeature); // Debug log

        // Create a deep copy of the current features array
        const updatedFeatures = features.map((f, i) => {
          if (i === index) {
            // Create the updated feature object
            const updatedFeature = {
              title: editedFeature.title,
              description: editedFeature.description,
              logo: editedFeature.logoPreview || editedFeature.logo // Use new logo preview if available, otherwise keep existing
            };
            console.log("Updated feature:", updatedFeature); // Debug log
            return updatedFeature;
          }
          return { ...f }; // Return a copy of other features
        });

        console.log("All updated features:", updatedFeatures); // Debug log

        // Prepare the complete payload
        const currentDateTime = new Date();
        const payload = {
          feature: updatedFeatures,
          latestUpdates: landingData.latestUpdates || [],
          header: landingData.header || "",
          headerDescription: landingData.headerDescription || "",
          featureHeader: landingData.featureHeader || "",
          featureHeaderDesc: landingData.featureHeaderDesc || "",
          org: org,
          landingImage: landingData.landingImage || "",
          createdBy: landingData.createdBy || "",
          createdDateTime: landingData.createdDateTime,
          updatedBy: currentUser || "SYSTEM",
          updatedDateTime: [
            currentDateTime.getFullYear(),
            currentDateTime.getMonth() + 1,
            currentDateTime.getDate(),
            currentDateTime.getHours(),
            currentDateTime.getMinutes(),
            currentDateTime.getSeconds()
          ]
        };

        console.log("Payload being sent:", payload); // Debug log

        // Update the feature data
        const response = await fetch(`${APIData.api}landing-page/Add`, {
          method: "POST",
          headers: {
            ...APIData.headers,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error("Failed to update feature details");
        }

        // Handle logo upload if there's a new logo
        if (editedFeature.newLogo) {
          const formData = new FormData();
          formData.append("id", org);
          formData.append("featureLogoImage", editedFeature.newLogo);
          formData.append("featureIndex", index);

          const logoResponse = await fetch(
            `${APIData.api}landing-page/${org}/upload-images?featureIndex=${index}`,
            {
              method: "POST",
              headers: {
                ...APIData.headers,
              },
              body: formData,
            }
          );

          if (!logoResponse.ok) {
            throw new Error("Failed to upload logo");
          }
        }

        await fetchFeatures(); // Refresh the data
        setEditingFeature(null); // Close the edit dialog

        await Swal.fire({
          icon: "success",
          title: "Feature Updated Successfully",
          timer: 1500,
          showConfirmButton: false,
        });
      } catch (error) {
        console.error("Error updating feature:", error);
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to update feature: ' + error.message,
          confirmButtonColor: '#6b46c1'
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };


  const handleEditLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setEditingFeature(prev => ({
          ...prev,
          newLogo: file,
          logoPreview: URL.createObjectURL(file)
        }));
        setErrors(prev => ({ ...prev, logo: "" }));
      } else {
        setErrors(prev => ({ ...prev, logo: "Please select an image file" }));
      }
    }
  };

  const editableStyles = {
    input: {
      background: "transparent",
      border: "none",
      fontSize: "inherit",
      fontWeight: "inherit",
      fontFamily: "inherit",
      color: "inherit",
      width: "100%",
      padding: "5px",
      outline: isEditing ? "2px solid #6b46c1" : "none",
    },
    textarea: {
      background: "transparent",
      border: "none",
      fontSize: "inherit",
      fontStyle: "inherit",
      color: "inherit",
      width: "100%",
      padding: "5px",
      resize: "vertical",
      minHeight: "100px",
      outline: isEditing ? "2px solid #6b46c1" : "none",
    },
    characterCount: {
      fontSize: "12px",
      color: "#666",
      textAlign: "right",
      marginTop: "4px"
    }
  };

  const cardSize = features.length <= 3 ? "250px" : "auto";

  return (
    <section id="features" style={{ padding: "60px 0" }}>
      <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 20px" }}>
        <div style={{ marginBottom: "48px" }}>
          {/* Control Buttons Container */}
          {shouldShowEditButton() && (
            <SoftBox
              position="relative"
              marginBottom="20px"
              display="flex"
              justifyContent="flex-end"
              gap={2}
            >
              {!isEditing ? (
                <Tooltip title="Edit Content" arrow>
                  <SoftButton
                    onClick={() => {
                      fetchFeatures();
                      setIsEditing(true);
                    }}
                    startIcon={<FaEdit />}
                    color="secondary"
                    variant="gradient"
                  >
                    Edit
                  </SoftButton>
                </Tooltip>
              ) : (
                <>
                  <Tooltip title="Save Changes" arrow>
                    <SoftButton
                      onClick={handleSave}
                      startIcon={<FaSave />}
                      variant="gradient"
                      color="success"
                    >
                      Save
                    </SoftButton>
                  </Tooltip>
                  <Tooltip title="Cancel Changes" arrow>
                    <SoftButton
                      onClick={() => {
                        setIsEditing(false);
                        setEditedData({
                          featureHeader: featureHeader,
                          featureHeaderDesc: featureDescription
                        });
                      }}
                      startIcon={<FaTimes />}
                      variant="gradient"
                      color="error"
                    >
                      Cancel
                    </SoftButton>
                  </Tooltip>
                </>
              )}
            </SoftBox>
          )}

          {/* Header Content Container */}
          <div style={{ textAlign: "center" }}>
            <div>
              {isEditing ? (
                <>
                  <input
                    type="text"
                    value={editedData.featureHeader}
                    onChange={(e) => setEditedData(prev => ({ ...prev, featureHeader: e.target.value }))}
                    style={{
                      ...editableStyles.input,
                      fontSize: "36px",
                      fontWeight: "bold",
                      marginBottom: "4px",
                      textAlign: "center"
                    }}
                  />
                  <div style={editableStyles.characterCount}>
                    {editedData.featureHeader.length}/75 characters
                  </div>
                </>
              ) : (
                <h2 style={{ fontSize: "36px", fontWeight: "bold", marginBottom: "12px", color: "black" }}>
                  {loading ? "Loading..." : error ? "Error Loading Features" : featureHeader}
                </h2>
              )}
            </div>

            <div>
              {isEditing ? (
                <>
                  <textarea
                    value={editedData.featureHeaderDesc}
                    onChange={(e) => setEditedData(prev => ({ ...prev, featureHeaderDesc: e.target.value }))}
                    style={{
                      ...editableStyles.textarea,
                      fontSize: "16px",
                      color: "#555",
                      marginBottom: "4px",
                      textAlign: "center"
                    }}
                  />
                  <div style={editableStyles.characterCount}>
                    {editedData.featureHeaderDesc.length}/400 characters
                  </div>
                </>
              ) : (
                <p style={{ fontSize: "16px", color: "#555", maxWidth: "600px", margin: "0 auto" }}>
                  {loading ? "Please wait..." : error ? "Try again later" : featureDescription}
                </p>
              )}
            </div>
          </div>
        </div>

        <div style={{
          display: "grid",
          gridTemplateColumns: `repeat(auto-fit, minmax(${cardSize}, 1fr))`,
          gap: "24px",
        }}>
          {!loading && !error && features.map((feature, index) => (
            <Card
              key={index}
              sx={{
                border: "none",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease-in-out",
                cursor: "pointer",
                height: cardSize,
                position: "relative",
                '&:hover': {
                  background: "linear-gradient(to right, rgb(205, 160, 245), rgb(255, 255, 255))",
                  transform: "scale(1.02)"
                }
              }}
            >
              {shouldShowEditButton() && (
                <div style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 1,
                  display: "flex",
                  gap: "5px"
                }}>
                  <Tooltip title="Edit Feature">
                    <IconButton
                      size="small"
                      onClick={() => {
                        fetchFeatures();
                        handleStartEdit(feature);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Feature">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteFeature(index);
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              <CardContent sx={{ padding: "20px", textAlign: "center" }}>
                {feature.logo ? (
                  <img
                    src={feature.logo}
                    alt={feature.title}
                    style={{ height: "50px", width: "50px", objectFit: "contain", marginBottom: "12px" }}
                  />
                ) : (
                  <AutoAwesomeIcon sx={{ fontSize: "50px", color: "purple", marginBottom: "12px" }} />
                )}
                <h3 style={{ fontSize: "22px", fontWeight: "600", marginBottom: "6px" }}>
                  {feature.title}
                </h3>
                <p style={{ fontSize: "16px", color: "#555" }}>
                  {feature.description}
                </p>
              </CardContent>
            </Card>
          ))}

          {shouldShowEditButton() && (
            <Card
              sx={{
                border: "2px dashed #6b46c1",
                height: cardSize,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                background: "transparent",
                '&:hover': {
                  background: "rgba(107, 70, 193, 0.1)"
                }
              }}
              onClick={() => {
                fetchFeatures();
                setShowAddDialog(true);
              }}
            >
              <CardContent sx={{ textAlign: "center" }}>
                <AddIcon sx={{ fontSize: 40, color: "#6b46c1", marginBottom: 1 }} />
                <p style={{ color: "#6b46c1" }}>Add New Feature</p>
              </CardContent>
            </Card>
          )}
        </div>
      </div>

      {/* Add Feature Dialog */}
      <Dialog
        open={showAddDialog}
        onClose={() => !isSubmitting && setShowAddDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <SoftBox p={3} mb={1} display="flex" alignItems="center">
          <Tooltip title="Close" arrow>
            <IconButton
              onClick={() => !isSubmitting && setShowAddDialog(false)}
              disabled={isSubmitting}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
            Add New Feature
          </SoftTypography>
        </SoftBox>

        <SoftBox component="form" role="form" px={3}>
          <SoftBox mb={2}>
            <SoftTypography variant="h6">Feature Title *</SoftTypography>
            <FormControl fullWidth error={!!errors.title}>
              <SoftInput
                placeholder="Feature Title"
                value={newFeature.title}
                onChange={(e) => setNewFeature(prev => ({ ...prev, title: e.target.value }))}
                size="small"
              />
              <FormHelperText>
                {errors.title || `${newFeature.title.length}/40 characters`}
              </FormHelperText>
            </FormControl>
          </SoftBox>

          <SoftBox mb={2}>
            <SoftTypography variant="h6">Description *</SoftTypography>
            <FormControl fullWidth error={!!errors.description}>
              <SoftInput
                placeholder="Feature Description"
                value={newFeature.description}
                onChange={(e) => setNewFeature(prev => ({ ...prev, description: e.target.value }))}
                multiline
                rows={4}
                size="small"
              />
              <FormHelperText>
                {errors.description || `${newFeature.description.length}/200 characters`}
              </FormHelperText>
            </FormControl>
          </SoftBox>

          <SoftBox mb={2}>
            <SoftTypography variant="h6">Logo/image *</SoftTypography>
            <FormControl fullWidth error={!!errors.logo}>
              <SoftBox display="flex" justifyContent="space-between" gap={2} mb={2}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="logo-upload"
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="logo-upload" style={{ width: '100%' }}>
                  <SoftButton
                    component="span"
                    variant="outlined"
                    color="info"
                    fullWidth
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload from Device
                  </SoftButton>
                </label>
                <SoftButton
                  variant="outlined"
                  color="primary"
                  fullWidth
                  startIcon={<Collections />}
                  onClick={() => setShowImageLibrary(true)}
                >
                  Select from Library
                </SoftButton>
              </SoftBox>
              {newFeature.logoPreview && (
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                  <img
                    src={newFeature.logoPreview}
                    alt="Logo preview"
                    style={{ maxWidth: '100px', maxHeight: '100px' }}
                  />
                </div>
              )}
              {errors.logo && (
                <FormHelperText>{errors.logo}</FormHelperText>
              )}
            </FormControl>
          </SoftBox>

          <SoftBox mt={4} mb={1}>
            <SoftButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={handleAddFeature}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Add Feature'
              )}
            </SoftButton>
          </SoftBox>
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
              position: 'absolute'
            }}
            open={isSubmitting}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </SoftBox>
      </Dialog>

      {/* Edit Feature Dialog */}
      <Dialog
        open={!!editingFeature}
        onClose={() => !isSubmitting && setEditingFeature(null)}
        maxWidth="sm"
        fullWidth
      >
        <SoftBox p={3} mb={1} display="flex" alignItems="center">
          <Tooltip title="Close" arrow>
            <IconButton
              onClick={() => !isSubmitting && setEditingFeature(null)}
              disabled={isSubmitting}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
            Edit Feature
          </SoftTypography>
        </SoftBox>

        {editingFeature && (
          <SoftBox component="form" role="form" px={3}>
            <SoftBox mb={2}>
              <SoftTypography variant="h6">Feature Title *</SoftTypography>
              <FormControl fullWidth error={!!errors.title}>
                <SoftInput
                  placeholder="Feature Title"
                  value={editingFeature.title}
                  onChange={(e) => setEditingFeature(prev => ({
                    ...prev,
                    title: e.target.value
                  }))}
                  size="small"
                />
                <FormHelperText>
                  {errors.title || `${editingFeature.title.length}/40 characters`}
                </FormHelperText>
              </FormControl>
            </SoftBox>

            <SoftBox mb={2}>
              <SoftTypography variant="h6">Description *</SoftTypography>
              <FormControl fullWidth error={!!errors.description}>
                <SoftInput
                  placeholder="Feature Description"
                  value={editingFeature.description}
                  onChange={(e) => setEditingFeature(prev => ({
                    ...prev,
                    description: e.target.value
                  }))}
                  multiline
                  rows={4}
                  size="small"
                />
                <FormHelperText>
                  {errors.description || `${editingFeature.description.length}/200 characters`}
                </FormHelperText>
              </FormControl>
            </SoftBox>

            <SoftBox mb={2}>
              <SoftTypography variant="h6">Logo/image</SoftTypography>
              <FormControl fullWidth error={!!errors.logo}>
                <SoftBox display="flex" justifyContent="space-between" gap={2} mb={2}>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="edit-logo-upload"
                    type="file"
                    onChange={handleEditLogoChange}
                  />
                  <label htmlFor="edit-logo-upload" style={{ width: '100%' }}>
                    <SoftButton
                      component="span"
                      variant="outlined"
                      color="info"
                      fullWidth
                      startIcon={<CloudUploadIcon />}
                      disabled={isSubmitting}
                    >
                      Upload from Device
                    </SoftButton>
                  </label>
                  <SoftButton
                    variant="outlined"
                    color="primary"
                    fullWidth
                    startIcon={<Collections />}
                    onClick={() => setShowImageLibrary(true)}
                    disabled={isSubmitting}
                  >
                    Select from Library
                  </SoftButton>
                </SoftBox>
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                  <img
                    src={editingFeature.logoPreview || editingFeature.logo}
                    alt="Logo preview"
                    style={{ maxWidth: '100px', maxHeight: '100px' }}
                  />
                </div>
                {errors.logo && (
                  <FormHelperText>{errors.logo}</FormHelperText>
                )}
              </FormControl>
            </SoftBox>

            <SoftBox mt={4} mb={1}>
              <SoftButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => handleEditFeature(editingFeature, editingFeature.originalIndex)}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Save Changes'
                )}
              </SoftButton>
            </SoftBox>
            <Backdrop
              sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'absolute'
              }}
              open={isSubmitting}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </SoftBox>
        )}
      </Dialog>
      {/* Image Library Dialog */}
      <Dialog
        open={showImageLibrary}
        onClose={() => setShowImageLibrary(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <SoftTypography variant="h5" fontWeight="medium">
            Select Image from Library
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <ImageLibrarySelector onSelectImage={editingFeature ? handleEditImageSelect : handleImageSelect} />
        </DialogContent>
        <DialogActions>
          <SoftButton
            variant="gradient"
            color="info"
            onClick={() => setShowImageLibrary(false)}
          >
            Cancel
          </SoftButton>
        </DialogActions>
      </Dialog>
    </section>
  );
}