// import React, { useState, useEffect, useRef } from 'react';
// import {
//     Card,
//     CardContent,
//     CardMedia,
//     Typography,
//     IconButton,
//     Box,
//     Container
// } from '@mui/material';
// import { ChevronLeft, ChevronRight } from '@mui/icons-material';
// import { useNavigate } from 'react-router-dom';
// import { org } from 'authentication/APIData';
// import { APIData } from 'authentication/APIData';

// const styles = {
//     scrollContainer: {
//         position: 'relative',
//         marginBottom: '40px',
//         marginTop: '40px',
//     },
//     scrollContent: {
//         display: 'flex',
//         overflowX: 'hidden',
//         scrollBehavior: 'smooth',
//         padding: '0 60px', // Increased padding for more space around cards
//         gap: '24px', // Increased gap between cards
//     },
//     scrollCard: {
//         flexShrink: 0,
//         width: '250px',
//         height: '250px',
//         cursor: 'pointer',
//         transition: 'box-shadow 0.3s ease',
//         '&:hover': {
//             boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
//         },
//     },
//     scrollButton: {
//         position: 'absolute',
//         top: '50%',
//         transform: 'translateY(-50%)',
//         zIndex: 1,
//         backgroundColor: '#fff',
//         boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//         margin: '0 10px', // Added margin to buttons
//         '&:hover': {
//             backgroundColor: '#f5f5f5',
//         },
//     },
//     leftButton: {
//         left: 0,
//     },
//     rightButton: {
//         right: 0,
//     },
//     cardMedia: {
//         height: '150px',
//     },
//     cardContent: {
//         padding: '12px', // Increased padding
//         '&:last-child': {
//             paddingBottom: '12px',
//         },
//     },
//     title: {
//         marginBottom: '32px',
//         fontWeight: 'bold',
//         textAlign: 'center',
//     },
//     viewAllButton: {
//         textAlign: 'center',
//         marginTop: '20px', // Increased top margin
//         cursor: 'pointer',
//         color: 'primary.main',
//         fontWeight: 'medium',
//         '&:hover': {
//             textDecoration: 'underline',
//         },
//     },
// };

// const LandingPageBlog = () => {
//     const [blogPosts, setBlogPosts] = useState([]);
//     const scrollContainerRef = useRef(null);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchBlogPosts = async () => {
//             try {
//                 const response = await fetch(`${APIData.api}blog/details/org/${org}`, {
//                     method: 'GET',
//                     headers: APIData.headers
//                 });
//                 const data = await response.json();

//                 // Filter only published blogs and sort by publishedDate (latest first)
//                 const filteredAndSortedBlogs = data
//                     .filter((post) => post.published) // Only include published posts
//                     .sort((a, b) => new Date(...b.publishedDate) - new Date(...a.publishedDate));

//                 setBlogPosts(filteredAndSortedBlogs);
//             } catch (error) {
//                 console.error('Error fetching blog posts:', error);
//             }
//         };

//         fetchBlogPosts();
//     }, []);

//     const scroll = (direction) => {
//         if (scrollContainerRef.current) {
//             const scrollAmount = 280;
//             const container = scrollContainerRef.current;
//             const newPosition = container.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);
//             container.scrollTo({
//                 left: newPosition,
//                 behavior: 'smooth'
//             });
//         }
//     };

//     const handleCardClick = (post) => {
//         // Store the selected post ID in localStorage so the Blog page can access it
//         localStorage.setItem('selectedBlogPostId', post.id);
//         // Navigate to the blog page
//         navigate('/blog');
//     };

//     return (
//         <Container maxWidth="lg">
//             <Typography variant="h4" sx={styles.title}>
//                 Latest Blog Posts
//             </Typography>
            
//             <Box sx={styles.scrollContainer}>
//                 <IconButton
//                     onClick={() => scroll('left')}
//                     sx={{ ...styles.scrollButton, ...styles.leftButton }}
//                     size="large"
//                 >
//                     <ChevronLeft />
//                 </IconButton>

//                 <Box
//                     ref={scrollContainerRef}
//                     sx={styles.scrollContent}
//                 >
//                     {blogPosts.map((post) => (
//                         <Card
//                             key={post.id}
//                             sx={styles.scrollCard}
//                             onClick={() => handleCardClick(post)}
//                             elevation={2} // Added elevation for better appearance
//                         >
//                             <CardMedia
//                                 component="img"
//                                 image={post.featuredImage}
//                                 alt={post.title}
//                                 sx={styles.cardMedia}
//                             />
//                             <CardContent sx={styles.cardContent}>
//                                 <Typography noWrap variant="subtitle2">
//                                     {post.title}
//                                 </Typography>
//                                 <Typography noWrap variant="caption" color="text.secondary">
//                                     {post.author}
//                                 </Typography>
//                             </CardContent>
//                         </Card>
//                     ))}
//                 </Box>

//                 <IconButton
//                     onClick={() => scroll('right')}
//                     sx={{ ...styles.scrollButton, ...styles.rightButton }}
//                     size="large"
//                 >
//                     <ChevronRight />
//                 </IconButton>
//             </Box>
//         </Container>
//     );
// };

// export default LandingPageBlog;

import React, { useState, useEffect, useRef } from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    IconButton,
    Box,
    Container,
    Skeleton
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { org } from 'authentication/APIData';
import { APIData } from 'authentication/APIData';

const styles = {
    scrollContainer: {
        position: 'relative',
        marginBottom: '40px',
        marginTop: '40px',
    },
    scrollContent: {
        display: 'flex',
        overflowX: 'hidden',
        scrollBehavior: 'smooth',
        padding: '0 60px', // Increased padding for more space around cards
        gap: '24px', // Increased gap between cards
    },
    scrollCard: {
        flexShrink: 0,
        width: '250px',
        height: '250px',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        },
    },
    scrollButton: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
        backgroundColor: '#fff',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        margin: '0 10px', // Added margin to buttons
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
    },
    leftButton: {
        left: 0,
    },
    rightButton: {
        right: 0,
    },
    cardMedia: {
        height: '150px',
    },
    cardContent: {
        padding: '12px', // Increased padding
        '&:last-child': {
            paddingBottom: '12px',
        },
    },
    title: {
        marginBottom: '32px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    viewAllButton: {
        textAlign: 'center',
        marginTop: '20px', // Increased top margin
        cursor: 'pointer',
        color: 'primary.main',
        fontWeight: 'medium',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
};

const LandingPageBlog = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const scrollContainerRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlogPosts = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${APIData.api}blog/details/org/${org}`, {
                    method: 'GET',
                    headers: APIData.headers
                });
                const data = await response.json();

                // Filter only published blogs and sort by publishedDate (latest first)
                const filteredAndSortedBlogs = data
                    .filter((post) => post.published) // Only include published posts
                    .sort((a, b) => new Date(...b.publishedDate) - new Date(...a.publishedDate));

                setBlogPosts(filteredAndSortedBlogs);
            } catch (error) {
                console.error('Error fetching blog posts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogPosts();
    }, []);

    const scroll = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = 280;
            const container = scrollContainerRef.current;
            const newPosition = container.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);
            container.scrollTo({
                left: newPosition,
                behavior: 'smooth'
            });
        }
    };

    const handleCardClick = (post) => {
        // Store the selected post ID in localStorage so the Blog page can access it
        localStorage.setItem('selectedBlogPostId', post.id);
        // Navigate to the blog page
        navigate('/blog');
    };

    // Skeleton for blog post cards
    const BlogCardSkeleton = () => (
        <Card sx={styles.scrollCard} elevation={2}>
            <Skeleton 
                variant="rectangular" 
                height={150} 
                width="100%" 
                animation="wave" 
            />
            <CardContent sx={styles.cardContent}>
                <Skeleton variant="text" height={24} width="90%" animation="wave" />
                <Skeleton variant="text" height={16} width="60%" animation="wave" />
            </CardContent>
        </Card>
    );

    return (
        <Container maxWidth="lg">
            {loading ? (
                <Skeleton 
                    variant="text" 
                    height={40} 
                    width="250px" 
                    animation="wave" 
                    sx={{ 
                        mx: 'auto', 
                        mb: '32px' 
                    }} 
                />
            ) : (
                <Typography variant="h4" sx={styles.title}>
                    Latest Blog Posts
                </Typography>
            )}
            
            <Box sx={styles.scrollContainer}>
                <IconButton
                    onClick={() => scroll('left')}
                    sx={{ ...styles.scrollButton, ...styles.leftButton }}
                    size="large"
                    disabled={loading}
                >
                    <ChevronLeft />
                </IconButton>

                <Box
                    ref={scrollContainerRef}
                    sx={styles.scrollContent}
                >
                    {loading ? (
                        // Display skeleton cards when loading
                        Array.from(new Array(6)).map((_, index) => (
                            <BlogCardSkeleton key={index} />
                        ))
                    ) : (
                        // Display actual blog post cards when loaded
                        blogPosts.map((post) => (
                            <Card
                                key={post.id}
                                sx={styles.scrollCard}
                                onClick={() => handleCardClick(post)}
                                elevation={2}
                            >
                                <CardMedia
                                    component="img"
                                    image={post.featuredImage}
                                    alt={post.title}
                                    sx={styles.cardMedia}
                                />
                                <CardContent sx={styles.cardContent}>
                                    <Typography noWrap variant="subtitle2">
                                        {post.title}
                                    </Typography>
                                    <Typography noWrap variant="caption" color="text.secondary">
                                        {post.author}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))
                    )}
                </Box>

                <IconButton
                    onClick={() => scroll('right')}
                    sx={{ ...styles.scrollButton, ...styles.rightButton }}
                    size="large"
                    disabled={loading}
                >
                    <ChevronRight />
                </IconButton>
            </Box>
        </Container>
    );
};

export default LandingPageBlog;