import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ImageIconLibrary.css';
import { APIData } from 'authentication/APIData';
import PropTypes from 'prop-types';

const ImageLibrarySelector = ({ onSelectImage }) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [view, setView] = useState('grid');
    const [selectedPage, setSelectedPage] = useState(null);

    const pages = [
        'service page',
        'image page',
        'logo page',
        'product page',
        'blog page',
        'course page',
        'landing page'
    ];

    // Fetch images from API
    const fetchImages = async (page = null) => {
        setLoading(true);
        setError(null);
        
        try {
            let url;
            
            if (page) {
                // Use the correct endpoint format for page filtering
                url = `${APIData.api}image-catalogue/by-page?page=${encodeURIComponent(page)}`;
            } else {
                url = `${APIData.api}image-catalogue/`;
            }
            
            const response = await axios.get(url, { 
                headers: APIData.headers,
                timeout: 15000 // 15 seconds timeout
            });
            
            if (response.data) {
                let processedImages = [];
                
                // Handle both API response formats
                if (Array.isArray(response.data)) {
                    // Check if we have an array of objects or direct URLs
                    if (typeof response.data[0] === 'string') {
                        // Handle direct URL array format from filtered results
                        processedImages = response.data.map((url, index) => ({
                            id: `filtered-${index}`,
                            imageUrl: url,
                            // Generate a title from the URL
                            title: `${selectedPage || 'Image'} ${index + 1}`,
                            // Adding page type for searchability
                            type: page || 'image'
                        }));
                    } else {
                        // Handle array of objects format
                        processedImages = response.data.map((image, index) => {
                            // Find the image URL from various possible fields
                            let imageUrl = '';
                            
                            if (image.imageUrl) {
                                imageUrl = image.imageUrl;
                            } else if (image.image) {
                                imageUrl = image.image;
                            } else if (image.url) {
                                imageUrl = image.url;
                            } else if (image.src) {
                                imageUrl = image.src;
                            } else if (image.path) {
                                imageUrl = image.path;
                            } else if (image.link) {
                                imageUrl = image.link;
                            } else if (image.source) {
                                imageUrl = image.source;
                            } else if (image.fileURL) {
                                imageUrl = image.fileURL;
                            } else if (image.fileUrl) {
                                imageUrl = image.fileUrl;
                            } else if (image.file_url) {
                                imageUrl = image.file_url;
                            } else if (image.fullPath) {
                                imageUrl = image.fullPath;
                            } else if (image.thumbnail) {
                                imageUrl = image.thumbnail;
                            }
                            
                            return {
                                ...image,
                                id: image.id || `img-${index}`,
                                imageUrl: imageUrl,
                                // Ensure we have a title for display
                                title: image.title || image.name || `Image ${index + 1}`
                            };
                        });
                    }
                } else {
                    throw new Error("Invalid data format received from API");
                }
                
                setImages(processedImages);
            } else {
                throw new Error("No data received from API");
            }
        } catch (err) {
            console.error("API Error:", err);
            
            if (err.response) {
                // The server responded with an error status
                setError(`Server error (${err.response.status}): ${err.response.data?.message || 'Unknown server error'}`);
            } else if (err.request) {
                // The request was made but no response received
                setError("No response from server. Please check your network connection.");
            } else {
                // Something else went wrong
                setError(`Error: ${err.message}`);
            }
            
            // Keep any existing images if available
            if (images.length === 0) {
                setImages([]);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);

    // Handle page filter selection
    const handlePageSelect = (page) => {
        setSelectedPage(page);
        setSearchTerm(''); // Clear search when changing page filter
        fetchImages(page);
    };

    // Clear page filter
    const clearPageFilter = () => {
        setSelectedPage(null);
        setSearchTerm(''); // Clear search when clearing filter
        fetchImages();
    };

    // Filter images based on search term
    const filteredImages = images.filter(image => {
        if (!searchTerm.trim()) return true;
        
        // Search across multiple possible fields
        const searchableFields = [
            image.id?.toString() || '',
            image.name || '',
            image.title || '',
            image.description || '',
            image.alt || '',
            image.tags || '',
            image.type || '',
            image.subType || '',
            image.page || ''
        ];
        
        return searchableFields.some(field => 
            field.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    // Handle image selection
    const handleImageSelect = (image) => {
        setSelectedImage(image);
    };

    // Handle immediate image selection and pass to parent component
    const selectAndUseImage = (image) => {
        setSelectedImage(image);
        onSelectImage(image);
    };

    // Function to handle image loading errors
    const handleImageError = (e) => {
        e.target.onerror = null;
        e.target.src = "https://via.placeholder.com/150?text=No+Image";
    };

    return (
        <div className="image-library-container">
            <div className="image-library-header">
                <h1>Image Library</h1>
                <div className="image-library-controls">
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Search images..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="search-input"
                        />
                    </div>
                    <div className="view-toggle">
                        <button
                            className={`view-btn ${view === 'grid' ? 'active' : ''}`}
                            onClick={() => setView('grid')}
                        >
                            Grid
                        </button>
                        <button
                            className={`view-btn ${view === 'list' ? 'active' : ''}`}
                            onClick={() => setView('list')}
                        >
                            List
                        </button>
                    </div>
                    <button className="refresh-btn" onClick={() => fetchImages(selectedPage)}>
                        Refresh
                    </button>
                </div>
            </div>

            <div className="page-filters">
                <div className="filter-label">Filter by page type:</div>
                <div className="filter-buttons">
                    {pages.map((page) => (
                        <button
                            key={page}
                            className={`filter-btn ${selectedPage === page ? 'active' : ''}`}
                            onClick={() => handlePageSelect(page)}
                        >
                            {page}
                        </button>
                    ))}
                    {selectedPage && (
                        <button className="clear-filter-btn" onClick={clearPageFilter}>
                            Clear
                        </button>
                    )}
                </div>
            </div>

            {loading && (
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                    <p>Loading images...</p>
                </div>
            )}

            {error && !loading && (
                <div className="error-message">
                    <p>{error}</p>
                    <button onClick={() => fetchImages(selectedPage)}>Try Again</button>
                </div>
            )}

            {!loading && !error && filteredImages.length === 0 && (
                <div className="no-results">
                    <p>
                        {selectedPage 
                            ? `No images found for "${selectedPage}". Try selecting a different page type.` 
                            : searchTerm 
                                ? `No images match "${searchTerm}". Try a different search term.`
                                : "No images found in the library. Try refreshing or selecting a different filter."}
                    </p>
                </div>
            )}

            <div className={`image-library-content ${view}-view`}>
                {filteredImages.map((image, index) => (
                    <div
                        key={image.id || index}
                        className={`image-item ${selectedImage && selectedImage.id === image.id ? 'selected' : ''}`}
                        onClick={() => handleImageSelect(image)}
                        onDoubleClick={() => selectAndUseImage(image)}
                    >
                        <div className="image-preview">
                            {image.imageUrl ? (
                                <img 
                                    src={image.imageUrl} 
                                    alt={`${image.title || image.id || index}`}
                                    onError={handleImageError}
                                />
                            ) : (
                                <div className="no-image-placeholder">
                                    No Preview Available
                                </div>
                            )}
                        </div>
                        <div className="image-details">
                            {/* {image.id && <div className="image-id">ID: {image.id}</div>} */}
                            {image.title && <div className="image-title">{image.title}</div>}
                            {/* {image.type && <div className="image-type">{image.type}</div>} */}
                        </div>
                        <div className="image-action">
                            <button 
                                className="select-image-btn" 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    selectAndUseImage(image);
                                }}
                            >
                                Select Image
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="modal-footer">
                <button className="cancel-btn" onClick={() => onSelectImage(null)}>
                    CANCEL
                </button>
            </div>
        </div>
    );
};

ImageLibrarySelector.propTypes = {
    onSelectImage: PropTypes.func.isRequired,
};

export default ImageLibrarySelector;