// import { useEffect, useState } from "react";
// import { APIData } from "authentication/APIData"; // Import APIData
// import axios from "axios";
// import { org } from "authentication/APIData"; // Assuming org contains orgId
// import Header from "./Mheader";
// import Footers from "./Footers";

// export default function ContactUs() {
//   const [orgDetails, setOrgDetails] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const orgId = org; // Get orgId dynamically from APIData
//     console.log("Fetching data for orgId:", orgId); // Log orgId to verify it's correct

//     if (orgId) {
//       // Fetch organization details using the provided API
//       axios
//         .get(`${APIData.api}org-mdm/org-id?orgId=${orgId}`, {
//           headers: APIData.headers, // Using the headers from APIData for authorization
//         })
//         .then((response) => {
//           console.log("API Response Data:", response.data); // Log the response data
//           setOrgDetails(response.data);
//           setLoading(false);
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error); // Log any fetch errors
//           setError("Failed to fetch organization details");
//           setLoading(false);
//         });
//     } else {
//       console.error("No orgId found in APIData");
//       setError("Organization ID not found");
//       setLoading(false);
//     }
//   }, []); // Empty array to run only once when the component mounts

//   if (loading) return <p style={{ textAlign: "center", color: "gray" }}>Loading...</p>;
//   if (error) return <p style={{ textAlign: "center", color: "red" }}>{error}</p>;

//   return (
//     <>
//     <Header/>
//     <div style={{ maxWidth: "600px", margin: "0 auto", padding: "20px", background: "#fff", borderRadius: "10px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",marginTop:"100px",marginBottom:"88px" }}>
//       <h1 style={{ textAlign: "center", color: "#007BFF", fontSize: "24px", marginBottom: "10px" }}>Contact Us</h1>
//       {orgDetails ? (
//           <div style={{ textAlign: "center" }}>
//           {/* Display the organization logo */}
//           <img
//             src={orgDetails.orgLogo}
//             alt={orgDetails.orgName}
//             style={{ width: "100px", height: "auto", marginBottom: "20px", borderRadius: "10px" }}
//             />
//           <div style={{ fontSize: "18px", color: "#333", marginBottom: "10px" }}>
//             <strong>Contact Email:</strong>
//             <p>{orgDetails.orgEmailId || "Not provided"}</p>
//           </div>
//           <div style={{ fontSize: "18px", color: "#333", marginBottom: "10px" }}>
//             <strong>Contact Mobile:</strong>
//             <p>{orgDetails.orgMob || "Not provided"}</p>
//           </div>
//         </div>
//       ) : (
//         <p style={{ textAlign: "center", color: "gray" }}>No contact details available.</p>
//       )}
//     </div>
//     <Footers/>
//             </>
//   );
// }


// import { useEffect, useState } from "react";
// import { APIData } from "authentication/APIData";
// import axios from "axios";
// import { org } from "authentication/APIData";
// import Header from "./Mheader";
// import Footers from "./Footers";
// import SoftBox from "components/SoftBox";
// import SoftInput from "components/SoftInput";
// import SoftButton from "components/SoftButton";
// import SoftTypography from "components/SoftTypography";
// import { Grid, Paper, Divider, Card, Container } from "@mui/material";
// import { BsFillTelephoneFill } from "react-icons/bs";
// import { TfiEmail } from "react-icons/tfi";
// import { FaMapMarkerAlt } from "react-icons/fa";
// import { toast } from "react-toastify";
// import { useForm } from "react-hook-form";
// import PageLayout from "examples/LayoutContainers/PageLayout";
// import Navbarrr from "./Navbarrr";
// import SocialSidebar from "./SocialSidebar";

// export default function ContactUs() {
//   const [orgDetails, setOrgDetails] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const { handleSubmit, register, formState: { errors }, reset } = useForm();

//   useEffect(() => {
//     const orgId = org;
//     if (orgId) {
//       axios
//         .get(`${APIData.api}org-mdm/org-id?orgId=${orgId}`, {
//           headers: APIData.headers,
//         })
//         .then((response) => {
//           setOrgDetails(response.data);
//           setLoading(false);
//         })
//         .catch(() => {
//           setError("Failed to fetch organization details");
//           setLoading(false);
//         });
//     } else {
//       setError("Organization ID not found");
//       setLoading(false);
//     }
//   }, []);

//   const onSubmit = async (data) => {
//     try {
//       // Adding empty course and org from the API
//       const formData = {
//         ...data,
//         course: "",
//         org: org || "",
//         date: new Date().toISOString()
//       };

//       const response = await axios.post(APIData.api + 'queries/', formData, {
//         headers: APIData.headers
//       });

//       toast.success("Form submitted successfully");
//       console.log(response);
//       reset(); // Reset form after successful submission
//     } catch (error) {
//       toast.error(error.response?.data?.message || "Error submitting form");
//       console.log(error);
//     }
//   };

//   return (
//     <PageLayout>
//       <Navbarrr />
//       <div style={{ marginTop: "6rem", marginBottom: "4rem" }}>
//         <Container maxWidth="lg" sx={{ px: { xs: 3, md: 6 } }}>
//           <SoftTypography
//             variant="h4"
//             component="h1"
//             sx={{
//               fontFamily: "Roboto Slab",
//               color: "rgb(49, 143, 220)",
//               textAlign: "center",
//               mb: 5,
//               fontWeight: 600
//             }}
//           >
//             Get In Touch With Us
//           </SoftTypography>

//           {/* Top section - Form and Map with spacing */}
//           <Grid container spacing={4} justifyContent="center" mb={5}>
//             {/* Form Section */}
//             <Grid item xs={12} md={5}>
//               <Paper elevation={2} sx={{ p: 3, borderRadius: "12px", overflow: "hidden", height: "100%" }}>
//                 <SoftTypography
//                   variant="h5"
//                   component="h2"
//                   sx={{
//                     fontFamily: "Roboto Slab",
//                     color: "#444",
//                     mb: 3,
//                     fontWeight: 500
//                   }}
//                 >
//                   Send Us A Message
//                 </SoftTypography>

//                 {loading ? (
//                   <SoftTypography align="center" color="gray">Loading...</SoftTypography>
//                 ) : error ? (
//                   <SoftTypography align="center" color="error">{error}</SoftTypography>
//                 ) : (
//                   <form onSubmit={handleSubmit(onSubmit)}>
//                     <Grid container spacing={2}>
//                       <Grid item xs={12}>
//                         <SoftTypography variant="subtitle2" fontWeight="medium" mb={0.5}>
//                           Your Name
//                         </SoftTypography>
//                         <SoftInput
//                           fullWidth
//                           placeholder="Enter your full name"
//                           {...register("name", { required: 'Name is required' })}
//                           error={!!errors.name}
//                           helperText={errors.name?.message}
//                           size="medium"
//                           sx={{ mb: 1 }}
//                         />
//                       </Grid>

//                       <Grid item xs={12} sm={6}>
//                         <SoftTypography variant="subtitle2" fontWeight="medium" mb={0.5}>
//                           Email Address
//                         </SoftTypography>
//                         <SoftInput
//                           fullWidth
//                           placeholder="Enter your email"
//                           {...register("email", {
//                             required: 'Email is required',
//                             pattern: {
//                               value: /\S+@\S+\.\S+/,
//                               message: 'Invalid email address'
//                             }
//                           })}
//                           error={!!errors.email}
//                           helperText={errors.email?.message}
//                           size="medium"
//                           sx={{ mb: 1 }}
//                         />
//                       </Grid>

//                       <Grid item xs={12} sm={6}>
//                         <SoftTypography variant="subtitle2" fontWeight="medium" mb={0.5}>
//                           Phone Number
//                         </SoftTypography>
//                         <SoftInput
//                           fullWidth
//                           placeholder="Enter your phone number"
//                           {...register("phone_number", { required: 'Phone number is required' })}
//                           error={!!errors.phone_number}
//                           helperText={errors.phone_number?.message}
//                           size="medium"
//                           sx={{ mb: 1 }}
//                         />
//                       </Grid>

//                       <Grid item xs={12}>
//                         <SoftTypography variant="subtitle2" fontWeight="medium" mb={0.5}>
//                           Your Message
//                         </SoftTypography>
//                         <SoftInput
//                           fullWidth
//                           multiline
//                           rows={3}
//                           placeholder="How can we help you?"
//                           {...register("query", { required: 'Message is required' })}
//                           error={!!errors.query}
//                           helperText={errors.query?.message}
//                           size="medium"
//                           sx={{ mb: 1 }}
//                         />
//                       </Grid>

//                       <Grid item xs={12}>
//                         <SoftButton
//                           type="submit"
//                           fullWidth
//                           variant="gradient"
//                           color="info"

//                         >
//                           Send Message
//                         </SoftButton>
//                       </Grid>
//                     </Grid>
//                   </form>
//                 )}
//               </Paper>
//             </Grid>

//             {/* Map Section */}
//             <Grid item xs={12} md={5}>
//               <Paper elevation={2} sx={{ borderRadius: "12px", overflow: "hidden", height: "100%" }}>
//                 <iframe
//                   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d663961.2336582167!2d79.54083836731532!3d13.098735323087931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52639690d3dcbf%3A0x5c593873d6d7558c!2sCentre%20of%20Maths%20Education!5e0!3m2!1sen!2sin!4v1741371493744!5m2!1sen!2sin"
//                   style={{ border: 0, width: '100%', height: '100%' }}
//                   allowFullScreen=""
//                   loading="lazy"
//                   referrerPolicy="no-referrer-when-downgrade"
//                   title="Centre of Maths Education"
//                 />
//               </Paper>
//             </Grid>
//           </Grid>

//           {/* Bottom section - Contact Information */}
//           {loading ? (
//             <SoftTypography align="center" color="gray" mt={4}>Loading company details...</SoftTypography>
//           ) : error ? (
//             <SoftTypography align="center" color="error" mt={4}>{error}</SoftTypography>
//           ) : orgDetails ? (
//             <Card sx={{ maxWidth: "550px", mx: "auto", p: 4, borderRadius: "12px", boxShadow: "0 4px 20px rgba(0,0,0,0.05)" }}>
//               <SoftBox textAlign="center" mb={3}>
//                 <SoftTypography
//                   variant="h5"
//                   component="h2"
//                   sx={{
//                     fontFamily: "Roboto Slab",
//                     color: "#de1818",
//                     mb: 3,
//                     fontWeight: 500
//                   }}
//                 >
//                   Our Contact Information
//                 </SoftTypography>

//                 <SoftBox display="flex" justifyContent="center" mb={3}>
//                   <img
//                     src={orgDetails.orgLogo}
//                     alt={orgDetails.orgName}
//                     width="80px"
//                     style={{ borderRadius: "8px" }}
//                   />
//                 </SoftBox>

//                 <SoftTypography variant="h6" fontWeight="bold" mb={1}>
//                   {orgDetails.orgName}
//                 </SoftTypography>

//                 <Divider sx={{ mb: 3, width: "60%", mx: "auto" }} />

//                 <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center" gap={2}>
//                   <SoftBox display="flex" alignItems="center" justifyContent="center">
//                     <FaMapMarkerAlt size={20} style={{ color: '#de1818', marginRight: '12px' }} />
//                     <SoftTypography variant="body2" color="text">
//                       {orgDetails.orgAddress || "Address not provided"}
//                     </SoftTypography>
//                   </SoftBox>

//                   <SoftBox display="flex" alignItems="center" justifyContent="center">
//                     <BsFillTelephoneFill size={18} style={{ color: '#0B7285', marginRight: '12px' }} />
//                     <SoftTypography variant="body2">
//                       <a href={`tel:${orgDetails.orgMob}`} style={{ textDecoration: 'none', color: '#0B7285', fontWeight: 500 }}>
//                         {orgDetails.orgMob || "Phone not provided"}
//                       </a>
//                     </SoftTypography>
//                   </SoftBox>

//                   <SoftBox display="flex" alignItems="center" justifyContent="center">
//                     <TfiEmail size={18} style={{ color: '#0B7285', marginRight: '12px' }} />
//                     <SoftTypography variant="body2">
//                       <a href={`mailto:${orgDetails.orgEmailId}`} style={{ textDecoration: 'none', color: '#0B7285', fontWeight: 500 }}>
//                         {orgDetails.orgEmailId || "Email not provided"}
//                       </a>
//                     </SoftTypography>
//                   </SoftBox>

//                   {orgDetails.orgGST && (
//                     <SoftTypography variant="body2" color="text" fontWeight="medium">
//                       GST: {orgDetails.orgGST}
//                     </SoftTypography>
//                   )}
//                 </SoftBox>
//               </SoftBox>
//             </Card>
//           ) : (
//             <SoftTypography sx={{ fontFamily: "Roboto Slab", textAlign: "center", mt: 4 }}>
//               No company information available
//             </SoftTypography>
//           )}
//         </Container>
//       </div>
//       <SocialSidebar/>
//       <Footers />
//     </PageLayout>
//   );
// }


import { useEffect, useState } from "react";
import { APIData } from "authentication/APIData";
import axios from "axios";
import { org } from "authentication/APIData";
import Header from "./Mheader";
import Footers from "./Footers";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Grid, Paper, Divider, Card, Container, Skeleton } from "@mui/material";
import { BsFillTelephoneFill } from "react-icons/bs";
import { TfiEmail } from "react-icons/tfi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Navbarrr from "./Navbarrr";
import SocialSidebar from "./SocialSidebar";

export default function ContactUs() {
  const [orgDetails, setOrgDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { handleSubmit, register, formState: { errors }, reset } = useForm();

  useEffect(() => {
    const orgId = org;
    if (orgId) {
      axios
        .get(`${APIData.api}org-mdm/org-id?orgId=${orgId}`, {
          headers: APIData.headers,
        })
        .then((response) => {
          setOrgDetails(response.data);
          setLoading(false);
        })
        .catch(() => {
          setError("Failed to fetch organization details");
          setLoading(false);
        });
    } else {
      setError("Organization ID not found");
      setLoading(false);
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      // Adding empty course and org from the API
      const formData = {
        ...data,
        course: "",
        org: org || "",
        date: new Date().toISOString()
      };

      const response = await axios.post(APIData.api + 'queries/', formData, {
        headers: APIData.headers
      });

      toast.success("Form submitted successfully");
      console.log(response);
      reset(); // Reset form after successful submission
    } catch (error) {
      toast.error(error.response?.data?.message || "Error submitting form");
      console.log(error);
    }
  };

  // Form skeleton component
  const FormSkeleton = () => (
    <>
      <Skeleton variant="text" width="70%" height={32} sx={{ mb: 3 }} />
      
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="text" width="30%" height={24} sx={{ mb: 0.5 }} />
          <Skeleton variant="rounded" height={40} sx={{ mb: 2 }} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" width="40%" height={24} sx={{ mb: 0.5 }} />
          <Skeleton variant="rounded" height={40} sx={{ mb: 2 }} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" width="40%" height={24} sx={{ mb: 0.5 }} />
          <Skeleton variant="rounded" height={40} sx={{ mb: 2 }} />
        </Grid>

        <Grid item xs={12}>
          <Skeleton variant="text" width="30%" height={24} sx={{ mb: 0.5 }} />
          <Skeleton variant="rounded" height={90} sx={{ mb: 2 }} />
        </Grid>

        <Grid item xs={12}>
          <Skeleton variant="rounded" height={45} sx={{ mb: 1 }} />
        </Grid>
      </Grid>
    </>
  );

  // Contact info skeleton component
  const ContactInfoSkeleton = () => (
    <Card sx={{ maxWidth: "550px", mx: "auto", p: 4, borderRadius: "12px", boxShadow: "0 4px 20px rgba(0,0,0,0.05)" }}>
      <SoftBox textAlign="center" mb={3}>
        <Skeleton variant="text" width="60%" height={32} sx={{ mx: "auto", mb: 3 }} />
        
        <SoftBox display="flex" justifyContent="center" mb={3}>
          <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "8px" }} />
        </SoftBox>
        
        <Skeleton variant="text" width="70%" height={28} sx={{ mx: "auto", mb: 1 }} />
        
        <Divider sx={{ mb: 3, width: "60%", mx: "auto" }} />
        
        <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Skeleton variant="text" width="80%" height={24} sx={{ mb: 1 }} />
          <Skeleton variant="text" width="50%" height={24} sx={{ mb: 1 }} />
          <Skeleton variant="text" width="70%" height={24} sx={{ mb: 1 }} />
          <Skeleton variant="text" width="40%" height={24} />
        </SoftBox>
      </SoftBox>
    </Card>
  );

  return (
    <PageLayout>
      <Navbarrr />
      <div style={{ marginTop: "6rem", marginBottom: "4rem" }}>
        <Container maxWidth="lg" sx={{ px: { xs: 3, md: 6 } }}>
          <SoftTypography
            variant="h4"
            component="h1"
            sx={{
              fontFamily: "Roboto Slab",
              color: "rgb(49, 143, 220)",
              textAlign: "center",
              mb: 5,
              fontWeight: 600
            }}
          >
            Get In Touch With Us
          </SoftTypography>

          {/* Top section - Form and Map with spacing */}
          <Grid container spacing={4} justifyContent="center" mb={5}>
            {/* Form Section */}
            <Grid item xs={12} md={5}>
              <Paper elevation={2} sx={{ p: 3, borderRadius: "12px", overflow: "hidden", height: "100%" }}>
                <SoftTypography
                  variant="h5"
                  component="h2"
                  sx={{
                    fontFamily: "Roboto Slab",
                    color: "#444",
                    mb: 3,
                    fontWeight: 500
                  }}
                >
                  Send Us A Message
                </SoftTypography>

                {loading ? (
                  <FormSkeleton />
                ) : error ? (
                  <SoftTypography align="center" color="error">{error}</SoftTypography>
                ) : (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <SoftTypography variant="subtitle2" fontWeight="medium" mb={0.5}>
                          Your Name
                        </SoftTypography>
                        <SoftInput
                          fullWidth
                          placeholder="Enter your full name"
                          {...register("name", { required: 'Name is required' })}
                          error={!!errors.name}
                          helperText={errors.name?.message}
                          size="medium"
                          sx={{ mb: 1 }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <SoftTypography variant="subtitle2" fontWeight="medium" mb={0.5}>
                          Email Address
                        </SoftTypography>
                        <SoftInput
                          fullWidth
                          placeholder="Enter your email"
                          {...register("email", {
                            required: 'Email is required',
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: 'Invalid email address'
                            }
                          })}
                          error={!!errors.email}
                          helperText={errors.email?.message}
                          size="medium"
                          sx={{ mb: 1 }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <SoftTypography variant="subtitle2" fontWeight="medium" mb={0.5}>
                          Phone Number
                        </SoftTypography>
                        <SoftInput
                          fullWidth
                          placeholder="Enter your phone number"
                          {...register("phone_number", { required: 'Phone number is required' })}
                          error={!!errors.phone_number}
                          helperText={errors.phone_number?.message}
                          size="medium"
                          sx={{ mb: 1 }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <SoftTypography variant="subtitle2" fontWeight="medium" mb={0.5}>
                          Your Message
                        </SoftTypography>
                        <SoftInput
                          fullWidth
                          multiline
                          rows={3}
                          placeholder="How can we help you?"
                          {...register("query", { required: 'Message is required' })}
                          error={!!errors.query}
                          helperText={errors.query?.message}
                          size="medium"
                          sx={{ mb: 1 }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <SoftButton
                          type="submit"
                          fullWidth
                          variant="gradient"
                          color="info"
                        >
                          Send Message
                        </SoftButton>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Paper>
            </Grid>

            {/* Map Section */}
            <Grid item xs={12} md={5}>
              <Paper elevation={2} sx={{ borderRadius: "12px", overflow: "hidden", height: "100%" }}>
                {loading ? (
                  <Skeleton variant="rectangular" width="100%" height="100%" animation="wave" />
                ) : (
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.629718213302!2d77.559811575078!3d13.059225387264263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae192611b2699f%3A0x2b9c835a916aaa99!2sAvinya%20Academy%20Powered%20by%20Care%204%20Edu%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1743087827179!5m2!1sen!2sin"
                    style={{ border: 0, width: '100%', height: '100%' }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Care4Edu"
                  />
                )}
              </Paper>
            </Grid>
          </Grid>

          {/* Bottom section - Contact Information */}
          {loading ? (
            <ContactInfoSkeleton />
          ) : error ? (
            <SoftTypography align="center" color="error" mt={4}>{error}</SoftTypography>
          ) : orgDetails ? (
            <Card sx={{ maxWidth: "550px", mx: "auto", p: 4, borderRadius: "12px", boxShadow: "0 4px 20px rgba(0,0,0,0.05)" }}>
              <SoftBox textAlign="center" mb={3}>
                <SoftTypography
                  variant="h5"
                  component="h2"
                  sx={{
                    fontFamily: "Roboto Slab",
                    color: "#de1818",
                    mb: 3,
                    fontWeight: 500
                  }}
                >
                  Our Contact Information
                </SoftTypography>

                <SoftBox display="flex" justifyContent="center" mb={3}>
                  <img
                    src={orgDetails.orgLogo}
                    alt={orgDetails.orgName}
                    width="80px"
                    style={{ borderRadius: "8px" }}
                  />
                </SoftBox>

                <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                  {orgDetails.orgName}
                </SoftTypography>

                <Divider sx={{ mb: 3, width: "60%", mx: "auto" }} />

                <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center" gap={2}>
                  <SoftBox display="flex" alignItems="center" justifyContent="center">
                    <FaMapMarkerAlt size={20} style={{ color: '#de1818', marginRight: '12px' }} />
                    <SoftTypography variant="body2" color="text">
                      {orgDetails.orgAddress || "Address not provided"}
                    </SoftTypography>
                  </SoftBox>

                  <SoftBox display="flex" alignItems="center" justifyContent="center">
                    <BsFillTelephoneFill size={18} style={{ color: '#0B7285', marginRight: '12px' }} />
                    <SoftTypography variant="body2">
                      <a href={`tel:${orgDetails.orgMob}`} style={{ textDecoration: 'none', color: '#0B7285', fontWeight: 500 }}>
                        {orgDetails.orgMob || "Phone not provided"}
                      </a>
                    </SoftTypography>
                  </SoftBox>

                  <SoftBox display="flex" alignItems="center" justifyContent="center">
                    <TfiEmail size={18} style={{ color: '#0B7285', marginRight: '12px' }} />
                    <SoftTypography variant="body2">
                      <a href={`mailto:${orgDetails.orgEmailId}`} style={{ textDecoration: 'none', color: '#0B7285', fontWeight: 500 }}>
                        {orgDetails.orgEmailId || "Email not provided"}
                      </a>
                    </SoftTypography>
                  </SoftBox>

                  {orgDetails.orgGST && (
                    <SoftTypography variant="body2" color="text" fontWeight="medium">
                      GST: {orgDetails.orgGST}
                    </SoftTypography>
                  )}
                </SoftBox>
              </SoftBox>
            </Card>
          ) : (
            <SoftTypography sx={{ fontFamily: "Roboto Slab", textAlign: "center", mt: 4 }}>
              No company information available
            </SoftTypography>
          )}
        </Container>
      </div>
      <SocialSidebar/>
      <Footers />
    </PageLayout>
  );
}