// import React, { useState, useEffect } from 'react';
// import {
//     Box,
//     Container,
//     Typography,
//     Grid,
//     Card,
//     CircularProgress
// } from '@mui/material';
// import { Link } from 'react-router-dom';
// import SoftBox from 'components/SoftBox';
// import SoftTypography from 'components/SoftTypography';
// import SoftButton from 'components/SoftButton';
// import SocialSidebar from './SocialSidebar';
// import { APIData } from 'authentication/APIData';
// import { org } from 'authentication/APIData';
// import Navbarrr from './Navbarrr';
// import Footers from './Footers';
// import PageLayout from 'examples/LayoutContainers/PageLayout';
// import Aos from 'aos';
// import 'aos/dist/aos.css';

// const ServicesDisplay = () => {
//     const [services, setServices] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     // Define colors that were previously in the theme
//     const colors = {
//         primary: {
//             main: '#1e90ff',
//             light: '#4da6ff',
//             dark: '#0066cc',
//         },
//         secondary: {
//             main: '#6a0dad',
//             light: '#8c2be0',
//             dark: '#4b0082',
//         },
//         background: {
//             default: '#f8f9fa',
//             paper: '#ffffff',
//         },
//         text: {
//             primary: '#333333',
//             secondary: '#555555',
//         },
//     };

//     useEffect(() => {
//         // Initialize AOS with a small delay
//         Aos.init({delay: 100});
        
//         const fetchServices = async () => {
//             setLoading(true);
//             try {
//                 const response = await fetch(`${APIData.api}services/by-org?org=${org}`, {
//                     method: 'GET',
//                     headers: APIData.headers
//                 });
//                 const data = await response.json();
//                 setServices(Array.isArray(data) ? data : [data]); // Ensure we always have an array
//                 setError(null);
//             } catch (error) {
//                 console.error('Error fetching services:', error);
//                 setError(error.message);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchServices();
//     }, []);

//     if (loading) {
//         return (
//             <PageLayout>
//                 <SoftBox
//                     display="flex"
//                     justifyContent="center"
//                     alignItems="center"
//                     minHeight="calc(100vh - 100px)"
//                     // className="overflow-hidden"
//                 >
//                     <CircularProgress />
//                     <SoftTypography variant="h6" ml={2}>
//                         Loading...
//                     </SoftTypography>
//                 </SoftBox>
//                 <Footers />
//             </PageLayout>
//         );
//     }

//     return (
//         <PageLayout>
//             <SoftBox
//                 display="flex"
//                 flexDirection="column"
//                 minHeight="calc(100vh - 100px)"
//                 className="overflow-y-auto"
//                 sx={{ 
//                     flexGrow: 1, 
//                     backgroundColor: colors.background.default,
//                     fontFamily: "Roboto Slab",
//                     pt: 0 
//                 }}
//             >
//                 <div className="cadvin" style={{ fontFamily: "Roboto Slab" }}>
//                     <br />
//                     <Typography
//                         variant="h3"
//                         fontWeight="bold"
//                         textAlign="center"
//                         color="#0C8599"
//                         style={{ fontFamily: "Roboto Slab" }}
//                     >
//                         Our Services
//                     </Typography>

//                     {error ? (
//                         <SoftBox display="flex" flexDirection="column" alignItems="center" p={3}>
//                             <SoftTypography
//                                 variant="h4"
//                                 color="error"
//                                 style={{
//                                     fontFamily: "Georgia, serif",
//                                     fontWeight: "bold",
//                                     fontSize: "50px"
//                                 }}
//                             >
//                                 Error Loading Services
//                             </SoftTypography>
//                             <SoftTypography 
//                                 variant="body1" 
//                                 color="error" 
//                                 align="center" 
//                                 mt={2} 
//                                 mb={3}
//                             >
//                                 {error}
//                             </SoftTypography>
//                             <SoftButton 
//                                 variant="contained" 
//                                 color="info" 
//                                 onClick={() => window.location.reload()}
//                             >
//                                 Retry
//                             </SoftButton>
//                         </SoftBox>
//                     ) : services.length === 0 ? (
//                         <SoftBox 
//                             display="flex" 
//                             flexDirection="column" 
//                             alignItems="center" 
//                             justifyContent="center"
//                             flex={1} 
//                             sx={{
//                                 flexGrow: 1,
//                                 minHeight: 'calc(100vh - 200px)',
//                                 display: 'flex',
//                                 flexDirection: 'column',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 textAlign: 'center',
//                                 padding: '0 20px'
//                             }}
//                         >
//                             <SoftTypography
//                                 variant="h4"
//                                 color="info"
//                                 style={{
//                                     fontFamily: "Georgia, serif",
//                                     fontWeight: "bold",
//                                     fontSize: "25px",
//                                     color: '#00bfff'
//                                 }}
//                             >
//                                 Currently, we are not offering any services. 
//                             </SoftTypography>
//                             <Link to='/' style={{ textDecoration: 'none', marginTop: '20px' }}>
//                                 <SoftButton 
//                                     variant="contained" 
//                                     color="info"
//                                     sx={{
//                                         backgroundColor: '#00bfff',
//                                         '&:hover': {
//                                             backgroundColor: '#1e90ff'
//                                         }
//                                     }}
//                                 >
//                                     Go Back
//                                 </SoftButton>
//                             </Link>
//                         </SoftBox>
//                     ) : (
//                         <div className="images">
//                             {services.map((service, index) => (
//                                 <div key={service.id} style={{ margin: "20px" }}>
//                                     <Container>
//                                         <Grid container spacing={4} direction={index % 2 === 0 ? 'row' : 'row-reverse'}>
//                                             {/* Image Section */}
//                                             <Grid 
//                                                 item 
//                                                 xs={12} 
//                                                 md={4} 
//                                                 style={{
//                                                     display: "flex",
//                                                     justifyContent: "center",
//                                                     alignItems: "center",
//                                                 }}
//                                                 data-aos="zoom-in"
//                                             >
//                                                 <img 
//                                                     src={service.image} 
//                                                     width="100%" 
//                                                     alt={service.name} 
//                                                     height="150" 
//                                                     style={{ objectFit: "contain" }}
//                                                 />
//                                             </Grid>

//                                             {/* Content Section */}
//                                             <Grid item xs={12} md={8}>
//                                                 <Typography
//                                                     variant="h6"
//                                                     style={{ fontFamily: "Roboto Slab" }}
//                                                     color="#0C8599"
//                                                 >
//                                                     {service.name}
//                                                 </Typography>
//                                                 <br />
//                                                 <Typography 
//                                                     style={{ 
//                                                         fontFamily: "Roboto Slab",
//                                                         fontSize: "0.95rem"
//                                                     }}
//                                                 >
//                                                     {service.description}
//                                                 </Typography>
//                                             </Grid>
//                                             <hr style={{ width: "100vw", border: "1px solid gray" }} />
//                                         </Grid>
//                                     </Container>
//                                 </div>
//                             ))}
//                         </div>
//                     )}
//                 </div>
//             </SoftBox>
//         </PageLayout>
//     );
// };

// export default ServicesDisplay;


import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Typography,
    Grid,
    Card,
    CircularProgress,
    Skeleton
} from '@mui/material';
import { Link } from 'react-router-dom';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import SocialSidebar from './SocialSidebar';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import Navbarrr from './Navbarrr';
import Footers from './Footers';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import Aos from 'aos';
import 'aos/dist/aos.css';
import PropTypes from 'prop-types';

const ServicesDisplay = () => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Define colors that were previously in the theme
    const colors = {
        primary: {
            main: '#1e90ff',
            light: '#4da6ff',
            dark: '#0066cc',
        },
        secondary: {
            main: '#6a0dad',
            light: '#8c2be0',
            dark: '#4b0082',
        },
        background: {
            default: '#f8f9fa',
            paper: '#ffffff',
        },
        text: {
            primary: '#333333',
            secondary: '#555555',
        },
    };

    useEffect(() => {
        // Initialize AOS with a small delay
        Aos.init({delay: 100});
        
        const fetchServices = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${APIData.api}services/by-org?org=${org}`, {
                    method: 'GET',
                    headers: APIData.headers
                });
                const data = await response.json();
                setServices(Array.isArray(data) ? data : [data]); // Ensure we always have an array
                setError(null);
            } catch (error) {
                console.error('Error fetching services:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    // Service item skeleton component
    const ServiceSkeleton = ({ index }) => (
        <div style={{ margin: "20px" }}>
            <Container>
                <Grid container spacing={4} direction={index % 2 === 0 ? 'row' : 'row-reverse'}>
                    {/* Image Section Skeleton */}
                    <Grid 
                        item 
                        xs={12} 
                        md={4} 
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Skeleton 
                            variant="rectangular" 
                            width="100%" 
                            height={150} 
                            animation="wave" 
                            sx={{ borderRadius: '4px' }}
                        />
                    </Grid>

                    {/* Content Section Skeleton */}
                    <Grid item xs={12} md={8}>
                        <Skeleton 
                            variant="text" 
                            height={32} 
                            width="70%" 
                            animation="wave" 
                            sx={{ mb: 2 }}
                        />
                        <Skeleton variant="text" height={20} animation="wave" />
                        <Skeleton variant="text" height={20} animation="wave" />
                        <Skeleton variant="text" height={20} animation="wave" />
                        <Skeleton variant="text" height={20} width="80%" animation="wave" />
                    </Grid>
                    <hr style={{ width: "100vw", border: "1px solid gray" }} />
                </Grid>
            </Container>
        </div>
    );

    ServiceSkeleton.propTypes = {
        index: PropTypes.number.isRequired,
    };
    return (
        <PageLayout>
            <SoftBox
                display="flex"
                flexDirection="column"
                minHeight="calc(100vh - 100px)"
                className="overflow-y-auto"
                sx={{ 
                    flexGrow: 1, 
                    backgroundColor: colors.background.default,
                    fontFamily: "Roboto Slab",
                    pt: 0 
                }}
            >
                <div className="cadvin" style={{ fontFamily: "Roboto Slab" }}>
                    <br />
                    {loading ? (
                        <Skeleton 
                            variant="text" 
                            height={60} 
                            width="250px" 
                            animation="wave" 
                            sx={{ mx: 'auto', mb: 4 }} 
                        />
                    ) : (
                        <Typography
                            variant="h3"
                            fontWeight="bold"
                            textAlign="center"
                            color="#0C8599"
                            style={{ fontFamily: "Roboto Slab" }}
                        >
                            Our Services
                        </Typography>
                    )}

                    {loading ? (
                        // Display skeleton services when loading
                        <div className="images">
                            {[1, 2, 3].map((_, index) => (
                                <ServiceSkeleton key={index} index={index} />
                            ))}
                        </div>
                    ) : error ? (
                        <SoftBox display="flex" flexDirection="column" alignItems="center" p={3}>
                            <SoftTypography
                                variant="h4"
                                color="error"
                                style={{
                                    fontFamily: "Georgia, serif",
                                    fontWeight: "bold",
                                    fontSize: "50px"
                                }}
                            >
                                Error Loading Services
                            </SoftTypography>
                            <SoftTypography 
                                variant="body1" 
                                color="error" 
                                align="center" 
                                mt={2} 
                                mb={3}
                            >
                                {error}
                            </SoftTypography>
                            <SoftButton 
                                variant="contained" 
                                color="info" 
                                onClick={() => window.location.reload()}
                            >
                                Retry
                            </SoftButton>
                        </SoftBox>
                    ) : services.length === 0 ? (
                        <SoftBox 
                            display="flex" 
                            flexDirection="column" 
                            alignItems="center" 
                            justifyContent="center"
                            flex={1} 
                            sx={{
                                flexGrow: 1,
                                minHeight: 'calc(100vh - 200px)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                padding: '0 20px'
                            }}
                        >
                            <SoftTypography
                                variant="h4"
                                color="info"
                                style={{
                                    fontFamily: "Georgia, serif",
                                    fontWeight: "bold",
                                    fontSize: "25px",
                                    color: '#00bfff'
                                }}
                            >
                                Currently, we are not offering any services. 
                            </SoftTypography>
                            <Link to='/' style={{ textDecoration: 'none', marginTop: '20px' }}>
                                <SoftButton 
                                    variant="contained" 
                                    color="info"
                                    sx={{
                                        backgroundColor: '#00bfff',
                                        '&:hover': {
                                            backgroundColor: '#1e90ff'
                                        }
                                    }}
                                >
                                    Go Back
                                </SoftButton>
                            </Link>
                        </SoftBox>
                    ) : (
                        <div className="images">
                            {services.map((service, index) => (
                                <div key={service.id} style={{ margin: "20px" }}>
                                    <Container>
                                        <Grid container spacing={4} direction={index % 2 === 0 ? 'row' : 'row-reverse'}>
                                            {/* Image Section */}
                                            <Grid 
                                                item 
                                                xs={12} 
                                                md={4} 
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                                data-aos="zoom-in"
                                            >
                                                <img 
                                                    src={service.image} 
                                                    width="100%" 
                                                    alt={service.name} 
                                                    height="150" 
                                                    style={{ objectFit: "contain" }}
                                                />
                                            </Grid>

                                            {/* Content Section */}
                                            <Grid item xs={12} md={8}>
                                                <Typography
                                                    variant="h6"
                                                    style={{ fontFamily: "Roboto Slab" }}
                                                    color="#0C8599"
                                                >
                                                    {service.name}
                                                </Typography>
                                                <br />
                                                <Typography 
                                                    style={{ 
                                                        fontFamily: "Roboto Slab",
                                                        fontSize: "0.95rem"
                                                    }}
                                                >
                                                    {service.description}
                                                </Typography>
                                            </Grid>
                                            <hr style={{ width: "100vw", border: "1px solid gray" }} />
                                        </Grid>
                                    </Container>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </SoftBox>
            
        </PageLayout>
    );
};

export default ServicesDisplay;