// import React, { useState, useEffect, useRef } from 'react';
// import {
//     Card,
//     CardContent,
//     CardMedia,
//     Typography,
//     IconButton,
//     Box,
//     Container,
//     Grid
// } from '@mui/material';
// import { ChevronLeft, ChevronRight } from '@mui/icons-material';
// import PageLayout from 'examples/LayoutContainers/PageLayout';
// import DefaultBlogCard from 'examples/Cards/BlogCards/DefaultBlogCard';
// import { org } from 'authentication/APIData';
// import { APIData } from 'authentication/APIData';
// import Footers from './Footers';
// import Navbarrr from './Navbarrr';

// const styles = {
//     scrollContainer: {
//         position: 'relative',
//         marginBottom: '50px',
//     },
//     scrollContent: {
//         display: 'flex',
//         overflowX: 'hidden',
//         scrollBehavior: 'smooth',
//         padding: '0 40px',
//         gap: '16px',
//     },
//     scrollCard: {
//         flexShrink: 0,
//         width: '250px',
//         height: '250px',
//         cursor: 'pointer',
//         transition: 'box-shadow 0.3s ease',
//         '&:hover': {
//             boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
//         },
//     },
//     scrollButton: {
//         position: 'absolute',
//         top: '50%',
//         transform: 'translateY(-50%)',
//         zIndex: 1,
//         backgroundColor: '#fff',
//         boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//         '&:hover': {
//             backgroundColor: '#f5f5f5',
//         },
//     },
//     leftButton: {
//         left: 0,
//     },
//     rightButton: {
//         right: 0,
//     },
//     cardMedia: {
//         height: '150px',
//     },
//     cardContent: {
//         padding: '8px',
//         '&:last-child': {
//             paddingBottom: '8px',
//         },
//     },
//     mainContent: {
//         padding: '24px 0',
//     },
//     title: {
//         marginBottom: '32px',
//         fontWeight: 'bold',
//     },
// };

// const Blog = () => {
//     const [blogPosts, setBlogPosts] = useState([]);
//     const [selectedPost, setSelectedPost] = useState(null);
//     const scrollContainerRef = useRef(null);

//     useEffect(() => {
//         const fetchBlogPosts = async () => {
//             try {
//                 const response = await fetch(`${APIData.api}blog/details/org/${org}`, {
//                     method: 'GET',
//                     headers: APIData.headers
//                 });
//                 const data = await response.json();

//                 // Filter only published blogs and sort by publishedDate (latest first)
//                 const filteredAndSortedBlogs = data
//                     .filter((post) => post.published) // Only include published posts
//                     .sort((a, b) => new Date(...b.publishedDate) - new Date(...a.publishedDate));

//                 setBlogPosts(filteredAndSortedBlogs);
                
//                 // Check if there's a selected post ID in localStorage
//                 const selectedPostId = localStorage.getItem('selectedBlogPostId');
//                 if (selectedPostId) {
//                     const selectedBlogPost = filteredAndSortedBlogs.find(post => post.id === selectedPostId);
//                     if (selectedBlogPost) {
//                         setSelectedPost(selectedBlogPost);
//                         // Clear the selected post ID from localStorage
//                         localStorage.removeItem('selectedBlogPostId');
                        
//                         // Scroll to the selected post details
//                         setTimeout(() => {
//                             const selectedPostElement = document.getElementById('selected-post-details');
//                             if (selectedPostElement) {
//                                 selectedPostElement.scrollIntoView({ behavior: 'smooth' });
//                             }
//                         }, 300);
//                     }
//                 }
//             } catch (error) {
//                 console.error('Error fetching blog posts:', error);
//             }
//         };

//         fetchBlogPosts();
//     }, []);

//     const scroll = (direction) => {
//         if (scrollContainerRef.current) {
//             const scrollAmount = 280;
//             const container = scrollContainerRef.current;
//             const newPosition = container.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);
//             container.scrollTo({
//                 left: newPosition,
//                 behavior: 'smooth'
//             });
//         }
//     };

//     const handleCardClick = (post) => {
//         setSelectedPost(post);
//         // After setting the selected post, scroll to its details
//         setTimeout(() => {
//             const selectedPostElement = document.getElementById('selected-post-details');
//             if (selectedPostElement) {
//                 selectedPostElement.scrollIntoView({ behavior: 'smooth' });
//             }
//         }, 100);
//     };

//     return (
//         <PageLayout>
//             <Navbarrr />
//             <Container maxWidth="lg" sx={{ marginBottom: "70px" }}>
//                 <Box display="flex" alignItems="center" gap={2} sx={{ marginTop: "20px" }}>
//                     <IconButton onClick={() => window.location.href = "/"} sx={{ color: "black" }}>
//                         <ChevronLeft />
//                     </IconButton>
//                     <Typography variant="h2" sx={styles.title} style={{ textAlign: "center", marginTop: "80px" }}>
//                         All Blog Posts
//                     </Typography>
//                 </Box>
//                 <Box sx={styles.scrollContainer}>
//                     <IconButton
//                         onClick={() => scroll('left')}
//                         sx={{ ...styles.scrollButton, ...styles.leftButton }}
//                     >
//                         <ChevronLeft />
//                     </IconButton>

//                     <Box
//                         ref={scrollContainerRef}
//                         sx={styles.scrollContent}
//                     >
//                         {blogPosts.map((post) => (
//                             <Card
//                                 key={post.id}
//                                 sx={styles.scrollCard}
//                                 onClick={() => handleCardClick(post)}
//                             >
//                                 <CardMedia
//                                     component="img"
//                                     image={post.featuredImage}
//                                     alt={post.title}
//                                     sx={styles.cardMedia}
//                                 />
//                                 <CardContent sx={styles.cardContent}>
//                                     <Typography noWrap variant="subtitle2">
//                                         {post.title}
//                                     </Typography>
//                                     <Typography noWrap variant="caption" color="text.secondary">
//                                         {post.author}
//                                     </Typography>
//                                 </CardContent>
//                             </Card>
//                         ))}
//                     </Box>

//                     <IconButton
//                         onClick={() => scroll('right')}
//                         sx={{ ...styles.scrollButton, ...styles.rightButton }}
//                     >
//                         <ChevronRight />
//                     </IconButton>
//                 </Box>

//                 {selectedPost && (
//                     <Box mb={6} id="selected-post-details">
//                         <DefaultBlogCard
//                             image={selectedPost.featuredImage}
//                             category={{
//                                 color: 'primary',
//                                 label: selectedPost.org,
//                             }}
//                             title={selectedPost.title}
//                             description={selectedPost.content}
//                             author={{
//                                 name: selectedPost.author,
//                                 date: selectedPost.publishedDate,
//                             }}
//                             action={{
//                                 type: 'internal',
//                             }}
//                         />
//                     </Box>
//                 )}

//                 <Typography variant="h4" sx={styles.title}>
//                     Latest Blog Posts
//                 </Typography>

//                 <Grid container spacing={3}>
//                     {blogPosts.map((post) => (
//                         <Grid item xs={12} md={6} lg={4} key={post.id}>
//                             <DefaultBlogCard
//                                 image={post.featuredImage}
//                                 category={{
//                                     color: 'primary',
//                                     label: post.org,
//                                 }}
//                                 title={post.title}
//                                 description={post.content}
//                                 author={{
//                                     name: post.author,
//                                     date: post.publishedDate,
//                                 }}
//                                 action={{
//                                     type: 'internal',
//                                 }}
//                             />
//                         </Grid>
//                     ))}
//                 </Grid>
//             </Container>
//             <Footers />
//         </PageLayout>
//     );
// };

// export default Blog;


import React, { useState, useEffect, useRef } from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    IconButton,
    Box,
    Container,
    Grid,
    Skeleton
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import DefaultBlogCard from 'examples/Cards/BlogCards/DefaultBlogCard';
import { org } from 'authentication/APIData';
import { APIData } from 'authentication/APIData';
import Footers from './Footers';
import Navbarrr from './Navbarrr';

const styles = {
    scrollContainer: {
        position: 'relative',
        marginBottom: '50px',
    },
    scrollContent: {
        display: 'flex',
        overflowX: 'hidden',
        scrollBehavior: 'smooth',
        padding: '0 40px',
        gap: '16px',
    },
    scrollCard: {
        flexShrink: 0,
        width: '250px',
        height: '250px',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        },
    },
    scrollButton: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
        backgroundColor: '#fff',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
    },
    leftButton: {
        left: 0,
    },
    rightButton: {
        right: 0,
    },
    cardMedia: {
        height: '150px',
    },
    cardContent: {
        padding: '8px',
        '&:last-child': {
            paddingBottom: '8px',
        },
    },
    mainContent: {
        padding: '24px 0',
    },
    title: {
        marginBottom: '32px',
        fontWeight: 'bold',
    },
};

const Blog = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        const fetchBlogPosts = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${APIData.api}blog/details/org/${org}`, {
                    method: 'GET',
                    headers: APIData.headers
                });
                const data = await response.json();

                // Filter only published blogs and sort by publishedDate (latest first)
                const filteredAndSortedBlogs = data
                    .filter((post) => post.published) // Only include published posts
                    .sort((a, b) => new Date(...b.publishedDate) - new Date(...a.publishedDate));

                setBlogPosts(filteredAndSortedBlogs);
                
                // Check if there's a selected post ID in localStorage
                const selectedPostId = localStorage.getItem('selectedBlogPostId');
                if (selectedPostId) {
                    const selectedBlogPost = filteredAndSortedBlogs.find(post => post.id === selectedPostId);
                    if (selectedBlogPost) {
                        setSelectedPost(selectedBlogPost);
                        // Clear the selected post ID from localStorage
                        localStorage.removeItem('selectedBlogPostId');
                        
                        // Scroll to the selected post details
                        setTimeout(() => {
                            const selectedPostElement = document.getElementById('selected-post-details');
                            if (selectedPostElement) {
                                selectedPostElement.scrollIntoView({ behavior: 'smooth' });
                            }
                        }, 300);
                    }
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching blog posts:', error);
                setLoading(false);
            }
        };

        fetchBlogPosts();
    }, []);

    const scroll = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = 280;
            const container = scrollContainerRef.current;
            const newPosition = container.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);
            container.scrollTo({
                left: newPosition,
                behavior: 'smooth'
            });
        }
    };

    const handleCardClick = (post) => {
        setSelectedPost(post);
        // After setting the selected post, scroll to its details
        setTimeout(() => {
            const selectedPostElement = document.getElementById('selected-post-details');
            if (selectedPostElement) {
                selectedPostElement.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    // Skeleton for horizontal scroll cards
    const ScrollCardSkeleton = () => (
        <>
            {[1, 2, 3, 4, 5, 6].map((item) => (
                <Card key={item} sx={styles.scrollCard}>
                    <Skeleton variant="rectangular" sx={styles.cardMedia} animation="wave" />
                    <CardContent sx={styles.cardContent}>
                        <Skeleton variant="text" width="80%" height={24} />
                        <Skeleton variant="text" width="60%" height={20} />
                    </CardContent>
                </Card>
            ))}
        </>
    );

    // Skeleton for featured blog post
    const FeaturedPostSkeleton = () => (
        <Box mb={6}>
            <Card>
                <Skeleton variant="rectangular" height={300} animation="wave" />
                <CardContent>
                    <Skeleton variant="text" width="20%" height={24} sx={{ mb: 1 }} />
                    <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
                    <Skeleton variant="text" width="100%" height={20} />
                    <Skeleton variant="text" width="100%" height={20} />
                    <Skeleton variant="text" width="100%" height={20} />
                    <Skeleton variant="text" width="80%" height={20} />
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <Skeleton variant="circular" width={40} height={40} sx={{ mr: 2 }} />
                        <Box>
                            <Skeleton variant="text" width={100} height={20} />
                            <Skeleton variant="text" width={140} height={16} />
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );

    // Skeleton for blog grid items
    const BlogGridSkeleton = () => (
        <Grid container spacing={3}>
            {[1, 2, 3, 4, 5, 6].map((item) => (
                <Grid item xs={12} md={6} lg={4} key={item}>
                    <Card>
                        <Skeleton variant="rectangular" height={200} animation="wave" />
                        <CardContent>
                            <Skeleton variant="text" width="30%" height={24} sx={{ mb: 1 }} />
                            <Skeleton variant="text" width="70%" height={32} sx={{ mb: 2 }} />
                            <Skeleton variant="text" width="100%" height={20} />
                            <Skeleton variant="text" width="100%" height={20} />
                            <Skeleton variant="text" width="60%" height={20} />
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                <Skeleton variant="circular" width={40} height={40} sx={{ mr: 2 }} />
                                <Box>
                                    <Skeleton variant="text" width={80} height={20} />
                                    <Skeleton variant="text" width={120} height={16} />
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    return (
        <PageLayout>
            <Navbarrr />
            <Container maxWidth="lg" sx={{ marginBottom: "70px" }}>
                <Box display="flex" alignItems="center" gap={2} sx={{ marginTop: "20px" }}>
                    <IconButton onClick={() => window.location.href = "/"} sx={{ color: "black" }}>
                        <ChevronLeft />
                    </IconButton>
                    <Typography variant="h2" sx={styles.title} style={{ textAlign: "center", marginTop: "80px" }}>
                        All Blog Posts
                    </Typography>
                </Box>
                <Box sx={styles.scrollContainer}>
                    <IconButton
                        onClick={() => scroll('left')}
                        sx={{ ...styles.scrollButton, ...styles.leftButton }}
                    >
                        <ChevronLeft />
                    </IconButton>

                    <Box
                        ref={scrollContainerRef}
                        sx={styles.scrollContent}
                    >
                        {loading ? (
                            <ScrollCardSkeleton />
                        ) : (
                            blogPosts.map((post) => (
                                <Card
                                    key={post.id}
                                    sx={styles.scrollCard}
                                    onClick={() => handleCardClick(post)}
                                >
                                    <CardMedia
                                        component="img"
                                        image={post.featuredImage}
                                        alt={post.title}
                                        sx={styles.cardMedia}
                                    />
                                    <CardContent sx={styles.cardContent}>
                                        <Typography noWrap variant="subtitle2">
                                            {post.title}
                                        </Typography>
                                        <Typography noWrap variant="caption" color="text.secondary">
                                            {post.author}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            ))
                        )}
                    </Box>

                    <IconButton
                        onClick={() => scroll('right')}
                        sx={{ ...styles.scrollButton, ...styles.rightButton }}
                    >
                        <ChevronRight />
                    </IconButton>
                </Box>

                {loading ? (
                    selectedPost && <FeaturedPostSkeleton />
                ) : (
                    selectedPost && (
                        <Box mb={6} id="selected-post-details">
                            <DefaultBlogCard
                                image={selectedPost.featuredImage}
                                category={{
                                    color: 'primary',
                                    label: selectedPost.org,
                                }}
                                title={selectedPost.title}
                                description={selectedPost.content}
                                author={{
                                    name: selectedPost.author,
                                    date: selectedPost.publishedDate,
                                }}
                                action={{
                                    type: 'internal',
                                }}
                            />
                        </Box>
                    )
                )}

                <Typography variant="h4" sx={styles.title}>
                    Latest Blog Posts
                </Typography>

                {loading ? (
                    <BlogGridSkeleton />
                ) : (
                    <Grid container spacing={3}>
                        {blogPosts.map((post) => (
                            <Grid item xs={12} md={6} lg={4} key={post.id}>
                                <DefaultBlogCard
                                    image={post.featuredImage}
                                    category={{
                                        color: 'primary',
                                        label: post.org,
                                    }}
                                    title={post.title}
                                    description={post.content}
                                    author={{
                                        name: post.author,
                                        date: post.publishedDate,
                                    }}
                                    action={{
                                        type: 'internal',
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Container>
            <Footers />
        </PageLayout>
    );
};

export default Blog;