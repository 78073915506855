// import { useEffect, useState } from "react";
// import { APIData } from "authentication/APIData"; // Import APIData
// import axios from "axios";
// import { org } from "authentication/APIData"; // Assuming `org` is your orgId
// import Header from "./Mheader";
// import Footers from "./Footers";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftAvatar from "components/SoftAvatar";

// export default function AboutUs() {
//   const [orgDetails, setOrgDetails] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const orgId = org; // Get orgId dynamically from APIData
//     console.log("Fetching data for orgId:", orgId); // Log orgId to verify it's correct

//     if (orgId) {
//       // Fetch organization details using the provided API
//       axios
//         .get(`${APIData.api}org-mdm/org-id?orgId=${orgId}`, {
//           headers: APIData.headers, // Using the headers from APIData for authorization
//         })
//         .then((response) => {
//           console.log("API Response Data:", response.data); // Log the response data
//           setOrgDetails(response.data);
//           setLoading(false);
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error); // Log any fetch errors
//           setError("Failed to fetch organization details");
//           setLoading(false);
//         });
//     } else {
//       console.error("No orgId found in APIData");
//       setError("Organization ID not found");
//       setLoading(false);
//     }
//   }, []); // Empty array to run only once when the component mounts

//   if (loading) return <SoftTypography variant="h6" color="secondary" textAlign="center">Loading...</SoftTypography>;
//   if (error) return <SoftTypography variant="h6" color="error" textAlign="center">{error}</SoftTypography>;

//   return (
//     <>
//       <Header />
//       <SoftBox maxWidth="600px" margin="auto" padding="20px" backgroundColor="white" borderRadius="10px" boxShadow={3} mt={10} mb={10}>
//         <SoftTypography variant="h4" color="primary" textAlign="center" gutterBottom>
//           About Us
//         </SoftTypography>
//         {orgDetails ? (
//           <SoftBox textAlign="center">
//             <SoftAvatar src={orgDetails.orgLogo} alt={orgDetails.orgName} size="xl" variant="rounded" />
//             <SoftTypography variant="h5" fontWeight="bold" color="dark">
//               {orgDetails.orgName || "Organization Name"}
//             </SoftTypography>
//             <SoftTypography variant="body2" color="textSecondary" mt={2}>
//               {orgDetails.about ? orgDetails.about.join(" ") : "No description available."}
//             </SoftTypography>
//           </SoftBox>
//         ) : (
//           <SoftTypography variant="body2" color="textSecondary" textAlign="center">
//             No details available.
//           </SoftTypography>
//         )}
//       </SoftBox>
//       <Footers />
//     </>
//   );
// }

// import { useEffect, useState } from "react";
// import { APIData } from "authentication/APIData";
// import axios from "axios";
// import { org } from "authentication/APIData";
// import Header from "./Mheader";
// import Footers from "./Footers";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftAvatar from "components/SoftAvatar";
// import PageLayout from "examples/LayoutContainers/PageLayout";
// import male from "assets/images/male.jpg";
// import female from "assets/images/female.jpeg";
// import notDisclosed from "assets/images/notdisclosed.webp";
// import Navbarrr from "./Navbarrr";
// import SocialSidebar from "./SocialSidebar";
// export default function AboutUs() {
//   const [orgDetails, setOrgDetails] = useState(null);
//   const [teamMembers, setTeamMembers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const orgId = org; // Get orgId dynamically from APIData
//     console.log("Fetching data for orgId:", orgId);

//     // Create an array of promises for parallel API calls
//     const fetchPromises = [
//       // Fetch organization details
//       axios.get(`${APIData.api}org-mdm/org-id?orgId=${orgId}`, {
//         headers: APIData.headers,
//       }),

//       // Fetch team members
//       axios.get(`https://qa-api.care4edu.com/c4e/employee/details?org=${orgId}`, {
//         headers: APIData.headers,
//       })
//     ];

//     if (orgId) {
//       // Execute all API calls in parallel
//       Promise.all(fetchPromises)
//         .then(([orgResponse, membersResponse]) => {
//           console.log("Organization API Response:", orgResponse.data);
//           console.log("Members API Response:", membersResponse.data);

//           setOrgDetails(orgResponse.data);
//           setTeamMembers(membersResponse.data || []);
//           setLoading(false);
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//           setError("Failed to fetch data");
//           setLoading(false);
//         });
//     } else {
//       console.error("No orgId found in APIData");
//       setError("Organization ID not found");
//       setLoading(false);
//     }
//   }, []);

//   return (
//     <PageLayout>
//       <Navbarrr />
//       <SocialSidebar />
//       <SoftBox
//         sx={{
//           width: "100%",
//           padding: "4rem 2rem",
//           backgroundColor: "white",
//           minHeight: "60vh",
//           marginTop: "4rem", 
//         }}
//       >
//         <SoftBox
//           maxWidth="1200px"
//           margin="auto"
//           display="flex"
//           flexDirection={{ xs: "column", md: "row" }}
//           alignItems="center"
//           justifyContent="space-between"
//           gap={4}
//         >
//           <SoftBox
//             width={{ xs: "100%", md: "40%" }}
//             display="flex"
//             justifyContent="center"
//           >
//             {orgDetails && (
//               <SoftAvatar
//                 src={orgDetails.orgLogo}
//                 alt={orgDetails.orgName}
//                 variant="rounded"
//                 size="xxl"
//                 sx={{ width: "300px", height: "300px" }}
//               />
//             )}
//           </SoftBox>
//           <SoftBox width={{ xs: "100%", md: "60%" }}>
//             <SoftTypography variant="h2" fontWeight="bold" color="blue" mb={2}>
//               About Us
//             </SoftTypography>
//             {orgDetails ? (
//               <>
//                 <SoftTypography sx={{ variant: "h3", fontWeight: "bold", color: "#00bcd4", mb: "4" }}>
//                   We at {orgDetails.orgName}
//                 </SoftTypography>

//                 <SoftTypography variant="body1" color="blue" mb={3}>
//                 {orgDetails.about && orgDetails.about.length > 0
//                     ? orgDetails.about.join(" ")
//                     : "No info available"}
//                 </SoftTypography>
//               </>
//             ) : (
//               <SoftTypography variant="body1" color="white">
//                 No organization details available.
//               </SoftTypography>
//             )}
//           </SoftBox>
//         </SoftBox>
//       </SoftBox>
//       <SoftBox
//         sx={{
//           width: "100%",
//           padding: "4rem 2rem",
//           backgroundColor: "#001842", 
//           minHeight: "60vh",
//         }}
//       >
//         <SoftBox maxWidth="1200px" margin="auto">
//           <SoftBox textAlign="center" mb={6}>
//             <SoftTypography variant="h2" fontWeight="bold" color="white" display="inline">
//               Our <SoftTypography variant="h2" fontWeight="bold" color="#00bcd4" display="inline">Members</SoftTypography>
//             </SoftTypography>
//           </SoftBox>
//           <SoftBox
//             display="grid"
//             gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
//             gap={4}
//           >
//             {teamMembers.length > 0 ? (
//               teamMembers.map((member, index) => (
//                 <SoftBox
//                   key={index}
//                   backgroundColor="#001842"
//                   borderRadius="lg"
//                   textAlign="center"
//                   p={3}
//                   boxShadow="0 8px 12px 0 rgba(0,0,0,0.2)"
//                 >
//                   <SoftBox
//                     display="flex"
//                     justifyContent="center"
//                     mb={2}
//                   >
//                     <SoftAvatar
//                       src={
//                         member.photoUrl
//                           ? member.photoUrl
//                           : member.gender === "Female"
//                             ? female
//                             : member.gender === "Male"
//                               ? male
//                               : notDisclosed
//                       }
//                       alt={member.name}
//                       size="xxl"
//                       variant="circular"
//                       sx={{
//                         width: "150px",
//                         height: "150px",
//                         border: "2px solid white",
//                         borderRadius: "50%",
//                         objectFit: "cover",   
//                         objectPosition: "center center", 
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "center",
//                         overflow: "hidden"
//                       }}
//                     />
//                   </SoftBox>
//                   <SoftTypography variant="h5" color="white" sx={{ fontWeight: "bold" }}>
//                     {member.name}
//                   </SoftTypography>
//                   <SoftTypography variant="body2" color="white">
//                     {member.role_privileges?.length > 0
//                       ? member.role_privileges[member.role_privileges.length - 1].role
//                       : ""}
//                   </SoftTypography>
//                 </SoftBox>
//               ))
//             ) : (
//               <SoftBox gridColumn="span 4" textAlign="center">
//                 <SoftTypography variant="body1" color="white">
//                   No team members found.
//                 </SoftTypography>
//               </SoftBox>
//             )}
//           </SoftBox>
//         </SoftBox>
//       </SoftBox>
//       <Footers />
//     </PageLayout>
//   );
// }

import { useEffect, useState } from "react";
import { APIData } from "authentication/APIData";
import axios from "axios";
import { org } from "authentication/APIData";
import Header from "./Mheader";
import Footers from "./Footers";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import PageLayout from "examples/LayoutContainers/PageLayout";
import male from "assets/images/male.jpg";
import female from "assets/images/female.jpeg";
import notDisclosed from "assets/images/notdisclosed.webp";
import Navbarrr from "./Navbarrr";
import SocialSidebar from "./SocialSidebar";
import Skeleton from "@mui/material/Skeleton";

export default function AboutUs() {
  const [orgDetails, setOrgDetails] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const orgId = org; // Get orgId dynamically from APIData
    console.log("Fetching data for orgId:", orgId);

    // Create an array of promises for parallel API calls
    const fetchPromises = [
      // Fetch organization details
      axios.get(`${APIData.api}org-mdm/org-id?orgId=${orgId}`, {
        headers: APIData.headers,
      }),

      // Fetch team members
      axios.get(`https://qa-api.care4edu.com/c4e/employee/details?org=${orgId}`, {
        headers: APIData.headers,
      })
    ];

    if (orgId) {
      // Execute all API calls in parallel
      Promise.all(fetchPromises)
        .then(([orgResponse, membersResponse]) => {
          console.log("Organization API Response:", orgResponse.data);
          console.log("Members API Response:", membersResponse.data);

          setOrgDetails(orgResponse.data);
          setTeamMembers(membersResponse.data || []);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setError("Failed to fetch data");
          setLoading(false);
        });
    } else {
      console.error("No orgId found in APIData");
      setError("Organization ID not found");
      setLoading(false);
    }
  }, []);

  // Skeleton for Organization Logo
  const OrganizationLogoSkeleton = () => (
    <Skeleton
      variant="rounded"
      width={300}
      height={300}
      animation="wave"
      sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }}
    />
  );

  // Skeleton for About Us Text
  const AboutUsTextSkeleton = () => (
    <>
      <Skeleton variant="text" width="80%" height={60} animation="wave" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', mb: 2 }} />
      <Skeleton variant="text" width="60%" height={40} animation="wave" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', mb: 2 }} />
      <Skeleton variant="text" width="100%" height={20} animation="wave" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
      <Skeleton variant="text" width="95%" height={20} animation="wave" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
      <Skeleton variant="text" width="90%" height={20} animation="wave" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
    </>
  );

  // Skeleton for Team Member Card
  const TeamMemberSkeleton = () => (
    <SoftBox
      backgroundColor="#001842"
      borderRadius="lg"
      textAlign="center"
      p={3}
      boxShadow="0 8px 12px 0 rgba(0,0,0,0.2)"
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        mb={2}
      >
        <Skeleton
          variant="circular"
          width={150}
          height={150}
          animation="wave"
          sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }}
        />
      </SoftBox>
      <Skeleton variant="text" width="70%" height={30} animation="wave" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', margin: "0 auto", mb: 1 }} />
      <Skeleton variant="text" width="50%" height={20} animation="wave" sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', margin: "0 auto" }} />
    </SoftBox>
  );

  return (
    <PageLayout>
      <Navbarrr />
      <SocialSidebar />
      <SoftBox
        sx={{
          width: "100%",
          padding: "4rem 2rem",
          backgroundColor: "white",
          minHeight: "60vh",
          marginTop: "4rem", 
        }}
      >
        <SoftBox
          maxWidth="1200px"
          margin="auto"
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={4}
        >
          <SoftBox
            width={{ xs: "100%", md: "40%" }}
            display="flex"
            justifyContent="center"
          >
            {loading ? (
              <OrganizationLogoSkeleton />
            ) : (
              orgDetails && (
                <SoftAvatar
                  src={orgDetails.orgLogo}
                  alt={orgDetails.orgName}
                  variant="rounded"
                  size="xxl"
                  sx={{ width: "300px", height: "300px" }}
                />
              )
            )}
          </SoftBox>
          <SoftBox width={{ xs: "100%", md: "60%" }}>
            <SoftTypography variant="h2" fontWeight="bold" color="blue" mb={2}>
              About Us
            </SoftTypography>
            {loading ? (
              <AboutUsTextSkeleton />
            ) : (
              orgDetails ? (
                <>
                  <SoftTypography sx={{ variant: "h3", fontWeight: "bold", color: "#00bcd4", mb: "4" }}>
                    We at {orgDetails.orgName}
                  </SoftTypography>

                  <SoftTypography variant="body1" color="blue" mb={3}>
                    {orgDetails.about && orgDetails.about.length > 0
                      ? orgDetails.about.join(" ")
                      : "No info available"}
                  </SoftTypography>
                </>
              ) : (
                <SoftTypography variant="body1" color="white">
                  No organization details available.
                </SoftTypography>
              )
            )}
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox
        sx={{
          width: "100%",
          padding: "4rem 2rem",
          backgroundColor: "#001842", 
          minHeight: "60vh",
        }}
      >
        <SoftBox maxWidth="1200px" margin="auto">
          <SoftBox textAlign="center" mb={6}>
            <SoftTypography variant="h2" fontWeight="bold" color="white" display="inline">
              Our <SoftTypography variant="h2" fontWeight="bold" color="#00bcd4" display="inline">Members</SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftBox
            display="grid"
            gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
            gap={4}
          >
            {loading ? (
              // Show skeleton team members (4 placeholders)
              Array.from(new Array(8)).map((_, index) => (
                <TeamMemberSkeleton key={index} />
              ))
            ) : (
              teamMembers.length > 0 ? (
                teamMembers.map((member, index) => (
                  <SoftBox
                    key={index}
                    backgroundColor="#001842"
                    borderRadius="lg"
                    textAlign="center"
                    p={3}
                    boxShadow="0 8px 12px 0 rgba(0,0,0,0.2)"
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="center"
                      mb={2}
                    >
                      <SoftAvatar
                        src={
                          member.photoUrl
                            ? member.photoUrl
                            : member.gender === "Female"
                              ? female
                              : member.gender === "Male"
                                ? male
                                : notDisclosed
                        }
                        alt={member.name}
                        size="xxl"
                        variant="circular"
                        sx={{
                          width: "150px",
                          height: "150px",
                          border: "2px solid white",
                          borderRadius: "50%",
                          objectFit: "cover",   
                          objectPosition: "center center", 
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden"
                        }}
                      />
                    </SoftBox>
                    <SoftTypography variant="h5" color="white" sx={{ fontWeight: "bold" }}>
                      {member.name}
                    </SoftTypography>
                    <SoftTypography variant="body2" color="white">
                      {member.role_privileges?.length > 0
                        ? member.role_privileges[member.role_privileges.length - 1].role
                        : ""}
                    </SoftTypography>
                  </SoftBox>
                ))
              ) : (
                <SoftBox gridColumn="span 4" textAlign="center">
                  <SoftTypography variant="body1" color="white">
                    No team members found.
                  </SoftTypography>
                </SoftBox>
              )
            )}
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <Footers />
    </PageLayout>
  );
}