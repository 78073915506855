import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftInput from "components/SoftInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { APIData } from 'authentication/APIData';
import PropTypes from 'prop-types';
import { org } from 'authentication/APIData';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlinePlusCircle } from "react-icons/ai";
import {
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Divider
} from '@mui/material';
import SoftButton from 'components/SoftButton';
import Swal from 'sweetalert2';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const ProductImage = ({ image, productName, onClick }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={image || '/img/product-placeholder.jpg'}
                alt={productName}
                size="sm"
                variant="rounded"
                style={{ cursor: 'pointer' }}
                onClick={onClick}
            />
        </SoftBox>
        <SoftTypography variant="button" fontWeight="medium">
            {productName}
        </SoftTypography>
    </SoftBox>
);

ProductImage.propTypes = {
    image: PropTypes.string,
    productName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const ProductList = () => {
    const [productData, setProductData] = useState([]);
    const [openImagePreview, setOpenImagePreview] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(accessLevel);
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);

    // Update the useEffect for fetching product data with the correct endpoint
    useEffect(() => {
        const fetchProductData = async () => {
            // Updated URL to fetch products instead of login-type/Details
            const url = `${APIData.api}product-catalogue/?org=${org}`;
            try {
                const response = await fetch(url, { headers: APIData.headers });

                // Check if response is ok
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                console.log('Fetched product data:', data);

                // Add default values for any missing fields to prevent toLowerCase() errors
                const processedData = data.map(product => ({
                    pid: product.pid || 0,
                    pname: product.pname || '',
                    ptype: product.ptype || '',
                    psubtype: product.psubtype || '',
                    softwareUrl: product.softwareUrl || '',
                    image: product.image || null,
                    description: product.description || '',
                    org: product.org || ''
                }));

                setProductData(processedData);
            } catch (error) {
                console.error('Error fetching product data:', error);
                // Set empty array if there's an error
                setProductData([]);
                // Optionally add user feedback about fetch failure
                Swal.fire('Error', 'Failed to fetch product data. Please try again later.', 'error');
            }
        };

        fetchProductData();
    }, []);

    const handleViewDetails = async (productId) => {
        try {
            // For now we'll use the product from state
            const product = productData.find(p => p.pid === productId);
            if (product) {
                setSelectedProduct(product);
                setOpenDetailsDialog(true);
            } else {
                Swal.fire('Error', 'Product not found', 'error');
            }
        } catch (error) {
            console.error('Error fetching product details:', error);
            Swal.fire('Error', 'Failed to fetch product details', 'error');
        }
    };

    const ProductDetailsDialog = () => (
        <Dialog
            open={openDetailsDialog}
            onClose={() => setOpenDetailsDialog(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <SoftTypography variant="h6">Product Details</SoftTypography>
            </DialogTitle>
            <DialogContent>
                {selectedProduct && (
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} mb={2}>
                            <SoftBox display="flex" justifyContent="center">
                                <img
                                    src={selectedProduct.image || '/img/product-placeholder.jpg'}
                                    alt={selectedProduct.pname}
                                    style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }}
                                />
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={6}>
                            <SoftTypography variant="subtitle2" color="text">Product Name</SoftTypography>
                            <SoftTypography variant="body2">{selectedProduct.pname}</SoftTypography>
                        </Grid>
                        <Grid item xs={6}>
                            <SoftTypography variant="subtitle2" color="text">Type</SoftTypography>
                            <SoftTypography variant="body2">{selectedProduct.ptype}</SoftTypography>
                        </Grid>
                        <Grid item xs={6}>
                            <SoftTypography variant="subtitle2" color="text">Subtype</SoftTypography>
                            <SoftTypography variant="body2">{selectedProduct.psubtype}</SoftTypography>
                        </Grid>
                        {/* Only show URL field if product type is software */}
                        {selectedProduct.ptype && selectedProduct.ptype.toLowerCase() === 'software' && (
                            <Grid item xs={6}>
                                <SoftTypography variant="subtitle2" color="text">Url</SoftTypography>
                                <SoftTypography variant="body2">{selectedProduct.softwareUrl || '-'}</SoftTypography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <SoftTypography variant="subtitle2" color="text">Description</SoftTypography>
                            <SoftTypography variant="body2">{selectedProduct.description || '-'}</SoftTypography>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <SoftButton onClick={() => setOpenDetailsDialog(false)} color="secondary">
                    Close
                </SoftButton>
            </DialogActions>
        </Dialog>
    );

    const canEditDelete = (product) => {
        return true;
    };

    const handleDelete = (productId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete this product?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                const deleteUrl = `${APIData.api}product-catalogue/delete?id=${productId}`;
                fetch(deleteUrl, { method: 'DELETE', headers: APIData.headers })
                    .then(response => {
                        if (response.ok) {
                            Swal.fire('Deleted!', 'Your product has been deleted.', 'success');
                            setProductData(productData.filter(product => product.pid !== productId));
                        } else {
                            Swal.fire('Failed!', 'There was an error deleting the product.', 'error');
                        }
                    })
                    .catch((error) => {
                        console.error('Error deleting product:', error);
                        Swal.fire('Error!', 'There was an error deleting the product.', 'error');
                    });
            }
        });
    };

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl || '/img/product-placeholder.jpg');
        setOpenImagePreview(true);
    };

    const handleNewProductClick = () => {
        navigate("/product-form");
    };

    const columns = [
        { name: "Product Name", align: "left" },
        { name: "Type", align: "left" },
        { name: "Subtype", align: "left" },
        { name: "Action", align: "center" },
    ];

    // Safe search function to handle potentially undefined values
    const safeSearch = (value) => {
        if (value === null || value === undefined) return '';
        return String(value).toLowerCase();
    };

    // Fixed filtering logic to safely handle potentially undefined values
    const filteredProducts = productData.filter(product => {
        if (!searchTerm) return true;

        const searchLower = searchTerm.toLowerCase();
        return (
            safeSearch(product.pname).includes(searchLower) ||
            safeSearch(product.ptype).includes(searchLower) ||
            safeSearch(product.psubtype).includes(searchLower) ||
            safeSearch(product.color).includes(searchLower) ||
            safeSearch(product.size).includes(searchLower)
        );
    });

    const paginatedProducts = filteredProducts.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const rows = paginatedProducts.map((product) => ({
        "Product Name": <ProductImage image={product.image} productName={product.pname || 'Unnamed Product'} onClick={() => handleImageClick(product.image)} />,
        Type: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {product.ptype || '-'}
            </SoftTypography>
        ),
        Subtype: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {product.psubtype || '-'}
            </SoftTypography>
        ),
        Action: (
            <SoftBox display="flex" justifyContent="center">
                <SoftTypography
                    component="span"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    mr={2}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleViewDetails(product.pid)}
                >
                    View
                </SoftTypography>
                {canEditDelete(product) && (
                    <>
                        <Link to={`/edit-product/${product.pid}`}>
                            {(permission === "1110" || permission === "1111" || isSuperAdmin) ? (
                                <SoftTypography
                                    component="span"
                                    variant="button"
                                    color="warning"
                                    fontWeight="medium"
                                    mr={2}
                                >
                                    Edit
                                </SoftTypography>
                            ) : null}
                        </Link>
                        {(permission === "1111" || isSuperAdmin) ? <SoftTypography
                            component="span"
                            variant="button"
                            color="error"
                            fontWeight="medium"
                            onClick={() => handleDelete(product.pid)}
                            style={{ cursor: 'pointer' }}
                        >
                            Delete
                        </SoftTypography> : ""}
                    </>
                )}
            </SoftBox>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Product List</SoftTypography>
                            <SoftBox display="flex" alignItems="center" gap={2}>
                                <SoftBox>
                                    <SoftInput
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        size="small"
                                    />
                                </SoftBox>
                                {(permission === "1100" || permission === "1110" || permission === "1111" || isSuperAdmin) ?
                                    <Tooltip title="Add New Product" arrow>
                                        <SoftButton variant="gradient" color="info" size="small" onClick={handleNewProductClick} startIcon={<AiOutlinePlusCircle />}>
                                            Add
                                        </SoftButton>
                                    </Tooltip> : ""}
                            </SoftBox>
                        </SoftBox>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={columns} rows={rows} />
                            <SoftBox
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                                p={3}
                            >
                                <SoftBox display="flex" gap={1} alignItems="center">
                                    <SoftTypography variant="caption" color="secondary">
                                        {filteredProducts.length > 0 ?
                                            `${page * itemsPerPage + 1}-${Math.min((page + 1) * itemsPerPage, filteredProducts.length)} of ${filteredProducts.length}` :
                                            'No products found'}
                                    </SoftTypography>
                                    <SoftButton
                                        variant="text"
                                        color="info"
                                        onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                        disabled={page === 0 || filteredProducts.length === 0}
                                        iconOnly
                                    >
                                        <KeyboardArrowLeft />
                                    </SoftButton>
                                    <SoftButton
                                        variant="text"
                                        color="info"
                                        onClick={() => setPage(prev => Math.min(Math.ceil(filteredProducts.length / itemsPerPage) - 1, prev + 1))}
                                        disabled={page >= Math.ceil(filteredProducts.length / itemsPerPage) - 1 || filteredProducts.length === 0}
                                        iconOnly
                                    >
                                        <KeyboardArrowRight />
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />

            {/* Image Preview Dialog */}
            <Dialog
                open={openImagePreview}
                onClose={() => setOpenImagePreview(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    <img
                        src={selectedImage}
                        alt="Preview"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </DialogContent>
            </Dialog>

            <ProductDetailsDialog />
        </DashboardLayout>
    );
};

export default ProductList;