import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { APIData } from 'authentication/APIData';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from 'components/SoftButton';
import SoftInput from "components/SoftInput";
import { Card, Grid, Tooltip, FormControl, FormHelperText, MenuItem } from "@mui/material";
import * as AiIcons from "react-icons/ai";
import Swal from 'sweetalert2';
import axios from 'axios';
import { org } from 'authentication/APIData';

const EditProduct = () => {
    const { pid } = useParams();
    const [productDetails, setProductDetails] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const productTypes = ['SOFTWARE', 'HARDWARE', 'SERVICE'];

    useEffect(() => {
        const fetchProductDetails = async () => {
            const url = `${APIData.api}product-catalogue/id?id=${pid}`;
            try {
                const response = await fetch(url, { headers: APIData.headers });
                if (response.ok) {
                    const data = await response.json();
                    setProductDetails(data);
                    if (data.image) setImagePreview(data.image);
                } else {
                    Swal.fire('Error', 'Failed to fetch product details', 'error');
                }
            } catch (error) {
                console.error('Error fetching product details:', error);
                Swal.fire('Error', 'Failed to fetch product details', 'error');
            }
        };

        fetchProductDetails();
    }, [pid]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductDetails(prevDetails => ({ ...prevDetails, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: '' }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 1000000) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "File too large. Max file size is 1MB.",
                });
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
                setProductDetails(prev => ({ ...prev, image: reader.result }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        const newErrors = {};
        
        // Validation
        if (!productDetails.pname || /^\d+$/.test(productDetails.pname)) {
            newErrors.pname = "Product name is required and must not be only numbers.";
        }
        if (!productDetails.ptype) {
            newErrors.ptype = "Product type is required.";
        }
        
        // Removed validation requirements for psubtype and softwareurl
        
        if (productDetails.description && (/^\d+$/.test(productDetails.description) || productDetails.description.length > 500)) {
            newErrors.description = "Description must not be only numbers and must not exceed 500 characters.";
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please fix the following errors and try again:',
                footer: Object.values(newErrors).join('<br>')
            });
            return;
        }

        setLoading(true);

        // Create FormData object like in the EventEdit component
        const formData = new FormData();
        formData.append('pid', pid);
        formData.append('pname', productDetails.pname);
        formData.append('ptype', productDetails.ptype);
        formData.append('org', org);
        
        // Always include these fields but use empty string if not provided
        formData.append('psubtype', productDetails.psubtype || '');
        formData.append('softwareurl', productDetails.softwareurl || '');
        
        // Add optional fields
        formData.append('description', productDetails.description || '');
        
        // Add image if available
        if (imagePreview) {
            formData.append('image', imagePreview);
        }

        // Using the same URL pattern as in the EventEdit component
        const putUrl = `${APIData.api}product-catalogue/?pid=${pid}&org=${org}`;

        try {
            const response = await axios.put(putUrl, formData, {
                headers: { 
                    ...APIData.headers, 
                    'Content-Type': 'multipart/form-data' 
                },
            });

            if (response.status === 200 || response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Product details updated successfully',
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(() => {
                    navigate('/products');
                }, 1500);
            } else {
                Swal.fire('Error', 'Failed to update product details', 'error');
            }
        } catch (error) {
            console.error('Error updating product:', error);
            Swal.fire('Error', 'Failed to update product details', 'error');
        }
        setLoading(false);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4" mt={3}>
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Tooltip title="Close" arrow>
                                <Link to="/products">
                                    <AiIcons.AiOutlineCloseCircle />
                                </Link>
                            </Tooltip>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Edit Product
                            </SoftTypography>
                        </SoftBox>
                        {productDetails ? (
                            <SoftBox component="form" role="form" onSubmit={handleSaveChanges} px={3}>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Product Name *</SoftTypography>
                                    <FormControl fullWidth error={!!errors.pname}>
                                        <SoftInput
                                            placeholder="Product Name"
                                            name="pname"
                                            value={productDetails.pname || ''}
                                            onChange={handleInputChange}
                                        />
                                        {errors.pname && (
                                            <FormHelperText>{errors.pname}</FormHelperText>
                                        )}
                                    </FormControl>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Product Type *</SoftTypography>
                                    <FormControl fullWidth error={!!errors.ptype}>
                                        <SoftInput
                                            select
                                            name="ptype"
                                            value={productDetails.ptype || ''}
                                            onChange={handleInputChange}
                                        >
                                            {productTypes.map((type) => (
                                                <MenuItem key={type} value={type}>
                                                    {type}
                                                </MenuItem>
                                            ))}
                                        </SoftInput>
                                        {errors.ptype && (
                                            <FormHelperText>{errors.ptype}</FormHelperText>
                                        )}
                                    </FormControl>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Product Subtype</SoftTypography>
                                    <FormControl fullWidth error={!!errors.psubtype}>
                                        <SoftInput
                                            placeholder="Product Subtype"
                                            name="psubtype"
                                            value={productDetails.psubtype || ''}
                                            onChange={handleInputChange}
                                        />
                                        {errors.psubtype && (
                                            <FormHelperText>{errors.psubtype}</FormHelperText>
                                        )}
                                    </FormControl>
                                </SoftBox>

                                {productDetails.ptype === 'SOFTWARE' && (
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Software URL</SoftTypography>
                                        <FormControl fullWidth error={!!errors.softwareurl}>
                                            <SoftInput
                                                placeholder="Software URL"
                                                name="softwareurl"
                                                value={productDetails.softwareurl || ''}
                                                onChange={handleInputChange}
                                            />
                                            {errors.softwareurl && (
                                                <FormHelperText>{errors.softwareurl}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </SoftBox>
                                )}

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Description</SoftTypography>
                                    <FormControl fullWidth>
                                        <SoftInput
                                            multiline
                                            rows={4}
                                            placeholder="Enter product description..."
                                            name="description"
                                            value={productDetails.description || ''}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="product-image"
                                            type="file"
                                            onChange={handleImageChange}
                                        />
                                        <label htmlFor="product-image">
                                            <SoftButton variant="contained" component="span">
                                                Upload Product Image
                                            </SoftButton>
                                        </label>
                                        {imagePreview && (
                                            <SoftBox mt={2}>
                                                <img
                                                    src={imagePreview}
                                                    alt="Product Preview"
                                                    style={{ width: '300px', height: 'auto', objectFit: 'contain' }}
                                                />
                                            </SoftBox>
                                        )}
                                    </SoftBox>
                                </SoftBox>

                                <SoftBox mt={4} mb={1}>
                                    <SoftButton
                                        type="submit"
                                        variant="gradient"
                                        color="info"
                                        fullWidth
                                        disabled={loading}
                                    >
                                        {loading ? "Saving Changes..." : "Save Changes"}
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        ) : (
                            <SoftBox p={3} className="text-center">
                                <SoftTypography variant="h6" color="text">
                                    Loading product details...
                                </SoftTypography>
                            </SoftBox>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default EditProduct;