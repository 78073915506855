// import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
// import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";
// import Logo from "../Image/Logo.png";
// import { RiLoginCircleFill } from "react-icons/ri";
// import NavbarSecondary from "./NavbarSecondary";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import { Paper } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   title: {
//     flexGrow: 1,
//   },
//   loginButton: {
//     fontSize: "1rem",
//     fontWeight: "bold",
//     color: "white",
//     backgroundColor: "#de1818",
//     borderRadius: "8px",
//     padding: "8px 16px",
//     transition: "background-color 0.3s ease",
//     '&:hover': {
//       backgroundColor: "red",
//       cursor: "pointer"
//     },
//     [theme.breakpoints.down('sm')]: {
//       fontSize: "0.8rem",
//       padding: "6px 12px", 
//       borderRadius: "6px", 
//     },
//   },
//   menu: {
//     marginTop: "40px",
//   },
//   menuItem: {
//     fontSize: "1rem",
//     fontFamily: "Roboto Slab",

//     '&:hover': {
//       backgroundColor: "#E90909",
//       color:"white"
//     },
//     [theme.breakpoints.down('sm')]: {
//       fontSize: "0.9rem",
//     },
//   },
// }));

// export default function Navbarrr() {
//   const classes = useStyles();
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleLoginClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <>
//       <div className={classes.root} style={{ height: "7rem"}}>
//         <AppBar position="fixed" variant="outlined" color="white">
//           <paper >
//           <Toolbar style={{background:"linear-gradient(to right,#edeff2,rgba(252,0,0,0.1),#edeff2)"}}>
//           {/* style={{background:"linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1))"}} */}
//             <Link to='/'>
//             <img
//               src={Logo}
//               alt="Logo"
//               height={"60"}
//               width={"165"}
//               style={{ paddingTop: "5px" , paddingBottom:"10px"}}
//             /></Link>
//             <Typography variant="h6" className={classes.title}></Typography>
//             <div>
//               <span
//                 aria-controls="login-menu"
//                 aria-haspopup="true"
//                 onClick={handleLoginClick}
//                 className={classes.loginButton}
//               >
//                 <span style={{ fontFamily: "Roboto Slab" }}>Login</span>
//                 <RiLoginCircleFill />
//               </span>
//             </div>
//             <Menu
//               id="login-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//               className={classes.menu}
//             >
//               <MenuItem component={Link} to="/institutionalSignIn" className={classes.menuItem}>
//                 Organization Login
//               </MenuItem>
//               <MenuItem component={Link} to="/user-signin" className={classes.menuItem}>
//                 Customer Login
//               </MenuItem>
//               {/* Add more menu items as needed */}
//             </Menu>
//           </Toolbar>

//           </paper>

//           <NavbarSecondary />

//         </AppBar>
//       </div>
//     </>
//   );
// }

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import { RiLoginCircleFill } from "react-icons/ri";
import NavbarSecondary from "./NavbarSecondary";
import { Link } from "react-router-dom";
import Header from "./Mheader";
import { APIData } from "authentication/APIData";
import { org } from "authentication/APIData";
import SoftButton from "components/SoftButton";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

// Styled components using MUI v5 syntax

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "1rem",
  fontFamily: "Roboto Slab",
  "&:hover": {
    backgroundColor: "#64bfed",
    color: "white",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9rem",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  background: "linear-gradient(to right,#edeff2,rgba(252,0,0,0.1),#edeff2)",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 2),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0, 1),
  },
}));

const LogoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(0.5),
  },
}));

const LogoText = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: "700",
  backgroundImage: "linear-gradient(to right, #6a0dad, #1e90ff)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));

export default function Navbarrr() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [orgLogo, setOrgLogo] = useState();
  const [orgname, setOrgname] = useState();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleLoginClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    fetchOrgData();
  }, []);
  const fetchOrgData = async () => {
    try {
      const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
      if (!response.ok) {
        throw new Error("Failed to fetch organization data");
      }
      const data = await response.json();
      // console.log(data);
      setOrgname(data.orgShortName)
      setOrgLogo(data.orgLogo);

    } catch (error) {
      console.error("Error fetching organization data:", error);
    }
  };
  return (
    <div style={{ height: isMobile ? "6rem" : "7rem" }}>
      <AppBar position="fixed" color="default" variant="outlined">
        <Paper elevation={0}>
          <StyledToolbar>
            <Link to="/" style={{ textDecoration: "none", display: "flex", alignItems: "center" }}>
              <LogoBox>
                <img
                  src={orgLogo}
                  alt="Organization Logo"
                  height={isMobile ? 65 : 60}
                  width={isMobile ? 65 : 60}
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    objectFit: "contain",
                    maxWidth: "100%"
                  }}
                />
                <LogoText variant="h6">
                  {orgname}
                </LogoText>
              </LogoBox>
            </Link>
            <Typography variant="h6" sx={{ flexGrow: 1 }} />
            <SoftButton
              onClick={handleLoginClick}
              endIcon={<RiLoginCircleFill size={isMobile ? 18 : 20} />}
              variant="gradient"
              color="info"
              size={isMobile ? "small" : "medium"}
              sx={{
                fontSize: isMobile ? "0.8rem" : "0.875rem",
                padding: isMobile ? "6px 12px" : "6px 16px",
                minWidth: isMobile ? "80px" : "100px",
                maxHeight: isMobile ? "32px" : "36px",
                lineHeight: "1.2",
              }}
            >
              <span style={{ fontFamily: "Roboto Slab" }}>Login</span>
            </SoftButton>
            <Menu
              id="login-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{
                marginTop: isMobile ? "30px" : "40px",
                "& .MuiPaper-root": {
                  width: isMobile ? "180px" : "220px",
                }
              }}
            >
              <StyledMenuItem
                component={Link}
                to="/authentication/org-sign-in"
                onClick={handleMenuClose}
              >
                Organization Login
              </StyledMenuItem>
              <StyledMenuItem
                component={Link}
                to="/authentication/sign-in"
                onClick={handleMenuClose}
              >
                User Login
              </StyledMenuItem>
            </Menu>
          </StyledToolbar>
        </Paper>
        <Header />
      </AppBar>
    </div>
  );
}