// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import html2canvas from 'html2canvas';
// import axios from "axios";
// import SoftBox from 'components/SoftBox';
// import { Grid } from '@mui/material';
// import { APIData, org } from 'authentication/APIData';
// import SoftButton from 'components/SoftButton';
// import SoftTypography from 'components/SoftTypography';
// import CustomSelect from 'assets/theme/components/Select/CustomSelect';
// import PageLayout from 'examples/LayoutContainers/PageLayout';
// import IdCard1 from 'layouts/idCards/idCard1';
// import IdCard2 from 'layouts/idCards/idCard2';
// import IdCard3 from 'layouts/idCards/idCard3';
// import IdCard4 from 'layouts/idCards/idCard4';
// import IdCard5 from 'layouts/idCards/idCard5';


// function IdCard() {
//     const [selectedID, setSelectedID] = useState("");
//     const [showBadge, setShowBadge] = useState(false);
//     const [dob, setDob] = useState("");
//     const [name, setName] = useState("");
//     const [id, setId] = useState("");
//     const [photo, setPhoto] = useState("");
//     const [role, setRole] = useState("");
//     const [orgName, setOrgName] = useState("");
//     const [orgAddress, setOrgAddress] = useState("");
//     const [logoUrl, setLogoUrl] = useState("");
//     const [empdata, setEmpdata] = useState([]);
//     const badgeRef1 = useRef();
//     const badgeRef2 = useRef();
//     const badgeRef3 = useRef();
//     const badgeRef4 = useRef();
//     const badgeRef5 = useRef();

//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, [])
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const url = `${APIData.api}employee/details?org=${org}`;
//                 const response = await fetch(url, { headers: APIData.headers });
//                 const data = await response.json();
//                 setEmpdata(data);
//             } catch (err) {
//                 console.error(err);
//             }
//         };

//         const fetchIcon = async () => {
//             try {
//                 const response = await axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers });
//                 if (response.status === 200) {
//                     const { orgLogo, orgName, orgAddress } = response.data;
//                     setLogoUrl(orgLogo || "");
//                     setOrgName(orgName || "");
//                     setOrgAddress(orgAddress || "");
//                 } else {
//                     console.error("No data found.");
//                 }
//             } catch (error) {
//                 console.error("Error fetching icon:", error);
//             }
//         };

//         fetchData();
//         fetchIcon();
//     }, []);

//     const handleIDChange = (e) => {
//         const id = e.value;
//         setSelectedID(id);
//         setShowBadge(false);
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         const url = `${APIData.api}employee/${selectedID}`;
//         axios.get(url, { headers: APIData.headers })
//             .then((response) => {
//                 setName(response.data.name);
//                 setDob(response.data.dob);
//                 setId(response.data.employee_id);
//                 setPhoto(response.data.photoUrl);
//                 setRole(response.data.access_profiles);
//                 setShowBadge(true);
//             })
//             .catch((err) => console.error(err));
//     };

//     const uniqueEmployeeIds = [...new Set(empdata.map(item => item.employee_id))];

//     const downloadBadge = useCallback(async (badgeRef) => {
//         if (badgeRef.current) {
//             try {
//                 const canvas = await html2canvas(badgeRef.current, { scale: 2, useCORS: true });
//                 const dataUrl = canvas.toDataURL('image/png');
//                 const link = document.createElement('a');
//                 link.download = `badge_${id}.png`;
//                 link.href = dataUrl;
//                 link.click();
//             } catch (error) {
//                 console.error('Error generating badge:', error);
//             }
//         }
//     }, [id]);

//     return (
//      <PageLayout>
//             <div className="carrybox" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//                 <SoftTypography variant="h5" >Id Card Template auto generating</SoftTypography>
//                 <SoftBox mb={2} mt={2}>
//                     <SoftTypography variant="h6">Select Employee Id</SoftTypography>
//                     <CustomSelect
//                         options={uniqueEmployeeIds.map(id => ({ value: id, label: id }))}
//                         placeholder="Select Sales Type"
//                         name="salesType"
//                         onChange={handleIDChange}
//                         value={uniqueEmployeeIds.find(id => id === selectedID) ? { value: selectedID, label: selectedID } : null}
//                         required
//                     />
//                 </SoftBox>
//                 <SoftBox mt={2} mb={1}>
//                     <SoftButton onClick={handleSubmit} variant="gradient" color="info" fullWidth>
//                         Generate id Card
//                     </SoftButton>
//                 </SoftBox>
//             </div>

//             {showBadge && (
//                 <SoftBox mb={1.5}>
//                     <Grid item xs={12} lg={8}>
//                         <Grid container spacing={2}>
//                             <Grid item xs={12} md={6} xl={4}>
//                                 <div ref={badgeRef1} style={{ display: 'inline-block', padding: '0', margin: '0' }}>
//                                     <IdCard1
//                                         name={name}
//                                         dob={dob}
//                                         id={id}
//                                         photo={photo}
//                                         role={role}
//                                         orgName={orgName}
//                                         orgAddress={orgAddress}
//                                         logoUrl={logoUrl}
//                                     />
//                                 </div>
//                                 <SoftBox mt={2} mb={1}>
//                                     <SoftButton variant="gradient" color="secondary" onClick={() => downloadBadge(badgeRef1)}>Download ID Card 1</SoftButton>
//                                 </SoftBox>
//                             </Grid>
//                             <Grid item xs={12} md={6} xl={4}>
//                                 <div ref={badgeRef2} style={{ display: 'inline-block', padding: '0', margin: '0' }}>
//                                     <IdCard2
//                                         name={name}
//                                         dob={dob}
//                                         id={id}
//                                         photo={photo}
//                                         role={role}
//                                         orgName={orgName}
//                                         orgAddress={orgAddress}
//                                         logoUrl={logoUrl}
//                                     />
//                                 </div>
//                                 <SoftBox mt={2} mb={1}>
//                                     <SoftButton variant="gradient" color="secondary" onClick={() => downloadBadge(badgeRef2)}>Download ID Card 2</SoftButton>
//                                 </SoftBox>
//                             </Grid>
//                             <Grid item xs={12} md={6} xl={4}>
//                                 <div ref={badgeRef3} style={{ display: 'inline-block', padding: '0', margin: '0' }}>
//                                     <IdCard3
//                                         name={name}
//                                         dob={dob}
//                                         id={id}
//                                         photo={photo}
//                                         role={role}
//                                         orgName={orgName}
//                                         orgAddress={orgAddress}
//                                         logoUrl={logoUrl}
//                                     />
//                                 </div>
//                                 <SoftBox mt={2} mb={1}>
//                                     <SoftButton variant="gradient" color="secondary" onClick={() => downloadBadge(badgeRef3)}>Download ID Card 3</SoftButton>
//                                 </SoftBox>
//                             </Grid>
//                             <Grid item xs={12} md={6} xl={4}>
//                                 <div ref={badgeRef4} style={{ display: 'inline-block', padding: '0', margin: '0' }}>
//                                     <IdCard4
//                                         name={name}
//                                         dob={dob}
//                                         id={id}
//                                         photo={photo}
//                                         role={role}
//                                         orgName={orgName}
//                                         orgAddress={orgAddress}
//                                         logoUrl={logoUrl}
//                                     />
//                                 </div>
//                                 <SoftBox mt={2} mb={1}>
//                                     <SoftButton variant="gradient" color="secondary" onClick={() => downloadBadge(badgeRef4)}>Download ID Card 4</SoftButton>
//                                 </SoftBox>
//                             </Grid>
//                             <Grid item xs={12} md={6} xl={4}>
//                                 <div ref={badgeRef5} style={{ display: 'inline-block', padding: '0', margin: '0' }}>
//                                     <IdCard5
//                                         name={name}
//                                         dob={dob}
//                                         id={id}
//                                         photo={photo}
//                                         role={role}
//                                         orgName={orgName}
//                                         orgAddress={orgAddress}
//                                         logoUrl={logoUrl}
//                                     />
//                                 </div>
//                                 <SoftBox mt={2} mb={1}>
//                                     <SoftButton variant="gradient" color="secondary" onClick={() => downloadBadge(badgeRef5)}>Download ID Card 5</SoftButton>
//                                 </SoftBox>
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 </SoftBox>
//             )}
//       </PageLayout>
//     );
// }

// export default IdCard;


import React, { useState, useEffect, useRef, useCallback } from 'react';
import html2canvas from 'html2canvas';
import axios from "axios";
import SoftBox from 'components/SoftBox';
import { Grid, CircularProgress, Backdrop } from '@mui/material';
import { APIData, org } from 'authentication/APIData';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import IdCard1 from 'layouts/idCards/idCard1';
import IdCard2 from 'layouts/idCards/idCard2';
import IdCard3 from 'layouts/idCards/idCard3';
import IdCard4 from 'layouts/idCards/idCard4';
import IdCard5 from 'layouts/idCards/idCard5';
import Swal from 'sweetalert2';

function IdCard() {
    const [selectedID, setSelectedID] = useState("");
    const [showBadge, setShowBadge] = useState(false);
    const [dob, setDob] = useState("");
    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [photo, setPhoto] = useState("");
    const [role, setRole] = useState("");
    const [orgName, setOrgName] = useState("");
    const [orgAddress, setOrgAddress] = useState("");
    const [logoUrl, setLogoUrl] = useState("");
    const [empdata, setEmpdata] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hoveredTemplate, setHoveredTemplate] = useState(null);
    
    const badgeRef1 = useRef();
    const badgeRef2 = useRef();
    const badgeRef3 = useRef();
    const badgeRef4 = useRef();
    const badgeRef5 = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
        fetchOrgData();
    }, []);

    const fetchData = async () => {
        try {
            const url = `${APIData.api}employee/details?org=${org}`;
            const response = await fetch(url, { headers: APIData.headers });
            const data = await response.json();
            setEmpdata(data);
        } catch (err) {
            console.error(err);
        }
    };

    const fetchOrgData = async () => {
        try {
            const response = await axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, {
                headers: APIData.headers
            });
            if (response.status === 200) {
                const { orgLogo, orgName, orgAddress } = response.data;
                setLogoUrl(orgLogo || "");
                setOrgName(orgName || "");
                setOrgAddress(orgAddress || "");
            }
        } catch (error) {
            console.error("Error fetching organization data:", error);
        }
    };

    const handleIDChange = (e) => {
        const selectedName = e.value;
        const selectedEmployee = empdata.find(emp => emp.name === selectedName);
        
        if (selectedEmployee) {
            setSelectedID(selectedEmployee.employee_id);
            setShowBadge(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = `${APIData.api}employee/${selectedID}`;
        axios.get(url, { headers: APIData.headers })
            .then((response) => {
                setName(response.data.name);
                setDob(response.data.dob);
                setId(response.data.employee_id);
                setPhoto(response.data.photoUrl);
    
                // Ensure role is properly handled
                const fetchedRole = response.data.access_profiles; // Ensure fetchedRole is defined
                setRole(Array.isArray(fetchedRole) ? (fetchedRole[0] || "Unknown") : (typeof fetchedRole === "string" ? fetchedRole : "Unknown"));
    
                setShowBadge(true);
            })
            .catch((err) => console.error(err));
    };
    
    const handleTemplateSelect = async (templateNumber) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to select Template Number ${templateNumber}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, select it!',
            cancelButtonText: 'No, cancel',
        });

        if (result.isConfirmed) {
            setLoading(true);
            setIsSubmitting(true);
            setSelectedTemplate(templateNumber);

            try {
                const formData = new FormData();
                formData.append('org_id', org);
                formData.append('org_Id_card', templateNumber.toString());
                formData.append('orgSpocPosition', 'SUPERADMIN');
                formData.append('updated_by', JSON.parse(localStorage.getItem("sessiondetails"))?.userName || '');

                const response = await axios.put(
                    `${APIData.api}org-mdm/`,
                    formData,
                    {
                        headers: {
                            ...APIData.headers,
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );

                Swal.fire({
                    icon: "success",
                    title: "Template Selected Successfully",
                    text: "The ID card template has been selected",
                    showConfirmButton: false,
                    timer: 1500
                });

            } catch (error) {
                console.error('Error updating template:', error);
                Swal.fire({
                    icon: "error",
                    title: "Update Error",
                    text: error.response?.data?.message ||
                        error.response?.data?.error ||
                        "Error updating template selection"
                });
            } finally {
                setLoading(false);
                setIsSubmitting(false);
            }
        }
    };

    const downloadBadge = useCallback(async (badgeRef) => {
        if (badgeRef.current) {
            try {
                const canvas = await html2canvas(badgeRef.current, { scale: 2, useCORS: true });
                const dataUrl = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.download = `badge_${id}.png`;
                link.href = dataUrl;
                link.click();
            } catch (error) {
                console.error('Error generating badge:', error);
            }
        }
    }, [id]);

    // Create dropdown options with only names
    const uniqueEmployeeOptions = empdata.map(emp => ({
        value: emp.name, 
        label: emp.name
    }));

    const getTemplateStyles = (templateNumber) => {
        const isSelected = selectedTemplate === templateNumber;
        const isHovered = hoveredTemplate === templateNumber;
        
        return {
            display: 'inline-block',
            padding: '8px',
            margin: '4px',
            borderRadius: '8px',
            border: isSelected ? '3px solid #2196f3' : '1px solid #e0e0e0',
            boxShadow: isSelected 
                ? '0 8px 16px rgba(33, 150, 243, 0.3)'
                : isHovered 
                    ? '0 6px 12px rgba(0, 0, 0, 0.15)'
                    : '0 2px 4px rgba(0, 0, 0, 0.1)',
            transform: isSelected 
                ? 'scale(1.02)'
                : isHovered 
                    ? 'scale(1.01)'
                    : 'scale(1)',
            transition: 'all 0.3s ease',
            cursor: 'pointer',
            backgroundColor: isSelected ? 'rgba(33, 150, 243, 0.05)' : 'white',
        };
    };

    return (
        <PageLayout>
            <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            
            <div className="carrybox" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <SoftTypography variant="h5">Id Card Template Auto Generating</SoftTypography>
                <SoftBox mb={2} mt={2}>
                    <SoftTypography variant="h6">Select Employee</SoftTypography>
                    <CustomSelect
                        options={uniqueEmployeeOptions}
                        placeholder="Select Employee"
                        name="employeeName"
                        onChange={handleIDChange}
                        value={
                            selectedID 
                                ? uniqueEmployeeOptions.find(
                                    option => option.value === name
                                )
                                : null
                        }
                        required
                    />
                </SoftBox>
                <SoftBox mt={2} mb={1}>
                    <SoftButton onClick={handleSubmit} variant="gradient" color="info" fullWidth>
                        Generate ID Card
                    </SoftButton>
                </SoftBox>

                {showBadge && (
                    <SoftBox mt={2} mb={1} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }} >
                        <SoftTypography mb={2} variant="h4" fullWidth>
                            Select ID Card Template
                            <SoftTypography variant="body2" color="text">
                                Selected template will be used to generate ID cards for the entire organization in future.
                            </SoftTypography>
                        </SoftTypography>
                    </SoftBox>
                )}
            </div>

            {showBadge && (
                <SoftBox mb={1.5}>
                    <Grid container spacing={1} justifyContent="center">
                        {[1, 2, 3, 4, 5].map((templateNumber) => (
                            <Grid item xs={12} sm={4} md={3} key={templateNumber}>
                                <div
                                    ref={eval(`badgeRef${templateNumber}`)}
                                    style={getTemplateStyles(templateNumber)}
                                    onClick={() => handleTemplateSelect(templateNumber)}
                                    onMouseEnter={() => setHoveredTemplate(templateNumber)}
                                    onMouseLeave={() => setHoveredTemplate(null)}
                                >
                                    {templateNumber === 1 && <IdCard1 name={name} dob={dob} id={id} photo={photo} role={role} orgName={orgName} orgAddress={orgAddress} logoUrl={logoUrl} />}
                                    {templateNumber === 2 && <IdCard2 name={name} dob={dob} id={id} photo={photo} role={role} orgName={orgName} orgAddress={orgAddress} logoUrl={logoUrl} />}
                                    {templateNumber === 3 && <IdCard3 name={name} dob={dob} id={id} photo={photo} role={role} orgName={orgName} orgAddress={orgAddress} logoUrl={logoUrl} />}
                                    {templateNumber === 4 && <IdCard4 name={name} dob={dob} id={id} photo={photo} role={role} orgName={orgName} orgAddress={orgAddress} logoUrl={logoUrl} />}
                                    {templateNumber === 5 && <IdCard5 name={name} dob={dob} id={id} photo={photo} role={role} orgName={orgName} orgAddress={orgAddress} logoUrl={logoUrl} />}
                                </div>
                                <SoftBox mt={2} mb={1} display="flex" justifyContent="center">
                                    <SoftButton 
                                        variant="gradient" 
                                        color="secondary" 
                                        onClick={() => downloadBadge(eval(`badgeRef${templateNumber}`))}
                                        fullWidth
                                    >
                                        Download Template {templateNumber}
                                    </SoftButton>
                                </SoftBox>
                            </Grid>
                        ))}
                    </Grid>
                </SoftBox>
            )}
        </PageLayout>
    );
}

export default IdCard;